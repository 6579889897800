import axios from "axios";
import { requestHandler } from ".";
import { Customer, CustomerFragment } from "../../generated/storefront";

export const getCustomer = async ({ customerAccessToken }: { customerAccessToken: string }) => {
  try {
    const request = async () => await axios.post("/.netlify/functions/getCustomer", { customerAccessToken });
    const { customer }: { customer: CustomerFragment } = await requestHandler(request);
    return customer;
  } catch (error) {
    console.error(`Error getting customer: ${error}`);
    throw error;
  }
};

export const getCustomerByEmail = async ({ email }: { email: string }) => {
  try {
    const request = async () => await axios.post("/.netlify/functions/getCustomerByEmail", { email });
    const result: { customer: Customer; accountActivationUrl?: string } = await requestHandler(request);
    return result;
  } catch (error: any) {
    console.error(`Error getting customer by email: ${error}`);
    throw error;
  }
};
