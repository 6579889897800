import axios, { AxiosResponse } from "axios";
import { EventType, EmailTags } from "../../types/email";

export * from "./localisation";
export * from "./products";
export * from "./images";
export * from "./orders";

export type PageInfo = {
  hasNextPage: boolean;
  endCursor?: string;
};

export const requestHandler = async (fn: () => Promise<AxiosResponse>): Promise<any> => {
  let retries = 0;
  let result: any;

  const request = async () => {
    retries++;
    result = await fn();
    if (retries > 0) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  };

  try {
    while (
      retries === 0 ||
      (result.data.errors?.graphQLErrors?.length && result.data.errors.graphQLErrors[0].message === "Throttled" && retries < 5)
    ) {
      await request();
    }
    if (result.data.errors) {
      throw new Error(`Error: ${result.data.errors.graphQLErrors[0].message}`);
    }
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const addContact = async ({
  email,
  subscribe,
  tags,
  firstName,
  lastName,
}: {
  email: string;
  firstName?: string;
  lastName?: string;
  subscribe: boolean;
  tags?: EmailTags[];
}) => {
  try {
    await axios.post("/.netlify/functions/addContact", { email, subscribe, tags, firstName, lastName });
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export const triggerEvent = async ({ email, type }: { email: string; type: EventType }) => {
  try {
    await axios.post("/.netlify/functions/triggerEvent", { email, type });
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

type CreatePayoutProps = {
  email: string;
  amount: number;
  month: string;
  invoiceNumber: number;
  currency?: string;
};

export const createPayout = async (payoutProps: CreatePayoutProps) => {
  try {
    await axios.post("/.netlify/functions/createPayout", payoutProps);
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
