import { BulkOrderFragment } from "../../generated/graphql";
import { getProductItemsFromOrders } from "../../helpers/orders";
import { getOrderItemTotals } from "../../helpers/prices";

export const getMonthlyProfitForProduct = (monthlyOrders: BulkOrderFragment[], productId: string) => {
  if (!monthlyOrders.length) return 0;
  const { commissionTotal } = getOrderItemTotals(getProductItemsFromOrders(monthlyOrders || [], productId));
  return commissionTotal;
};

export const getMonthlySalesForProduct = (monthlyOrders: BulkOrderFragment[], productId: string) => {
  const productOrders = getProductItemsFromOrders(monthlyOrders || [], productId);
  return productOrders.reduce((acc, curr) => acc + curr.quantity, 0);
};
