import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { getCurrencySymbol } from "../../helpers/money";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import { CountryCode } from "../../generated/storefront";

const CountrySelector = ({
  navTransparent,
  fontSize,
  color,
}: {
  navTransparent?: boolean;
  fontSize?: number;
  color?: string;
}) => {
  const { availableMarkets = [], selectedCountry, setSelectedCountry } = useAppState();

  const options = availableMarkets.map((market) => {
    const { currencyCode } = market.currencySettings.baseCurrency;
    return {
      label: `${market.name} · ${currencyCode} ${getCurrencySymbol(currencyCode)}`,
      value: market.regions.nodes[0].code,
    };
  });

  const onChange = ({ target }: SelectChangeEvent<CountryCode>) => setSelectedCountry(target.value as CountryCode);

  return options.length ? (
    <Select
      size="small"
      onChange={onChange}
      value={selectedCountry}
      renderValue={(value) => {
        const market = availableMarkets.find((m) => m.regions.nodes[0].code === value);
        if (!market) return null;
        const { currencyCode } = market.currencySettings.baseCurrency;
        return `${currencyCode} · ${getCurrencySymbol(currencyCode)}`;
      }}
      sx={{
        color: color || (navTransparent ? colors.white : colors.black),
        border: "none",
        fontSize,
        fontFamily: "inherit",
        boxShadow: "none",
        minWidth: 0,
        backgroundColor: "transparent",
        ".MuiSelect-select": {
          display: "flex !important",
          alignItems: "center",
          backgroundColor: "transparent !important",
          fontSize,
          ":hover": {
            backgroundColor: "transparent",
          },
        },
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: 0,
        },
        ".MuiSvgIcon-root": {
          fill: "none",
        },
        ".MuiOutlinedInput-input": {
          padding: 0,
          paddingRight: "0px !important",
        },
      }}
    >
      {options.map((o) => (
        <MenuItem key={o.value} value={o.value}>
          {o.label}
        </MenuItem>
      ))}
    </Select>
  ) : null;
};

export default CountrySelector;
