import jsPDF from "jspdf";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { Row } from "../Tabs/Payments";
import { createOrUpdateInvoice, getInvoiceNumber, updateInvoice } from "../../../services/Firebase/invoices";
import { InvoiceStatus } from "../../../types/invoice";
import { createPdf } from "../../../helpers/pdf";
import { storage } from "../../../services/Firebase";

export const createDraftInvoices = async (rows: Row[], month: string) => {
  try {
    const date = new Date(month);
    const nextInvoiceNumber = await getInvoiceNumber();
    const promises = rows.map((row, index) => {
      const { id, artist, paypalEmail, commissionAmount: amount, vatNumber } = row;
      if (!id) return Promise.resolve();
      return createOrUpdateInvoice({
        invoiceNumber: nextInvoiceNumber + index,
        date,
        artist,
        artistId: id,
        paypalEmail,
        amount,
        vatNumber,
        status: InvoiceStatus.DRAFT,
      });
    });
    await Promise.allSettled(promises);
  } catch (error) {
    console.error(error);
  }
};

const saveInvoicePdf = async (pdf: jsPDF, invoiceId: string, invoiceNumber: number) => {
  const invoiceRef = `Invoice-GM${invoiceNumber}.pdf`;
  const storageRef = ref(storage, `invoicePdfs/${invoiceRef}`);
  const uploadTask = uploadBytesResumable(storageRef, pdf.output("blob"));

  uploadTask.on(
    "state_changed",
    () => {},
    (error) => console.log(error),
    async () => {
      const downloadURL = await getDownloadURL(storageRef);
      console.log("File available at", downloadURL);
      await updateInvoice(invoiceId, { pdfUrl: downloadURL });
    }
  );
};

export const createInvoicePdf = async (invoiceId: string, invoiceNumber: number, invoiceElement: HTMLElement) => {
  try {
    await createPdf(invoiceElement, (doc) => saveInvoicePdf(doc, invoiceId, invoiceNumber));
  } catch (error) {
    console.error(`Could not create invoice pdf: ${error}`);
    throw error;
  }
};

export const markInvoiceAsPaid = async (invoiceId: string) => {
  try {
    await updateInvoice(invoiceId, { status: InvoiceStatus.PAID });
  } catch (error) {
    console.error(`Could not mark invoice as paid: ${error}`);
    throw error;
  }
};
