import arrowDown from "./arrowDown.svg";
import arrowRight from "./arrowRight.svg";
import backArrow from "./backArrow.svg";
import basket from "./basket.svg";
import bell from "./bell.svg";
import blackSpinner from "./blackSpinner.svg";
import burger from "./burger.svg";
import chevronRight from "./chevronRight.svg";
import clock from "./clock.svg";
import close from "./close.svg";
import cornerUpLeft from "./cornerUpLeft.svg";
import cornerUpRight from "./cornerUpRight.svg";
import filters from "./filters.svg";
import forwardArrow from "./forwardArrow.svg";
import fourSquares from "./fourSquares.svg";
import info from "./info.svg";
import instagram from "./instagram.svg";
import logo from "./logo.svg";
import longArrowRight from "./longArrowRight.svg";
import minus from "./minus.svg";
import oneSquare from "./oneSquare.svg";
import person from "./person.svg";
import plus from "./plus.svg";
import rotateLeft from "./rotateLeft.svg";
import rotateRight from "./rotateRight.svg";
import search from "./search.svg";
import star from "./star.svg";
import threeSquares from "./threeSquares.svg";
import tick from "./tick.svg";
import tickRound from "./tickRound.svg";
import twoSquares from "./twoSquares.svg";
import upload from "./upload.svg";
import van from "./van.svg";
import whiteSpinner from "./whiteSpinner.svg";

const icons = {
  arrowDown,
  arrowRight,
  backArrow,
  basket,
  bell,
  blackSpinner,
  burger,
  chevronRight,
  clock,
  close,
  cornerUpLeft,
  cornerUpRight,
  filters,
  forwardArrow,
  fourSquares,
  info,
  instagram,
  logo,
  longArrowRight,
  minus,
  oneSquare,
  person,
  plus,
  rotateLeft,
  rotateRight,
  search,
  star,
  threeSquares,
  tick,
  tickRound,
  twoSquares,
  upload,
  van,
  whiteSpinner,
};

export default icons;
