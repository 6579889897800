import { Helmet } from "react-helmet";
import { Box, Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import trade from "../../assets/images/tradeHero.png";
import tradeWebp from "../../assets/images/tradeHero.webp";
import trade1 from "../../assets/images/trade1.png";
import trade2 from "../../assets/images/trade2.png";
import trade3 from "../../assets/images/trade3.png";
import trade4 from "../../assets/images/trade4.png";
import { Image } from "../../components";
import Button from "../../components/Button";

const Trade = () => {
  return (
    <>
      <Helmet>
        <title>Trade | GoodMood</title>
        <meta name="description" content="Transform your space with GoodMood" />
      </Helmet>
      <PageHeader header="Trade" background={colors.beige} image={trade} webpImage={tradeWebp}>
        At GoodMood, we believe in the transformative power of art to elevate spaces into vibrant, inspiring environments. If
        you've got a project in mind - get in touch, we'd love to help
      </PageHeader>
      <Box padding={{ xs: 3, md: 5 }}>
        <Stack gap={5}>
          <Stack direction={{ xs: "column", md: "row" }} gap={{ xs: 3, md: 0 }}>
            <Box width={{ xs: "100%", md: "50%" }}>
              <Image src={trade1} alt="office" />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }} padding={{ xs: 0, md: 4 }}>
              <Stack gap={{ xs: 1, md: 2 }} width={{ xs: "100%", md: "80%" }} height="100%" justifyContent="center">
                <Typography variant="h2">Something for every space</Typography>
                <Typography variant="body2" lineHeight="150%">
                  Our curated collection of artwork offers a diverse range of styles, from contemporary to traditional, ensuring
                  there's something for every workspace aesthetic.
                </Typography>
              </Stack>
            </Box>
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} gap={{ xs: 3, md: 0 }}>
            <Box width={{ xs: "100%", md: "50%" }}>
              <Image src={trade2} alt="office" />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }} padding={{ xs: 0, md: 4 }}>
              <Stack gap={{ xs: 1, md: 2 }} width={{ xs: "100%", md: "80%" }} height="100%" justifyContent="center">
                <Typography variant="h2">Support independent artists</Typography>
                <Typography variant="body2" lineHeight="150%">
                  We are a global community of creatives, with over 400 artists from 40+ countries. Purchasing from GoodMood
                  directly puts money in artists pockets and we pride ourselves on paying our Artists better than alternative
                  print websites. We can help curate art from GoodMood artists in your local area.
                </Typography>
              </Stack>
            </Box>
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} gap={{ xs: 3, md: 0 }}>
            <Box width={{ xs: "100%", md: "50%" }}>
              <Image src={trade3} alt="office" />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }} padding={{ xs: 0, md: 4 }}>
              <Stack gap={{ xs: 1, md: 2 }} width={{ xs: "100%", md: "80%" }} height="100%" justifyContent="center">
                <Typography variant="h2">Our global, local network</Typography>
                <Typography variant="body2" lineHeight="150%">
                  We have a global network of printers and framers. This means we can produce and deliver locally, within 5 days,
                  in the UK, EU, US & Australia. It also means it avoids international customs and importantly dramatically
                  reduces our carbon footprint.
                </Typography>
              </Stack>
            </Box>
          </Stack>

          <Stack direction={{ xs: "column", md: "row" }} gap={{ xs: 3, md: 0 }}>
            <Box width={{ xs: "100%", md: "50%" }}>
              <Image src={trade4} alt="office" />
            </Box>
            <Box width={{ xs: "100%", md: "50%" }} padding={{ xs: 0, md: 4 }}>
              <Stack gap={{ xs: 1, md: 2 }} width={{ xs: "100%", md: "80%" }} height="100%" justifyContent="center">
                <Typography variant="h2">Pricing, VAT invoicing & payment terms</Typography>
                <Typography variant="body2" lineHeight="150%">
                  For trade accounts we can provide trade discount pricing, VAT invoices and can discuss payment terms for repeat
                  customers. We can review pricing on larger orders on a case by case basis.
                </Typography>
              </Stack>
            </Box>
          </Stack>
        </Stack>
        <Box bgcolor={colors.peach} padding="8%" marginTop={5}>
          <Stack gap={{ xs: 1, md: 2 }} alignItems="center">
            <Typography variant="h2" textAlign="center">
              Get in touch
            </Typography>
            <Stack gap={1} alignItems="center">
              <a href="mailto:hello@goodmoodprints.io">
                <Button size="medium" secondary>
                  Email us
                </Button>
              </a>
              <a href="mailto:hello@goodmoodprints.io">
                <Button tertiary>hello@goodmoodprints.io</Button>
              </a>
            </Stack>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default Trade;
