import axios from "axios";
import { ArtistInvoiceEmail, CollaboratorRequestEmail } from "../../types/email";

export const sendArtistInvoiceEmail = async (emailProps: ArtistInvoiceEmail) => {
  try {
    await axios.post("/.netlify/functions/sendArtistInvoiceEmail", emailProps);
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export const sendWishlistCollaboratorRequestEmail = async (emailProps: CollaboratorRequestEmail) => {
  try {
    await axios.post("/.netlify/functions/sendEmail", { ...emailProps, templateName: "collaboratorRequest" });
  } catch (error) {
    console.log({ error });
    throw error;
  }
};
