import { useSearchParams } from "react-router-dom";
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import CheckboxSelect from "./CheckboxSelect";
import Button from "../../../../components/Button";
import { last12Months } from "../../../../state/constants";
import useFilterOptions from "../useFilterOptions";

const FiltersBar = ({
  updateProducts,
  productsSelected,
}: {
  updateProducts: (tags: string[]) => void;
  productsSelected: boolean;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { mediumOptions, styleOptions, subjectOptions, sizeOptions, orientationOptions, sortByOptions } = useFilterOptions();

  const handleSortByChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    searchParams.set("sort", value);
    setSearchParams(searchParams, { preventScrollReset: true });
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams();
    setSearchParams(newParams, { preventScrollReset: true });
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  return (
    <Stack gap={2} direction="row" alignItems="center">
      <CheckboxSelect name="month" options={last12Months} />
      <CheckboxSelect name="medium" options={mediumOptions} />
      <CheckboxSelect name="style" options={styleOptions} updateProducts={updateProducts} productsSelected={productsSelected} />
      <CheckboxSelect
        name="subject"
        options={subjectOptions}
        updateProducts={updateProducts}
        productsSelected={productsSelected}
      />
      <CheckboxSelect name="size" options={sizeOptions} />
      <CheckboxSelect name="orientation" options={orientationOptions} />
      <Select
        name="sort"
        variant="filled"
        displayEmpty
        onChange={handleSortByChange}
        renderValue={(selected) => sortByOptions.find((o) => o.value === selected)?.label || "Sort by"}
      >
        {sortByOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <Typography fontSize={14}>{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>

      {searchParams.size > 0 && (
        <Button onClick={clearFilters} tertiary size="small">
          Clear
        </Button>
      )}
    </Stack>
  );
};

export default FiltersBar;
