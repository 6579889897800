import { useState } from "react";
import { Link } from "react-router-dom";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import Image from "../Image";
import { CollectionCardFragment } from "../../generated/storefront";
import { getIdNumber } from "../../helpers/shopify";

type Props = {
  collection: CollectionCardFragment;
  imageHeight?: string;
  borderRadius?: number;
  size?: "small" | "medium";
};

const CollectionCard = ({ collection, imageHeight, borderRadius, size = "medium" }: Props) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Link to={`/collections/${getIdNumber(collection.id, "Collection")}`} key={collection.id}>
      <Stack gap={size === "small" ? 1.25 : 2}>
        <Box height={imageHeight} width="100%" position="relative">
          {!imageLoaded && (
            <Skeleton
              width="100%"
              height="100%"
              animation="wave"
              variant="rectangular"
              sx={{ position: "absolute", bgcolor: colors.grey02 }}
              style={{ borderRadius }}
            />
          )}
          <Image
            src={collection.image?.url}
            webp={collection.image?.url}
            alt={collection.image?.altText || collection.title}
            style={{ borderRadius }}
            onLoad={() => setImageLoaded(true)}
          />
        </Box>
        <Stack gap={0.5}>
          <Typography variant="body2" fontSize={size === "small" ? 12 : 16}>
            {collection.title}
          </Typography>
          <Typography variant="body2" fontSize={size === "small" ? 12 : 16} color={colors.error}>
            25% OFF
          </Typography>
        </Stack>
      </Stack>
    </Link>
  );
};

export default CollectionCard;
