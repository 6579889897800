import styled from "styled-components";
import { Stack } from "@mui/material";
import { Container, Grid, GridItem, Margin } from "../../../../components/Layout";
import { Text } from "../../../../components/Text";
import { format } from "date-fns";
import { colors } from "../../../../theme";
import { formattedPrice } from "../../../../helpers/money";
import { Ref, forwardRef } from "react";
import { Invoice } from "../../../../types/invoice";
import { CurrencyCode } from "../../../../generated/storefront";

const Header1 = styled.h1`
  font-family: Arial;
  font-size: 80px;
`;

const InvoicePdf = forwardRef(({ invoiceData }: { invoiceData?: Invoice }, ref: Ref<HTMLDivElement>) => {
  if (!invoiceData) return null;
  const { artist, paypalEmail, amount, issueDate, invoiceNumber, vatNumber } = invoiceData;
  const customItems = (invoiceData.items || []).filter((i) => i.custom);
  const customItemTotal = customItems.reduce((total, i) => total + Number(i.amount), 0);
  return (
    <div ref={ref} style={{ zIndex: -1 }}>
      <Container padding="45px" style={{ height: 1080, width: 840 }} margin="auto">
        <Stack spacing={3} direction="column" flexGrow={1} width="100%">
          <div>
            <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="baseline">
              <Header1>Invoice</Header1>
              <Stack>
                <Text font="Arial" size={20} align="right">
                  Billed from: {artist}
                </Text>
                <Text font="Arial" size={20} align="right">
                  Paypal email: {paypalEmail}
                </Text>
                {vatNumber && <Text font="Arial">VAT/GST number: {vatNumber}</Text>}
              </Stack>
            </Stack>
            <Margin margin={60} />
            <Stack spacing={2} direction="row" justifyContent="end" alignItems="baseline">
              <Stack justifyContent="end">
                <Text font="Arial" align="right">
                  Invoice Reference: GM{invoiceNumber}
                </Text>
                <Text font="Arial" align="right">
                  Date of issue: {format(issueDate.toDate(), "dd/MM/yyyy")}
                </Text>
              </Stack>
            </Stack>
          </div>
          <Stack direction="column" justifyContent="space-between" alignItems="baseline">
            <div>
              <Text font="Arial">Billed to: GoodMood Prints Ltd.</Text>
              <Text font="Arial">Avins, College Road</Text>
              <Text font="Arial">Ardingly</Text>
              <Text font="Arial">RH17 6SH</Text>
              <Text font="Arial">hello@goodmoodprints.io</Text>
              <Text font="Arial">VAT number: 451336119</Text>
            </div>
          </Stack>
          <Margin margin={60} />
          <Margin margin={60} />
          <Margin margin={60} />
          <Grid columns={2}>
            <GridItem backgroundColor={colors.purple} outline={false}>
              <Text font="Arial">ITEM</Text>
            </GridItem>
            <GridItem backgroundColor={colors.purple} align="right" outline={false}>
              <Text font="Arial">SUBTOTAL</Text>
            </GridItem>
            <GridItem backgroundColor={colors.purple10} outline={false}>
              <Text font="Arial">GoodMood Commission {format(issueDate.toDate(), "MMMM yy")}</Text>
            </GridItem>
            <GridItem backgroundColor={colors.purple10} align="right" outline={false}>
              <Text font="Arial">{formattedPrice(amount, CurrencyCode.Gbp)}</Text>
            </GridItem>
            {customItems.map((item) => (
              <>
                <GridItem backgroundColor={colors.purple10} outline={false}>
                  <Text font="Arial">{item.description}</Text>
                </GridItem>
                <GridItem backgroundColor={colors.purple10} align="right" outline={false}>
                  <Text font="Arial">{formattedPrice(item.amount, CurrencyCode.Gbp)}</Text>
                </GridItem>
              </>
            ))}
            <Margin margin={60} />
            <Margin margin={60} />
            <Margin margin={47} />
            <GridItem backgroundColor={colors.purple} align="right" style={{ justifyContent: "space-between" }} outline={false}>
              <Text font="Arial">TOTAL DUE:</Text>
              <Text font="Arial">{formattedPrice(amount + customItemTotal, CurrencyCode.Gbp)}</Text>
            </GridItem>
          </Grid>
          {/* <Stack spacing={2} direction="row" justifyContent="end" alignItems="end" flexGrow={1}>
            <Header1>GoodMood</Header1>
          </Stack> */}
        </Stack>
      </Container>
    </div>
  );
});

export default InvoicePdf;
