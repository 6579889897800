import { useContext, useState } from "react";
import { uniq } from "lodash";
import { Autocomplete, Chip, OutlinedInput, RadioGroup, Stack, TextField, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import Button from "../../components/Button";
import Radio from "../../components/Radio";
import { PrivacyType, Wishlist } from "../../types/wishlist";
import { getWishlistById, updateWishlist } from "../../services/Firebase/wishlists";
import { sendWishlistCollaboratorRequestEmail } from "../../services/API/emails";
import AuthContext, { Customer } from "../../state/auth";
import { colors } from "../../theme";

type Props = {
  open: boolean;
  onClose: () => void;
  wishlist: Wishlist;
  customer: Customer;
};

const EditWishlistModal = ({ open, onClose, wishlist, customer }: Props) => {
  const { refetchCustomer } = useContext(AuthContext);
  const [name, setName] = useState(wishlist.name);
  const [collboratorEmails, setCollboratorEmails] = useState<string[]>(wishlist.collaborators.map((c) => c.email));
  const [privacyType, setPrivacyType] = useState<PrivacyType>(wishlist.privacyType);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleEmailInputChange = (_: any, newValue: string) => {
    setInputValue(newValue);
    setOptions([newValue]);
  };

  const onCollaboratorsEmailChange = (_: any, newValue: string[]) => {
    setInputValue("");
    // split emails by comma or space
    const newEmails = newValue
      .join(",")
      .split(/,| /)
      .filter((email: string) => email);
    const uniqueEmails = uniq(newEmails);
    setCollboratorEmails(uniqueEmails);
  };

  const onUpdate = async () => {
    setLoading(true);
    const upToDateWishlist = await getWishlistById(wishlist.id);
    const existingCollaborators =
      privacyType === "collaborative" ? upToDateWishlist.collaborators.filter((c) => collboratorEmails.includes(c.email)) : [];
    const newCollaborators = collboratorEmails.filter(
      (email) => !upToDateWishlist.collaborators.map((c) => c.email).includes(email)
    );
    const collaborators = [...existingCollaborators, ...newCollaborators.map((email) => ({ email, status: "pending" as const }))];
    await updateWishlist(wishlist.id, {
      name,
      privacyType,
      collaborators,
    });
    const newEmails = collboratorEmails.filter((email) => !upToDateWishlist.collaborators.map((c) => c.email).includes(email));
    if (wishlist && customer && privacyType === "collaborative") {
      await Promise.all(
        newEmails.map(async (email) => {
          sendWishlistCollaboratorRequestEmail({
            to: { email },
            dynamicTemplateData: {
              wishlistId: wishlist.id,
              wishlistName: name,
              customerName: customer.firstName || "A GoodMood customer",
              link: `${window.location.origin}/wishlist/${wishlist.id}?source=email_invite`,
            },
          });
        })
      );
    }
    refetchCustomer();
    onClose();
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <CloseRounded
        onClick={onClose}
        fontSize="small"
        sx={{ cursor: "pointer", position: "absolute", top: 0, right: 0, margin: 2 }}
      />
      <Typography variant="h3" marginBottom={1}>
        Edit Wishlist
      </Typography>
      <Stack gap={2}>
        <OutlinedInput placeholder="Name of wishlist" value={name} onChange={onNameChange} />
        <RadioGroup
          sx={{ flexDirection: "row", gap: 2 }}
          value={privacyType}
          onChange={({ target }) => setPrivacyType(target.value as PrivacyType)}
        >
          <Radio value="private" label="Private" />
          <Radio value="collaborative" label="Collaborative" />
        </RadioGroup>
        {privacyType === "collaborative" && (
          <Autocomplete
            id="emails-autocomplete"
            multiple
            freeSolo
            value={collboratorEmails}
            onChange={onCollaboratorsEmailChange}
            inputValue={inputValue}
            onInputChange={handleEmailInputChange}
            options={options}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Collaborators email" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  sx={{
                    border: 0,
                    backgroundColor:
                      wishlist.collaborators.find((c) => c.email === option)?.status === "accepted" ? colors.mint : colors.peach,
                  }}
                  {...getTagProps({ index })}
                />
              ))
            }
            clearOnBlur={false}
          />
        )}
        <Button onClick={onUpdate} fullWidth loading={loading} disabled={!name}>
          Update
        </Button>
      </Stack>
    </Dialog>
  );
};

export default EditWishlistModal;
