import { format } from "date-fns";
import styled from "styled-components";
import { Stack, Tooltip, Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { formattedPrice } from "../../../helpers/money";
import { Grid, GridItem } from "../../../components/Layout";
import { getAvailableSizes, getCampaignFromTags, sizeLabelsForCountry } from "../../../helpers/product";
import { useAppState } from "../../../state";
import { CurrencyCode } from "../../../generated/storefront";
import { colors } from "../../../theme";
import { Medium, MediumNames, Size } from "../../../types/product";
import { Fragment } from "react";
import { DashboardProductFragment, Frame } from "../../../generated/graphql";

const ProfileGrid = styled(Grid)`
  row-gap: 4px;
  width: 100%;

  > div {
    border-radius: 4px;
  }
`;

const GridRow = styled.div`
  display: contents;
  color: inherit;

  > div {
    border-radius: 4px;
  }
`;

const Stats = ({ product, isMobileScreen }: { product: DashboardProductFragment; isMobileScreen: boolean }) => {
  const { countryOfUser } = useAppState();

  const findVariants = (size: Size, framed: boolean) => {
    const variant = product.variants.nodes.filter((variant) => {
      const sizeVariant = variant.selectedOptions.find((o) => o.name === "size");
      const frameVariant = variant.selectedOptions.find((o) => o.name === "frame");
      return (
        sizeVariant?.value === size && (framed ? frameVariant?.value !== Frame.Unframed : frameVariant?.value === Frame.Unframed)
      );
    });
    if (!variant) throw Error("No variant found");
    return variant;
  };

  return (
    <>
      <Stack gap={3}>
        <Grid columns={5}>
          <GridItem padding="6px 0" outline={false}>
            {product.productType ? "Collection:" : "Campaign:"}
          </GridItem>
          <GridItem padding="6px 0" outline={false}>
            {product.productType ? format(new Date(product.productType), "MMMM yyyy") : getCampaignFromTags(product.tags)}
          </GridItem>
          <GridItem outline={false} />
          <GridItem outline={false} />
          <GridItem outline={false} />
          <GridItem padding="6px 0" outline={false}>
            Medium:
          </GridItem>
          <GridItem padding="6px 0" outline={false}>
            {product.medium ? MediumNames[product.medium.value as Medium] : ""}
          </GridItem>
        </Grid>
        <Stack gap={4} direction={isMobileScreen ? "column" : "row"}>
          <ProfileGrid columns={3} evenColumns>
            <GridRow>
              <GridItem padding="12px 0" outline={false}>
                Size
              </GridItem>
              <GridItem padding="12px 0" outline={false} align="center">
                Price
              </GridItem>
              <GridItem padding="12px 0" outline={false} align="right">
                <Stack direction="row" alignItems="center" gap={1}>
                  <Tooltip
                    title={
                      <Typography>
                        Please note commission is calculated from the sale price so may vary depending on discounts and/or
                        currency conversions
                      </Typography>
                    }
                  >
                    <InfoOutlined fontSize="small" />
                  </Tooltip>
                  Commission (30%)
                </Stack>
              </GridItem>
            </GridRow>
            <GridRow>
              {getAvailableSizes(product.variants.nodes).map((size) => (
                <Fragment key={size}>
                  <GridItem backgroundColor={colors.cardGrey} outline={false}>
                    {sizeLabelsForCountry(countryOfUser)[size].shortHand}
                  </GridItem>
                  <GridItem backgroundColor={colors.cardGrey} outline={false} align="center">
                    {formattedPrice(findVariants(size, false)[0].price, CurrencyCode.Gbp)}
                  </GridItem>
                  <GridItem backgroundColor={colors.cardGrey} outline={false} align="right">
                    {formattedPrice(Number(findVariants(size, false)[0].price) * 0.3, CurrencyCode.Gbp)}
                  </GridItem>
                </Fragment>
              ))}
            </GridRow>
          </ProfileGrid>
        </Stack>
      </Stack>
    </>
  );
};

export default Stats;
