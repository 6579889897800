import { HTMLAttributes, PropsWithChildren } from "react";
import styled from "styled-components";
import { match } from "ts-pattern";
import { colors } from "../../theme";
import { Icon, IconType } from "../Icon";
import { Text } from "../Text";

type Props = {
  icon: IconType;
  onClick?: () => void;
  variation?: "normal" | "light" | "dark";
  size?: "small" | "large";
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Button = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonText = styled(Text)`
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const StyledIconButton = styled.button<{ variation: "normal" | "light" | "dark"; size: "small" | "large"; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) =>
    match(p.variation)
      .with("normal", () => colors.grey02)
      .with("light", () => colors.cream)
      .with("dark", () => colors.black)
      .exhaustive()};
  border: ${(p) =>
    match(p.variation)
      .with("normal", () => 0)
      .with("light", () => 0)
      .with("dark", () => `1px solid ${colors.white}`)
      .exhaustive()};
  border-radius: 100px;
  ${(p) => !p.disabled && `cursor: pointer;`}

  height: ${(p) =>
    match(p.size)
      .with("small", () => "48px")
      .with("large", () => "64px")
      .exhaustive()};
  width: ${(p) =>
    match(p.size)
      .with("small", () => "48px")
      .with("large", () => "64px")
      .exhaustive()};

  ${(p) =>
    !p.disabled &&
    `&:hover {
    background-color: 
      ${match(p.variation)
        .with("normal", () => colors.grey10)
        .with("light", () => colors.grey10)
        .with("dark", () => colors.grey80)
        .exhaustive()};

  }`}
`;

const IconButton = ({
  icon,
  onClick,
  variation = "normal",
  children,
  size = "small",
  disabled = false,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Button {...props}>
      <StyledIconButton onClick={disabled ? () => {} : onClick} variation={variation} size={size} disabled={disabled}>
        <Icon icon={icon} fill={disabled ? colors.grey20 : variation === "dark" ? colors.white : colors.black} size={13} />
      </StyledIconButton>
      {children && <ButtonText size={16}>{children}</ButtonText>}
    </Button>
  );
};

export default IconButton;
