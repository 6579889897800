import { createContext, useContext, useEffect, useState } from "react";
import { format, startOfMonth } from "date-fns";
import { filterVendorItemsInOrders } from "../helpers/orders";
import { getOrdersTotals } from "../helpers/prices";
import AuthContext from "./auth";
import useGetBulkOrders from "../hooks/useGetBulkOrders";
import { ArtistProductFragment, BulkOrderFragment, useGetArtistProductsLazyQuery } from "../generated/graphql";

const ProfileContext = createContext<{
  products?: ArtistProductFragment[];
  loading: boolean;
  hasMore: boolean;
  getMoreArtistProducts: () => void;
  month: Date;
  setMonth: (date: Date) => void;
  getMonthsOrders: (selectedMonth: Date) => void;
  monthlyOrders?: BulkOrderFragment[];
  monthsTotalProfit?: number;
  loadingMonthsOrders: boolean;
}>({
  month: startOfMonth(new Date()),
  setMonth: () => {},
  getMonthsOrders: () => {},
  getMoreArtistProducts: () => {},
  loading: false,
  hasMore: true,
  loadingMonthsOrders: false,
});

export const ProfileStateProvider: React.FC = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [loadingMonthsOrders, setLoadingMonthsOrders] = useState(false);
  const [ordersByMonth, setOrdersByMonth] = useState<{ [month: string]: BulkOrderFragment[] }>({});
  const [monthlyOrders, setMonthlyOrders] = useState<BulkOrderFragment[]>();
  const [monthsTotalProfit, setMonthTotalProfit] = useState<number | undefined>();
  const [month, setMonth] = useState(startOfMonth(new Date()));
  const { getOrders, orders } = useGetBulkOrders();
  const [getProducts, { data, loading, fetchMore }] = useGetArtistProductsLazyQuery();
  const products = data?.products?.nodes;
  const hasMore = data?.products?.pageInfo?.hasNextPage || false;
  const afterCursor = data?.products?.pageInfo?.endCursor;

  const getMoreArtistProducts = async () => {
    fetchMore({
      variables: {
        afterCursor,
      },
    });
  };

  const setOrders = (orders: BulkOrderFragment[]) => {
    if (!user) return;
    const vendorOrders = filterVendorItemsInOrders(orders, user.id);
    setMonthlyOrders(vendorOrders);
    const { commissionTotal } = getOrdersTotals(vendorOrders);
    setMonthTotalProfit(commissionTotal);
    setLoadingMonthsOrders(false);
  };

  const getMonthsOrders = async (selectedMonth: Date) => {
    if (!user) return;
    setLoadingMonthsOrders(true);
    const formattedMonth = format(selectedMonth, "yyyy-MM-dd");
    if (ordersByMonth[formattedMonth]) {
      setOrders(ordersByMonth[formattedMonth]);
    } else {
      await getOrders({ month: selectedMonth, vendorId: user.id });
    }
  };

  useEffect(() => {
    if (!orders) return;
    const formattedMonth = format(new Date(month), "yyyy-MM-dd");
    setOrdersByMonth({ ...ordersByMonth, [formattedMonth]: orders });
    setOrders(orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  useEffect(() => {
    if (user && !products && !loading) {
      getProducts({
        variables: {
          query: `vendor:${user.id}`,
          limit: 10,
          afterCursor,
        },
      });
    }
  });

  return (
    <ProfileContext.Provider
      value={{
        products,
        loading,
        hasMore,
        getMoreArtistProducts,
        month,
        setMonth,
        getMonthsOrders,
        monthlyOrders,
        monthsTotalProfit,
        loadingMonthsOrders,
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
