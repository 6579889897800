import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import notFound from "../../assets/images/notFound.png";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import { Text } from "../../components/Text";
import { colors } from "../../theme";

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404 | GoodMood</title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <PageHeader header="404" background={colors.mint} image={notFound}>
        <Text margin="0 0 32px">Nothing to see here...</Text>
        <Link
          to={{
            pathname: "/shop",
            search: "?collection=true",
          }}
        >
          <Button secondary>Explore the collection</Button>
        </Link>
      </PageHeader>
    </>
  );
};

export default NotFound;
