import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import styled, { css, keyframes } from "styled-components";
import { media } from "../../helpers/layout";
import { colors } from "../../theme";
import NewsletterModal from "../NewsletterModal";
import { useAppState } from "../../state";
import { getCurrencySymbol } from "../../helpers/money";
import { CurrencyCode } from "../../generated/storefront";
import CountrySelector from "../Nav/CountrySelector";

const StyledBanner = styled.div<{ bannerHeight: number }>`
  background-color: ${colors.beige};
  width: 100%;
  text-align: center;
  padding: 4px;
  height: ${(p) => p.bannerHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${media.m} {
    font-size: 11px;
    letter-spacing: 0;
  }
`;

const rollingAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }
  3% {
    transform: translateY(0%);
  }
  33% {
    transform: translateY(0%);
  }
  36% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-100%);
  }
`;

const RollingMessageContainer = styled.div<{ bannerHeight: number }>`
  position: relative;
  height: ${(p) => p.bannerHeight}px;
  width: 100%;
  overflow: hidden;
`;

const animation = () => css`
  ${rollingAnimation} 12s linear infinite;
`;

const RollingMessage = styled.div<{ delay?: number }>`
  position: absolute;
  font-size: 14px;
  line-height: 32px;
  padding: 5px;
  color: ${colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  animation: ${animation};
  ${(p) =>
    p.delay &&
    `
    transform: translateY(100%);
    animation-delay: ${p.delay}s;
  `}
`;

const Banner = () => {
  const { isMobileScreen, bannerHeight, selectedMarket, inFreeDeliveryCountry, freeDeliveryThreshold } = useAppState();
  const [modalOpen, setModalOpen] = useState(false);
  const currencyCode = selectedMarket?.regions.nodes[0]?.currency.currencyCode || CurrencyCode.Gbp;

  const messages = [
    () => (
      <Typography fontWeight={500} fontSize={14} letterSpacing={-0.2}>
        Sign up to our newsletter to get 15% off
      </Typography>
    ),
    () => (
      <Typography fontWeight={500} fontSize={14} letterSpacing={-0.2}>
        {/* 20% off when you buy two */}
        Summer sale - 25% off everything
      </Typography>
    ),
    () => (
      <Typography fontWeight={500} fontSize={14} letterSpacing={-0.2}>
        {inFreeDeliveryCountry
          ? `Free delivery over ${getCurrencySymbol(currencyCode)}${freeDeliveryThreshold}`
          : "Support an independent artist today"}
      </Typography>
    ),
  ];

  useEffect(() => {
    const seenNewsletterModal = localStorage.getItem("seenNewsletterModal") === "true";
    if (!seenNewsletterModal) {
      const timeout = setTimeout(() => {
        if (window.location.pathname === "/login/customer") return;
        setModalOpen(true);
        localStorage.setItem("seenNewsletterModal", "true");
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  });

  return (
    <>
      <StyledBanner bannerHeight={bannerHeight}>
        <RollingMessageContainer bannerHeight={bannerHeight} onClick={() => setModalOpen(true)}>
          {messages.map((Message, i) => (
            <RollingMessage delay={4 * i} key={i}>
              <Message />
            </RollingMessage>
          ))}
        </RollingMessageContainer>
        {!isMobileScreen && (
          <Stack position="absolute" right={40} gap={1} direction="row" alignItems="center">
            <Link to="/trade">
              <Typography fontSize={14} sx={{ ":hover": { textDecoration: "underline", textUnderlineOffset: 2 } }}>
                Trade
              </Typography>
            </Link>
            <Typography fontSize={14}>|</Typography>
            <CountrySelector fontSize={14} />
          </Stack>
        )}
      </StyledBanner>

      {modalOpen && <NewsletterModal setModalOpen={setModalOpen} />}
    </>
  );
};

export default Banner;
