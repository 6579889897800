import { useState } from "react";
import styled from "styled-components";
import { colors } from "../../theme";
import { Flex } from "../Layout";
import { Text } from "../Text";
import { inputStyles, labelStyles, errorStyles, Variant } from "./styles";

export type Props = {
  label?: string;
  placeholder?: string;
  name?: string;
  value?: string;
  onChange: (event: any) => void;
  maxLength?: number;
  error?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLabel = styled.label<{ error: boolean }>`
  ${labelStyles(Variant.Standard)}

  ${(p) =>
    p.error &&
    `
    ${errorStyles}
  `}
`;

const StyledTextArea = styled.textarea<{ error: boolean }>`
  ${inputStyles(Variant.Standard)}
  resize: vertical;
  ${(p) =>
    p.error &&
    `
    ${errorStyles}
  `}
`;

const TextArea = ({ label, placeholder, name, onChange, value, maxLength = 200, error }: Props) => {
  const [currentLength, setCurrentLength] = useState(0);

  const onTextAreaChange = ({ target }: any) => {
    const length = target.value.length;
    setCurrentLength(length);
    onChange({ target });
  };

  return (
    <Wrapper>
      {label && <StyledLabel error={Boolean(error)}>{label}</StyledLabel>}
      <StyledTextArea
        placeholder={placeholder}
        name={name}
        onChange={onTextAreaChange}
        maxLength={maxLength}
        value={value}
        error={Boolean(error)}
      />
      <Flex justifyContent="space-between">
        <span>
          {error && (
            <Text size={14} color={colors.error} as="span" margin="4px 0">
              {error}
            </Text>
          )}
        </span>
        <Text size={14} as="span" margin="4px 0 0" align="right">{`${currentLength}/${maxLength}`}</Text>
      </Flex>
    </Wrapper>
  );
};

export default TextArea;
