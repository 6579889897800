import styled from "styled-components";
import { colors } from "../../theme";

type Props = {
  selected: boolean;
  disabled?: boolean;
};

export const Steps = styled.div`
  display: flex;
  align-items: center;
  border-radius: 56px;
  background-color: ${colors.grey02};
  width: min-content;
  padding: 8px 16px;
  margin: 0 24px 0;

  > :not(:last-child) {
    margin-right: 8px;
  }
`;

export const ImageSection = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductImage = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const StepsContainer = styled.div<{ sticky: boolean; navHeight: number }>`
  position: ${(p) => (p.sticky ? "sticky" : "relative")};
  top: ${(p) => (p.sticky ? `${p.navHeight}px` : "0")};
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const BackButton = styled.div`
  position: absolute;
  top: 32px;
  left: 40px;
  z-index: 1;
`;

export const RotateButtons = styled.div`
  position: absolute;
  display: flex;
  top: 32px;
  right: 40px;

  > div {
    margin-left: 12px;
  }
`;

export const Step = styled.button<Props>`
  height: 22px;
  width: 22px;
  color: ${(p) => (p.selected ? colors.white : colors.black)};
  background: ${(p) => (p.selected ? colors.black : p.disabled ? "none" : colors.white)};
  border: 0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(p) => (p.disabled ? "initial" : "pointer")};
  font-size: 14px;
  line-height: 7px;
  padding: 16px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  flex-direction: column;
  overflow: auto;
  flex-grow: 1;
`;

export const Card = styled.div<{ background?: string }>`
  display: flex;
  justify-content: space-between;
  background-color: ${(p) => p.background || colors.white};
  border-radius: 2px;
  padding: 16px;
`;

export const Banner = styled.div<{ color: string }>`
  background-color: ${(p) => p.color};
  padding: 24px;
  width: 100%;
`;

export const ImageContainer = styled.div<{ width: number; height: number; isLandscape: boolean }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  background-color: ${colors.grey40};
  margin: 60px 0;
  position: relative;
  ${({ width, height, isLandscape }) => isLandscape && `width: ${height}px; height: ${width}px;`}
`;
