import { Stack } from "@mui/material";
import Accordion from "../../components/Accordion";
import { Column, Container, Flex } from "../../components/Layout";
import { Text } from "../../components/Text";
import { Indent } from "./styles";
import TextLink from "../../components/TextLink";

const Buyer = () => {
  return (
    <Container padding="0 0 400px">
      <Flex justifyContent="center">
        <Column width={50}>
          <Accordion
            highlight
            items={[
              {
                label: "Is it possible to order in different sizes?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We currently offer prints and framed prints in 70x100cm (28x40in), A1, 50x70cm (20x28in), A2 & A3 in
                      portrait and landscape and 30x30cm (12x12in), 50x50cm (20x20in) & 70x70cm (28x28in).
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What wall fixture will my frame have?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      Each artwork arrives ready to hang with the necessary back fixture. The size and country of production will
                      determine which fixture you get. The frame will either be fully strung or with a sawtooth hanger. Please
                      refer to our <TextLink to="/hanging-guide">Print Opening and Hanging Guide</TextLink> for more information.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What printing method do you use?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We only use industry leading Giclée printers, printing in up to individual 12 colours leading to museum
                      level quality prints. This ensures vibrancy and depth of colour. The high quality inks and perspex glazes we
                      use on our frames offer great sunlight-resistance, but direct sunlight for prolonged periods of time could
                      still fade the print. Cheaper alternatives will fade rapidly in direct sunlight.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What paper do you use?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We use enhanced matte 200gsm, a premium quality heavyweight fine art print material with a smooth, clean
                      finish. This slight off-white museum-quality paper is extremely consistent and works perfectly with large,
                      full colour graphics, illustrations and photography. The matte finish emphasises different highlights and
                      tones in the source artworks; helping to create stunning works of art.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What frames do you offer?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We offer frames in black, white or natural oak. Created from high-quality wood, milled with simple clean
                      lines and presented with a satin finish. This frame has a square profile measuring 20mm (front face) by 23mm
                      (depth from wall). The frames have a perspex glaze.
                    </Text>
                    <Text>Frame details:</Text>
                    <Indent>
                      <Text>- Flush surface mount</Text>
                      <Text>- Satin laminated finish for added protection</Text>
                      <Text>- Lightweight and robust</Text>
                      <Text>- Delivered fully strung or with a sawtooth hanger</Text>
                    </Indent>
                  </Stack>
                ),
              },
              {
                label: "How are prints and framed packaged?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>All prints and framed prints are very carefully packaged.</Text>
                    <Text>
                      Our unframed prints are hand rolled in protective tissue paper and then shipped in extra thick cardboard
                      tubes.
                    </Text>
                    <Text>
                      All framed prints are wrapped in a protective sleeve with individual plastic or cardboard guards added to
                      each corner. Additional cardboard is secured over the print surface to minimise the risk of breakages or
                      transit damage. Each print is then shipped in a heavy duty cardboard box.
                    </Text>
                    <Text>
                      It is extremely rare for breakages or damages to occur, as our printers have refined their packaging
                      solutions many times.
                    </Text>
                    <Text>
                      On the rare occasion when damage does happen, we ask for photographic evidence and then strive to organise
                      collection and replacement immediately.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What are your environmental credentials?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      The planet is incredibly important to us. We only use eco-inks and all of our paper, wood and packaging is
                      sustainably sourced.
                    </Text>
                    <Text>
                      We also print on demand as close to the delivery destination as possible. Printing on demand means there is
                      no wastage as every item is made to order. Printing and framing as locally as possible reduces transport
                      distances and therefore emissions.
                    </Text>
                  </Stack>
                ),
              },
            ]}
          />
        </Column>
      </Flex>
    </Container>
  );
};

export default Buyer;
