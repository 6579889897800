import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { match } from "ts-pattern";
import { Stack, Typography } from "@mui/material";
import { Text, Header } from "../../components/Text";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import { Flex, Form, Margin } from "../../components/Layout";
import Input from "../../components/Form/Input";
import contact from "../../assets/images/contact.png";
import { colors } from "../../theme";
import { useAppState } from "../../state";
import TextLink from "../../components/TextLink";
import Checkbox from "../../components/Checkbox";
import PasswordInput from "../../components/Form/PasswordInput";
import useLoginArtist from "../Login/Artist/useLoginArtist";

type View = "login" | "register";

const HeaderButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
`;

const LoginModal = ({ setModalOpen }: { setModalOpen: (open: boolean) => void }) => {
  const { errors } = useAppState();
  const {
    handleSignInChange,
    handleSignUpChange,
    signInUser,
    signUpUser,
    loading,
    signInErrors,
    signUpErrors,
    agreedTerms,
    onTermsChange,
  } = useLoginArtist();
  const [view, setView] = useState<View>("login");

  return (
    <Modal image={contact} onClose={() => setModalOpen(false)}>
      <Flex gap={16} alignItems="center">
        <HeaderButton onClick={() => setView("login")}>
          <Header type="h2" color={view === "login" ? colors.black : colors.grey20}>
            Login
          </Header>
        </HeaderButton>
        <HeaderButton onClick={() => setView("register")}>
          <Header type="h2" color={view === "register" ? colors.black : colors.grey20}>
            Register
          </Header>
        </HeaderButton>
      </Flex>
      <Text size={18} color={colors.grey60} margin="12px 0 24px">
        To continue you must sign in to an existing account or create an account
      </Text>
      <Margin margin={12} />
      {match(view)
        .with("login", () => (
          <Form onSubmit={(event) => signInUser(event, false)}>
            <Stack gap={1}>
              <Input
                placeholder="Email"
                id="email"
                name="email"
                onChange={handleSignInChange}
                error={errors?.signIn?.email || signInErrors.email}
              />
              <PasswordInput onChange={handleSignInChange} error={errors?.signIn?.password || signInErrors.password} />
            </Stack>
            <Button fullWidth loading={loading}>
              Sign In
            </Button>
            <Flex justifyContent="center">
              <Link to="/reset-password">
                <Text>Forgot Password?</Text>
              </Link>
            </Flex>
          </Form>
        ))
        .with("register", () => (
          <Form onSubmit={(event) => signUpUser(event, false)}>
            <Flex direction="column" gap={8}>
              <Flex gap={8}>
                <Input
                  placeholder="First name"
                  id="firstName"
                  name="firstName"
                  onChange={handleSignUpChange}
                  error={signUpErrors.firstName}
                />
                <Input
                  placeholder="Last name"
                  id="lastName"
                  name="lastName"
                  onChange={handleSignUpChange}
                  error={signUpErrors.lastName}
                />
              </Flex>
              <Input
                placeholder="Email"
                id="email"
                name="email"
                onChange={handleSignUpChange}
                error={errors?.signUp?.email || signUpErrors.email}
              />
              <Input
                placeholder="Paypal email"
                id="paypalEmail"
                name="paypalEmail"
                onChange={handleSignUpChange}
                error={errors?.signUp?.paypalEmail || signUpErrors.paypalEmail}
              />
              <PasswordInput onChange={handleSignUpChange} error={errors?.signUp?.password || signUpErrors.password} />
            </Flex>
            <Checkbox
              name="termsAndConditions"
              onChange={onTermsChange}
              error={signUpErrors.agreedTerms}
              label={
                <Typography fontSize={14}>
                  I agree to the{" "}
                  <TextLink to="/terms" openInNewTab>
                    terms and conditions
                  </TextLink>
                </Typography>
              }
            />
            <Button fullWidth loading={loading} disabled={!agreedTerms}>
              Create
            </Button>
          </Form>
        ))
        .exhaustive()}
    </Modal>
  );
};

export default LoginModal;
