import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import hanging from "../../assets/images/hanging.jpg";
import { Container } from "../../components/Layout";
import { Header, Text } from "../../components/Text";
import { useAppState } from "../../state";
import sawtoothHanger from "../../assets/images/sawtoothHanger.jpg";
import measureHanging from "../../assets/images/measureHanging.jpg";
import wireHanger from "../../assets/images/wireHanger.jpg";

const ListItem = styled.li`
  margin-bottom: 16px;
`;

const HangingGuide = () => {
  const { isMobileScreen } = useAppState();

  return (
    <>
      <Helmet>
        <title>Print Opening & Hanging Guide | GoodMood</title>
        <meta
          name="description"
          content="Here are our instructions and top tips for how best to open your new prints and hang your frames"
        />
      </Helmet>
      <PageHeader header="Print Opening & Hanging Guide" background={colors.blue} image={hanging}>
        Here are our instructions and top tips for how best to open your new prints and hang your frames
      </PageHeader>
      <Container padding="40px 0 300px">
        <Stack gap={3} justifyContent="center" alignItems="center" direction="column">
          <Container width={isMobileScreen ? 100 : 50} padding={isMobileScreen ? "0 24px" : ""}>
            <Header type="h3" margin="0 0 16px" bold>
              Prints:
            </Header>
            <Stack gap={3} direction="column">
              <Text size={18}>
                To ensure a smooth experience opening your print, we'd like to share some simple tips on how to open and flatten
                your unframed piece.
              </Text>
              <ol>
                <ListItem>
                  <Text size={18}>Locate a flat, clean, and dry surface to open the package.</Text>
                </ListItem>
                <ListItem>
                  <Text size={18}>
                    Carefully remove the print from the tube, ensuring the protective paper is gently taken off.
                  </Text>
                </ListItem>
                <ListItem>
                  <Text size={18}>
                    Unroll the print slowly onto the clean surface. To help it flatten, use clean, large books or paperweights on
                    the corners. Allow it to sit for 24 hours.
                  </Text>
                </ListItem>
              </ol>
            </Stack>
          </Container>

          <Container width={isMobileScreen ? 100 : 50} padding={isMobileScreen ? "0 24px" : ""}>
            <Header type="h3" margin="0 0 16px" bold>
              Framed Prints:
            </Header>
            <Stack gap={3} direction="column">
              <Stack gap={1}>
                <Text size={18}>Please see our tips below for how best to hang your framed prints.</Text>
                <Text size={16} color={colors.grey60}>
                  *It is important to note that our framed prints will have either a sawtooth hanger or a wire hanger depending on
                  where you have ordered from. Wire hanger: Australia, New Zealand, China, Hong Kong, Singapore, India, Indonesia,
                  Thailand, Taiwan, Vietnam, Maldives, Japan, North and South Korea. Sawtooth hanger: Everywhere else
                </Text>
              </Stack>
              <div>
                <Text size={18} bold>
                  Recommended tools:
                </Text>

                <ul>
                  <li>
                    <Text size={18}>Measuring tape</Text>
                  </li>
                  <li>
                    <Text size={18}>Pencil</Text>
                  </li>
                  <li>
                    <Text size={18}>Spirit level</Text>
                  </li>
                  <li>
                    <Text size={18}>If using a nail:</Text>
                    <ul>
                      <li>
                        <Text size={18}>Nail</Text>
                      </li>
                      <li>
                        <Text size={18}>Hammer</Text>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Text size={18}>If using a screw:</Text>
                    <ul>
                      <li>
                        <Text size={18}>Screw</Text>
                      </li>
                      <li>
                        <Text size={18}>Drill and drill bit</Text>
                      </li>
                      <li>
                        <Text size={18}>Screw driver</Text>
                      </li>
                      <li>
                        <Text size={18}>Drywall anchor</Text>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div>
                <Text size={18} bold>
                  Step by step guide:
                </Text>

                <ol>
                  <ListItem>
                    <Text size={18}>Choose the location for your frame.</Text>
                  </ListItem>
                  <ListItem>
                    <Text size={18}>
                      Use masking tape or have a friend hold the frame in place to visualize its placement. Avoid direct sunlight
                      to prevent colour fading.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size={18}>Mark the center of the top side of the frame on the wall.</Text>
                  </ListItem>
                  <ListItem>
                    <Text size={18}>
                      Measure the distance from the top edge of the frame to either the sawtooth hanger (whether it's positioned
                      at the center of the back of the print or on the frame itself) or to the wire hanger (considering the point
                      where it will stretch to when hung on something).
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size={18}>Make a mark below the center mark based on the measurement from step 4.</Text>
                  </ListItem>
                  <ListItem>
                    <Text size={18}>
                      Create a pilot hole for a drywall anchor at the marked spot. Alternatively, you can directly hammer a nail
                      into the wall.
                    </Text>
                  </ListItem>
                  <ListItem>
                    <Text size={18}>Hang the frame on the screw or nail, ensuring it's centered on the sawtooth hanger.</Text>
                  </ListItem>
                  <ListItem>
                    <Text size={18}>Hang the frame on the screw, ensuring it's centered on the sawtooth hanger.</Text>
                  </ListItem>
                </ol>
                <Stack direction="row" gap={2} flexWrap="wrap">
                  <Stack direction="column" gap={1}>
                    <img src={measureHanging} alt="measure" height={200} />
                    <Text color={colors.grey60}>Measure centre</Text>
                  </Stack>
                  <Stack direction="column" gap={1}>
                    <img src={sawtoothHanger} alt="hanging-guide" height={200} />
                    <Text color={colors.grey60}>Sawtooth hanger</Text>
                  </Stack>
                  <Stack direction="column" gap={1}>
                    <img src={wireHanger} alt="wire-hanging-guide" height={200} />
                    <Text color={colors.grey60}>Wire hanger</Text>
                  </Stack>
                </Stack>
              </div>
            </Stack>
          </Container>
        </Stack>
      </Container>
    </>
  );
};

export default HangingGuide;
