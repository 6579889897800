import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box, MenuItem, Select, SelectChangeEvent, Skeleton, Stack, Typography } from "@mui/material";
import { formattedPrice } from "../../helpers/money";
import { getIdNumber } from "../../helpers/shopify";
import { colors } from "../../theme";
import { Frame } from "../../types/product";
import FrameMockUp from "../FrameMockUp";
import { media } from "../../helpers/layout";
import RollingTextBanner from "../RollingTextBanner";
import { useAppState } from "../../state";
import Checkbox from "../Checkbox";
import { updateProduct } from "../../services/API";
import AuthContext from "../../state/auth";
import { giftCardId, styleFilterOptions, subjectFilterOptions } from "../../state/constants";
import { AristProductFragment, CountryCode, ProductCardProductFragment } from "../../generated/storefront";
import { AdminProductFragment } from "../../generated/graphql";
import GiftCard from "./GiftCard";
import WishlistBookmark from "../WishlistBookmark";
import { User } from "../../types/user";

export type ProductCardProduct = (
  | AristProductFragment
  | Omit<ProductCardProductFragment, "collections">
  | AdminProductFragment
) & {
  collections?: { nodes: { id: string; title: string }[] };
};

export type Props = {
  product: ProductCardProduct;
  artist: User | null;
  nextMonths?: boolean;
  indent?: boolean;
  frame?: Frame;
  refetch?: () => void;
};

export const Card = styled.div`
  position: relative;
  background-color: ${colors.grey02};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  flex-grow: 1;
  min-height: 220px;

  @media ${media.m} {
    padding: 24px;
  }
`;

const ProductCard = ({ product, artist, nextMonths = false, indent, frame = Frame.Natural }: Props) => {
  const giftCard = product.id === giftCardId;
  const id = typeof product.id === "number" ? product.id : getIdNumber(product.id);
  const { isMobileScreen, selectedCountry } = useAppState();
  const { customer, refetchCustomer, isAdmin } = useContext(AuthContext);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [updatingProduct, setUpdatingProduct] = useState(false);
  const image = product.images.nodes[0];
  const priceRange = (product as ProductCardProductFragment).priceRange || (product as AdminProductFragment).priceRangeV2;
  const { minVariantPrice } = priceRange;
  // const showDiscountedPrices = Boolean(basket?.lines.nodes.length && basket?.lines.nodes.length > 0);
  const showDiscountedPrices = true;
  const showDecimals = selectedCountry === CountryCode.At;
  const nextMonthText = "coming soon · next month · ";
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const artistName = artist ? `${artist.firstName} ${artist.lastName}` : "GoodMood";

  if (giftCard) return <GiftCard product={product} frame={frame} indent={indent} showDecimals={showDecimals} />;

  const handleUpdateTag = async (target: EventTarget & HTMLInputElement, id: string) => {
    setUpdatingProduct(true);
    await updateProduct(id, { [target.name]: target.checked });
    setUpdatingProduct(false);
  };

  const handleUpdateTags = async (event: SelectChangeEvent<string[]>) => {
    setUpdatingProduct(true);
    const gmTags = event.target.value;
    if (typeof gmTags === "string") return;
    await updateProduct(product.id, { gmTags });
    setUpdatingProduct(false);
  };

  const tags = [...styleFilterOptions, ...subjectFilterOptions, { label: "Studio Favourites", value: "studiofavourites" }].map(
    (tag) => ({ value: `gm.${tag.value}`, label: tag.label })
  );

  return (
    <Stack gap={1} height="100%" minHeight={350}>
      <Link to={`/products/${id}`} style={{ height: "100%", zIndex: 0 }} onClick={(e) => popoverOpen && e.preventDefault()}>
        <Stack height="100%" width="100%">
          {nextMonths && (
            <RollingTextBanner
              text={nextMonthText}
              background={colors.grey90}
              color={colors.white}
              size={isMobileScreen ? 6 : 14}
              speed={50}
            />
          )}
          <Card>
            {!imageLoaded && (
              <Skeleton
                width="100%"
                height="100%"
                animation="wave"
                variant="rectangular"
                sx={{ position: "absolute", bgcolor: colors.grey02 }}
              />
            )}
            <FrameMockUp frame={frame} image={image} fit="fill" imageLoaded={imageLoaded} setImageLoaded={setImageLoaded} />
          </Card>
          {nextMonths && (
            <RollingTextBanner
              text={nextMonthText}
              background={colors.grey90}
              color={colors.white}
              size={isMobileScreen ? 6 : 14}
              speed={50}
            />
          )}
          <Stack gap={1} direction="row" justifyContent="space-between" paddingX={indent ? 1.5 : 0} paddingTop={2}>
            <Stack gap={{ xs: 0.2, md: 0.25 }} width={{ xs: "82%", md: "89%" }}>
              <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
                {product.title}
              </Typography>
              <Stack gap={{ xs: 0.3, md: 0.5 }}>
                <Link to={`/artists/${artist?.id}`}>
                  <Typography variant="body2" textOverflow="ellipsis" overflow="hidden" style={{ whiteSpace: "nowrap" }}>
                    {artistName}
                  </Typography>
                </Link>
                <Stack gap={{ xs: 0.5, md: 1 }} direction="row">
                  <Stack direction="row" gap={{ xs: 0.5, md: 0.75 }}>
                    {/* <Typography variant="body2">From</Typography> */}
                    <Typography
                      variant="body2"
                      style={{ textDecoration: showDiscountedPrices ? "line-through" : "none" }}
                      color={showDiscountedPrices ? "text.disabled" : "primary"}
                    >
                      {formattedPrice(minVariantPrice.amount, minVariantPrice.currencyCode, showDecimals)}
                    </Typography>
                  </Stack>
                  {showDiscountedPrices && (
                    <Typography variant="body2" color={colors.red}>
                      {formattedPrice(Number(minVariantPrice.amount) * 0.75, minVariantPrice.currencyCode, showDecimals)} · 25%
                      OFF
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
            <WishlistBookmark
              productId={product.id}
              customer={customer}
              refetchCustomer={refetchCustomer}
              popoverOpen={popoverOpen}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
          </Stack>
        </Stack>
      </Link>
      {isAdmin && (
        <Stack gap={2}>
          <Stack gap={1} direction="row" flexWrap="wrap">
            <Checkbox
              name="pick"
              label="Pick"
              onChange={({ target }) => handleUpdateTag(target, product.id)}
              defaultChecked={product.tags.includes("pick")}
              disabled={updatingProduct}
            />
            <Checkbox
              name="favourite"
              label="Favourite"
              onChange={({ target }) => handleUpdateTag(target, product.id)}
              defaultChecked={product.tags.includes("favourite")}
              disabled={updatingProduct}
            />
            <Checkbox
              name="communityFavourite"
              label="Community favourites"
              onChange={({ target }) => handleUpdateTag(target, product.id)}
              defaultChecked={product.tags.includes("community_favourites")}
              disabled={updatingProduct}
            />
          </Stack>

          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {product.tags
              .filter((tag) => tag.startsWith("gm."))
              .map((value) => (
                <Checkbox
                  name="gmTags"
                  key={value}
                  onChange={() => {
                    // @ts-ignore
                    handleUpdateTags({ target: { value: product.tags.filter((tag) => tag !== value) } });
                  }}
                  defaultChecked={product.tags.includes(value)}
                  disabled={updatingProduct}
                  label={tags.find((tag) => tag.value === value)?.label}
                />
              ))}
          </Box>

          <Select
            id="tags-select"
            variant="filled"
            multiple
            displayEmpty
            value={product.tags.filter((tag) => tag.startsWith("gm."))}
            name="tags"
            onChange={handleUpdateTags}
            renderValue={(selected) => selected.map((s) => tags.find((tag) => tag.value === s)?.label).join(", ")}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "40vh",
                },
              },
            }}
          >
            {tags.map((tag) => (
              <MenuItem key={tag.value} value={tag.value}>
                <Checkbox
                  name="tags"
                  label={tag.label}
                  defaultChecked={product.tags.includes(tag.value)}
                  disabled={updatingProduct}
                />
              </MenuItem>
            ))}
          </Select>
        </Stack>
      )}
    </Stack>
  );
};

export default ProductCard;
