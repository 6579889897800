import { useEffect } from "react";
import styled from "styled-components";
import { colors } from "../../theme";
import { labelStyles, Variant } from "./styles";

export type Props = {
  label?: string;
  placeholder?: string;
  min: number;
  max: number;
  step: number;
  value: number;
  valueLabel?: string;
  onChange: (event: any) => void;
  name?: string;
  width?: string;
  options?: {
    value: number;
    label: string;
  }[];
  disabled?: boolean;
};

const Wrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${(p) => (p.width ? p.width : "100%")};
`;

const RangeWrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-bottom: 8px;
`;

const StyledLabel = styled.label`
  ${labelStyles(Variant.Standard)}
  margin-bottom: 12px;
`;

const ValueBubble = styled.div<{ percentage: number; hasOptions: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 50px;
  border: 1px solid ${colors.grey05};
  background-color: ${colors.white};
  border-radius: 5px;
  position: absolute;
  top: -8px;
  left: calc(${(p) => p.percentage}% - ${(p) => p.percentage / 2.5 + 5}px);
  z-index: 1;

  &::after {
    content: "";
    height: 10px;
    width: 10px;
    background-color: ${colors.white};
    border-right: 1px solid ${colors.grey05};
    border-bottom: 1px solid ${colors.grey05};
    border-radius: 4px;
    transform: rotate(45deg);
    bottom: -6px;
    position: absolute;
  }
`;

const StyledRange = styled.input<{ hasOptions: boolean; disabled?: boolean; valueLabel?: boolean }>`
  -webkit-appearance: none;
  margin: ${(p) => (p.valueLabel ? "50px 0 10px" : "18px 0 10px")};
  width: 100%;
  background: ${colors.grey10};
  background-image: linear-gradient(
    ${(p) => (p.disabled ? colors.grey40 : colors.black)},
    ${(p) => (p.disabled ? colors.grey40 : colors.black)}
  );
  background-repeat: no-repeat;
  border-radius: 20px;
  height: 6px;

  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    width: 100%;
    cursor: pointer;
    animate: 0.2s;
    height: 100%;
    background: transparent;
  }
  &::-webkit-slider-thumb {
    border: 5px solid ${(p) => (p.disabled ? colors.grey40 : colors.black)};
    height: 40px;
    width: 40px;
    border-radius: 100px;
    background: ${colors.white};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -17px;
    position: relative;
  }
  &::-webkit-fill-lower {
    background: ${(p) => (p.disabled ? colors.grey40 : colors.black)};
  }

  &::-webkit-fill-upper {
    background: ${colors.white};
  }
`;

const Datalist = styled.datalist`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  padding: 5px;
  width: 100%;
`;

const Option = styled.option`
  display: flex;

  &::before {
    width: 20px;
    height: 20px;
    background-color: ${colors.black};
  }
`;

const Range = ({ label, placeholder, min, max, step, name, onChange, value, valueLabel, options, width, disabled }: Props) => {
  const percentage = ((value - min) / (max - min)) * 100;

  useEffect(() => {
    const range = document.querySelector("input[type=range]") as HTMLInputElement;
    range.style.backgroundSize = ((value - min) * 100) / (max - min) + "% 100%";
  }, [value, min, max]);

  return (
    <Wrapper width={width}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <RangeWrapper width={width}>
        {!options && valueLabel && (
          <ValueBubble percentage={percentage} hasOptions={Boolean(options)}>
            {valueLabel}
          </ValueBubble>
        )}
        {options && (
          <Datalist id="options">
            {options.map((option) => (
              <Option key={option.value} value={option.value} label={option.label}></Option>
            ))}
          </Datalist>
        )}
        <StyledRange
          placeholder={placeholder}
          type="range"
          min={min}
          max={max}
          step={step}
          name={name}
          onChange={onChange}
          value={value}
          list="options"
          hasOptions={Boolean(options)}
          disabled={disabled}
          valueLabel={Boolean(valueLabel || options)}
        />
      </RangeWrapper>
    </Wrapper>
  );
};

export default Range;
