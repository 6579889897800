import { useContext, useEffect, useRef, useState } from "react";
import { Grid, IconButton, Stack } from "@mui/material";
import { ProductCardProductFragment, useGetProductsForProductCardLazyQuery } from "../../generated/storefront";
import { useAppState } from "../../state";
import ProductCard from "../../components/ProductCard";
import { appendArtistsToProducts } from "../../helpers/product";
import { User } from "../../types/user";
import Button from "../../components/Button";
import { useSearchParams } from "react-router-dom";
import { AddRounded, CheckRounded, ContentCopyRounded, IosShareRounded } from "@mui/icons-material";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import { Loader } from "../../components/Loader";
import FrameBadges from "../../components/FrameBadges";
import { Frame } from "../../types/product";
import { colors } from "../../theme";
import AuthContext from "../../state/auth";
import CustomerLoginModal from "../../components/LoginModal/customer";
import EmptyState from "./EmptyState";

type ProductCardProduct = ProductCardProductFragment & { artist: User | null };

const Unauthenticated = () => {
  const { isMobileScreen, selectedCountry, toggleGalleryWallBuilderIsOpen } = useAppState();
  const [searchParams] = useSearchParams();
  const { customer } = useContext(AuthContext);
  const [selectedFrame, setSelectedFrame] = useState<Frame>(Frame.Natural);
  const ref = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const paramIds = searchParams.get("ids") || undefined;
  const [wishlistProducts, setWishlistProducts] = useState<ProductCardProduct[]>([]);
  const wishlistIds = JSON.parse(localStorage.getItem("wishlist") || "[]");
  const productIds: string[] = paramIds ? paramIds.split(",") : wishlistIds;
  const [getProducts, { data, fetchMore }] = useGetProductsForProductCardLazyQuery();
  const [loading, setLoading] = useState(true);
  const hasMore = data?.products?.pageInfo?.hasNextPage || false;
  const afterCursor = data?.products?.pageInfo?.endCursor || "";

  useLoadMoreOnScroll(
    ref,
    () =>
      fetchMore({
        variables: {
          afterCursor,
        },
      }),
    hasMore,
    loading
  );

  const onShare = () => {
    const link = `${process.env.REACT_APP_SITE_URL}/wishlist?ids=${wishlistIds.join(",")}`;
    if (navigator.share) {
      navigator.share({
        title: "Wishlist",
        text: "Check out my wishlist",
        url: link,
      });
    } else {
      navigator.clipboard.writeText(link);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  };

  const onNewWishlist = () => {
    setShowLoginModal(true);
  };

  const getWishlistProducts = async () => {
    setLoading(true);
    if (productIds.length === 0) return setLoading(false);
    const { data } = await getProducts({
      variables: {
        query: `id:${productIds.join(" OR ")}`,
        country: selectedCountry,
        limit: 24,
      },
    });
    const productsWithArtists = await appendArtistsToProducts(data?.products?.nodes || []);
    setWishlistProducts(productsWithArtists);
    setLoading(false);
  };

  useEffect(() => {
    getWishlistProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productIds.length]);

  return (
    <>
      <Stack gap={2} paddingY={2} paddingX={{ xs: 2, md: 5 }}>
        <Stack gap={1} direction="row" alignItems="center" justifyContent="space-between">
          <Button tertiary onClick={onNewWishlist}>
            <Stack alignItems="center" direction="row" gap={0.75}>
              New wishlist
              <AddRounded />
            </Stack>
          </Button>
          <Stack direction="row" alignItems="center" gap={2}>
            {!isMobileScreen && (
              <Button secondary onClick={toggleGalleryWallBuilderIsOpen} style={{ whiteSpace: "nowrap" }}>
                Build a gallery wall
              </Button>
            )}
            {!customer && (
              <IconButton
                color="primary"
                onClick={onShare}
                disabled={wishlistProducts.length === 0}
                style={{ width: 40, height: 40, backgroundColor: colors.grey02 }}
              >
                {Boolean(navigator.share) ? (
                  <IosShareRounded />
                ) : copied ? (
                  <CheckRounded fontSize="inherit" />
                ) : (
                  <ContentCopyRounded fontSize="small" color="inherit" />
                )}
              </IconButton>
            )}
          </Stack>
          <FrameBadges selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} />
        </Stack>

        <>
          {wishlistProducts.length === 0 && !loading && <EmptyState />}
          <Grid container paddingY={{ xs: 2, md: 3 }} spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }} ref={ref}>
            {wishlistProducts.map((product) => (
              <Grid item key={product.id} xs={6} md={3} xl={3} xxl={2} xxxl={1.5}>
                <ProductCard product={product} artist={product.artist} frame={selectedFrame} />
              </Grid>
            ))}
          </Grid>
        </>

        {loading ? (
          <Loader />
        ) : wishlistProducts.length && hasMore ? (
          <Stack width="100%" alignItems="center" padding={5}>
            <Button onClick={() => fetchMore({ variables: { afterCursor } })}>Load more</Button>
          </Stack>
        ) : null}
      </Stack>
      {showLoginModal && <CustomerLoginModal onClose={() => setShowLoginModal(false)} />}
    </>
  );
};

export default Unauthenticated;
