import { useRef } from "react";
import { match } from "ts-pattern";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { Ratio } from "../../types/product";
import html2canvas from "html2canvas";
import storyMockUp from "../../assets/images/storyMockUp.png";
import storyMockUpLandscape from "../../assets/images/storyMockUpLandscape.png";
import postMockUp from "../../assets/images/postMockUp.png";
import postMockUpLandscape from "../../assets/images/postMockUpLandscape.png";
import postSquareDownload from "../../assets/images/postSquareDownload.png";
import storySquareDownload from "../../assets/images/storySquareDownload.png";
import Button from "../../components/Button";
import { colors } from "../../theme";
import { useAppState } from "../../state";
import { trackEvent } from "../../helpers/analytics";
import { getRatioFromVariants } from "../../helpers/product";
import { ArtistProductFragment } from "../../generated/graphql";

export const MockUpBackground = styled.img`
  position: relative;
  width: 100%;
`;

const MockUpImage = styled.img<{ positions: { top: string; left: string; width: string } }>`
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 1;
  ${({ positions }) => `
    top: ${positions.top};
    left: ${positions.left};
    width: ${positions.width};
  `}
`;

const DownloadItem = ({ product }: { product: ArtistProductFragment }) => {
  const { isMobileScreen } = useAppState();
  const storyRef = useRef<HTMLDivElement>(null);
  const postRef = useRef<HTMLDivElement>(null);
  const image = product.images.nodes[0];
  const isLandscape = image.height < image.width;
  const ratio = getRatioFromVariants(product.variants.nodes);

  const downloadImages = () => {
    const storyElement = storyRef.current as unknown as HTMLElement;
    const postElement = postRef.current as unknown as HTMLElement;
    downloadImage(storyElement, "Story");
    downloadImage(postElement, "Post");
  };

  const downloadImage = async (element: any, type: string) => {
    trackEvent("DownloadAsset");
    const canvas = await html2canvas(element, {
      useCORS: true,
      scale: 10,
    });

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = `${product.title} ${type}.jpg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const storyMockUpImage = match(ratio)
    .with(Ratio.Square, () => storySquareDownload)
    .with(Ratio.Rectangle, () => (isLandscape ? storyMockUpLandscape : storyMockUp))
    .exhaustive();

  const postMockUpImage = match(ratio)
    .with(Ratio.Square, () => postSquareDownload)
    .with(Ratio.Rectangle, () => (isLandscape ? postMockUpLandscape : postMockUp))
    .exhaustive();

  const postPositions = match(ratio)
    .with(Ratio.Square, () => ({ top: "39.7%", left: "49.6%", width: "56.4%" }))
    .with(Ratio.Rectangle, () =>
      isLandscape ? { top: "48.7%", left: "49.5%", width: "81.4%" } : { top: "48.7%", left: "49.7%", width: "54.7%" }
    )
    .exhaustive();

  const storyPositions = match(ratio)
    .with(Ratio.Square, () => ({ top: "39.8%", left: "50%", width: "64.8%" }))
    .with(Ratio.Rectangle, () =>
      isLandscape ? { top: "41%", left: "50%", width: "81.4%" } : { top: "40.9%", left: "49.8%", width: "62%" }
    )
    .exhaustive();

  return (
    <Stack
      bgcolor={colors.cardGrey}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      borderRadius={1}
      paddingY={{ xs: 1.5, md: 3 }}
      paddingX={{ xs: 3, md: 4 }}
    >
      <Stack direction="row" alignItems="center">
        <>
          <Stack justifyContent="center" ref={storyRef} style={{ position: "relative", width: "90px" }}>
            <MockUpBackground src={storyMockUpImage} alt="insta story mock up" />
            <MockUpImage src={image.src} height="auto" width="auto" alt="print" positions={storyPositions} />
          </Stack>

          <Stack justifyContent="center" ref={postRef} style={{ position: "absolute", width: "90px", zIndex: -1 }}>
            <MockUpBackground src={postMockUpImage} alt="insta post mock up" />
            <MockUpImage src={image.src} height="auto" width="auto" alt="print" positions={postPositions} />
          </Stack>
        </>

        {!isMobileScreen && (
          <Typography variant="h3" marginLeft={6}>
            {product.title}
          </Typography>
        )}
      </Stack>

      <Button onClick={downloadImages} secondary size="medium">
        Download
      </Button>
    </Stack>
  );
};

export default DownloadItem;
