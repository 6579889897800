import WebpImage from "react-image-webp";
import styled from "styled-components";
import { getFixedDimensions } from "../../helpers/layout";
import LazyLoadImage from "../Image/LazyLoadImage";
import { useAppState } from "../../state";
import { Frame, Ratio, Size } from "../../types/product";
import { Wrapper } from "./styles";
import { getFrameImages } from "./helpers";
import { sizeToTShirtSize } from "../../helpers/product";

type Props = {
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  size: Size;
  frame: Frame;
  scale?: number;
  shadow?: boolean;
};

export const FrameImage = styled(WebpImage)<{ w: number; h: number }>`
  z-index: 1;
  width: ${(p) => p.w}px;
  height: ${(p) => p.h}px;
  transition: height 0.5s ease, width 0.5s ease;
`;

export const MockUpImage = styled(LazyLoadImage)<{ w: number; h: number }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(p) => p.w}px;
  height: ${(p) => p.h}px;
  transition: height 0.5s ease, width 0.5s ease;
`;

const DynamicFrameMockUp = ({ image, size, frame, scale = 1, shadow = false }: Props) => {
  const { isMobileScreen } = useAppState();
  const isLandscape = image.height && image.width ? image.height < image.width : false;
  const ratio = image.width === image.height ? Ratio.Square : Ratio.Rectangle;
  const frameImages = getFrameImages(ratio, frame, sizeToTShirtSize(size), isLandscape);
  const fixedDims = getFixedDimensions(size, isLandscape, isMobileScreen);
  const { width, height } = fixedDims;
  const frameDifference = isMobileScreen ? 6 : 15;
  const imageWidth = width * scale;
  const imageHeight = height * scale;
  const frameWidth = (width + frameDifference) * scale;
  const frameHeight = (height + frameDifference) * scale;

  return (
    <Wrapper shadow={shadow} fixedWidth={frameWidth} fixedHeight={frameHeight} style={{ pointerEvents: "none" }}>
      {frame !== Frame.Unframed && (
        <FrameImage src={frameImages.src} webp={frameImages.webp} w={frameWidth} h={frameHeight} alt="frame" />
      )}
      <MockUpImage src={image.src} w={imageWidth} h={imageHeight} alt="print" />
    </Wrapper>
  );
};

export default DynamicFrameMockUp;
