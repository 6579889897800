import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Section } from "../../components/Layout";
import Tabs from "../../components/Tabs";
import Artists from "./Tabs/Artists";
import Orders from "./Tabs/Orders";
import useAdmin from "./useAdmin";
import { monthStart, today } from "../../helpers/time";
import Payments from "./Tabs/Payments";
import Products from "./Tabs/Products";
import Invoices from "./Tabs/Invoices";
import Extras from "./Tabs/Extras";
import Button from "../../components/Button";
import { authSignOutUser } from "../../services/Firebase";

export type OrderFilters = {
  dateRange: (string | null)[];
  vendorId?: string;
};

const tabs = [
  { value: "artists", label: "Artists" },
  { value: "products", label: "Products" },
  { value: "orders", label: "Orders" },
  { value: "payments", label: "Payments" },
  { value: "invoices", label: "Invoices" },
  { value: "extras", label: "Extras" },
];

const Admin = () => {
  const { hash } = useLocation();
  const tab = hash.replace("#", "");
  const [selectedTab, setSelectedTab] = useState<string>(tab || tabs[0].value);
  const { users, searchedUsers, fetchUsers, handleArtistSearch } = useAdmin();
  const [orderFilters, setOrderFilters] = useState<OrderFilters>({ dateRange: [monthStart, today] });
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
  const navigate = useNavigate();

  const onTabChange = (tab: number | string) => {
    window.location.hash = tab as string;
    setSelectedTab(tab as string);
  };

  const getUsers = async () => {
    setLoadingUsers(true);
    await fetchUsers();
    setLoadingUsers(false);
  };

  useEffect(() => {
    if (!users && !loadingUsers) {
      getUsers();
    }
  });

  return (
    <Section>
      <Flex justifyContent="space-between">
        <Tabs onTabChange={onTabChange} selectedTab={selectedTab} tabs={tabs} />
        <Button secondary size="medium" onClick={() => authSignOutUser(navigate)}>
          Logout
        </Button>
      </Flex>
      {selectedTab === "artists" && <Artists users={searchedUsers || users} handleSearch={handleArtistSearch} />}
      {selectedTab === "products" && <Products />}
      {selectedTab === "orders" && (
        <Orders
          users={searchedUsers || users}
          handleSearch={handleArtistSearch}
          orderFilters={orderFilters}
          setOrderFilters={setOrderFilters}
        />
      )}
      {selectedTab === "payments" && <Payments />}
      {selectedTab === "invoices" && <Invoices />}
      {selectedTab === "extras" && <Extras />}
    </Section>
  );
};

export default Admin;
