import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { MenuItem, Popover } from "@mui/material";
import { PersonOutlineRounded } from "@mui/icons-material";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import AuthContext from "../../state/auth";
import { NavItem } from "./styles";

const Account = ({ isMobile }: { isMobile?: boolean }) => {
  const { user, customer, isAdmin, loading, loadingCustomer } = useContext(AuthContext);
  const { navTransparent } = useAppState();
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  if (loading || loadingCustomer) return null;

  return (
    <>
      {isAdmin ? (
        <Link to="/admin">
          <NavItem fontSize={14} color={navTransparent ? colors.white : colors.black}>
            Admin
          </NavItem>
        </Link>
      ) : customer?.firstName ? (
        <Link to="/customer/account">
          <NavItem
            fontSize={{ xs: 14, md: 16 }}
            color={{ xs: colors.grey40, md: navTransparent ? colors.white : colors.black }}
          >{`${customer.firstName?.charAt(0)}${customer.lastName?.charAt(0)}`}</NavItem>
        </Link>
      ) : user ? (
        <Link to="/profile">
          <NavItem
            fontSize={{ xs: 14, md: 16 }}
            color={{ xs: colors.grey40, md: navTransparent ? colors.white : colors.black }}
          >{`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}</NavItem>
        </Link>
      ) : (
        <>
          <NavItem
            onClick={(event) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            }}
            fontSize={{ xs: 14, md: 16 }}
            color={{ xs: colors.grey40, md: navTransparent ? colors.white : colors.black }}
          >
            {isMobile ? "Login" : <PersonOutlineRounded fill={navTransparent ? colors.white : colors.black} fontSize="small" />}
          </NavItem>
          <Popover
            id={popoverId}
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPopover-paper": {
                minWidth: 200,
              },
            }}
          >
            <Link to="/login/artist">
              <MenuItem>Artist</MenuItem>
            </Link>
            <Link to="/login/customer">
              <MenuItem>Customer</MenuItem>
            </Link>
          </Popover>
        </>
      )}
    </>
  );
};

export default Account;
