import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { SearchProductFragment, useSearchQuery } from "../../generated/storefront";
import { User } from "../../types/user";
import { appendArtistsToProducts, productSearchFilter } from "../../helpers/product";
import { Loader } from "../../components/Loader";
import { useAppState } from "../../state";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import Button from "../../components/Button";
import ProductCard from "../../components/ProductCard";

type ProductWithArtist = SearchProductFragment & { artist: User | null };

const Search = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { selectedCountry, isMobileScreen } = useAppState();
  const [serachParams] = useSearchParams();
  const searchQs = serachParams.get("qs") || "";
  const {
    data,
    loading: loadingProducts,
    fetchMore,
    refetch,
  } = useSearchQuery({
    variables: { query: searchQs, limit: 250, country: selectedCountry },
    fetchPolicy: "no-cache",
  });
  const [productsWithArtists, setProductsWithArtist] = useState<ProductWithArtist[]>();
  const hasMore = data?.search.pageInfo.hasNextPage || false;
  const afterCursor = data?.search.pageInfo.endCursor || "";

  useLoadMoreOnScroll(
    ref,
    () =>
      fetchMore({
        variables: {
          afterCursor,
        },
      }),
    hasMore,
    loadingProducts
  );

  useEffect(() => {
    const getProductsArtists = async (products: SearchProductFragment[]) => {
      const productsWithArtist = await appendArtistsToProducts(products);
      setProductsWithArtist(productsWithArtist);
    };

    const products = data?.search.nodes.filter(productSearchFilter) as SearchProductFragment[];
    if (products) {
      getProductsArtists(products);
    }
  }, [data?.search]);

  return (
    <>
      <Helmet>
        <title>{`Search results for ${searchQs} | GoodMood`}</title>
      </Helmet>
      <Box paddingX={{ xs: 0, md: 5 }} paddingY={{ xs: 3 }} ref={ref}>
        {productsWithArtists ? (
          <Stack gap={3} alignItems="center">
            <Typography variant="h3">
              Showing {productsWithArtists.length === 250 ? "250+" : productsWithArtists.length} results for "{searchQs}"
            </Typography>

            <Grid container spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }}>
              {productsWithArtists.map((product) => (
                <Grid item xs={12 / 2} md={12 / 4} key={product.id}>
                  <ProductCard product={product} artist={product.artist} indent={isMobileScreen} refetch={refetch} />
                </Grid>
              ))}
            </Grid>
            {loadingProducts ? (
              <Loader />
            ) : hasMore ? (
              <Stack width="100%" alignItems="center" padding={5}>
                <Button
                  onClick={() =>
                    fetchMore({
                      variables: {
                        afterCursor,
                      },
                    })
                  }
                >
                  Load more
                </Button>
              </Stack>
            ) : null}
          </Stack>
        ) : (
          <Loader />
        )}
      </Box>
    </>
  );
};

export default Search;
