import { ChangeEvent, FormEvent, SyntheticEvent, useState } from "react";
import Button from "../../../../components/Button";
import { InvoiceStatus, NewDraftInvoiceInput } from "../../../../types/invoice";
import Modal from "../../../../components/Modal";
import { Form } from "../../../../components/Layout";
import { Header, Text } from "../../../../components/Text";
import { Autocomplete, Stack, TextField } from "@mui/material";
import Input from "../../../../components/Form/Input";
import { colors } from "../../../../theme";
import { validate } from "../../../../helpers/formValidation";
import Dropdown from "../../../../components/Form/Dropdown";
import { last12Months } from "../../../../state/constants";
import useAdmin from "../../useAdmin";
import { createOrUpdateInvoice } from "../../../../services/Firebase/invoices";
import { getUserById } from "../../../../services/Firebase";

type Props = {
  onClose: () => void;
  refetch: () => void;
};

const CreateInvoiceModal = ({ onClose, refetch }: Props) => {
  const { searchedUsers, handleArtistSearch } = useAdmin();
  const [error, setError] = useState<string>();
  const [errors, setErrors] = useState<{ [field: string]: string }>();
  const [invoiceState, setInvoiceState] = useState<NewDraftInvoiceInput>({
    date: last12Months[0].value,
    amount: 0,
    artistId: "",
  });

  const onArtistChange = (
    _: SyntheticEvent<Element, Event>,
    value: {
      value: string;
      label: string;
    } | null
  ) => {
    setErrors({ ...errors, artistId: "" });
    setInvoiceState({ ...invoiceState, artistId: value?.value || "" });
  };

  const onInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    setErrors({ ...errors, [target.name]: "" });
    if (!target.value) return;
    setInvoiceState({ ...invoiceState, [target.name]: target.value });
  };

  const createNewInvoice = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = validate(invoiceState);
    if (result.error) return setErrors(result.errors);
    const artist = await getUserById(result.state.artistId);
    if (!artist) return setError("Artist not found");
    await createOrUpdateInvoice({
      date: new Date(result.state.date),
      artist: artist?.firstName + " " + artist?.lastName,
      artistId: result.state.artistId,
      paypalEmail: artist.paypalEmail || "",
      amount: Number(result.state.amount),
      vatNumber: artist.vatRegistered?.vatNumber || "",
      status: InvoiceStatus.DRAFT,
    });
    onClose();
    refetch();
  };

  return (
    <Modal onClose={onClose}>
      <Stack gap={3} alignItems="stretch" height="100%">
        <Header align="center">Create Invoice</Header>
        <Form style={{ height: "100%" }} onSubmit={createNewInvoice}>
          <Stack direction="column" gap={1}>
            <Autocomplete
              options={searchedUsers?.map((user) => ({ value: user.id, label: user.firstName + " " + user.lastName })) || []}
              onChange={onArtistChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ zIndex: 0 }}
                  label="Choose artist"
                  onChange={(event) => handleArtistSearch(event)}
                />
              )}
            />
            {errors?.artistId && (
              <Text size={14} color={colors.error}>
                *{errors?.artistId}
              </Text>
            )}
            <Input
              label="Amount"
              name="amount"
              id="amount"
              onChange={onInputChange}
              type="number"
              step=".01"
              error={errors?.amount}
            />
            <Dropdown
              label="Month"
              options={last12Months}
              value={last12Months.find((m) => m.value === invoiceState.date)}
              name="date"
              onChange={({ value }) => setInvoiceState({ ...invoiceState, date: value })}
              width="auto"
              style={{ zIndex: 5 }}
            />
          </Stack>
          <Stack justifyContent="end" gap={1} flexGrow={1}>
            {error && <Text color={colors.error}>{error}</Text>}
            <Button>Create</Button>
          </Stack>
        </Form>
      </Stack>
    </Modal>
  );
};

export default CreateInvoiceModal;
