import { useRef, useState } from "react";
import { match } from "ts-pattern";
import { format } from "date-fns";
import { IconButton, MenuItem, Select, Stack } from "@mui/material";
import { Delete } from "@mui/icons-material";
import Button from "../../../../components/Button";
import { createInvoicePdf, markInvoiceAsPaid } from "../../helpers/invoices";
import { getInvoiceSubtotal } from "../../../../helpers/prices";
import { InvoiceStatus } from "../../../../types/invoice";
import { createPayout } from "../../../../services/API";
import InvoicePdf from "./InvoicePdf";
import { InvoiceRow } from ".";
import { deleteInvoice, getInvoiceById, updateInvoice } from "../../../../services/Firebase/invoices";
import { CurrencyCode } from "../../../../generated/storefront";
import { updateUser } from "../../../../services/Firebase/users";
import { sendArtistInvoiceEmail } from "../../../../services/API/emails";

type Props = {
  row: InvoiceRow;
  fetchInvoices: () => Promise<void>;
};

const InvoiceActionButtons = ({ row, fetchInvoices }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState(row.currency);
  const currencies = Object.values(CurrencyCode);

  const sendEmail = async () => {
    const invoice = await getInvoiceById(row.id);
    if (!invoice) {
      return alert("Invoice not found.");
    }
    if (!row.email) {
      return alert("No email address found for this artist.");
    }
    if (!invoice.pdfUrl) {
      return alert("No PDF found for this invoice.");
    }
    try {
      await sendArtistInvoiceEmail({
        to: { email: row.email, name: row.artist },
        pdfUrl: invoice.pdfUrl,
        invoiceNumber: row.invoiceNumber,
        dynamicTemplateData: {
          artistName: row.artist,
          month: format(row.issueDate.toDate(), "MMMM"),
          year: format(row.issueDate.toDate(), "yyyy"),
        },
      });
    } catch (error) {
      console.error(error);
      alert("Could not send email. Please try again.");
    }
  };

  const onApprove = async () => {
    setLoading(true);
    if (ref.current) {
      try {
        await createInvoicePdf(row.id, row.invoiceNumber, ref.current);
        await updateInvoice(row.id, { status: InvoiceStatus.APPROVED });
        fetchInvoices();
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        alert("Something went wrong, please try again.");
      }
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await deleteInvoice(row.id);
      fetchInvoices();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      alert("Something went wrong, please try again.");
    }
  };

  const markAsPaid = async () => {
    setLoading(true);
    try {
      await fetchInvoices();
      await markInvoiceAsPaid(row.id);
      await updateUser(row.artistId, { currency });
      await sendEmail();
      fetchInvoices();
      setLoading(false);
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      alert(`Something went wrong: ${error?.message}`);
    }
  };

  const onPay = async () => {
    if (!row.paypalEmail) {
      return alert("No paypal email address found for this artist.");
    }
    setLoading(true);
    try {
      await createPayout({
        email: row.paypalEmail,
        amount: getInvoiceSubtotal(row),
        month: format(row.issueDate.toDate(), "MMMM yyyy"),
        invoiceNumber: row.invoiceNumber,
        currency,
      });
      await updateUser(row.artistId, { currency });
      await markAsPaid();
    } catch (error: any) {
      console.error(error);
      setLoading(false);
      alert(`Something went wrong - ${error?.message}`);
    }
  };

  const onResendEmail = async () => {
    setLoading(true);
    await sendEmail();
    setLoading(false);
  };

  return match(row.status)
    .with(InvoiceStatus.DRAFT, () => (
      <>
        <Stack direction="row" gap={1} alignItems="center">
          <Button size="small" onClick={onApprove} loading={loading}>
            Approve
          </Button>
          <IconButton size="small" onClick={onDelete}>
            <Delete fontSize="small" />
          </IconButton>
        </Stack>
        <div style={{ marginLeft: "200px" }}>
          <InvoicePdf ref={ref} invoiceData={row} />
        </div>
      </>
    ))
    .with(InvoiceStatus.APPROVED, () => (
      <Stack direction="row" gap={1} alignItems="center">
        <Button size="small" onClick={onPay} loading={loading} fullWidth>
          Pay
        </Button>
        <Select
          size="small"
          variant="filled"
          onChange={({ target }) => setCurrency(target.value as CurrencyCode)}
          value={currency}
          style={{ minWidth: "100px" }}
        >
          {currencies.map((c) => (
            <MenuItem key={c} value={c}>
              {c}
            </MenuItem>
          ))}
        </Select>
        <Button size="small" onClick={markAsPaid} loading={loading} fullWidth>
          Mark as paid
        </Button>
      </Stack>
    ))
    .with(InvoiceStatus.PAID, () => (
      <Button size="small" onClick={onResendEmail} loading={loading} fullWidth>
        Resend email
      </Button>
    ))
    .exhaustive();
};

export default InvoiceActionButtons;
