import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { match } from "ts-pattern";
import { Box, Grid, Stack } from "@mui/material";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import galleryWalls from "../../assets/images/Inspiration.png";
import galleryWallsWebp from "../../assets/images/Inspiration.webp";
import { useGetAllCollectionsQuery } from "../../generated/storefront";
import Tabs from "../../components/Tabs";
import CollectionCarousel from "./CollectionCarousel";
import { Loader } from "../../components/Loader";
import Switch from "../../components/Switch";
import { useAppState } from "../../state";
import CollectionCard from "../../components/Card/Collection";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";

const GalleryWalls = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useAppState();
  const [selectedTab, setSelectedTab] = useState<"interiors" | "artwork">("interiors");
  const { data, loading, fetchMore } = useGetAllCollectionsQuery();
  const interiors = data?.collections.nodes.filter((collection) => collection.image?.url) || [];
  const collections = interiors.filter((collection) => !/\d/.test(collection.title));
  const pageInfo = data?.collections.pageInfo;
  const hasMore = pageInfo?.hasNextPage || false;

  const loadMore = async () => {
    if (!pageInfo?.hasNextPage || !pageInfo.endCursor) return;
    await fetchMore({
      variables: {
        afterCursor: pageInfo.endCursor,
      },
    });
  };

  useLoadMoreOnScroll(ref, loadMore, hasMore, loading);

  return (
    <>
      <Helmet>
        <title>Gallery Walls | GoodMood</title>
        <meta
          name="description"
          content="Explore our diverse collection of curated walls. Whether you choose to shop them as they are or seek inspiration to craft your own unique collection, we've got you covered."
        />
      </Helmet>
      <PageHeader
        header="Gallery walls"
        background={colors.beige}
        image={galleryWalls}
        webpImage={galleryWallsWebp}
        imageLink="/collections/434403770582"
      >
        Explore our diverse collection of curated walls. Whether you choose to shop them as they are or seek inspiration to craft
        your own unique collection, we've got you covered.
      </PageHeader>
      <Box paddingY={3} ref={ref}>
        <Stack gap={3}>
          <Box paddingX={{ xs: 2, md: 5 }}>
            {isMobileScreen ? (
              <Switch
                fullWidth
                options={[
                  { label: "View interiors", value: "interiors" },
                  { label: "View artwork", value: "artwork" },
                ]}
                selected={selectedTab}
                onChange={(tab) => setSelectedTab(tab as "interiors" | "artwork")}
              />
            ) : (
              <Tabs
                tabs={[
                  {
                    label: "View interiors",
                    value: "interiors",
                  },
                  {
                    label: "View artwork",
                    value: "artwork",
                  },
                ]}
                onTabChange={(tab) => setSelectedTab(tab)}
                selectedTab={selectedTab}
              />
            )}
          </Box>

          {loading ? (
            <Loader />
          ) : (
            match(selectedTab)
              .with("interiors", () => (
                <Box paddingX={{ xs: 2, md: 5 }}>
                  <Grid container spacing={3} rowSpacing={{ xs: 3, md: 9 }}>
                    {interiors.map((collection) => (
                      <Grid item key={collection.id} xs={12} md={4}>
                        <CollectionCard collection={collection} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))
              .with("artwork", () => (
                <Stack gap={{ xs: 6, md: 9 }}>
                  {collections.map((collection) => (
                    <CollectionCarousel key={collection.id} collection={collection} />
                  ))}
                </Stack>
              ))
              .exhaustive()
          )}
        </Stack>
      </Box>
    </>
  );
};

export default GalleryWalls;
