import styled from "styled-components";
import { colors } from "../../theme";
import { Icon } from "../Icon";

const Wrapper = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
`;

const Container = styled.div`
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 100px 0;
`;

const Loading = styled.div`
  @keyframes loading {
    0% {
      height: 0;
    }
    25% {
      height: 30px;
    }
    50% {
      height: 60px;
    }
    75% {
      height: 30px;
    }
    100% {
      height: 0;
    }
  }
  width: 60px;
  background-color: ${colors.white};
  position: absolute;
  top: 0;
  left: 0;
  animation: loading 1s infinite linear;
  z-index: -1;
`;

const Background = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 100px;
  background-color: ${colors.mint};
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
`;

export const Loader = () => (
  <Container>
    <Wrapper>
      <Icon icon="logo" size={45} />
      <Loading />
      <Background />
    </Wrapper>
  </Container>
);
