import { CurrencyCode } from './storefront';
import { CountryCode } from './storefront';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type BulkOperation = {
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  objectCount?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type BulkOperationResult = {
  __typename?: 'BulkOperationResult';
  bulkOperation: BulkOperation;
  userErrors: Array<UserError>;
};

export type Collection = {
  __typename?: 'Collection';
  id: Scalars['ID']['output'];
  image?: Maybe<Image>;
  products?: Maybe<ProductsResponse>;
  ruleSet?: Maybe<CollectionRuleSet>;
  title: Scalars['String']['output'];
};


export type CollectionProductsArgs = {
  first: Scalars['Int']['input'];
};

export type CollectionRule = {
  __typename?: 'CollectionRule';
  condition: Scalars['String']['output'];
};

export type CollectionRuleSet = {
  __typename?: 'CollectionRuleSet';
  rules: Array<CollectionRule>;
};

export enum CollectionSortKeys {
  Id = 'ID',
  Relevance = 'RELEVANCE',
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT'
}

export type CollectionsResponse = {
  __typename?: 'CollectionsResponse';
  nodes: Array<Collection>;
  pageInfo: PageInfo;
};

export type ContextInput = {
  country?: InputMaybe<CountryCode>;
};

export { CountryCode };

export { CurrencyCode };

export type DiscountAllocation = {
  __typename?: 'DiscountAllocation';
  allocatedAmountSet: MoneyBag;
};

export enum Frame {
  Black = 'black',
  Natural = 'natural',
  Unframed = 'unframed',
  White = 'white'
}

export type Image = {
  __typename?: 'Image';
  height: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  src: Scalars['String']['output'];
  width: Scalars['Int']['output'];
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  nodes: Array<Image>;
};

export type LineItem = {
  __typename?: 'LineItem';
  discountAllocations: Array<DiscountAllocation>;
  id: Scalars['String']['output'];
  originalTotalSet: MoneyBag;
  product: Product;
  quantity: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  variantTitle?: Maybe<Scalars['String']['output']>;
  vendor: Scalars['String']['output'];
};

export type LineItems = {
  __typename?: 'LineItems';
  nodes: Array<LineItem>;
};

export type Metafield = {
  __typename?: 'Metafield';
  key: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  type: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type MetafieldAccessUpdateInput = {
  admin: MetafieldAdminAccess;
  storefront?: InputMaybe<MetafieldStorefrontAccess>;
};

export enum MetafieldAdminAccess {
  MerchantReadWrite = 'MERCHANT_READ_WRITE',
  PublicRead = 'PUBLIC_READ'
}

export type MetafieldDefinition = {
  __typename?: 'MetafieldDefinition';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
};

export type MetafieldDefinitionCreatePayload = {
  __typename?: 'MetafieldDefinitionCreatePayload';
  createdDefinition: MetafieldDefinition;
  userErrors: Array<UserError>;
};

export type MetafieldDefinitionDeletePayload = {
  __typename?: 'MetafieldDefinitionDeletePayload';
  deletedDefinitionId: Scalars['ID']['output'];
  userErrors: Array<UserError>;
};

export type MetafieldDefinitionInput = {
  access: MetafieldAccessUpdateInput;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  namespace: Scalars['String']['input'];
  ownerType: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type MetafieldDefinitionUpdateInput = {
  access: MetafieldAccessUpdateInput;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
  namespace: Scalars['String']['input'];
  ownerType: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MetafieldDefinitionUpdatePayload = {
  __typename?: 'MetafieldDefinitionUpdatePayload';
  updatedDefinition: MetafieldDefinition;
  userErrors: Array<UserError>;
};

export enum MetafieldStorefrontAccess {
  PublicRead = 'PUBLIC_READ'
}

export type MoneyBag = {
  __typename?: 'MoneyBag';
  presentmentMoney: Price;
  shopMoney: Price;
};

export type Mutation = {
  __typename?: 'Mutation';
  bulkOperationCancel: BulkOperationResult;
  bulkOperationRunQuery: BulkOperationResult;
  metafieldDefinitionCreate: MetafieldDefinitionCreatePayload;
  metafieldDefinitionDelete: MetafieldDefinitionDeletePayload;
  metafieldDefinitionUpdate: MetafieldDefinitionUpdatePayload;
  productChangeStatus: ProductChangeStatusResult;
};


export type MutationBulkOperationCancelArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBulkOperationRunQueryArgs = {
  query: Scalars['String']['input'];
};


export type MutationMetafieldDefinitionCreateArgs = {
  definition: MetafieldDefinitionInput;
};


export type MutationMetafieldDefinitionDeleteArgs = {
  deleteAllAssociatedMetafields?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationMetafieldDefinitionUpdateArgs = {
  definition: MetafieldDefinitionUpdateInput;
};


export type MutationProductChangeStatusArgs = {
  productId: Scalars['ID']['input'];
  status: ProductStatus;
};

export type Node = BulkOperation & {
  __typename?: 'Node';
  completedAt?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  errorCode?: Maybe<Scalars['String']['output']>;
  fileSize?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  objectCount?: Maybe<Scalars['Int']['output']>;
  status: Scalars['String']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export enum OptionType {
  Frame = 'frame',
  Size = 'size'
}

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['String']['output'];
  discountCode: Scalars['String']['output'];
  id: Scalars['String']['output'];
  lineItems: LineItems;
};


export type OrderLineItemsArgs = {
  first: Scalars['Int']['input'];
};

export enum OrderSortKeys {
  CreatedAt = 'CREATED_AT'
}

export type OrdersResponse = {
  __typename?: 'OrdersResponse';
  nodes: Array<Order>;
  pageInfo: PageInfo;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['String']['output'];
  currencyCode: CurrencyCode;
};

export type PriceRange = {
  __typename?: 'PriceRange';
  maxVariantPrice: Price;
  minVariantPrice: Price;
};

export type Product = {
  __typename?: 'Product';
  contextualPricing: ProductContextualPricing;
  defaultCursor: Scalars['String']['output'];
  descriptionHtml?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  images: ImageConnection;
  metafield: Metafield;
  priceRangeV2: PriceRange;
  productType: Scalars['String']['output'];
  status: ProductStatus;
  tags: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  variants: ProductVariantConnection;
  vendor: Scalars['String']['output'];
};


export type ProductContextualPricingArgs = {
  context?: InputMaybe<ContextInput>;
};


export type ProductImagesArgs = {
  first: Scalars['Int']['input'];
};


export type ProductMetafieldArgs = {
  key: Scalars['String']['input'];
  namespace: Scalars['String']['input'];
};


export type ProductVariantsArgs = {
  first: Scalars['Int']['input'];
};

export type ProductChangeStatusResult = {
  __typename?: 'ProductChangeStatusResult';
  product: Product;
  userErrors: Array<UserError>;
};

export type ProductContextualPricing = {
  __typename?: 'ProductContextualPricing';
  maxVariantPrice?: Maybe<ProductVariantContextualPricing>;
  minVariantPrice?: Maybe<ProductVariantContextualPricing>;
  priceRange?: Maybe<ProductVariantContextualPriceRange>;
};

export enum ProductSortKeys {
  CreatedAt = 'CREATED_AT'
}

export enum ProductStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT'
}

export type ProductVariant = {
  __typename?: 'ProductVariant';
  contextualPricing: ProductVariantContextualPricing;
  id: Scalars['String']['output'];
  price: Scalars['String']['output'];
  selectedOptions: Array<SelectedOption>;
  sku: Scalars['String']['output'];
};


export type ProductVariantContextualPricingArgs = {
  context?: InputMaybe<ContextInput>;
};

export type ProductVariantConnection = {
  __typename?: 'ProductVariantConnection';
  nodes: Array<ProductVariant>;
};

export type ProductVariantContextualPriceRange = {
  __typename?: 'ProductVariantContextualPriceRange';
  maxVariantPrice?: Maybe<Price>;
  minVariantPrice?: Maybe<Price>;
};

export type ProductVariantContextualPricing = {
  __typename?: 'ProductVariantContextualPricing';
  price: Price;
};

export type ProductsResponse = {
  __typename?: 'ProductsResponse';
  nodes: Array<Product>;
  pageInfo: PageInfo;
};

export type Query = {
  __typename?: 'Query';
  collections: CollectionsResponse;
  node: Node;
  orders?: Maybe<OrdersResponse>;
  product: Product;
  products: ProductsResponse;
};


export type QueryCollectionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<CollectionSortKeys>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrdersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<OrderSortKeys>;
};


export type QueryProductArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  sortKey?: InputMaybe<ProductSortKeys>;
};

export enum RectangleSize {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3'
}

export type SelectedOption = {
  __typename?: 'SelectedOption';
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum Size {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  Size_12X12 = 'SIZE_12X12',
  Size_20X20 = 'SIZE_20X20',
  Size_28X28 = 'SIZE_28X28'
}

export enum SizeOrFrame {
  A1 = 'A1',
  A2 = 'A2',
  A3 = 'A3',
  Black = 'BLACK',
  Natural = 'NATURAL',
  Size_12X12 = 'SIZE_12X12',
  Size_20X20 = 'SIZE_20X20',
  Size_28X28 = 'SIZE_28X28',
  Unframed = 'UNFRAMED',
  White = 'WHITE'
}

export enum SquareSize {
  Size_12X12 = 'SIZE_12X12',
  Size_20X20 = 'SIZE_20X20',
  Size_28X28 = 'SIZE_28X28'
}

export type UserError = {
  __typename?: 'UserError';
  field?: Maybe<Scalars['String']['output']>;
  message?: Maybe<Scalars['String']['output']>;
};

export type ArtistProductVariantFragment = { __typename?: 'ProductVariant', id: string, price: string, selectedOptions: Array<{ __typename?: 'SelectedOption', name: string, value: string }> };

export type ArtistProductFragment = { __typename?: 'Product', id: string, title: string, productType: string, status: ProductStatus, images: { __typename?: 'ImageConnection', nodes: Array<{ __typename?: 'Image', src: string, height: number, width: number }> }, variants: { __typename?: 'ProductVariantConnection', nodes: Array<{ __typename?: 'ProductVariant', id: string, price: string, selectedOptions: Array<{ __typename?: 'SelectedOption', name: string, value: string }> }> } };

export type GetArtistProductsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  sortKey?: InputMaybe<ProductSortKeys>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetArtistProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsResponse', nodes: Array<{ __typename?: 'Product', id: string, title: string, productType: string, status: ProductStatus, images: { __typename?: 'ImageConnection', nodes: Array<{ __typename?: 'Image', src: string, height: number, width: number }> }, variants: { __typename?: 'ProductVariantConnection', nodes: Array<{ __typename?: 'ProductVariant', id: string, price: string, selectedOptions: Array<{ __typename?: 'SelectedOption', name: string, value: string }> }> } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type AdminProductFragment = { __typename?: 'Product', id: string, title: string, descriptionHtml?: string | null, vendor: string, tags: Array<string>, productType: string, images: { __typename?: 'ImageConnection', nodes: Array<{ __typename?: 'Image', id: string, src: string, height: number, width: number }> }, variants: { __typename?: 'ProductVariantConnection', nodes: Array<{ __typename?: 'ProductVariant', id: string, price: string, sku: string, selectedOptions: Array<{ __typename?: 'SelectedOption', name: string, value: string }> }> }, orientation: { __typename?: 'Metafield', value: string, type: string }, medium: { __typename?: 'Metafield', value: string, type: string }, priceRangeV2: { __typename?: 'PriceRange', minVariantPrice: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode }, maxVariantPrice: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode } } };

export type GetAdminProductsQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  query?: InputMaybe<Scalars['String']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  sortKey?: InputMaybe<ProductSortKeys>;
  reverse?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetAdminProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductsResponse', nodes: Array<{ __typename?: 'Product', id: string, title: string, descriptionHtml?: string | null, vendor: string, tags: Array<string>, productType: string, images: { __typename?: 'ImageConnection', nodes: Array<{ __typename?: 'Image', id: string, src: string, height: number, width: number }> }, variants: { __typename?: 'ProductVariantConnection', nodes: Array<{ __typename?: 'ProductVariant', id: string, price: string, sku: string, selectedOptions: Array<{ __typename?: 'SelectedOption', name: string, value: string }> }> }, orientation: { __typename?: 'Metafield', value: string, type: string }, medium: { __typename?: 'Metafield', value: string, type: string }, priceRangeV2: { __typename?: 'PriceRange', minVariantPrice: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode }, maxVariantPrice: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode } } }>, pageInfo: { __typename?: 'PageInfo', endCursor?: string | null, hasNextPage: boolean } } };

export type GetAdminProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetAdminProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, title: string, descriptionHtml?: string | null, vendor: string, tags: Array<string>, productType: string, images: { __typename?: 'ImageConnection', nodes: Array<{ __typename?: 'Image', id: string, src: string, height: number, width: number }> }, variants: { __typename?: 'ProductVariantConnection', nodes: Array<{ __typename?: 'ProductVariant', id: string, price: string, sku: string, selectedOptions: Array<{ __typename?: 'SelectedOption', name: string, value: string }> }> }, orientation: { __typename?: 'Metafield', value: string, type: string }, medium: { __typename?: 'Metafield', value: string, type: string }, priceRangeV2: { __typename?: 'PriceRange', minVariantPrice: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode }, maxVariantPrice: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode } } } };

export type GetBulkOrdersMutationVariables = Exact<{
  query: Scalars['String']['input'];
}>;


export type GetBulkOrdersMutation = { __typename?: 'Mutation', bulkOperationRunQuery: { __typename?: 'BulkOperationResult', bulkOperation: { __typename?: 'Node', id: string, status: string }, userErrors: Array<{ __typename?: 'UserError', field?: string | null, message?: string | null }> } };

export type GetBulkOperationQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetBulkOperationQuery = { __typename?: 'Query', node: { __typename?: 'Node', id: string, url?: string | null, status: string } };

export type CancelBulkOperationMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CancelBulkOperationMutation = { __typename?: 'Mutation', bulkOperationCancel: { __typename?: 'BulkOperationResult', bulkOperation: { __typename?: 'Node', id: string, status: string }, userErrors: Array<{ __typename?: 'UserError', field?: string | null, message?: string | null }> } };

export type BulkLineItemFragment = { __typename?: 'LineItem', id: string, title: string, quantity: number, vendor: string, variantTitle?: string | null, product: { __typename?: 'Product', id: string }, originalTotalSet: { __typename?: 'MoneyBag', shopMoney: { __typename?: 'Price', amount: string }, presentmentMoney: { __typename?: 'Price', currencyCode: CurrencyCode } }, discountAllocations: Array<{ __typename?: 'DiscountAllocation', allocatedAmountSet: { __typename?: 'MoneyBag', shopMoney: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode } } }> };

export type BulkOrderFragment = { __typename?: 'Order', id: string, discountCode: string, createdAt: string, lineItems: { __typename?: 'LineItems', nodes: Array<{ __typename?: 'LineItem', id: string, title: string, quantity: number, vendor: string, variantTitle?: string | null, product: { __typename?: 'Product', id: string }, originalTotalSet: { __typename?: 'MoneyBag', shopMoney: { __typename?: 'Price', amount: string }, presentmentMoney: { __typename?: 'Price', currencyCode: CurrencyCode } }, discountAllocations: Array<{ __typename?: 'DiscountAllocation', allocatedAmountSet: { __typename?: 'MoneyBag', shopMoney: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode } } }> }> } };

export type GetOrdersQueryVariables = Exact<{
  sortKey?: InputMaybe<OrderSortKeys>;
  query?: InputMaybe<Scalars['String']['input']>;
  afterCursor?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetOrdersQuery = { __typename?: 'Query', orders?: { __typename?: 'OrdersResponse', nodes: Array<{ __typename?: 'Order', id: string, discountCode: string, createdAt: string, lineItems: { __typename?: 'LineItems', nodes: Array<{ __typename?: 'LineItem', id: string, title: string, quantity: number, vendor: string, variantTitle?: string | null, product: { __typename?: 'Product', id: string }, originalTotalSet: { __typename?: 'MoneyBag', shopMoney: { __typename?: 'Price', amount: string }, presentmentMoney: { __typename?: 'Price', currencyCode: CurrencyCode } }, discountAllocations: Array<{ __typename?: 'DiscountAllocation', allocatedAmountSet: { __typename?: 'MoneyBag', shopMoney: { __typename?: 'Price', amount: string, currencyCode: CurrencyCode } } }> }> } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } | null };

export type DashboardProductFragment = { __typename?: 'Product', id: string, title: string, descriptionHtml?: string | null, status: ProductStatus, productType: string, tags: Array<string>, variants: { __typename?: 'ProductVariantConnection', nodes: Array<{ __typename?: 'ProductVariant', id: string, price: string, selectedOptions: Array<{ __typename?: 'SelectedOption', name: string, value: string }> }> }, images: { __typename?: 'ImageConnection', nodes: Array<{ __typename?: 'Image', src: string, height: number, width: number }> }, medium: { __typename?: 'Metafield', value: string, type: string } };

export type GetDashboardProductQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetDashboardProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: string, title: string, descriptionHtml?: string | null, status: ProductStatus, productType: string, tags: Array<string>, variants: { __typename?: 'ProductVariantConnection', nodes: Array<{ __typename?: 'ProductVariant', id: string, price: string, selectedOptions: Array<{ __typename?: 'SelectedOption', name: string, value: string }> }> }, images: { __typename?: 'ImageConnection', nodes: Array<{ __typename?: 'Image', src: string, height: number, width: number }> }, medium: { __typename?: 'Metafield', value: string, type: string } } };

export type ChangeProductStatusMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  status: ProductStatus;
}>;


export type ChangeProductStatusMutation = { __typename?: 'Mutation', productChangeStatus: { __typename?: 'ProductChangeStatusResult', product: { __typename?: 'Product', id: string } } };

export const ArtistProductVariantFragmentDoc = gql`
    fragment ArtistProductVariant on ProductVariant {
  id
  price
  selectedOptions {
    name
    value
  }
}
    `;
export const ArtistProductFragmentDoc = gql`
    fragment ArtistProduct on Product {
  id
  title
  productType
  status
  images(first: 1) {
    nodes {
      src
      height
      width
    }
  }
  variants(first: 1) {
    nodes {
      ...ArtistProductVariant
    }
  }
}
    ${ArtistProductVariantFragmentDoc}`;
export const AdminProductFragmentDoc = gql`
    fragment AdminProduct on Product {
  id
  title
  descriptionHtml
  images(first: 1) {
    nodes {
      id
      src
      height
      width
    }
  }
  vendor
  tags
  productType
  variants(first: 20) {
    nodes {
      id
      price
      sku
      selectedOptions {
        name
        value
      }
    }
  }
  orientation: metafield(namespace: "custom", key: "orientation") {
    value
    type
  }
  medium: metafield(namespace: "custom", key: "medium") {
    value
    type
  }
  priceRangeV2 {
    minVariantPrice {
      amount
      currencyCode
    }
    maxVariantPrice {
      amount
      currencyCode
    }
  }
}
    `;
export const BulkLineItemFragmentDoc = gql`
    fragment BulkLineItem on LineItem {
  id
  title
  quantity
  vendor
  product {
    id
  }
  originalTotalSet {
    shopMoney {
      amount
    }
    presentmentMoney {
      currencyCode
    }
  }
  discountAllocations {
    allocatedAmountSet {
      shopMoney {
        amount
        currencyCode
      }
    }
  }
  variantTitle
}
    `;
export const BulkOrderFragmentDoc = gql`
    fragment BulkOrder on Order {
  id
  discountCode
  createdAt
  lineItems(first: 1) {
    nodes {
      ...BulkLineItem
    }
  }
}
    ${BulkLineItemFragmentDoc}`;
export const DashboardProductFragmentDoc = gql`
    fragment DashboardProduct on Product {
  id
  title
  descriptionHtml
  status
  productType
  tags
  variants(first: 20) {
    nodes {
      id
      price
      selectedOptions {
        name
        value
      }
    }
  }
  images(first: 1) {
    nodes {
      src
      height
      width
    }
  }
  medium: metafield(namespace: "custom", key: "medium") {
    value
    type
  }
}
    `;
export const GetArtistProductsDocument = gql`
    query getArtistProducts($limit: Int!, $sortKey: ProductSortKeys, $reverse: Boolean, $query: String, $afterCursor: String) {
  products(
    first: $limit
    sortKey: $sortKey
    reverse: $reverse
    after: $afterCursor
    query: $query
  ) {
    nodes {
      ...ArtistProduct
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${ArtistProductFragmentDoc}`;

/**
 * __useGetArtistProductsQuery__
 *
 * To run a query within a React component, call `useGetArtistProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      sortKey: // value for 'sortKey'
 *      reverse: // value for 'reverse'
 *      query: // value for 'query'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetArtistProductsQuery(baseOptions: Apollo.QueryHookOptions<GetArtistProductsQuery, GetArtistProductsQueryVariables> & ({ variables: GetArtistProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistProductsQuery, GetArtistProductsQueryVariables>(GetArtistProductsDocument, options);
      }
export function useGetArtistProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistProductsQuery, GetArtistProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistProductsQuery, GetArtistProductsQueryVariables>(GetArtistProductsDocument, options);
        }
export function useGetArtistProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetArtistProductsQuery, GetArtistProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetArtistProductsQuery, GetArtistProductsQueryVariables>(GetArtistProductsDocument, options);
        }
export type GetArtistProductsQueryHookResult = ReturnType<typeof useGetArtistProductsQuery>;
export type GetArtistProductsLazyQueryHookResult = ReturnType<typeof useGetArtistProductsLazyQuery>;
export type GetArtistProductsSuspenseQueryHookResult = ReturnType<typeof useGetArtistProductsSuspenseQuery>;
export type GetArtistProductsQueryResult = Apollo.QueryResult<GetArtistProductsQuery, GetArtistProductsQueryVariables>;
export const GetAdminProductsDocument = gql`
    query getAdminProducts($limit: Int!, $query: String, $afterCursor: String, $sortKey: ProductSortKeys, $reverse: Boolean) {
  products(
    first: $limit
    after: $afterCursor
    query: $query
    sortKey: $sortKey
    reverse: $reverse
  ) {
    nodes {
      ...AdminProduct
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${AdminProductFragmentDoc}`;

/**
 * __useGetAdminProductsQuery__
 *
 * To run a query within a React component, call `useGetAdminProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminProductsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      query: // value for 'query'
 *      afterCursor: // value for 'afterCursor'
 *      sortKey: // value for 'sortKey'
 *      reverse: // value for 'reverse'
 *   },
 * });
 */
export function useGetAdminProductsQuery(baseOptions: Apollo.QueryHookOptions<GetAdminProductsQuery, GetAdminProductsQueryVariables> & ({ variables: GetAdminProductsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminProductsQuery, GetAdminProductsQueryVariables>(GetAdminProductsDocument, options);
      }
export function useGetAdminProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminProductsQuery, GetAdminProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminProductsQuery, GetAdminProductsQueryVariables>(GetAdminProductsDocument, options);
        }
export function useGetAdminProductsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdminProductsQuery, GetAdminProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminProductsQuery, GetAdminProductsQueryVariables>(GetAdminProductsDocument, options);
        }
export type GetAdminProductsQueryHookResult = ReturnType<typeof useGetAdminProductsQuery>;
export type GetAdminProductsLazyQueryHookResult = ReturnType<typeof useGetAdminProductsLazyQuery>;
export type GetAdminProductsSuspenseQueryHookResult = ReturnType<typeof useGetAdminProductsSuspenseQuery>;
export type GetAdminProductsQueryResult = Apollo.QueryResult<GetAdminProductsQuery, GetAdminProductsQueryVariables>;
export const GetAdminProductDocument = gql`
    query getAdminProduct($id: ID!) {
  product(id: $id) {
    ...AdminProduct
  }
}
    ${AdminProductFragmentDoc}`;

/**
 * __useGetAdminProductQuery__
 *
 * To run a query within a React component, call `useGetAdminProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminProductQuery(baseOptions: Apollo.QueryHookOptions<GetAdminProductQuery, GetAdminProductQueryVariables> & ({ variables: GetAdminProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminProductQuery, GetAdminProductQueryVariables>(GetAdminProductDocument, options);
      }
export function useGetAdminProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminProductQuery, GetAdminProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminProductQuery, GetAdminProductQueryVariables>(GetAdminProductDocument, options);
        }
export function useGetAdminProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAdminProductQuery, GetAdminProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAdminProductQuery, GetAdminProductQueryVariables>(GetAdminProductDocument, options);
        }
export type GetAdminProductQueryHookResult = ReturnType<typeof useGetAdminProductQuery>;
export type GetAdminProductLazyQueryHookResult = ReturnType<typeof useGetAdminProductLazyQuery>;
export type GetAdminProductSuspenseQueryHookResult = ReturnType<typeof useGetAdminProductSuspenseQuery>;
export type GetAdminProductQueryResult = Apollo.QueryResult<GetAdminProductQuery, GetAdminProductQueryVariables>;
export const GetBulkOrdersDocument = gql`
    mutation getBulkOrders($query: String!) {
  bulkOperationRunQuery(query: $query) {
    bulkOperation {
      id
      status
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type GetBulkOrdersMutationFn = Apollo.MutationFunction<GetBulkOrdersMutation, GetBulkOrdersMutationVariables>;

/**
 * __useGetBulkOrdersMutation__
 *
 * To run a mutation, you first call `useGetBulkOrdersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetBulkOrdersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getBulkOrdersMutation, { data, loading, error }] = useGetBulkOrdersMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useGetBulkOrdersMutation(baseOptions?: Apollo.MutationHookOptions<GetBulkOrdersMutation, GetBulkOrdersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetBulkOrdersMutation, GetBulkOrdersMutationVariables>(GetBulkOrdersDocument, options);
      }
export type GetBulkOrdersMutationHookResult = ReturnType<typeof useGetBulkOrdersMutation>;
export type GetBulkOrdersMutationResult = Apollo.MutationResult<GetBulkOrdersMutation>;
export type GetBulkOrdersMutationOptions = Apollo.BaseMutationOptions<GetBulkOrdersMutation, GetBulkOrdersMutationVariables>;
export const GetBulkOperationDocument = gql`
    query getBulkOperation($id: ID!) {
  node(id: $id) {
    ... on BulkOperation {
      id
      url
      status
    }
  }
}
    `;

/**
 * __useGetBulkOperationQuery__
 *
 * To run a query within a React component, call `useGetBulkOperationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkOperationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkOperationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBulkOperationQuery(baseOptions: Apollo.QueryHookOptions<GetBulkOperationQuery, GetBulkOperationQueryVariables> & ({ variables: GetBulkOperationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBulkOperationQuery, GetBulkOperationQueryVariables>(GetBulkOperationDocument, options);
      }
export function useGetBulkOperationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBulkOperationQuery, GetBulkOperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBulkOperationQuery, GetBulkOperationQueryVariables>(GetBulkOperationDocument, options);
        }
export function useGetBulkOperationSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBulkOperationQuery, GetBulkOperationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBulkOperationQuery, GetBulkOperationQueryVariables>(GetBulkOperationDocument, options);
        }
export type GetBulkOperationQueryHookResult = ReturnType<typeof useGetBulkOperationQuery>;
export type GetBulkOperationLazyQueryHookResult = ReturnType<typeof useGetBulkOperationLazyQuery>;
export type GetBulkOperationSuspenseQueryHookResult = ReturnType<typeof useGetBulkOperationSuspenseQuery>;
export type GetBulkOperationQueryResult = Apollo.QueryResult<GetBulkOperationQuery, GetBulkOperationQueryVariables>;
export const CancelBulkOperationDocument = gql`
    mutation cancelBulkOperation($id: ID!) {
  bulkOperationCancel(id: $id) {
    bulkOperation {
      id
      status
    }
    userErrors {
      field
      message
    }
  }
}
    `;
export type CancelBulkOperationMutationFn = Apollo.MutationFunction<CancelBulkOperationMutation, CancelBulkOperationMutationVariables>;

/**
 * __useCancelBulkOperationMutation__
 *
 * To run a mutation, you first call `useCancelBulkOperationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBulkOperationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBulkOperationMutation, { data, loading, error }] = useCancelBulkOperationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelBulkOperationMutation(baseOptions?: Apollo.MutationHookOptions<CancelBulkOperationMutation, CancelBulkOperationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelBulkOperationMutation, CancelBulkOperationMutationVariables>(CancelBulkOperationDocument, options);
      }
export type CancelBulkOperationMutationHookResult = ReturnType<typeof useCancelBulkOperationMutation>;
export type CancelBulkOperationMutationResult = Apollo.MutationResult<CancelBulkOperationMutation>;
export type CancelBulkOperationMutationOptions = Apollo.BaseMutationOptions<CancelBulkOperationMutation, CancelBulkOperationMutationVariables>;
export const GetOrdersDocument = gql`
    query getOrders($sortKey: OrderSortKeys, $query: String, $afterCursor: String) {
  orders(
    first: 5
    sortKey: $sortKey
    reverse: true
    query: $query
    after: $afterCursor
  ) {
    nodes {
      ...BulkOrder
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    ${BulkOrderFragmentDoc}`;

/**
 * __useGetOrdersQuery__
 *
 * To run a query within a React component, call `useGetOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrdersQuery({
 *   variables: {
 *      sortKey: // value for 'sortKey'
 *      query: // value for 'query'
 *      afterCursor: // value for 'afterCursor'
 *   },
 * });
 */
export function useGetOrdersQuery(baseOptions?: Apollo.QueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
      }
export function useGetOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export function useGetOrdersSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetOrdersQuery, GetOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrdersQuery, GetOrdersQueryVariables>(GetOrdersDocument, options);
        }
export type GetOrdersQueryHookResult = ReturnType<typeof useGetOrdersQuery>;
export type GetOrdersLazyQueryHookResult = ReturnType<typeof useGetOrdersLazyQuery>;
export type GetOrdersSuspenseQueryHookResult = ReturnType<typeof useGetOrdersSuspenseQuery>;
export type GetOrdersQueryResult = Apollo.QueryResult<GetOrdersQuery, GetOrdersQueryVariables>;
export const GetDashboardProductDocument = gql`
    query GetDashboardProduct($id: ID!) {
  product(id: $id) {
    ...DashboardProduct
  }
}
    ${DashboardProductFragmentDoc}`;

/**
 * __useGetDashboardProductQuery__
 *
 * To run a query within a React component, call `useGetDashboardProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDashboardProductQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardProductQuery, GetDashboardProductQueryVariables> & ({ variables: GetDashboardProductQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardProductQuery, GetDashboardProductQueryVariables>(GetDashboardProductDocument, options);
      }
export function useGetDashboardProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardProductQuery, GetDashboardProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardProductQuery, GetDashboardProductQueryVariables>(GetDashboardProductDocument, options);
        }
export function useGetDashboardProductSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetDashboardProductQuery, GetDashboardProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetDashboardProductQuery, GetDashboardProductQueryVariables>(GetDashboardProductDocument, options);
        }
export type GetDashboardProductQueryHookResult = ReturnType<typeof useGetDashboardProductQuery>;
export type GetDashboardProductLazyQueryHookResult = ReturnType<typeof useGetDashboardProductLazyQuery>;
export type GetDashboardProductSuspenseQueryHookResult = ReturnType<typeof useGetDashboardProductSuspenseQuery>;
export type GetDashboardProductQueryResult = Apollo.QueryResult<GetDashboardProductQuery, GetDashboardProductQueryVariables>;
export const ChangeProductStatusDocument = gql`
    mutation ChangeProductStatus($id: ID!, $status: ProductStatus!) {
  productChangeStatus(productId: $id, status: $status) {
    product {
      id
    }
  }
}
    `;
export type ChangeProductStatusMutationFn = Apollo.MutationFunction<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>;

/**
 * __useChangeProductStatusMutation__
 *
 * To run a mutation, you first call `useChangeProductStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProductStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProductStatusMutation, { data, loading, error }] = useChangeProductStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useChangeProductStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>(ChangeProductStatusDocument, options);
      }
export type ChangeProductStatusMutationHookResult = ReturnType<typeof useChangeProductStatusMutation>;
export type ChangeProductStatusMutationResult = Apollo.MutationResult<ChangeProductStatusMutation>;
export type ChangeProductStatusMutationOptions = Apollo.BaseMutationOptions<ChangeProductStatusMutation, ChangeProductStatusMutationVariables>;
export type BulkOperationKeySpecifier = ('completedAt' | 'createdAt' | 'errorCode' | 'fileSize' | 'id' | 'objectCount' | 'status' | 'url' | BulkOperationKeySpecifier)[];
export type BulkOperationFieldPolicy = {
	completedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	errorCode?: FieldPolicy<any> | FieldReadFunction<any>,
	fileSize?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	objectCount?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BulkOperationResultKeySpecifier = ('bulkOperation' | 'userErrors' | BulkOperationResultKeySpecifier)[];
export type BulkOperationResultFieldPolicy = {
	bulkOperation?: FieldPolicy<any> | FieldReadFunction<any>,
	userErrors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CollectionKeySpecifier = ('id' | 'image' | 'products' | 'ruleSet' | 'title' | CollectionKeySpecifier)[];
export type CollectionFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	image?: FieldPolicy<any> | FieldReadFunction<any>,
	products?: FieldPolicy<any> | FieldReadFunction<any>,
	ruleSet?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CollectionRuleKeySpecifier = ('condition' | CollectionRuleKeySpecifier)[];
export type CollectionRuleFieldPolicy = {
	condition?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CollectionRuleSetKeySpecifier = ('rules' | CollectionRuleSetKeySpecifier)[];
export type CollectionRuleSetFieldPolicy = {
	rules?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CollectionsResponseKeySpecifier = ('nodes' | 'pageInfo' | CollectionsResponseKeySpecifier)[];
export type CollectionsResponseFieldPolicy = {
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type DiscountAllocationKeySpecifier = ('allocatedAmountSet' | DiscountAllocationKeySpecifier)[];
export type DiscountAllocationFieldPolicy = {
	allocatedAmountSet?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ImageKeySpecifier = ('height' | 'id' | 'src' | 'width' | ImageKeySpecifier)[];
export type ImageFieldPolicy = {
	height?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	src?: FieldPolicy<any> | FieldReadFunction<any>,
	width?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ImageConnectionKeySpecifier = ('nodes' | ImageConnectionKeySpecifier)[];
export type ImageConnectionFieldPolicy = {
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LineItemKeySpecifier = ('discountAllocations' | 'id' | 'originalTotalSet' | 'product' | 'quantity' | 'title' | 'variantTitle' | 'vendor' | LineItemKeySpecifier)[];
export type LineItemFieldPolicy = {
	discountAllocations?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	originalTotalSet?: FieldPolicy<any> | FieldReadFunction<any>,
	product?: FieldPolicy<any> | FieldReadFunction<any>,
	quantity?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	variantTitle?: FieldPolicy<any> | FieldReadFunction<any>,
	vendor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LineItemsKeySpecifier = ('nodes' | LineItemsKeySpecifier)[];
export type LineItemsFieldPolicy = {
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MetafieldKeySpecifier = ('key' | 'namespace' | 'type' | 'value' | MetafieldKeySpecifier)[];
export type MetafieldFieldPolicy = {
	key?: FieldPolicy<any> | FieldReadFunction<any>,
	namespace?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MetafieldDefinitionKeySpecifier = ('description' | 'id' | 'key' | 'name' | 'namespace' | MetafieldDefinitionKeySpecifier)[];
export type MetafieldDefinitionFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	key?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	namespace?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MetafieldDefinitionCreatePayloadKeySpecifier = ('createdDefinition' | 'userErrors' | MetafieldDefinitionCreatePayloadKeySpecifier)[];
export type MetafieldDefinitionCreatePayloadFieldPolicy = {
	createdDefinition?: FieldPolicy<any> | FieldReadFunction<any>,
	userErrors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MetafieldDefinitionDeletePayloadKeySpecifier = ('deletedDefinitionId' | 'userErrors' | MetafieldDefinitionDeletePayloadKeySpecifier)[];
export type MetafieldDefinitionDeletePayloadFieldPolicy = {
	deletedDefinitionId?: FieldPolicy<any> | FieldReadFunction<any>,
	userErrors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MetafieldDefinitionUpdatePayloadKeySpecifier = ('updatedDefinition' | 'userErrors' | MetafieldDefinitionUpdatePayloadKeySpecifier)[];
export type MetafieldDefinitionUpdatePayloadFieldPolicy = {
	updatedDefinition?: FieldPolicy<any> | FieldReadFunction<any>,
	userErrors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MoneyBagKeySpecifier = ('presentmentMoney' | 'shopMoney' | MoneyBagKeySpecifier)[];
export type MoneyBagFieldPolicy = {
	presentmentMoney?: FieldPolicy<any> | FieldReadFunction<any>,
	shopMoney?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('bulkOperationCancel' | 'bulkOperationRunQuery' | 'metafieldDefinitionCreate' | 'metafieldDefinitionDelete' | 'metafieldDefinitionUpdate' | 'productChangeStatus' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	bulkOperationCancel?: FieldPolicy<any> | FieldReadFunction<any>,
	bulkOperationRunQuery?: FieldPolicy<any> | FieldReadFunction<any>,
	metafieldDefinitionCreate?: FieldPolicy<any> | FieldReadFunction<any>,
	metafieldDefinitionDelete?: FieldPolicy<any> | FieldReadFunction<any>,
	metafieldDefinitionUpdate?: FieldPolicy<any> | FieldReadFunction<any>,
	productChangeStatus?: FieldPolicy<any> | FieldReadFunction<any>
};
export type NodeKeySpecifier = ('completedAt' | 'createdAt' | 'errorCode' | 'fileSize' | 'id' | 'objectCount' | 'status' | 'url' | NodeKeySpecifier)[];
export type NodeFieldPolicy = {
	completedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	errorCode?: FieldPolicy<any> | FieldReadFunction<any>,
	fileSize?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	objectCount?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrderKeySpecifier = ('createdAt' | 'discountCode' | 'id' | 'lineItems' | OrderKeySpecifier)[];
export type OrderFieldPolicy = {
	createdAt?: FieldPolicy<any> | FieldReadFunction<any>,
	discountCode?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	lineItems?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OrdersResponseKeySpecifier = ('nodes' | 'pageInfo' | OrdersResponseKeySpecifier)[];
export type OrdersResponseFieldPolicy = {
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PriceKeySpecifier = ('amount' | 'currencyCode' | PriceKeySpecifier)[];
export type PriceFieldPolicy = {
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	currencyCode?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PriceRangeKeySpecifier = ('maxVariantPrice' | 'minVariantPrice' | PriceRangeKeySpecifier)[];
export type PriceRangeFieldPolicy = {
	maxVariantPrice?: FieldPolicy<any> | FieldReadFunction<any>,
	minVariantPrice?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductKeySpecifier = ('contextualPricing' | 'defaultCursor' | 'descriptionHtml' | 'id' | 'images' | 'metafield' | 'priceRangeV2' | 'productType' | 'status' | 'tags' | 'title' | 'updatedAt' | 'variants' | 'vendor' | ProductKeySpecifier)[];
export type ProductFieldPolicy = {
	contextualPricing?: FieldPolicy<any> | FieldReadFunction<any>,
	defaultCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	descriptionHtml?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	images?: FieldPolicy<any> | FieldReadFunction<any>,
	metafield?: FieldPolicy<any> | FieldReadFunction<any>,
	priceRangeV2?: FieldPolicy<any> | FieldReadFunction<any>,
	productType?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	tags?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>,
	updatedAt?: FieldPolicy<any> | FieldReadFunction<any>,
	variants?: FieldPolicy<any> | FieldReadFunction<any>,
	vendor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductChangeStatusResultKeySpecifier = ('product' | 'userErrors' | ProductChangeStatusResultKeySpecifier)[];
export type ProductChangeStatusResultFieldPolicy = {
	product?: FieldPolicy<any> | FieldReadFunction<any>,
	userErrors?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductContextualPricingKeySpecifier = ('maxVariantPrice' | 'minVariantPrice' | 'priceRange' | ProductContextualPricingKeySpecifier)[];
export type ProductContextualPricingFieldPolicy = {
	maxVariantPrice?: FieldPolicy<any> | FieldReadFunction<any>,
	minVariantPrice?: FieldPolicy<any> | FieldReadFunction<any>,
	priceRange?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductVariantKeySpecifier = ('contextualPricing' | 'id' | 'price' | 'selectedOptions' | 'sku' | ProductVariantKeySpecifier)[];
export type ProductVariantFieldPolicy = {
	contextualPricing?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	price?: FieldPolicy<any> | FieldReadFunction<any>,
	selectedOptions?: FieldPolicy<any> | FieldReadFunction<any>,
	sku?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductVariantConnectionKeySpecifier = ('nodes' | ProductVariantConnectionKeySpecifier)[];
export type ProductVariantConnectionFieldPolicy = {
	nodes?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductVariantContextualPriceRangeKeySpecifier = ('maxVariantPrice' | 'minVariantPrice' | ProductVariantContextualPriceRangeKeySpecifier)[];
export type ProductVariantContextualPriceRangeFieldPolicy = {
	maxVariantPrice?: FieldPolicy<any> | FieldReadFunction<any>,
	minVariantPrice?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductVariantContextualPricingKeySpecifier = ('price' | ProductVariantContextualPricingKeySpecifier)[];
export type ProductVariantContextualPricingFieldPolicy = {
	price?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProductsResponseKeySpecifier = ('nodes' | 'pageInfo' | ProductsResponseKeySpecifier)[];
export type ProductsResponseFieldPolicy = {
	nodes?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('collections' | 'node' | 'orders' | 'product' | 'products' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	collections?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>,
	orders?: FieldPolicy<any> | FieldReadFunction<any>,
	product?: FieldPolicy<any> | FieldReadFunction<any>,
	products?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SelectedOptionKeySpecifier = ('name' | 'value' | SelectedOptionKeySpecifier)[];
export type SelectedOptionFieldPolicy = {
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserErrorKeySpecifier = ('field' | 'message' | UserErrorKeySpecifier)[];
export type UserErrorFieldPolicy = {
	field?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	BulkOperation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BulkOperationKeySpecifier | (() => undefined | BulkOperationKeySpecifier),
		fields?: BulkOperationFieldPolicy,
	},
	BulkOperationResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BulkOperationResultKeySpecifier | (() => undefined | BulkOperationResultKeySpecifier),
		fields?: BulkOperationResultFieldPolicy,
	},
	Collection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CollectionKeySpecifier | (() => undefined | CollectionKeySpecifier),
		fields?: CollectionFieldPolicy,
	},
	CollectionRule?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CollectionRuleKeySpecifier | (() => undefined | CollectionRuleKeySpecifier),
		fields?: CollectionRuleFieldPolicy,
	},
	CollectionRuleSet?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CollectionRuleSetKeySpecifier | (() => undefined | CollectionRuleSetKeySpecifier),
		fields?: CollectionRuleSetFieldPolicy,
	},
	CollectionsResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CollectionsResponseKeySpecifier | (() => undefined | CollectionsResponseKeySpecifier),
		fields?: CollectionsResponseFieldPolicy,
	},
	DiscountAllocation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | DiscountAllocationKeySpecifier | (() => undefined | DiscountAllocationKeySpecifier),
		fields?: DiscountAllocationFieldPolicy,
	},
	Image?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ImageKeySpecifier | (() => undefined | ImageKeySpecifier),
		fields?: ImageFieldPolicy,
	},
	ImageConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ImageConnectionKeySpecifier | (() => undefined | ImageConnectionKeySpecifier),
		fields?: ImageConnectionFieldPolicy,
	},
	LineItem?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LineItemKeySpecifier | (() => undefined | LineItemKeySpecifier),
		fields?: LineItemFieldPolicy,
	},
	LineItems?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LineItemsKeySpecifier | (() => undefined | LineItemsKeySpecifier),
		fields?: LineItemsFieldPolicy,
	},
	Metafield?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MetafieldKeySpecifier | (() => undefined | MetafieldKeySpecifier),
		fields?: MetafieldFieldPolicy,
	},
	MetafieldDefinition?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MetafieldDefinitionKeySpecifier | (() => undefined | MetafieldDefinitionKeySpecifier),
		fields?: MetafieldDefinitionFieldPolicy,
	},
	MetafieldDefinitionCreatePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MetafieldDefinitionCreatePayloadKeySpecifier | (() => undefined | MetafieldDefinitionCreatePayloadKeySpecifier),
		fields?: MetafieldDefinitionCreatePayloadFieldPolicy,
	},
	MetafieldDefinitionDeletePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MetafieldDefinitionDeletePayloadKeySpecifier | (() => undefined | MetafieldDefinitionDeletePayloadKeySpecifier),
		fields?: MetafieldDefinitionDeletePayloadFieldPolicy,
	},
	MetafieldDefinitionUpdatePayload?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MetafieldDefinitionUpdatePayloadKeySpecifier | (() => undefined | MetafieldDefinitionUpdatePayloadKeySpecifier),
		fields?: MetafieldDefinitionUpdatePayloadFieldPolicy,
	},
	MoneyBag?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MoneyBagKeySpecifier | (() => undefined | MoneyBagKeySpecifier),
		fields?: MoneyBagFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	Node?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | NodeKeySpecifier | (() => undefined | NodeKeySpecifier),
		fields?: NodeFieldPolicy,
	},
	Order?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrderKeySpecifier | (() => undefined | OrderKeySpecifier),
		fields?: OrderFieldPolicy,
	},
	OrdersResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OrdersResponseKeySpecifier | (() => undefined | OrdersResponseKeySpecifier),
		fields?: OrdersResponseFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	Price?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PriceKeySpecifier | (() => undefined | PriceKeySpecifier),
		fields?: PriceFieldPolicy,
	},
	PriceRange?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PriceRangeKeySpecifier | (() => undefined | PriceRangeKeySpecifier),
		fields?: PriceRangeFieldPolicy,
	},
	Product?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductKeySpecifier | (() => undefined | ProductKeySpecifier),
		fields?: ProductFieldPolicy,
	},
	ProductChangeStatusResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductChangeStatusResultKeySpecifier | (() => undefined | ProductChangeStatusResultKeySpecifier),
		fields?: ProductChangeStatusResultFieldPolicy,
	},
	ProductContextualPricing?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductContextualPricingKeySpecifier | (() => undefined | ProductContextualPricingKeySpecifier),
		fields?: ProductContextualPricingFieldPolicy,
	},
	ProductVariant?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductVariantKeySpecifier | (() => undefined | ProductVariantKeySpecifier),
		fields?: ProductVariantFieldPolicy,
	},
	ProductVariantConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductVariantConnectionKeySpecifier | (() => undefined | ProductVariantConnectionKeySpecifier),
		fields?: ProductVariantConnectionFieldPolicy,
	},
	ProductVariantContextualPriceRange?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductVariantContextualPriceRangeKeySpecifier | (() => undefined | ProductVariantContextualPriceRangeKeySpecifier),
		fields?: ProductVariantContextualPriceRangeFieldPolicy,
	},
	ProductVariantContextualPricing?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductVariantContextualPricingKeySpecifier | (() => undefined | ProductVariantContextualPricingKeySpecifier),
		fields?: ProductVariantContextualPricingFieldPolicy,
	},
	ProductsResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProductsResponseKeySpecifier | (() => undefined | ProductsResponseKeySpecifier),
		fields?: ProductsResponseFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	SelectedOption?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SelectedOptionKeySpecifier | (() => undefined | SelectedOptionKeySpecifier),
		fields?: SelectedOptionFieldPolicy,
	},
	UserError?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserErrorKeySpecifier | (() => undefined | UserErrorKeySpecifier),
		fields?: UserErrorFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BulkOperation": [
      "Node"
    ]
  }
};
      export default result;
    