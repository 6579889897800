import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext, { Customer } from "../../state/auth";
import { Loader } from "../Loader";

type Props = {
  element: (props: { customer: Customer }) => JSX.Element | null;
};

const CustomerAuth = ({ element: Element }: Props) => {
  const { customer, loadingCustomer } = useContext(AuthContext);

  if (loadingCustomer) {
    return <Loader />;
  }
  if (!customer) {
    const loginLink = `/login/customer?redirect_url=${window.location.pathname}${window.location.search}`;
    return <Navigate to={loginLink} replace />;
  }
  return <Element customer={customer} />;
};

export default CustomerAuth;
