import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import Authenticated from "./Authenticated";
import Unauthenticated from "./Unauthenticated";
import AuthContext from "../../state/auth";
import AutoResizeText from "../../components/AutoResizeText";
import { Loader } from "../../components/Loader";

const Wishlists = () => {
  const [searchParams] = useSearchParams();
  const { customer, loadingCustomer } = useContext(AuthContext);
  const paramIds = searchParams.get("ids") || undefined;

  return (
    <>
      <Helmet>
        <title>Wishlists | GoodMood</title>
        <meta
          name="description"
          content="Access your wishlist on any device. Create multiple wishlists and collaborate with others seamlessly."
        />
      </Helmet>
      <Box>
        <Stack gap={{ xs: 1, md: 1 }} alignItems="center" paddingX={{ xs: 2, md: 2 }} paddingY={{ xs: 4, md: 2 }}>
          <AutoResizeText text="Wishlists" align="center" />
          <Typography width={{ xs: "100%", md: "50%" }} align="center" fontSize={{ xs: 14, md: 20 }}>
            {customer
              ? "Access your wishlist on any device. Create multiple wishlists and collaborate with others seamlessly."
              : "Create an account to access your wishlist on any device. With an account, you can create multiple wishlists and make them collaborative."}
          </Typography>
        </Stack>

        {loadingCustomer ? <Loader /> : customer && !paramIds ? <Authenticated customer={customer} /> : <Unauthenticated />}
      </Box>
    </>
  );
};

export default Wishlists;
