import { ReactNode, useCallback } from "react";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";
import { colors } from "../../theme";
import { Text } from "../Text";
import { Icon } from "../Icon";

type Props = {
  onImageDrop: (files: File[]) => void;
  label?: string | ReactNode;
  error?: string;
};

const Container = styled.div<{ error?: string }>`
  background: ${(p) => (p.error ? colors.errorLight : colors.grey05)};
  border: ${(p) => (p.error ? colors.error : colors.grey10)} 1px solid;
  padding: 28px;
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-contents: center;
  flex-direction: column;
  width: 100%;
`;

const DragAndDrop = ({ onImageDrop, label, error }: Props) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onImageDrop(acceptedFiles);
    },
    [onImageDrop]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Container {...getRootProps()} error={error}>
      <input {...getInputProps()} />
      <Icon icon="upload" fill={colors.black} size={22} />
      {error ? (
        <Text size={18} align="center" margin="16px 0 0">
          {error}
        </Text>
      ) : isDragActive ? (
        <Text size={18} margin="16px 0 0">
          Drop the files here ...
        </Text>
      ) : typeof label === "string" ? (
        <Text size={18} margin="16px 0 0">
          {label}
        </Text>
      ) : (
        label
      )}
    </Container>
  );
};

export default DragAndDrop;
