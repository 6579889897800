import styled from "styled-components";
import { match } from "ts-pattern";
import { Text } from "../../components/Text";
import { colors } from "../../theme";

type Props = {
  status: "draft" | "active" | "archived" | "upcoming";
};

const Status = styled.div<{ color: string }>`
  display: inline-block;
  background: ${(p) => p.color};
  border-radius: 40px;
  padding: 4px 12px;
`;

const StatusPill = ({ status }: Props) => {
  return match(status)
    .with("active", () => (
      <Status color={colors.mint}>
        <Text align="center" size={14}>
          Active
        </Text>
      </Status>
    ))
    .with("draft", () => (
      <Status color={colors.peach}>
        <Text align="center" size={14}>
          Draft
        </Text>
      </Status>
    ))
    .with("archived", () => (
      <Status color={colors.grey10}>
        <Text align="center" size={14}>
          Archived
        </Text>
      </Status>
    ))
    .with("upcoming", () => (
      <Status color={colors.blue}>
        <Text align="center" size={14}>
          Upcoming
        </Text>
      </Status>
    ))
    .exhaustive();
};

export default StatusPill;
