import styled from "styled-components";
import { media } from "../../helpers/layout";
import { colors } from "../../theme";

type Props = {
  onTabChange: (tab: any) => void;
  selectedTab: any;
  tabs: {
    value: any;
    label: string;
  }[];
  vertical?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

type TabProps = {
  selected: boolean;
};

const TabsWrapper = styled.div<{ vertical: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(p) =>
    p.vertical &&
    `
    flex-direction: column;
    gap: 16px;
    align-items: center;
  `}

  @media ${media.m} {
    justify-content: start;
    overflow: auto;
  }
`;

const StyledTab = styled.div<TabProps>`
  padding: 8px 24px;
  color: ${(p) => (p.selected ? colors.white : colors.black)};
  background-color: ${(p) => (p.selected ? colors.black : colors.white)};
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  border: 1px solid transparent;
  border-color: ${(p) => (p.selected ? colors.black : colors.white)};

  @media ${media.m} {
    padding: 6px 16px;
    line-height: 1.4;
  }
`;

const Tabs = ({ tabs, selectedTab, onTabChange, vertical = false, ...props }: Props) => {
  return (
    <TabsWrapper vertical={vertical} {...props}>
      {tabs.map((tab) => (
        <StyledTab key={tab.value} onClick={() => onTabChange(tab.value)} selected={selectedTab === tab.value}>
          {tab.label}
        </StyledTab>
      ))}
    </TabsWrapper>
  );
};

export default Tabs;
