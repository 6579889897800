import { Helmet } from "react-helmet";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import contact from "../../assets/images/contact.png";
import TextLink from "../../components/TextLink";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact | GoodMood</title>
        <meta name="description" content="For more information please contact hello@goodmoodprints.io" />
      </Helmet>
      <PageHeader header="Contact" background={colors.blue} image={contact}>
        For more information please contact <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink>
      </PageHeader>
    </>
  );
};

export default Contact;
