import styled, { keyframes } from "styled-components";
import Image from "react-image-webp";
import { media } from "../../helpers/layout";
import { colors } from "../../theme";

export const HeroImage = styled(Image)<{ height: number }>`
  height: ${(p) => p.height}px;
  width: 100vw;
  position: absolute;
  top: 0;
  object-fit: cover;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

export const Veil = styled.div<{ height: number }>`
  width: 100%;
  height: ${(p) => p.height}px;
  position: absolute;
  left: 0;
  top: 0;

  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
`;

export const HeroTitle = styled.h1`
  color: ${colors.cream};
  font-size: calc(6vw);
  line-height: 100%;
  letter-spacing: -2px;
  margin: 0;

  @media ${media.m} {
    font-size: 54px;
  }
`;

export const HeroText = styled.p`
  font-size: calc(0.5vw + 10px);
  color: ${colors.cream};
  margin: 0 0 24px;

  @media ${media.m} {
    font-size: 18px;
    line-height: 1.4;
    margin: 0 0 24px;
  }
`;

const translate1 = keyframes`
  0%    { transform: translateX(100%); }
  100%  { transform: translateX(-100%); }
`;
const translate2 = keyframes`
  0%    { transform: translateX(100%); }
  100%  { transform: translateX(-100%); }
`;

export const RotatingText = styled.div`
  overflow-x: hidden;
  > * {
    position: absolute;
    width: max-content;
    padding: 0 20px;
  }
  > :first-child {
    animation: ${translate1} 20s linear infinite;
  }
  > :nth-child(2) {
    animation: ${translate2} 20s linear infinite;
    animation-delay: 10s;
  }
`;

export const ImageAndButtonWrapper = styled.div`
  position: relative;
  flex: 1;

  > a > button {
    position: absolute;
    bottom: 30px;
    left: 30px;

    @media ${media.m} {
      bottom: 24px;
      left: 24px;
    }
  }
`;

export const ArtistButton = styled.div`
  position: absolute;
  bottom: 30px;
  left: 30px;

  @media ${media.m} {
    bottom: 24px;
    left: 24px;
  }
`;
