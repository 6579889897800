import { useEffect, useState } from "react";
import { getUsers, searchArtists } from "../../../services/Firebase";
import { Medium } from "../../../types/product";
import { User } from "../../../types/user";
import {
  ProductSortKeys,
  SearchArticleFragment,
  SearchProductFragment,
  useGetProductsForProductCardQuery,
  useSearchLazyQuery,
} from "../../../generated/storefront";
import { useAppState } from "../../../state";
import { appendArtistsToProducts, productSearchFilter } from "../../../helpers/product";

export type SearchProductWithArtist = SearchProductFragment & { artist: User | null };

export const useSearch = () => {
  const mediumValues = Object.values(Medium);
  const { selectedCountry } = useAppState();
  const [artists, setArtists] = useState<User[]>([]);
  const [defaultArtists, setDefaultArtists] = useState<User[]>([]);
  const [mediums, setMediums] = useState<Medium[]>(mediumValues);
  const [defaultProducts, setDefaultProducts] = useState<SearchProductWithArtist[]>([]);
  const [products, setProducts] = useState<SearchProductFragment[]>();
  const [articles, setArticles] = useState<SearchArticleFragment[]>([]);
  const [productsWithArtists, setProductsWithArtists] = useState<SearchProductWithArtist[]>([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [search, { loading }] = useSearchLazyQuery();
  const { data: defaultProductsData, loading: loadingDefaultProducts } = useGetProductsForProductCardQuery({
    fetchPolicy: "no-cache",
    variables: {
      limit: 3,
      query: "tag:pick",
      sortKey: ProductSortKeys.BestSelling,
      country: selectedCountry,
    },
  });
  const [searchQs, setSearchQs] = useState("");

  const fetchArtists = async () => {
    const { users } = await getUsers({
      filters: { hasProducts: true },
      orderBy: { field: "salesCount", direction: "desc" },
      limit: 3,
    });
    setDefaultArtists(users);
  };

  const searchItems = async (query: string) => {
    const { data } = await search({ variables: { limit: 6, query, country: selectedCountry }, fetchPolicy: "no-cache" });
    const result = data?.search.nodes || [];
    const searchedProducts = (result?.filter(productSearchFilter) as SearchProductFragment[]) || [];
    const searchedArticles = (result?.filter((node) => node?.__typename === "Article") as SearchArticleFragment[]) || [];
    setProducts(searchedProducts);
    setArticles(searchedArticles);
  };

  const onSearchChange = async (value: string) => {
    setSearchQs(value);
    if (value) {
      const searchedArtists = await searchArtists(value);
      setArtists(searchedArtists);
      const searchedMediums = value ? mediums.filter((medium) => medium.match(value)) : [];
      setMediums(searchedMediums.length ? searchedMediums : mediums);
      await searchItems(value);
    } else {
      setArtists([]);
    }
  };

  useEffect(() => {
    if (!artists.length) {
      fetchArtists();
    }
  }, [artists]);

  const setDefaultProductsWithArtist = async (products: SearchProductFragment[]) => {
    const productsWithArtist = await appendArtistsToProducts(products);
    setDefaultProducts(productsWithArtist);
  };

  const setProductsWithArtist = async (products: SearchProductFragment[]) => {
    setLoadingProducts(true);
    const productsWithArtist = await appendArtistsToProducts(products);
    setProductsWithArtists(productsWithArtist);
    setLoadingProducts(false);
  };

  useEffect(() => {
    if (products?.length) {
      setProductsWithArtist(products);
    }
  }, [products]);

  useEffect(() => {
    if (defaultProducts.length === 0) {
      setDefaultProductsWithArtist(defaultProductsData?.products.nodes || []);
    }
  }, [defaultProductsData?.products.nodes, defaultProducts.length]);

  return {
    onSearchChange,
    defaultArtists,
    artists,
    mediums,
    defaultProducts,
    products: productsWithArtists,
    articles,
    loadingProducts: loading || loadingProducts,
    loadingDefaultProducts,
    searchQs,
  };
};
