import styled from "styled-components";
import { colors } from "../../theme";

export type Props = {
  name: string;
  id: string;
  value: string;
  label: string;
  disabled?: boolean;
  checked?: boolean;
};

const RadioButtonWrapper = styled.div`
  position: relative;
  display: flex;
`;

const StyledInput = styled.input<Partial<Props>>`
  padding: 10px 16px;
  height: 40px;
  border-radius: 100px;
  cursor: pointer;
  display: block;
  opacity: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  min-width: 70px;

  &:checked ~ .styled-radio {
    color: ${colors.white};
    background-color: ${colors.black};
  }
`;

const StyledRadioButton = styled.span<Partial<Props>>`
  display: flex;
  justify-content: center;
  padding: 10px 16px;
  color: ${colors.black};
  background-color: ${colors.cream};
  border: 1px solid ${colors.grey10};
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.2;
  cursor: pointer;
  min-width: 62px;
`;

const RadioButton = ({ name, label, value, checked }: Props) => {
  return (
    <RadioButtonWrapper>
      <StyledInput type="radio" id={value} name={name} value={value} checked={checked} readOnly />
      <StyledRadioButton className="styled-radio">{label}</StyledRadioButton>
    </RadioButtonWrapper>
  );
};

export default RadioButton;
