import Box from "@mui/material/Box";
import { useGridApiContext } from "@mui/x-data-grid";
import Button from "../../components/Button";

const Toolbar = () => {
  const apiRef = useGridApiContext();

  const onSave = () => {
    console.log(apiRef.current.getRowModels());
  };

  return (
    <Box sx={{ width: "100%" }} paddingTop={2} paddingRight={3} display="flex" justifyContent="end">
      <Button onClick={onSave} size="small">
        Save
      </Button>
    </Box>
  );
};

export default Toolbar;
