import styled from "styled-components";
import { Container } from "../Layout";
import { colors } from "../../theme";
import { media } from "../../helpers/layout";

export const Wrapper = styled(Container)<{ shadow?: boolean; fixedWidth?: number; fixedHeight?: number }>`
  z-index: 0;
  ${(p) => p.fixedHeight && `height: ${p.fixedHeight}px;`}
  ${(p) => p.fixedWidth && `width: ${p.fixedWidth}px;`}
${(p) =>
    p.shadow &&
    `
  box-shadow: 3px 3px 3px ${colors.black}25;

  @media ${media.m} {
    box-shadow: 1px 1px 3px ${colors.black}25;
  }
`};
  transition: height 0.5s ease, width 0.5s ease;
`;
