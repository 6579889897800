import styled from "styled-components";
import { colors } from "../../theme";
import { Text } from "../Text";

export type Props = {
  errorMessage: string;
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 6px 0;
`;

const ErrorMessage = ({ errorMessage }: Props) => {
  return (
    <Wrapper>
      <Text size={14} color={colors.error} as="span" margin="4px 0">
        *{errorMessage}
      </Text>
    </Wrapper>
  );
};

export default ErrorMessage;
