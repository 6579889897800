import { useEffect, useState } from "react";
import axios from "axios";
import { addDays, endOfMonth, format, startOfMonth } from "date-fns";
import {
  Order,
  OrderSortKeys,
  useCancelBulkOperationMutation,
  useGetBulkOperationLazyQuery,
  useGetBulkOrdersMutation,
} from "../generated/graphql";

type Props = {
  startDate?: string | Date | null;
  endDate?: string | Date | null;
  month?: string | Date;
  vendorId?: string;
};

const createOrderArray = (lines: Record<string, any>[]) => {
  const orders = lines.filter((line) => line?.id?.includes("Order"));
  const lineItems = lines.filter((line) => line?.id?.includes("LineItem"));
  const productVariants = lines.filter((line) => line?.id?.includes("ProductVariant"));

  productVariants?.forEach((variant: any) => {
    const parentLineItem = lineItems.find((lineItem: any) => lineItem.id === variant.__parentId);
    if (!parentLineItem) return;
    if (parentLineItem.product.variants?.nodes) {
      parentLineItem.product.variants.nodes.push(variant);
    } else {
      parentLineItem.product.variants = {
        nodes: [variant],
      };
    }
  });

  lineItems?.forEach((lineItem: any) => {
    const parentOrder = orders.find((order: any) => order.id === lineItem.__parentId);
    if (!parentOrder) return;
    if (parentOrder.lineItems?.nodes) {
      parentOrder.lineItems.nodes.push(lineItem);
    } else {
      parentOrder.lineItems = {
        nodes: [lineItem],
      };
    }
  });

  return orders as Order[];
};

const useGetBulkOrders = () => {
  const [orders, setOrders] = useState<Order[]>();
  const [loading, setLoading] = useState(false);
  const [getBulkOrders, { loading: loadingMutation }] = useGetBulkOrdersMutation();
  const [getBulkOperation, { data: bulkUrl, refetch }] = useGetBulkOperationLazyQuery();
  const [cancelBulkOperation] = useCancelBulkOperationMutation();
  const [bulkOperationId, setBulkOperationId] = useState<string | null>(null);

  const cancelCurrentOperation = async () => {
    let cancelStatus = "CANCELING";
    if (bulkOperationId) await cancelBulkOperation({ variables: { id: bulkOperationId } });
    if (bulkUrl?.node?.status === "RUNNING") {
      const { data } = await cancelBulkOperation({ variables: { id: bulkUrl.node.id } });
      cancelStatus = data?.bulkOperationCancel.bulkOperation.status || "CANCELING";

      while (cancelStatus === "CANCELING") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const { data: bulkData } = await refetch({ id: bulkUrl.node.id });
        cancelStatus = bulkData.node.status;
      }
    }
  };

  const getOrders = async ({ startDate, endDate, month, vendorId = "" }: Props) => {
    await cancelCurrentOperation();
    const start = startDate || format(startOfMonth(new Date(month || "")), "yyyy-MM-dd");
    const end = endDate || endOfMonth(new Date(month || ""));
    const dayAfterEndDate = format(addDays(new Date(end), 1), "yyyy-MM-dd");

    const bulkOrderQuery = `{
      orders(sortKey: ${OrderSortKeys.CreatedAt}, reverse: true, query: "created_at:>=${start} created_at:<${dayAfterEndDate} ${vendorId}") {
        edges {
          node {
            ...BulkOrder
          }
        }
      }
    }
    fragment BulkOrder on Order {
      id
      createdAt
      lineItems(first: 1) {
        edges {
          node {
            ...BulkLineItem
          }
        }
      }
    }
    fragment BulkLineItem on LineItem {
      id
      title
      quantity
      vendor
      product {
        id
      }
      originalTotalSet {
        shopMoney {
          amount
        }
        presentmentMoney {
          currencyCode
        }
      }
      discountAllocations {
        allocatedAmountSet {
          shopMoney {
            amount
            currencyCode
          }
        }
      }
      variantTitle
    }
  `;

    const { data } = await getBulkOrders({ variables: { query: bulkOrderQuery } });
    setBulkOperationId(data?.bulkOperationRunQuery.bulkOperation?.id || null);
  };

  const downloadData = async (url: string) => {
    const { data: ordersData } = await axios.get(url);
    const lines = ordersData.split(/\n/);
    const dataLines = lines.map((line: string) => line && JSON.parse(line));
    const ordersArray = createOrderArray(dataLines);
    setOrders(ordersArray);
    setLoading(false);
  };

  const checkStatus = async (node?: { status?: string; url?: string | null }) => {
    const { status, url } = node || {};
    if (!status || status === "RUNNING" || status === "CREATED") {
      const { data } = await refetch();
      setTimeout(async () => await checkStatus(data.node), 2000);
    }

    if (status === "COMPLETED") {
      if (url) {
        downloadData(url);
      } else {
        setLoading(false);
        setOrders([]);
      }
    }
  };

  const fetchBulkResultUrl = async (bulkQueryId: string) => {
    setLoading(true);
    const { data } = await getBulkOperation({ variables: { id: bulkQueryId } });
    await checkStatus(data?.node);
  };

  useEffect(() => {
    if (bulkOperationId) {
      fetchBulkResultUrl(bulkOperationId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkOperationId]);

  return { getOrders, orders, loading: loadingMutation || loading };
};

export default useGetBulkOrders;
