import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Box, Stack } from "@mui/material";
import placeholder from "../../assets/images/placeholder.png";
import { colors } from "../../theme";
import Dashboard from "./Dashboard";
import PageHeader from "../../components/PageHeader";
import Tabs from "../../components/Tabs";
import Account from "./Account";
import AuthContext from "../../state/auth";
import { User } from "../../types/user";
import Button from "../../components/Button";
import { useAppState } from "../../state";
import Assets from "./Assets";

const UploadButton = styled(Link)`
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
`;

const Profile = () => {
  const { hash } = useLocation();
  const tab = hash.replace("#", "");
  const { isMobileScreen } = useAppState();
  const { user: authUser } = useContext(AuthContext);
  const [user, setUser] = useState<User | null>(authUser);
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState(location.state?.registered ? "account" : tab || "dashboard");
  const [imageUrl, setImageUrl] = useState<string>("");

  const onTabChange = (tab: number | string) => {
    window.location.hash = tab as string;
    setSelectedTab(tab as string);
  };

  useEffect(() => {
    setUser(authUser);
  }, [authUser]);

  return user ? (
    <>
      <Helmet>
        <title>{`${user.firstName} ${user.lastName} Profile | GoodMood`}</title>
      </Helmet>
      <PageHeader
        header={`${user.firstName} ${user.lastName}`}
        background={colors.mint}
        image={imageUrl || user.image || placeholder}
      >
        <Stack gap={2} justifyContent="center">
          {user.about}
          <Link to={`/artists/${user.permalink}`} target="_blank">
            <Button secondary size="medium">
              View profile
            </Button>
          </Link>
        </Stack>
      </PageHeader>
      <Stack gap={2} direction="row" justifyContent="center" position="relative">
        <Box bgcolor={colors.white} width="100%">
          <Tabs
            onTabChange={onTabChange}
            selectedTab={selectedTab}
            tabs={[
              { value: "dashboard", label: "Dashboard" },
              { value: "account", label: "Account details" },
              { value: "assets", label: "Assets" },
            ]}
            style={{ padding: "40px" }}
          />
        </Box>
        {!isMobileScreen && (
          <UploadButton to="/upload">
            <Button secondary size="medium">
              Upload and sell
            </Button>
          </UploadButton>
        )}
      </Stack>
      {selectedTab === "dashboard" && <Dashboard />}
      {selectedTab === "account" && <Account user={user} setUser={setUser} imageUrl={imageUrl} setImageUrl={setImageUrl} />}
      {selectedTab === "assets" && <Assets />}
    </>
  ) : null;
};

export default Profile;
