import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Stack } from "@mui/material";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import aboutHero from "../../assets/images/AboutContent.png";
import aboutHeroWebp from "../../assets/images/AboutContent.webp";
import AboutPageMockUp from "../../assets/images/AboutPageMockUp.png";
import AboutPageMockUpWebp from "../../assets/images/AboutPageMockUp.webp";
import AboutPageMockUp2 from "../../assets/images/AboutPageMockUp2.png";
import AboutPageMockUp2Webp from "../../assets/images/AboutPageMockUp2.webp";
import { Column, Container, Flex } from "../../components/Layout";
import { Header } from "../../components/Text";
import Button from "../../components/Button";
import { useAppState } from "../../state";
import { media } from "../../helpers/layout";
import Image from "../../components/Image";

const AboutText = styled.p`
  font-size: calc(0.5vw + 10px);
  line-height: 1.5;
  margin: 0;

  @media ${media.m} {
    font-size: 16px;
  }
`;

const About = () => {
  const appState = useAppState();
  const { isMobileScreen } = appState;
  return (
    <>
      <Helmet>
        <title>About | GoodMood</title>
        <meta name="description" content="Platform that connects artists directly to a global audience with zero effort" />
      </Helmet>
      <PageHeader header="About" background={colors.purple} image={aboutHero} webpImage={aboutHeroWebp} imagePosition="top">
        We want to bust the myth that it's difficult, complicated or impossible for artists to get their work into the hands of
        someone who'll love it. So we created a platform that connects them directly to a global audience with zero effort.
      </PageHeader>

      <Container padding={isMobileScreen ? "24px" : "40px"}>
        <Flex justifyContent="center">
          <Column width={50}>
            <Flex direction="column" gap={isMobileScreen ? 24 : 32}>
              <AboutText>
                Too much amazing work is left unseen, unrealised and unenjoyed. GoodMood is here to change this.
              </AboutText>
              <AboutText>
                Using our platform, anyone with artwork can get it from file to frame and delivered anywhere in the world with
                practically zero effort.
              </AboutText>
              <AboutText>
                Let’s paint the full picture: GoodMood is open to anyone to submit digital art files, we then take care of the
                rest: printing, framing, fulfilment, payment and customer service. This allows artists to focus on what they do
                best, whilst earning a passive income.
              </AboutText>
              <AboutText>
                We review every submission and showcase our favourite new pieces on our homepage, in monthly collections – think
                of them as rolling pop-up exhibitions on our platform, which keeps the site fresh for our audience.
              </AboutText>
            </Flex>
          </Column>
        </Flex>
      </Container>
      <Container padding={isMobileScreen ? "24px 24px 0" : "40px 40px 0"}>
        <Stack direction="row" justifyContent="center" gap={isMobileScreen ? 3 : 4} height={{ xs: "30vh", md: "50vh" }}>
          <Stack flexBasis="50%">
            <Image src={AboutPageMockUp} webp={AboutPageMockUpWebp} alt="mock image" />
          </Stack>
          <Stack flexBasis="50%">
            <Image src={AboutPageMockUp2} webp={AboutPageMockUp2Webp} alt="mock image" />
          </Stack>
        </Stack>
      </Container>
      <Container padding={isMobileScreen ? "24px" : "40px"}>
        <Container background={colors.peach} padding="115px 40px">
          <Flex alignItems="center" direction="column" gap={isMobileScreen ? 16 : 42}>
            <Header type="h2">Want to know more?</Header>
            <Link to="/faqs">
              <Button secondary>FAQs</Button>
            </Link>
          </Flex>
        </Container>
      </Container>
    </>
  );
};

export default About;
