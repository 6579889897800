import { useContext, useRef } from "react";
import { Stack, Typography } from "@mui/material";
import { Container } from "../../components/Layout";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import wallBuilder from "../../assets/images/wallBuilderBanner.png";
import DownloadItem, { MockUpBackground } from "./DownloadItem";
import { Loader } from "../../components/Loader";
import ProfileContext from "../../state/profile";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import Button from "../../components/Button";

const Assets = () => {
  const { isMobileScreen, toggleGalleryWallBuilderIsOpen } = useAppState();
  const ref = useRef<HTMLDivElement>(null);
  const { products, hasMore, loading, getMoreArtistProducts } = useContext(ProfileContext);
  useLoadMoreOnScroll(ref, getMoreArtistProducts, hasMore, loading);

  return (
    <Container ref={ref} padding={isMobileScreen ? "0" : "0 40px 40px"} background={colors.white}>
      <Stack rowGap={{ xs: 0.25, md: 0.5 }}>
        {!isMobileScreen && (
          <Stack
            bgcolor={colors.black}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            borderRadius={1}
            paddingY={{ xs: 1.5, md: 3 }}
            paddingX={{ xs: 3, md: 4 }}
          >
            <Stack direction="row" alignItems="center">
              <Stack justifyContent="center" style={{ position: "relative", width: "90px" }}>
                <MockUpBackground src={wallBuilder} alt="insta story mock up" />
              </Stack>
              <Stack gap={0.5} paddingLeft={6} maxWidth="50%">
                <Typography color={colors.white} variant="h3">
                  Create your own composition
                </Typography>
                <Typography color={colors.white}>
                  Use the gallery wall builder to mock-up your work. You can can upload your own backgrounds or experiment with
                  ours.
                </Typography>
              </Stack>
            </Stack>
            <Button secondary size="medium" onClick={toggleGalleryWallBuilderIsOpen} style={{ whiteSpace: "nowrap" }}>
              Gallery wall builder
            </Button>
          </Stack>
        )}
        {products?.map((product) => (
          <DownloadItem product={product} key={product.id} />
        ))}
      </Stack>

      {loading ? (
        <Loader />
      ) : hasMore ? (
        <Stack width="100%" alignItems="center" padding={5}>
          <Button onClick={getMoreArtistProducts}>Load more</Button>
        </Stack>
      ) : null}
    </Container>
  );
};

export default Assets;
