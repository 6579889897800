import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Drawer, Stack, Typography } from "@mui/material";
import { CloseRounded, ExpandMoreRounded } from "@mui/icons-material";
import CheckboxGroup from "./CheckboxGroup";
import Button from "../../../../components/Button";
import { last12Months } from "../../../../state/constants";
import useFilterOptions from "../useFilterOptions";
import { colors } from "../../../../theme";
import RadioGroup from "./RadioGroup";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const FiltersDrawer: React.FC<Props> = ({ isOpen, onClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { mediumOptions, styleOptions, subjectOptions, sizeOptions, orientationOptions, sortByOptions } = useFilterOptions();
  const [state, setState] = useState<{
    [key: string]: string;
  }>(searchParams.toString() ? Object.fromEntries(searchParams) : {});

  const getFilterCount = (name: string) => {
    const count = state[name] ? state[name].split(",").length : 0;
    return count > 0 ? `(${count})` : "";
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams();
    setSearchParams(newParams, { preventScrollReset: true });
    setState({});
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  const onConfirm = () => {
    setSearchParams(state);
    if (window.scrollY > 650) window.scrollTo(0, 650);
    onClose();
  };

  return (
    <Drawer anchor="left" open={isOpen} onClose={onClose}>
      <Box width="90vw">
        <Stack
          paddingX={2}
          paddingY={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${colors.grey10}`}
        >
          <Typography variant="h3">Filters</Typography>
          <CloseRounded color="primary" onClick={onClose} />
        </Stack>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls="month-collection-content"
            id="month-collection-header"
          >
            <Typography>Sort by</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <RadioGroup name="sort" options={sortByOptions} state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="medium-content" id="medium-header">
            <Typography>Medium {getFilterCount("medium")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxGroup name="medium" options={mediumOptions} state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="style-content" id="style-header">
            <Typography>Style {getFilterCount("style")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxGroup name="style" options={styleOptions} state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="subject-content" id="subject-header">
            <Typography>Subject {getFilterCount("subject")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxGroup name="subject" options={subjectOptions} state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="size-content" id="size-header">
            <Typography>Size {getFilterCount("size")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxGroup name="size" options={sizeOptions} state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreRounded />} aria-controls="orientation-content" id="orientation-header">
            <Typography>Orientation {getFilterCount("orientation")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxGroup name="orientation" options={orientationOptions} state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRounded />}
            aria-controls="month-collection-content"
            id="month-collection-header"
          >
            <Typography>Month collection {getFilterCount("month")}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CheckboxGroup name="month" options={last12Months} state={state} setState={setState} />
          </AccordionDetails>
        </Accordion>

        <Stack gap={1} padding={2} position="sticky" bottom={0} bgcolor={colors.white} borderTop={`1px solid ${colors.grey10}`}>
          <Button onClick={onConfirm}>Confirm</Button>
          {searchParams.size > 0 && (
            <Button onClick={clearFilters} tertiary size="small">
              Clear
            </Button>
          )}
        </Stack>
      </Box>
    </Drawer>
  );
};

export default FiltersDrawer;
