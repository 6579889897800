import { useState } from "react";
import { Link } from "react-router-dom";
import { Header, Text } from "../../components/Text";
import Input from "../../components/Form/Input";
import Button from "../../components/Button";
import { Flex, Form, Row, Section } from "../../components/Layout";
import { authSendPasswordResetEmail } from "../../services/Firebase/auth";
import { useAppState } from "../../state";

const ResetPassword = () => {
  const { isMobileScreen } = useAppState();
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const handleChange = (event: any) => {
    setError("");
    setEmail(event.target.value);
  };

  const sendPasswordResetEmail = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      await authSendPasswordResetEmail(email);
      setEmailSent(true);
      setTimeout(() => setEmailSent(false), 3000);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = err.message;
      const message = errorMessage.split("Firebase: ");
      setLoading(false);
      setError(message);
    }
  };

  return (
    <Section narrow>
      <Row gap justifyContent="center">
        <Flex direction="column" width={isMobileScreen ? 100 : 30} gap={8} style={{ minWidth: isMobileScreen ? "0" : "400px" }}>
          <Header type="h2">Reset Password</Header>
          <Text>Please enter your username or email address. You will receive a link to create a new password via email.</Text>
          <Form onSubmit={sendPasswordResetEmail}>
            <Input placeholder="Email" id="email" name="email" onChange={handleChange} error={error} />
            <Button secondary fullWidth loading={loading}>
              {emailSent ? "Sent!" : "Send link"}
            </Button>
            <Flex justifyContent="center">
              <Link to="/login/artist">
                <Button tertiary>Back to login</Button>
              </Link>
            </Flex>
          </Form>
        </Flex>
      </Row>
    </Section>
  );
};

export default ResetPassword;
