import { Link } from "react-router-dom";
import { Chip, Stack, Typography } from "@mui/material";
import { orientationOptions, styleFilterOptions, subjectFilterOptions } from "../../../../state/constants";
import { Medium, MediumNames } from "../../../../types/product";
import { colors } from "../../../../theme";

type Props = {
  mediums: Medium[];
};

const FiltersSearchTab = ({ mediums }: Props) => {
  return (
    <Stack gap={3} width="100%">
      <Stack gap={1}>
        <Typography variant="h4">Medium</Typography>
        <Stack gap={1} direction="row" flexWrap="wrap">
          {mediums.map((medium: Medium) => (
            <Link
              to={{
                pathname: "/shop",
                search: `?medium=${medium}`,
              }}
              key={medium}
            >
              <Chip
                label={MediumNames[medium]}
                color="info"
                clickable
                sx={{ color: colors.darkPurple, backgroundColor: colors.purple20, fontSize: 14, padding: "8px 16px" }}
              />
            </Link>
          ))}
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h4">Style</Typography>
        <Stack gap={1} direction="row" flexWrap="wrap">
          {styleFilterOptions.map((option) => (
            <Link
              to={{
                pathname: "/shop",
                search: `?style=${option.value}`,
              }}
              key={option.value}
            >
              <Chip
                label={option.label}
                color="info"
                clickable
                sx={{ color: colors.darkPurple, backgroundColor: colors.purple20, fontSize: 14, padding: "8px 16px" }}
              />
            </Link>
          ))}
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h4">Subject</Typography>
        <Stack gap={1} direction="row" flexWrap="wrap">
          {subjectFilterOptions.map((option) => (
            <Link
              to={{
                pathname: "/shop",
                search: `?subject=${option.value}`,
              }}
              key={option.value}
            >
              <Chip
                label={option.label}
                color="info"
                clickable
                sx={{ color: colors.darkPurple, backgroundColor: colors.purple20, fontSize: 14, padding: "8px 16px" }}
              />
            </Link>
          ))}
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Typography variant="h4">Orientation</Typography>
        <Stack gap={1} direction="row" flexWrap="wrap">
          {orientationOptions.map((orientation) => (
            <Link
              to={{
                pathname: "/shop",
                search: `?orientation=${orientation.value}`,
              }}
              key={orientation.value}
            >
              <Chip
                label={orientation.label}
                color="info"
                clickable
                sx={{ color: colors.darkPurple, backgroundColor: colors.purple20, fontSize: 14, padding: "8px 16px" }}
              />
            </Link>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FiltersSearchTab;
