import jsPDF from "jspdf";

export const createPdf = async (element: HTMLElement | null, callback: (doc: jsPDF) => void) => {
  const doc = new jsPDF();

  if (element) {
    const pdf = await doc.html(element, {
      callback,
      x: 0,
      y: 0,
      width: 210, //target width in the PDF document
      windowWidth: 840, //window width in CSS pixels
    });
    return pdf;
  }
};
