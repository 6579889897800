import styled from "styled-components";
import { fonts } from "../../theme";

export type Props = {
  text: string;
  background: string;
  color: string;
  padding?: number;
  size?: number;
  speed?: number;
};

const Banner = styled.div<{ background: string; padding?: number }>`
  background-color: ${(p) => p.background};
  padding: ${(p) => (p.padding ? `${p.padding}px` : "4px")};
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;
`;

const BannerText = styled.span<{ color: string; size: number; speed: number; delay?: boolean }>`
  @keyframes roll {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
  font-family: ${fonts.banner};
  line-height: 1;
  display: inline-block;
  letter-spacing: 4px;
  font-size: ${(p) => `${p.size}px`};
  margin: 0;
  width: max-content;
  color: ${(p) => p.color};
  text-transform: uppercase;
  animation: roll ${(p) => `${p.speed}s`} linear infinite;

  ${(p) =>
    p.delay &&
    `
    animation: roll ${p.speed}s linear infinite;
    padding-left: 16px;`}
`;

const RollingTextBanner = ({ text, background, color, padding, size = 14, speed = 80 }: Props) => {
  return (
    <Banner background={background} padding={padding}>
      <BannerText color={color} size={size} speed={speed}>
        {text.repeat(4)}
      </BannerText>
      <BannerText color={color} size={size} speed={speed} delay>
        {text.repeat(4)}
      </BannerText>
    </Banner>
  );
};

export default RollingTextBanner;
