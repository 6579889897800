import styled from "styled-components";
import { Typography } from "@mui/material";
import { media } from "../../helpers/layout";
import { colors } from "../../theme";

type Props = {
  transparent?: boolean;
};

export const NavWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 6;
`;

export const NavBar = styled.nav<Props>`
  background-color: ${(p) => (p.transparent ? "transparent" : colors.white)};
  transition: background-color 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  height: 72px;

  p {
    transition: color 0.5s ease;
  }

  @media ${media.m} {
    height: 56px;
    background-color: ${(p) => (p.transparent ? "transparent" : colors.white)};
    border-bottom: 1px solid ${(p) => (p.transparent ? "transparent" : colors.grey05)};
    padding: 0 16px;
  }
`;

export const MobileNav = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  background: ${colors.black};
  transform: translateX(100%);
`;

export const NavItem = styled(Typography)`
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
`;

export const Logo = styled.p`
  font-size: 34px;
  margin: 0;
`;

export const SearchBarWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 840px;
  height: 80vh;
  max-width: 100%;
  border-radius: 32px;
  background-color: ${colors.white};
  overflow: hidden;
  z-index: 1;

  @media ${media.m} {
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    border-radius: 0;
  }
`;

export const Veil = styled.div`
  width: 100%;
  height: 100vh;
  background-color: transparent;
  position: absolute;
  left: 0;
  top: 0;
  transition: background-color 0.2s ease;
`;

export const BigSearch = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

export const Search = styled.div`
  position: relative;
  width: 100%;
`;

export const SearchResult = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  background-color: ${colors.grey90};
  display: flex;
  align-items: center;
  padding: 24px;
  border-radius: 8px;
  z-index: 1;
`;
