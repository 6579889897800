import { Timestamp } from "firebase/firestore";

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  APPROVED = "APPROVED",
  PAID = "PAID",
}

export type InvoiceItem = {
  id: string;
  description: string;
  amount: number;
  custom?: boolean;
};

export type Invoice = {
  id: string;
  invoiceNumber: number;
  artistId: string;
  artist: string;
  paypalEmail?: string;
  amount: number;
  issueDate: Timestamp;
  pdfUrl?: string;
  vatNumber: string;
  status: InvoiceStatus;
  items: InvoiceItem[];
  createdAt: Timestamp;
  updatedAt: Timestamp;
};

export type InvoiceInput = {
  invoiceNumber?: number;
  artist: string;
  artistId: string;
  paypalEmail: string;
  amount: number;
  date: Date;
  vatNumber: string;
  status: InvoiceStatus;
};

export type NewDraftInvoiceInput = {
  artistId: string;
  amount: number;
  date: string;
};
