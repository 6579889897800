import { useParams, Link } from "react-router-dom";
// @ts-ignore
import { Markup } from "react-render-markup";
import { Stack, Typography } from "@mui/material";
import { match } from "ts-pattern";
import { Header } from "../../../components/Text";
import { Column, Container, Flex } from "../../../components/Layout";
import { Image } from "../../../components";
import { colors } from "../../../theme";
import { Loader } from "../../../components/Loader";
import { useAppState } from "../../../state";
import { isNextMonth } from "../../../helpers/time";
import StatusPill from "../StatusPill";
import TextLink from "../../../components/TextLink";
import IconButton from "../../../components/Button/IconButton";
import Stats from "./Stats";
import { ProductStatus, useChangeProductStatusMutation, useGetDashboardProductQuery } from "../../../generated/graphql";
import ActionButtons from "./ActionButtons";

const ProductBreakdown = () => {
  const { isMobileScreen } = useAppState();
  const { id } = useParams();
  const { data, loading, refetch } = useGetDashboardProductQuery({
    fetchPolicy: "network-only",
    variables: { id: `gid://shopify/Product/${id}` },
  });
  const [changeProductStatus, { loading: updating }] = useChangeProductStatusMutation();
  const product = data?.product;

  const updateStatus = async (status: ProductStatus) => {
    if (product) {
      await changeProductStatus({
        variables: {
          id: product.id,
          status,
        },
      });
      await refetch();
    }
  };

  if (loading) return <Loader />;

  if (!product)
    return (
      <Container width={100} style={{ height: "50vh" }}>
        <Stack alignItems="center" justifyContent="center" style={{ height: "100%" }}>
          <Typography>Product not found</Typography>
        </Stack>
      </Container>
    );

  return (
    <Container padding={isMobileScreen ? "0" : "24px"}>
      <Flex gap={32} wrap={isMobileScreen}>
        <Column width={isMobileScreen ? 100 : 50}>
          <Flex gap={32} direction="column">
            <Container padding={isMobileScreen ? "24px 24px 0" : "0"}>
              <Flex gap={16} direction="column">
                {!isMobileScreen && (
                  <Link to="/profile">
                    <IconButton icon="backArrow">Back to profile</IconButton>
                  </Link>
                )}
                <Flex alignItems="center" justifyContent="space-between">
                  <TextLink to={`/products/${id}`}>
                    <Header type="h3">{product.title}</Header>
                  </TextLink>
                  {match(product)
                    .when(
                      ({ status }) => status === ProductStatus.Archived,
                      () => <StatusPill status="archived" />
                    )
                    .when(
                      ({ status }) => status === ProductStatus.Draft,
                      () => <StatusPill status="draft" />
                    )
                    .when(
                      ({ productType }) => !isNextMonth(productType),
                      () => <StatusPill status="active" />
                    )
                    .when(
                      ({ productType }) => isNextMonth(productType),
                      () => <StatusPill status="upcoming" />
                    )
                    .otherwise(() => (
                      <StatusPill status="archived" />
                    ))}
                </Flex>

                {product.descriptionHtml && (
                  <div>
                    <Markup markup={product.descriptionHtml} style={{ margin: 0 }} />
                  </div>
                )}
                {!isMobileScreen && <ActionButtons product={product} updateStatus={updateStatus} loading={updating} />}
              </Flex>
            </Container>

            {!isMobileScreen && <Stats product={product} isMobileScreen={isMobileScreen} />}

            <Container padding={isMobileScreen ? "0 24px" : "0"}>
              {isMobileScreen && <Stats product={product} isMobileScreen={isMobileScreen} />}
            </Container>
          </Flex>
        </Column>

        <Column width={isMobileScreen ? 100 : 50}>
          <Container background={colors.cardGrey} padding="64px 24px">
            <Flex justifyContent="center">
              <Column width={50}>
                <Image src={product.images.nodes[0]?.src} />
              </Column>
            </Flex>
          </Container>
        </Column>

        {isMobileScreen && (
          <Column width={100}>
            <Container padding="0 24px 24px">
              <ActionButtons product={product} updateStatus={updateStatus} loading={loading} />
            </Container>
          </Column>
        )}
      </Flex>
    </Container>
  );
};

export default ProductBreakdown;
