import { Dispatch, SetStateAction } from "react";
import { Header, Text } from "../../components/Text";
import TextLink from "../../components/TextLink";
import { Tab } from ".";

type Props = {
  setSelectedTab: Dispatch<SetStateAction<Tab>>;
};

const AcceptableTerms = ({ setSelectedTab }: Props) => {
  return (
    <>
      <Text size={18}>
        This acceptable use policy sets out the terms between you and us under which you may access our website. This acceptable
        use policy applies to all users of, and visitors to, our site.
      </Text>
      <Text size={18}>
        Your use of our site means that you accept, and agree to abide by, all the policies in this acceptable use policy, which
        supplement our website <TextLink onClick={() => setSelectedTab(3)}>Terms of Use</TextLink>.
      </Text>
      <Text size={18}>
        Goodmoodprints.io is a site operated by GoodMood Prints Ltd (we or us). We are GoodMood Prints Ltd, a limited company
        registered in England and Wales under company number 14180872, whose registered address is Avins Farm, College Road,
        Ardingly, West Sussex, RH17 6SH.
      </Text>

      <Header type="h2" margin="16px 0 0">
        THERE ARE OTHER TERMS THAT MAY APPLY TO YOU
      </Header>
      <Text size={18}>
        Our website <TextLink onClick={() => setSelectedTab(3)}>Terms of Use</TextLink> and our{" "}
        <TextLink openInNewTab to="/privacy">
          Privacy Policy
        </TextLink>{" "}
        also apply to your use of our site.
      </Text>
      <Header type="h2" margin="16px 0 0">
        PROHIBITED USES
      </Header>
      <Text size={18}>You may use our site only for lawful purposes.</Text>
      <Text size={18}>
        You may not use our site: In any way that breaches any applicable local, national or international law or regulation.
      </Text>
      <Text size={18}> In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</Text>
      <Text size={18}> For the purpose of harming or attempting to harm minors in any way.</Text>
      <Text size={18}>
        To send, knowingly receive, upload, download, use or re-use any material which does not comply with our content standards
        below.
      </Text>
      <Text size={18}>
        To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other
        form of similar solicitation (spam).
      </Text>
      <Text size={18}>
        To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs,
        keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the
        operation of any computer software or hardware.
      </Text>

      <Text size={18}>You also agree not to:</Text>
      <Text size={18}>
        reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of our website terms of use.
      </Text>
      <Text size={18}>access without authority, interfere with, damage or disrupt:</Text>
      <Text size={18}>any part of our site;</Text>
      <Text size={18}>any equipment or network on which our site is stored;</Text>
      <Text size={18}>any software used in the provision of our site; or</Text>
      <Text size={18}>any equipment or network or software owned or used by any third party.</Text>

      <Header type="h2" margin="16px 0 0">
        INTERACTIVE SERVICES
      </Header>

      <Text size={18}>
        We may from time to time provide interactive services on our site, including, without limitation: Chat rooms (for example,
        a feature enabling customer chat). Bulletin boards.
      </Text>

      <Text size={18}>
        Where we do provide any interactive service, we will provide clear information to you about the kind of service offered,
        if it is moderated and what form of moderation is used (including whether it is human or technical).
      </Text>

      <Text size={18}>
        We will do our best to assess any possible risks for users (and in particular, for children) from third parties when they
        use any interactive service provided on our site, and we will decide in each case whether it is appropriate to use
        moderation of the relevant service (including what kind of moderation to use) in the light of those risks. However, we are
        under no obligation to oversee, monitor or moderate any interactive service we provide on our site, and we expressly
        exclude our liability for any loss or damage arising from the use of any interactive service by a user in contravention of
        our content standards, whether the service is moderated or not.
      </Text>

      <Text size={18}>
        The use of any of our interactive services by a minor is subject to the consent of their parent or guardian. We advise
        parents who permit their children to use an interactive service that it is important that they communicate with their
        children about their safety online, as moderation is not fool proof. Minors who are using any interactive service should
        be made aware of the potential risks to them.
      </Text>

      <Text size={18}>
        Where we do moderate an interactive service, we will normally provide you with a means of contacting the moderator, should
        a concern or difficulty arise.
      </Text>

      <Header type="h2" margin="16px 0 0">
        CONTENT STANDARDS
      </Header>

      <Text size={18}>
        These content standards apply to any and all material which you contribute or download to our site, (contributions), and
        to any interactive services associated with it.
      </Text>
      <Text size={18}>
        You must comply with the spirit and the letter of the following standards. The standards apply to each part of any
        contribution as well as to its whole.
      </Text>
      <Text size={18}>Contributions must:</Text>
      <ul>
        <li>
          <Text size={18}>Be accurate (where they state facts).</Text>
        </li>
        <li>
          <Text size={18}>Be genuinely held (where they state opinions).</Text>
        </li>
        <li>
          <Text size={18}>
            Comply with applicable law in the UK and in any country from which they are posted (including without limitation the
            requirements of the General Data Protection Regulation and any associated laws regarding data privacy).
          </Text>
        </li>
      </ul>
      <Text size={18}>Contributions must not:</Text>
      <ul>
        <li>
          <Text size={18}>Contain any material which is defamatory of any person.</Text>
        </li>
        <li>
          <Text size={18}>Contain any material which is obscene, offensive, hateful or inflammatory.</Text>
        </li>
        <li>
          <Text size={18}>Promote sexually explicit material.</Text>
        </li>
        <li>
          <Text size={18}>Promote violence.</Text>
        </li>
        <li>
          <Text size={18}>
            Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.
          </Text>
        </li>
        <li>
          <Text size={18}>Infringe any copyright, database right or trademark of any other person.</Text>
        </li>
        <li>
          <Text size={18}>Be likely to deceive any person.</Text>
        </li>
        <li>
          <Text size={18}>
            Be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.
          </Text>
        </li>
        <li>
          <Text size={18}>Promote any illegal activity.</Text>
        </li>
        <li>
          <Text size={18}>
            Be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety.
          </Text>
        </li>
        <li>
          <Text size={18}>Be likely to harass, upset, embarrass, alarm or annoy any other person.</Text>
        </li>
        <li>
          <Text size={18}>
            Be used to impersonate any person or organisation, or to misrepresent your identity or affiliation with any person or
            organisation.
          </Text>
        </li>
        <li>
          <Text size={18}>Give the impression that they emanate from us, if this is not the case.</Text>
        </li>
        <li>
          <Text size={18}>
            Advocate, promote or assist any unlawful act such as (by way of example only) copyright infringement or computer
            misuse.
          </Text>
        </li>
      </ul>

      <Header type="h2" margin="16px 0 0">
        SUSPENSION AND TERMINATION
      </Header>
      <Text size={18}>
        We will determine, in our discretion, whether there has been a breach of this acceptable use policy through your use of
        our site. When a breach of this policy has occurred, we may take such action as we deem appropriate.
      </Text>
      <Text size={18}>
        Failure to comply with this acceptable use policy constitutes a material breach of the website{" "}
        <TextLink onClick={() => setSelectedTab(3)}>Terms of Use</TextLink> upon which you are permitted to use our site, and may
        result in our taking all or any of the following actions:
      </Text>
      <ul>
        <li>
          <Text size={18}>Immediate, temporary or permanent withdrawal of your right to use our site. </Text>
        </li>
        <li>
          <Text size={18}>
            Immediate, temporary or permanent removal of any posting or material uploaded by you to our site.{" "}
          </Text>
        </li>
        <li>
          <Text size={18}>Issue of a warning to you.</Text>
        </li>
        <li>
          <Text size={18}>
            Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to,
            reasonable administrative and legal costs) resulting from the breach. Further legal action against you.
          </Text>
        </li>
        <li>
          <Text size={18}>
            Disclosure of such information to law enforcement authorities as we reasonably feel is necessary.{" "}
          </Text>
        </li>
      </ul>
      <Text size={18}>
        We exclude liability for actions taken in response to breaches of this acceptable use policy. The responses described in
        this policy are not limited, and we may take any other action we reasonably deem appropriate.
      </Text>

      <Header type="h2" margin="16px 0 0">
        CHANGES TO THE ACCEPTABLE USE POLICY
      </Header>
      <Text size={18}>
        We may revise this acceptable use policy at any time by amending this page. You are expected to check this page from time
        to time to take notice of any changes we make, as they are legally binding on you. Some of the provisions contained in
        this acceptable use policy may also be superseded by provisions or notices published elsewhere on our site.
      </Text>
    </>
  );
};

export default AcceptableTerms;
