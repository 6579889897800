import { ProductCardProductFragment } from "../generated/storefront";
import { User } from "./user";

export enum SortKey {
  BEST_SELLING = "BEST_SELLING",
  PRICE = "PRICE",
  CREATED_AT = "CREATED_AT",
  TITLE = "TITLE",
}

export enum SortBy {
  BestSelling = "best_selling",
  PriceLowToHigh = "price_low_to_high",
  PriceHighToLow = "price_high_to_low",
  Newest = "newest",
}

export enum Medium {
  Photography = "photography",
  Graphic = "graphic_design",
  Mixed = "mixed_media",
  Painting = "painting",
}

export enum MediumNames {
  photography = "Photography",
  graphic_design = "Graphic Design",
  mixed_media = "Mixed Media",
  painting = "Painting",
}

export enum Orientation {
  Landscape = "landscape",
  Portrait = "portrait",
  Square = "square",
}

export enum OrientationNames {
  landscape = "Landscape",
  portrait = "Portrait",
  square = "Square",
}

export enum PaperType {
  Matte = "matte",
  Luster = "luster",
}

export enum PaperTypeNames {
  matte = "Matte 200gsm",
  luster = "Satin Luster 240gsm (recommended for photography)",
}

export enum Ratio {
  Rectangle = "rectangle",
  Square = "square",
}

export enum TShirtSize {
  Small = "small",
  Medium = "medium",
  Large = "large",
  XLarge = "xlarge",
}

export enum RectangleSize {
  A3 = "A3",
  A2 = "A2",
  "20X28" = "20X28",
  A1 = "A1",
  "28X40" = "28X40",
}

export enum SquareSize {
  "12X12" = "12X12",
  "20X20" = "20X20",
  "28X28" = "28X28",
}

export type Size = RectangleSize | SquareSize;

export const SizeScale = {
  [RectangleSize.A3]: 1,
  [RectangleSize.A2]: 2,
  [RectangleSize["20X28"]]: 2,
  [RectangleSize.A1]: 3,
  [RectangleSize["28X40"]]: 4,
  [SquareSize["12X12"]]: 1,
  [SquareSize["20X20"]]: 2,
  [SquareSize["28X28"]]: 3,
};

export const rectangleSizes = Object.values(RectangleSize);
export const squareSizes = Object.values(SquareSize);
export const tShirtSizes = Object.values(TShirtSize);

export const tShirtSizeNumbers = {
  [TShirtSize.Small]: 1,
  [TShirtSize.Medium]: 2,
  [TShirtSize.Large]: 3,
  [TShirtSize.XLarge]: 4,
};

export const maxRectangleSize = rectangleSizes[rectangleSizes.length - 1];
export const maxSquareSize = squareSizes[squareSizes.length - 1];

export enum Frame {
  Unframed = "unframed",
  White = "white",
  Black = "black",
  // Brown = "brown",
  Natural = "natural",
}

export const frames = Object.values(Frame);

export type UploadState = {
  title: string;
  description: string;
  image: {
    src: string;
    altText: string;
    width: number;
    height: number;
  };
  imageFile?: File;
  imageName: string;
  price: number;
  paperType: PaperType;
  confirmedRgb: boolean;
  maxSize: TShirtSize;
  ratio: Ratio;
  keywords: string[];
  medium?: Medium;
  campaign?: string;
  qualityCheckImageSrc?: string;
  gmTags: string[];
};

export type UploadInput = {
  title: string;
  description: string;
  imageName: string;
  imageSrc: string;
  price: number;
  medium: Medium;
  maxSize: TShirtSize;
  ratio: Ratio;
  orientation: Orientation;
  keywords: string[];
  gmTags: string[];
  campaign?: string;
  pick?: boolean;
  favourite?: boolean;
  communityFavourite?: boolean;
  collectionsToJoin?: string[];
  collectionsToLeave?: string[];
  metafields?: { namespace: string; key: string; value: string }[];
};

export type ProductWithArtist = ProductCardProductFragment & { artist: User | null };
