import Box from "@mui/material/Box";
import { Input } from "@mui/material";
import { ChangeEventHandler } from "react";

type Props = {
  handleSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const Search = ({ handleSearch }: Props) => {
  return (
    <Box padding={2}>
      <Input placeholder="Search..." onChange={handleSearch} />
    </Box>
  );
};

export default Search;
