import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { match } from "ts-pattern";
import { addMonths, format, isThisMonth, subMonths } from "date-fns";
import { isNumber } from "lodash";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { ArrowForwardRounded, InfoOutlined } from "@mui/icons-material";
import Button from "../../../components/Button";
import { Container, Flex } from "../../../components/Layout";
import { Loader } from "../../../components/Loader";
import { Header, Text } from "../../../components/Text";
import { media } from "../../../helpers/layout";
import { formattedPrice } from "../../../helpers/money";
import { isNextMonth } from "../../../helpers/time";
import { useAppState } from "../../../state";
import { colors } from "../../../theme";
import { getMonthlyProfitForProduct, getMonthlySalesForProduct } from "../helpers";
import StatusPill from "../StatusPill";
import { Icon } from "../../../components/Icon";
import ProfileContext from "../../../state/profile";
import { getIdNumber } from "../../../helpers/shopify";
import IconButton from "../../../components/Button/IconButton";
import { CurrencyCode } from "../../../generated/storefront";
import { ProductStatus } from "../../../generated/graphql";
import useLoadMoreOnScroll from "../../../hooks/useLoadMoreOnScroll";
import GridItem from "./GridItem";

const Card = styled(Flex)<{ padding?: string }>`
  background: ${colors.cardGrey};
  padding: ${(p) => (p.padding ? p.padding : "32px")};
  border-radius: 4px;

  @media ${media.m} {
    padding: 24px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 60px;
`;

const Dashboard = () => {
  const { isMobileScreen } = useAppState();
  const ref = useRef<HTMLDivElement>(null);
  const {
    products,
    hasMore,
    loading,
    getMoreArtistProducts,
    month,
    setMonth,
    getMonthsOrders,
    monthlyOrders,
    loadingMonthsOrders,
    monthsTotalProfit,
  } = useContext(ProfileContext);
  useLoadMoreOnScroll(ref, getMoreArtistProducts, hasMore, loading);

  const goToPreviousMonth = () => {
    const prevMonth = subMonths(month, 1);
    setMonth(prevMonth);
  };

  const goToNextMonth = () => {
    const nextMonth = addMonths(month, 1);
    setMonth(nextMonth);
  };

  useEffect(() => {
    if (!loadingMonthsOrders && monthsTotalProfit === undefined) {
      getMonthsOrders(month);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingMonthsOrders, monthsTotalProfit]);

  useEffect(() => {
    getMonthsOrders(month);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  return (
    <Container ref={ref} padding={isMobileScreen ? "0" : "0 40px 40px"}>
      <Stack spacing={3}>
        <Card gap={32} stretch fullWidth padding="8px">
          <IconButton icon="backArrow" onClick={goToPreviousMonth} disabled={loading} />
          <Header type="h3" align="center">
            {format(month, "MMMM")}
          </Header>
          <IconButton
            icon="forwardArrow"
            onClick={goToNextMonth}
            disabled={loading || isThisMonth(month)}
            style={{ justifyContent: "end" }}
          />
        </Card>
        <Flex gap={32} stretch fullWidth>
          <Card direction="column" gap={isMobileScreen ? 24 : 60}>
            <Header type="h3">Sales</Header>
            <Header type="h3" align="right">
              {monthlyOrders && !loadingMonthsOrders ? (
                monthlyOrders.flatMap((order) => order.lineItems.nodes).length
              ) : (
                <Icon icon="blackSpinner" size={30} />
              )}
            </Header>
          </Card>

          <Card direction="column" gap={isMobileScreen ? 24 : 60}>
            <Header type="h3">Profit</Header>
            <Header type="h3" align="right">
              {isNumber(monthsTotalProfit) && !loadingMonthsOrders ? (
                formattedPrice(monthsTotalProfit, CurrencyCode.Gbp)
              ) : (
                <Icon icon="blackSpinner" size={30} />
              )}
            </Header>
          </Card>
        </Flex>

        {products ? (
          products.length ? (
            <>
              <Grid container rowGap={{ xs: 0.25, sm: 0.5 }}>
                <Grid item xs={12}>
                  <Grid container bgcolor={colors.grey02} borderRadius={0.5}>
                    <GridItem paddingY={{ xs: 1.5, sm: 1 }} paddingX={{ xs: 3, sm: 2 }}>
                      Image
                    </GridItem>
                    <GridItem paddingY={{ xs: 1.5, sm: 1 }} paddingX={{ xs: 1.5, sm: 2 }}>
                      Name
                    </GridItem>
                    {!isMobileScreen && (
                      <GridItem paddingY={{ xs: 1.5, sm: 1 }} paddingX={{ xs: 1.5, sm: 2 }}>
                        Sales
                      </GridItem>
                    )}
                    <GridItem paddingY={{ xs: 1.5, sm: 1 }} paddingX={{ xs: 1.5, sm: 2 }}>
                      <Stack direction="row" gap={1} alignItems="center">
                        Profit
                        <Tooltip
                          title={
                            <Typography>
                              Please note commission is calculated from the sale price so may vary depending on discounts and/or
                              currency conversions
                            </Typography>
                          }
                        >
                          <InfoOutlined fontSize="small" />
                        </Tooltip>
                      </Stack>
                    </GridItem>
                    <GridItem paddingY={{ xs: 1.5, sm: 1 }} paddingX={{ xs: 3, sm: 2 }}>
                      Status
                    </GridItem>
                    {!isMobileScreen && (
                      <GridItem paddingY={{ xs: 1.5, sm: 1 }} paddingX={{ xs: 3, sm: 2 }}>
                        Actions
                      </GridItem>
                    )}
                  </Grid>
                </Grid>

                {products.map((product) => (
                  <Grid item xs={12} key={product.id}>
                    <Link key={product.id} to={`/profile/dashboard/${getIdNumber(product.id)}`}>
                      <Grid
                        container
                        bgcolor={colors.cardGrey}
                        borderRadius={0.5}
                        sx={{
                          color: "inherit",
                          "&:hover": {
                            backgroundColor: colors.grey10,
                            "& #arrow-forward": {
                              transform: "translateX(5px)",
                              transition: "transform 0.3s",
                            },
                          },
                        }}
                      >
                        <GridItem paddingY={{ xs: 1.5, sm: 2 }} paddingX={{ xs: 3, sm: 2 }}>
                          <Image src={product.images.nodes[0]?.src} />
                        </GridItem>
                        <GridItem padding={{ xs: 1.5, sm: 2 }}>{product.title}</GridItem>
                        {!isMobileScreen && (
                          <GridItem padding={{ xs: 1.5, sm: 2 }}>
                            {monthlyOrders && !loadingMonthsOrders ? (
                              getMonthlySalesForProduct(monthlyOrders, getIdNumber(product.id))
                            ) : (
                              <Icon icon="blackSpinner" />
                            )}
                          </GridItem>
                        )}
                        <GridItem padding={{ xs: 1.5, sm: 2 }}>
                          {monthlyOrders && !loadingMonthsOrders ? (
                            formattedPrice(getMonthlyProfitForProduct(monthlyOrders, getIdNumber(product.id)), CurrencyCode.Gbp)
                          ) : (
                            <Icon icon="blackSpinner" />
                          )}
                        </GridItem>
                        <GridItem padding={{ xs: 1.5, sm: 2 }} justifyContent={{ xs: "center", sm: "initial" }}>
                          {match(product)
                            .when(
                              ({ status }) => status === ProductStatus.Archived,
                              () => <StatusPill status="archived" />
                            )
                            .when(
                              ({ status }) => status === ProductStatus.Draft,
                              () => <StatusPill status="draft" />
                            )
                            .when(
                              ({ productType }) => !isNextMonth(productType),
                              () => <StatusPill status="active" />
                            )
                            .when(
                              ({ productType }) => isNextMonth(productType),
                              () => <StatusPill status="upcoming" />
                            )
                            .otherwise(() => (
                              <StatusPill status="archived" />
                            ))}
                        </GridItem>
                        {!isMobileScreen &&
                          (product.status === ProductStatus.Archived ? (
                            <GridItem paddingY={{ xs: 1.5, sm: 2 }} paddingX={{ xs: 3, sm: 2 }}>
                              <Stack direction="row" gap={3}>
                                <div style={{ width: "60px" }}></div>
                                <Link to={`/profile/dashboard/${getIdNumber(product.id)}`}>
                                  <ArrowForwardRounded id="arrow-forward" color="action" />
                                </Link>
                              </Stack>
                            </GridItem>
                          ) : (
                            <GridItem paddingY={{ xs: 1.5, sm: 2 }} paddingX={{ xs: 3, sm: 2 }}>
                              <Stack direction="row" gap={3}>
                                <Link to={`/profile/dashboard/${getIdNumber(product.id)}/edit`}>
                                  <Button size="small" secondary>
                                    Edit
                                  </Button>
                                </Link>
                                <Link to={`/profile/dashboard/${getIdNumber(product.id)}`}>
                                  <ArrowForwardRounded id="arrow-forward" color="action" />
                                </Link>
                              </Stack>
                            </GridItem>
                          ))}
                      </Grid>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              {loading ? (
                <Loader />
              ) : hasMore ? (
                <Stack width="100%" alignItems="center" padding={5}>
                  <Button onClick={getMoreArtistProducts}>Load more</Button>
                </Stack>
              ) : null}
            </>
          ) : (
            <Container background={colors.grey02} padding="105px 0">
              <Container width={isMobileScreen ? 70 : 40} margin="auto">
                <Flex direction="column" justifyContent="center" alignItems="center">
                  <Header type="h2" align="center">
                    You haven't uploaded anything yet
                  </Header>
                  <Text size={20} margin="16px 0 24px" align="center">
                    There are no upfront costs to submit your work. Just simply upload your files and sell your way.
                  </Text>
                  <Link to="/sell">
                    <Button secondary>Upload and sell</Button>
                  </Link>
                </Flex>
              </Container>
            </Container>
          )
        ) : (
          <Loader />
        )}
      </Stack>
    </Container>
  );
};

export default Dashboard;
