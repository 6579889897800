import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Stack, Typography } from "@mui/material";
import Button from "../../components/Button";
import IconButton from "../../components/Button/IconButton";
import { Container, Flex, Margin, Row } from "../../components/Layout";
import { Header, Text } from "../../components/Text";
import StepFour from "../Upload/StepFour";
import StepThree from "../Upload/StepThree";
import StepTwo from "../Upload/StepTwo";
import { BackButton, Content, ImageContainer, ImageSection, ProductImage, Step, Steps, StepsContainer } from "../Upload/styles";
import { useAppState } from "../../state";
import { Frame, Ratio, UploadState, TShirtSize, tShirtSizeNumbers } from "../../types/product";
import { colors } from "../../theme";
import MockUps from "../../components/MockUps";
import { updateProduct, uploadImageToShopify } from "../../services/API";
import { Loader } from "../../components/Loader";
import { getIdNumber } from "../../helpers/shopify";
import {
  getDimensionsForCanvas,
  getDimensionsForMockUpTemplate,
  getGmTagsFromTags,
  getKeywordsFromTags,
  getMaxSize,
  getRatioFromVariants,
  tShirtSizeToSize,
} from "../../helpers/product";
import StepOne from "../Upload/StepOne";
import useFirebaseImage from "../../hooks/useFirebaseImage";
import { imageZoom } from "../Upload/helpers";
import { useGetAdminProductQuery } from "../../generated/graphql";

const EditProduct = () => {
  const { navHeight, isMobileScreen, setError } = useAppState();
  const { id } = useParams();
  const [step, setStep] = useState(1);
  const [updated, setUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateFields, setUpdateFields] = useState<Partial<UploadState>>({});
  const { data, loading: loadingProduct } = useGetAdminProductQuery({
    fetchPolicy: "network-only",
    variables: { id: `gid://shopify/Product/${id}` },
  });
  const product = data?.product;
  const ratio = product ? getRatioFromVariants(product.variants.nodes) : Ratio.Rectangle;
  const currentMaxSize = product ? getMaxSize(product.variants.nodes) : TShirtSize.Large;
  const selectedMaxSize = updateFields.maxSize || currentMaxSize;
  const { width, height } = getDimensionsForCanvas(ratio);
  const { width: containerWidth, height: containerHeight } = getDimensionsForMockUpTemplate(ratio);
  const uploadedImageRef = useRef<HTMLImageElement>(null);

  const setUpdate = (state: Partial<UploadState>) => {
    setUpdateFields({ ...updateFields, ...state });
  };

  const {
    uploadImage,
    uploading,
    progress,
    imageUploadError,
    imageUploadWarning,
    isLandscape: newUploadIsLandscape,
    sizeOfUpload,
  } = useFirebaseImage(setUpdate, selectedMaxSize, ratio);

  const onStepChange = (step: number) => {
    setStep(step);
    window.scrollTo(0, 0);
  };

  const onSubmit = async () => {
    if (!product) return;
    setLoading(true);
    try {
      const imageSrc =
        updateFields.imageFile && updateFields.imageName
          ? await uploadImageToShopify(updateFields.imageName, updateFields.imageFile)
          : "";
      const { title, description, price, medium, imageName, maxSize, keywords, gmTags } = updateFields;
      await updateProduct(product.id, {
        title,
        description,
        price,
        medium,
        imageName,
        imageSrc,
        maxSize,
        ratio,
        keywords,
        gmTags: gmTags?.map((tag) => `gm.${tag}`),
      });
      setLoading(false);
      setUpdated(true);
    } catch (error: any) {
      setLoading(false);
      setError({ upload: "Error trying to update product, please try again. If error persists, please get in touch." });
    }
  };

  const createImageZoom = (event: SyntheticEvent<HTMLImageElement, Event>) => {
    imageZoom(event.target as HTMLImageElement, "zoomedUploadedImage", selectedMaxSize, ratio, isLandscape);
  };

  useEffect(() => {
    if (uploadedImageRef.current) {
      imageZoom(uploadedImageRef.current, "zoomedUploadedImage", selectedMaxSize, ratio, isLandscape);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMaxSize]);

  if (loadingProduct) return <Loader />;
  if (!product)
    return (
      <Container width={100} style={{ height: `calc(80vh - ${navHeight}px)` }}>
        <Stack alignItems="center" justifyContent="center" style={{ height: "100%" }}>
          <Typography>Product not found</Typography>
        </Stack>
      </Container>
    );

  const { title, descriptionHtml, images } = product;
  const productImage = images.nodes[0] || {};
  const productImageSrc = updateFields.image?.src || productImage?.src;
  const isLandscape = updateFields.image?.src
    ? newUploadIsLandscape
    : productImage.height && productImage.width
    ? productImage.height < productImage.width
    : false;
  const maxSize = getMaxSize(product.variants.nodes);
  const size = tShirtSizeToSize(selectedMaxSize, ratio);
  const keywords = getKeywordsFromTags(product.tags);
  const gmTags = getGmTagsFromTags(product.tags);
  const productState = {
    title,
    description: descriptionHtml,
    price: Number(product.priceRangeV2.minVariantPrice.amount),
    medium: product.medium.value,
    ratio,
    maxSize,
    keywords,
    gmTags,
    ...updateFields,
  } as UploadState;

  const reuploadRequired =
    tShirtSizeNumbers[selectedMaxSize] > (sizeOfUpload ? tShirtSizeNumbers[sizeOfUpload] : tShirtSizeNumbers[currentMaxSize]);

  return (
    <>
      {isMobileScreen ? (
        <Container padding="24px">
          <Flex direction="column" alignItems="center">
            <Text>Use a desktop to upload and start selling.</Text>
            <Margin margin={24} />
            <Link
              to={{
                pathname: "/shop",
                search: "?collection=true",
              }}
            >
              <Button secondary>Explore the collection</Button>
            </Link>
          </Flex>
        </Container>
      ) : (
        <Row nowrap>
          <Stack width="66%">
            <ImageSection>
              {step !== 1 && step !== 4 && (
                <BackButton>
                  <IconButton onClick={() => onStepChange(step - 1)} icon="backArrow" variation="light" />
                </BackButton>
              )}
              <Flex direction="column" fullWidth>
                <Container
                  background={colors.grey02}
                  width={100}
                  margin="0 0 4px"
                  padding="0 0 24px"
                  style={{ overflow: "hidden" }}
                >
                  <Flex justifyContent="center" alignItems="center" style={{ height: "80vh" }}>
                    <ImageContainer width={containerWidth} height={containerHeight} isLandscape={isLandscape}>
                      <canvas id="qualityCheckCanvas" style={{ visibility: "hidden" }}></canvas>
                      <canvas id="canvasPortrait" width={width} height={height} style={{ visibility: "hidden" }}></canvas>
                      <canvas id="canvasLandscape" width={height} height={width} style={{ visibility: "hidden" }}></canvas>
                      {updateFields.image?.src ? <div id="zoomedUploadedImage" className="img-zoom-result"></div> : null}
                      {productImageSrc && <ProductImage ref={uploadedImageRef} onLoad={createImageZoom} src={productImageSrc} />}
                    </ImageContainer>
                  </Flex>
                  {updateFields.image?.src && <Text align="center">Hover over piece to view print quality at each size</Text>}
                </Container>
                {productImageSrc && <MockUps image={updateFields.image || productImage} frame={Frame.Natural} size={size} />}
              </Flex>
            </ImageSection>
          </Stack>
          <Stack width="34%" minWidth={450}>
            <StepsContainer sticky={false} navHeight={navHeight}>
              {updated ? (
                <Container padding="24px">
                  <Flex gap={16} direction="column">
                    <Header type="h2">Product updated!</Header>
                    <a
                      href={`${process.env.REACT_APP_SITE_URL}/products/${getIdNumber(product.id)}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button fullWidth>View page</Button>
                    </a>
                    <Link to={`/profile/dashboard/${id}`}>
                      <Button fullWidth secondary>
                        Back
                      </Button>
                    </Link>
                  </Flex>
                </Container>
              ) : (
                <>
                  <Steps>
                    <Text size={14} as="span">
                      Step
                    </Text>
                    <Step selected={step === 1} onClick={() => onStepChange(1)}>
                      1
                    </Step>
                    <Step selected={step === 2} onClick={() => onStepChange(2)} disabled={reuploadRequired}>
                      2
                    </Step>
                    <Step selected={step === 3} onClick={() => onStepChange(3)} disabled={reuploadRequired}>
                      3
                    </Step>
                    <Step selected={step === 4} onClick={() => onStepChange(4)} disabled={reuploadRequired}>
                      4
                    </Step>
                  </Steps>
                  <Content>
                    {step === 1 && (
                      <StepOne
                        state={productState}
                        setState={setUpdate}
                        onStepChange={onStepChange}
                        stepComplete={!reuploadRequired}
                        reuploadRequired={reuploadRequired}
                        imageUploaded={Boolean(updateFields.image?.src && !uploading)}
                        uploadImage={uploadImage}
                        uploading={uploading}
                        progress={progress}
                        uploadError={imageUploadError}
                        uploadWarning={imageUploadWarning}
                        setConfirmedRgb={(value: boolean) => setUpdate({ confirmedRgb: value })}
                        editMode
                      />
                    )}
                    {step === 2 && <StepTwo state={productState} setState={setUpdate} onStepChange={onStepChange} stepComplete />}
                    {step === 3 && (
                      <StepThree state={productState} setState={setUpdate} onStepChange={onStepChange} stepComplete />
                    )}
                    {step === 4 && <StepFour state={productState} onSubmit={onSubmit} loading={loading} editMode />}
                  </Content>
                </>
              )}
            </StepsContainer>
          </Stack>
        </Row>
      )}
    </>
  );
};

export default EditProduct;
