import { HTMLAttributes } from "react";
import styled from "styled-components";
import { match } from "ts-pattern";
import { Box } from "@mui/material";
import { media } from "../../helpers/layout";
import { colors } from "../../theme";

export type SectionProps = {
  background?: string;
  narrow?: boolean;
  margin?: string;
  center?: boolean;
  verticallyCenter?: boolean;
  bottom?: boolean;
};

export type ColumnProps = {
  width?: number;
  smallWidth?: number;
  alignItems?: string;
  justifyContent?: string;
  background?: string;
  gap?: boolean;
};

export type RowProps = {
  background?: string;
  alignItems?: string;
  justifyContent?: string;
  gap?: boolean;
  nowrap?: boolean;
};

export type MarginProps = {
  margin: number;
  padding?: boolean;
};

export type FlexProps = {
  direction?: "column" | "row" | "row-reverse" | "column-reverse";
  alignItems?: string;
  justifyContent?: string;
  gap?: number;
  rowGap?: number;
  width?: number;
  fullWidth?: boolean;
  stretch?: boolean;
  grow?: boolean;
  wrap?: boolean;
};

export type ConatinerProps = {
  padding?: string;
  margin?: string;
  background?: string;
  border?: boolean;
  width?: number;
  borderRadius?: string;
  borderTop?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Page = styled(Box)<{ navHeight: number }>`
  min-height: calc(100vh - ${(p) => p.navHeight}px);
`;

export const Section = styled.div<SectionProps>`
  padding: ${(p) => (p.narrow ? "40px 80px" : "40px")};
  background-color: ${(p) => p.background || colors.white};
  transition: background-color 0.5s ease;
  margin: ${(p) => p.margin};
  color: ${colors.black};

  ${(p) =>
    p.center &&
    `
    display: flex;
    justify-content: center;
  `};
  ${(p) =>
    p.verticallyCenter &&
    `
    display: flex;
    flex-direction: column;
    justify-content: center;
  `};
  ${(p) =>
    p.bottom &&
    `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  `};

  @media ${media.m} {
    padding: 24px;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const Flex = styled.div<FlexProps>`
  display: flex;

  ${(p) => `
    flex-direction: ${p.direction || "normal"};
    align-items: ${p.alignItems || "normal"};
    justify-content: ${p.justifyContent || "normal"};
    width: ${p.width ? `${p.width}%` : "auto"};
    flex-wrap:  ${p.wrap ? "wrap" : "nowrap"};
    gap: ${p.gap || 0}px;
    ${typeof p.rowGap === "number" ? `row-gap: ${p.rowGap}px;` : ""}
  `}

  ${(p) =>
    p.fullWidth &&
    `
    width: 100%;
  `}
  ${(p) =>
    p.grow &&
    `
    flex-grow: 1;
  `}
  ${(p) =>
    p.stretch &&
    `
    > * {
      flex-grow: 1;
    }
  `}
`;

export const Row = styled.div<RowProps>`
  display: flex;
  position: relative;
  flex-wrap: ${(p) => (p.nowrap ? "nowrap" : "wrap")};
  width: 100%;
  ${(p) => p.gap && `gap: 24px; row-gap: 72px;`}
  background-color: ${(p) => p.background || "transparent"};
  ${(p) => `
    align-items: ${p.alignItems || "normal"};
    justify-content: ${p.justifyContent || "normal"};
  `}

  @media ${media.m} {
    ${(p) => p.gap && `row-gap: 32px;`}
  }
`;

export const Column = styled.div<ColumnProps>`
  background-color: ${(p) => p.background || "transparent"};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${(p) => p.width && `width: calc(${p.width}% ${p.gap ? `- ${((100 / p.width - 1) * 24) / (100 / p.width)}px` : ""});`};
  ${(p) => `
    align-items: ${p.alignItems || "normal"};
    justify-content: ${p.justifyContent || "normal"};
  `}

  @media ${media.m} {
    width: ${(p) => (p.width ? `${p.width}%` : "auto")};
  }

  @media ${media.m} {
    width: ${(p) =>
      p.smallWidth
        ? `calc(${p.smallWidth}% ${p.gap ? `- ${((100 / p.smallWidth - 1) * 24) / (100 / p.smallWidth)}px` : ""})`
        : "100%"};
  }
`;

export const Margin = styled.div<MarginProps>`
  width: 100%;

  ${(p) => p.margin && `margin-top: ${p.margin}px;`}
  ${(p) =>
    p.padding &&
    `padding-top: ${p.margin}px;
    margin: 0;
    `}
`;

export const Grid = styled.div<{ columns?: number; width?: number; evenColumns?: boolean; gap?: number; rowGap?: number }>`
  display: grid;
  grid-template-columns: ${(p) =>
    p.columns
      ? `repeat(${p.columns}, ${p.width ? `${p.width}px` : p.evenColumns ? "1fr" : "auto"})`
      : "minmax(337px, 1fr) repeat(auto-fill,  minmax(337px, 1fr))"};
  grid-template-rows: min-content;
  ${(p) => p.gap && `gap: ${p.gap}px`};
  ${(p) => typeof p.rowGap === "number" && `row-gap: ${p.rowGap}px`};

  @media ${media.m} {
    grid-template-columns: ${(p) =>
      p.columns
        ? `repeat(${p.columns}, ${p.width ? `${p.width}px` : p.evenColumns ? "1fr" : "auto"})`
        : "minmax(171px, 1fr) repeat(auto-fill,  minmax(171px, 1fr))"};
  }
`;

export const GridItem = styled.div<{
  span?: number;
  outline?: boolean;
  backgroundColor?: string;
  padding?: string;
  align?: "right" | "center";
}>`
  padding: 12px;
  display: flex;
  align-items: center;

  ${(p) => p.span && `grid-column: span ${p.span};`}
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.align && `text-align: ${p.align};`}
  ${(p) => p.align === "right" && "justify-content: end;"}
  ${(p) => p.align === "center" && "justify-content: center;"}
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : colors.white)};
  ${(p) =>
    match(p.outline)
      .with(false, () => "")
      .otherwise(() => `outline: 1px solid ${colors.grey05};`)}
`;

export const Container = styled.div<ConatinerProps>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${(p) => p.width && `width: ${p.width}%;`}
  ${(p) => p.padding && `padding: ${p.padding};`}
  ${(p) => p.margin && `margin: ${p.margin};`}
  ${(p) => p.background && `background: ${p.background};`}
  ${(p) => p.borderRadius && `border-radius: ${p.borderRadius};`}
  ${(p) => p.borderTop && `border-top: solid 1px ${colors.grey10};`}
  ${(p) =>
    p.border &&
    `
      border-top: solid 1px ${colors.grey10};
      border-bottom: solid 1px ${colors.grey10};
    `}
`;
