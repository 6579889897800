import { useState } from "react";
import {
  CartLineInput,
  useAddItemToBasketMutation,
  useCreateBasketMutation,
  useGetBasketLazyQuery,
} from "../generated/storefront";
import { trackEvent } from "../helpers/analytics";
import { useAppState } from "../state";

const useBasketActions = () => {
  const { selectedCountry, setBasket, setItemAdded } = useAppState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [getBasket] = useGetBasketLazyQuery();
  const [createBasket] = useCreateBasketMutation();
  const [addItemToBasket] = useAddItemToBasketMutation();

  const addOrCreateBasket = async (lines: CartLineInput[]) => {
    trackEvent("AddToCart");
    setLoading(true);
    const basketId = localStorage.getItem("basketId");
    const isValidBasket = basketId ? await getBasket({ variables: { basketId } }) : null;

    try {
      if (basketId && isValidBasket?.data?.cart?.id) {
        const { data } = await addItemToBasket({
          variables: { basketId, lines },
        });
        if (data?.cartLinesAdd?.cart) setBasket(data.cartLinesAdd.cart);
      } else {
        trackEvent("CreateCart");
        const { data } = await createBasket({
          variables: {
            lines,
            buyerIdentity: {
              countryCode: selectedCountry,
            },
          },
        });
        if (data?.cartCreate?.cart) {
          setBasket(data.cartCreate.cart);
          localStorage.setItem("basketId", data.cartCreate.cart.id);
        }
      }
      setLoading(false);
      setItemAdded(true);
    } catch (e) {
      setLoading(false);
      setError("Could not add item to basket");
    }
  };

  return { getBasket, addOrCreateBasket, loading, error };
};

export default useBasketActions;
