import { useContext, useEffect, useState } from "react";
import { useToggle } from "react-use";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { debounce } from "lodash";
import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { getInvoices } from "../../../../services/Firebase/invoices";
import { Invoice } from "../../../../types/invoice";
import { formattedPrice } from "../../../../helpers/money";
import Button from "../../../../components/Button";
import TextLink from "../../../../components/TextLink";
import StatusPill from "./StatusPill";
import Dropdown from "../../../../components/Form/Dropdown";
import AdminContext from "../../../../state/admin";
import { getUserById } from "../../../../services/Firebase";
import { getInvoiceSubtotal } from "../../../../helpers/prices";
import Search from "../../Search";
import CreateInvoiceModal from "./CreateInvoiceModal";
import { last12Months } from "../../../../state/constants";
import { CurrencyCode } from "../../../../generated/storefront";
import InvoiceActionButtons from "./InvoiceActionButtons";

export type InvoiceRow = Invoice & {
  invoiceReference: string;
  email?: string;
  paypalEmail?: string;
  currency?: CurrencyCode;
};

const Invoices = () => {
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<Invoice[]>();
  const [rows, setRows] = useState<InvoiceRow[]>([]);
  const { month, setMonth } = useContext(AdminContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [createInvoiceModalOpen, toggleCreateInvoiceModalOpen] = useToggle(false);
  const [rowsToShow, setRowsToShow] = useState<InvoiceRow[]>([]);

  const fetchInvoices = async () => {
    setLoading(true);
    const result = await getInvoices(month);
    setInvoices(result);
  };

  const approveAllInvoices = async () => {};

  const handleSearch = debounce((event: any) => {
    setSearchQuery(event.target.value);
  }, 1000);

  useEffect(() => {
    if (month) {
      fetchInvoices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);

  const columns: GridColDef[] = [
    {
      field: "invoiceReference",
      headerName: "Invoice Reference",
      width: 150,
      renderCell: ({ row }: { row: InvoiceRow }) => <Link to={`/admin/invoices/${row.id}`}>{row.invoiceReference}</Link>,
    },
    { field: "month", headerName: "Month", width: 100 },
    { field: "artist", headerName: "Artist", width: 150 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "paypalEmail", headerName: "Paypal Email", width: 220 },
    { field: "currency", headerName: "Currency", width: 100 },
    { field: "vatRegistered", headerName: "VAT/GST", width: 80, align: "center" },
    { field: "formattedAmount", headerName: "Amount", width: 90, align: "right" },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: ({ row }) => (row.status ? <StatusPill status={row.status} /> : null),
    },
    {
      field: "pdf",
      headerName: "PDF",
      width: 100,
      renderCell: (params) =>
        params.row.pdfUrl && (
          <TextLink href={params.row.pdfUrl} openInNewTab>
            <Button size="small">Open</Button>
          </TextLink>
        ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: ({ row }: { row: InvoiceRow }) => <InvoiceActionButtons row={row} fetchInvoices={fetchInvoices} />,
    },
  ];

  const getRows = async (invoices: Invoice[]) => {
    const invoicePromises = invoices.map(async (invoice) => {
      const artist = await getUserById(invoice.artistId);
      return {
        ...invoice,
        id: invoice.id,
        invoiceReference: `GM${invoice.invoiceNumber}`,
        email: artist?.email,
        paypalEmail: artist?.paypalEmail,
        currency: artist?.currency,
        vatRegistered: artist?.vatRegistered?.vatNumber ? "✅" : "❌",
        month: format(invoice.issueDate.toDate(), "MMMM"),
        formattedAmount: formattedPrice(getInvoiceSubtotal(invoice), CurrencyCode.Gbp),
      };
    });
    const rowsToBeSet = await Promise.all(invoicePromises);
    setRows(rowsToBeSet);
    setLoading(false);
  };

  useEffect(() => {
    if (invoices) {
      getRows(invoices);
    }
  }, [invoices]);

  useEffect(() => {
    if (searchQuery) {
      const filteredRows = rows.filter(
        (r) =>
          r.artist.toLowerCase().includes(searchQuery.toLowerCase()) ||
          r.invoiceNumber.toString().includes(searchQuery) ||
          r.id.includes(searchQuery) ||
          r.email?.includes(searchQuery) ||
          r.paypalEmail?.includes(searchQuery)
      );
      setRowsToShow(filteredRows);
    } else {
      setRowsToShow(rows);
    }
  }, [rows, searchQuery]);

  return (
    <>
      <Box sx={{ height: "70vh", width: "100%" }} paddingY={3}>
        <Box paddingTop={2} paddingBottom={2}>
          <Stack direction="row" spacing={2} justifyContent="space-between">
            <Stack direction="row" spacing={2}>
              <Dropdown
                options={last12Months}
                value={last12Months.find((m) => m.value === month)}
                onChange={({ value }) => setMonth(value)}
                width="auto"
                style={{ zIndex: 5 }}
              />
              <Search handleSearch={handleSearch} />
            </Stack>
            <Stack direction="row" spacing={2}>
              <Button size="medium" onClick={approveAllInvoices} disabled>
                Approve all
              </Button>
              <Button size="medium" onClick={toggleCreateInvoiceModalOpen} secondary>
                Create new draft
              </Button>
            </Stack>
          </Stack>
        </Box>
        <DataGrid
          loading={loading}
          rows={rowsToShow}
          columns={columns}
          pageSizeOptions={[25]}
          pagination
          slots={{ toolbar: GridToolbar }}
        />
      </Box>
      {createInvoiceModalOpen && <CreateInvoiceModal onClose={toggleCreateInvoiceModalOpen} refetch={fetchInvoices} />}
    </>
  );
};

export default Invoices;
