import { Route, Routes } from "react-router";
import { ApolloProvider } from "@apollo/client";
import AuthGuard from "./components/AuthGuard";
import Invoice from "./pages/Admin/Tabs/Invoices/Invoice";
import AdminCheckout from "./pages/Admin/Checkout";
import Admin from "./pages/Admin";
import { adminClient } from "./helpers/apolloClients";
import { AdminStateProvider } from "./state/admin";

const AdminApp = () => {
  return (
    <ApolloProvider client={adminClient}>
      <AdminStateProvider>
        <Routes>
          <Route path="/checkout" element={<AuthGuard type="admin" element={AdminCheckout} />} />
          <Route path="/invoices/:id" element={<AuthGuard type="admin" element={Invoice} />} />
          <Route path="/" element={<AuthGuard type="admin" element={Admin} />} />
        </Routes>
      </AdminStateProvider>
    </ApolloProvider>
  );
};

export default AdminApp;
