import { createContext, useEffect, useState } from "react";
import { endOfMonth, format, startOfMonth } from "date-fns";
import useGetBulkOrders from "../hooks/useGetBulkOrders";
import { BulkOrderFragment } from "../generated/graphql";

const AdminContext = createContext<{
  month: string;
  setMonth: (date: string) => void;
  getOrders: (startDate: string, endDate: string) => void;
  monthlyOrders?: BulkOrderFragment[];
  loadingMonthsOrders: boolean;
}>({
  month: startOfMonth(new Date()).toISOString(),
  setMonth: () => {},
  getOrders: () => {},
  loadingMonthsOrders: false,
});

export const AdminStateProvider: React.FC = ({ children }) => {
  const [loadingMonthsOrders, setLoadingMonthsOrders] = useState(false);
  const [ordersByDateRange, setOrdersByDateRange] = useState<{ [dateRange: string]: BulkOrderFragment[] }>({});
  const [monthlyOrders, setMonthlyOrders] = useState<BulkOrderFragment[]>();
  const [month, setMonth] = useState("");
  const [dateRange, setDateRange] = useState<string>(
    `${format(startOfMonth(new Date()), "yyyy-MM-dd")}-${format(endOfMonth(new Date()), "yyyy-MM-dd")}`
  );
  const { getOrders, orders } = useGetBulkOrders();

  const setOrders = (orders: BulkOrderFragment[]) => {
    setMonthlyOrders(orders);
    setLoadingMonthsOrders(false);
  };

  const getOrdersForDateRange = async (startDate: string, endDate: string) => {
    setLoadingMonthsOrders(true);
    const dateRangeString = `${startDate}-${endDate}`;
    setDateRange(dateRangeString);
    if (ordersByDateRange[dateRangeString]) {
      setOrders(ordersByDateRange[dateRangeString]);
    } else {
      await getOrders({ startDate, endDate });
    }
  };

  useEffect(() => {
    if (!orders) return;
    setOrdersByDateRange({ ...ordersByDateRange, [dateRange]: orders });
    setOrders(orders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  return (
    <AdminContext.Provider
      value={{
        month,
        setMonth,
        getOrders: getOrdersForDateRange,
        monthlyOrders,
        loadingMonthsOrders,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export default AdminContext;
