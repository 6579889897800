import { sizeLabelsForCountry } from "../../../helpers/product";
import { useAppState } from "../../../state";
import { orientationOptions, styleFilterOptions, subjectFilterOptions } from "../../../state/constants";
import { Medium, MediumNames, SortBy, rectangleSizes, squareSizes } from "../../../types/product";

const useFilterOptions = () => {
  const { selectedCountry } = useAppState();
  const mediumOptions = Object.values(Medium).map((medium) => ({ label: MediumNames[medium], value: medium }));
  const sizeLabels = sizeLabelsForCountry(selectedCountry);
  const sizeOptions = [...rectangleSizes, ...squareSizes].map((size) => ({ label: sizeLabels[size].shortHand, value: size }));

  const sortByOptions = [
    {
      label: "Most popular",
      value: SortBy.BestSelling,
    },
    {
      label: "Price: low to high",
      value: SortBy.PriceLowToHigh,
    },
    {
      label: "Price: high to low",
      value: SortBy.PriceHighToLow,
    },
    {
      label: "Newest",
      value: SortBy.Newest,
    },
  ];

  return {
    mediumOptions,
    sizeOptions,
    orientationOptions,
    sortByOptions,
    styleOptions: styleFilterOptions,
    subjectOptions: subjectFilterOptions,
  };
};

export default useFilterOptions;
