import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { subMonths } from "date-fns";
import { Chip, Grid, Stack, Typography } from "@mui/material";
import { User } from "../../../../types/user";
import { SearchProductWithArtist } from "../useSearch";
import { Loader } from "../../../Loader";
import SearchProductCard from "../SearchProductCard";
import { CountryCode, ProductSortKeys, useGetProductsForProductCardQuery } from "../../../../generated/storefront";
import { getMonthsDate, getThisMonthsDate } from "../../../../helpers/time";
import { allFilterOptions, styleFilterOptions } from "../../../../state/constants";
import ArtistCard from "../../../Card/Artist";
import { colors } from "../../../../theme";

type Props = {
  artists: User[];
  defaultProducts: SearchProductWithArtist[];
  loadingDefaultProducts: boolean;
  isMobile?: boolean;
};

const TrendingSearchTab = ({ artists, defaultProducts, loadingDefaultProducts, isMobile = false }: Props) => {
  const [tags, setTags] = useState<{ label: string; value: string }[]>();
  const [loadingTags, setLoadingTags] = useState(false);
  const { data: trendingData } = useGetProductsForProductCardQuery({
    variables: {
      query: `product_type:${getThisMonthsDate()} OR product_type:${getMonthsDate(
        subMonths(new Date(), 1)
      )} OR product_type:${getMonthsDate(subMonths(new Date(), 2))} OR product_type:${getMonthsDate(subMonths(new Date(), 3))}`,
      sortKey: ProductSortKeys.BestSelling,
      country: CountryCode.Gb,
      limit: 20,
    },
  });

  const getTop5MostCommonTags = (tags: string[]) => {
    const counts: Record<string, number> = {};
    tags.forEach((tag) => {
      counts[tag] = (counts[tag] || 0) + 1;
    });
    const entries = Object.entries(counts);
    entries.sort((a, b) => b[1] - a[1]);
    return entries.slice(0, 5).map((entry) => entry[0]);
  };

  useEffect(() => {
    if (tags || loadingTags) return;
    setLoadingTags(true);
    const trendingProducts = trendingData?.products?.nodes || [];
    const trendingTags = trendingProducts
      .map((product) => product.tags)
      .flat()
      .filter((tag) => tag.startsWith("gm."));
    const top5Tags = getTop5MostCommonTags(trendingTags);
    setTags(allFilterOptions.filter((option) => top5Tags.includes(`gm.${option.value}`)));
    setLoadingTags(false);
  }, [tags, trendingData, loadingTags]);

  if (loadingTags || loadingDefaultProducts || !defaultProducts)
    return (
      <Stack gap={3}>
        <Loader />
      </Stack>
    );

  return (
    <Stack gap={3}>
      <Stack gap={1} width="100%" direction="row" flexWrap="wrap">
        {tags?.map((tag) => (
          <Link
            to={{
              pathname: "/shop",
              search: styleFilterOptions.find((o) => o.value === tag.value) ? `?style=${tag.value}` : `?subject=${tag.value}`,
            }}
            key={tag.value}
          >
            <Chip
              label={tag.label}
              color="info"
              clickable
              sx={{ color: colors.darkPurple, backgroundColor: colors.purple20, fontSize: 14, padding: "8px 16px" }}
            />
          </Link>
        ))}
      </Stack>

      <Stack gap={1} width="100%">
        <Typography variant="h4">Artwork</Typography>
        <Grid container spacing={1}>
          {defaultProducts.map((product) => (
            <Grid item xs={12 / 3} key={product.id}>
              <SearchProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Stack>

      <Stack gap={1} width="100%">
        <Typography variant="h4">Artists</Typography>
        <Grid container spacing={1} rowSpacing={2}>
          {artists.map((artist) => (
            <Grid item xs={12 / 3} key={artist.id}>
              <ArtistCard artist={artist} size="small" imageHeight={isMobile ? "30vw" : "150px"} borderRadius={12} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Stack>
  );
};

export default TrendingSearchTab;
