import { Stack } from "@mui/material";
import Accordion from "../../components/Accordion";
import { Column, Container, Flex } from "../../components/Layout";
import { Text } from "../../components/Text";
import TextLink from "../../components/TextLink";
import { Indent } from "./styles";

const Artist = () => {
  return (
    <Container padding="0 0 400px">
      <Flex justifyContent="center">
        <Column width={50}>
          <Accordion
            highlight
            items={[
              {
                label: "How does it work?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <div>
                      <Text>1. Upload your files</Text>
                      <Text>Upload your files. Set your orientation. Add a title and description.</Text>
                    </div>
                    <div>
                      <Text>2. Set your prices</Text>
                      <Text>
                        Set the prices you’d like to charge. See your potential payout with our estimated payout calculator.
                      </Text>
                    </div>
                    <div>
                      <Text>3. Your work goes live</Text>
                      <Text>
                        Every submission becomes available to purchase from the following month, with thousands of potential new
                        buyers. If we love your work, we’ll also feature it on our shop page and on our social media.
                      </Text>
                    </div>
                    <div>
                      <Text>4. People buy your work</Text>
                      <Text>
                        Your work is available for anyone in the world to buy, as a print or framed print. We handle everything:
                        printing, framing, delivery and customer service.
                      </Text>
                    </div>
                    <div>
                      <Text>5. You get paid</Text>
                      <Text>We’ll then pay you within 10 working days of the end of each month via Paypal.</Text>
                    </div>
                  </Stack>
                ),
              },
              {
                label: "Why sell on GoodMood?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <div>
                      <Text>No upfront costs</Text>
                      <Text>It’s free to submit your work to GoodMood. Just simply upload your files and sell your work.</Text>
                    </div>
                    <div>
                      <Text>No hassle or effort</Text>
                      <Text>
                        We'll take care of everything. Printing, framing, packaging, delivery, payments, returns & customer
                        service. You can concentrate on doing what you love.
                      </Text>
                    </div>
                    <div>
                      <Text>Reach a global audience</Text>
                      <Text>
                        Every submission becomes available to purchase from the following month, with thousands of potential new
                        buyers. If we love your work, we’ll also feature it on our shop page and on our social media.
                      </Text>
                    </div>
                    <div>
                      <Text>Earn a passive income</Text>
                      <Text>
                        Following the end of each month, you’ll get paid your share of the profit within 10 working days via
                        Paypal.
                      </Text>
                    </div>
                  </Stack>
                ),
              },
              {
                label: "How do I upload my work?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      You can upload your artwork <TextLink to="/sell">here</TextLink> through our sell journey.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "Can I have multiple prints per collection?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>Yes, there is no limit to how many items you can submit per collection.</Text>
                  </Stack>
                ),
              },
              {
                label: "Is there a minimum sell threshold?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      No, there is no minimum threshold. If you only sell 1 item, we will still produce and send it and pay you
                      your profit share.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What do I need to do to sell on GoodMood?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>When you upload a file to GoodMood, there are only 2 things you need to manage:</Text>
                    <Text>1. Creating your digital artwork and preparing it correctly.</Text>
                    <Text>2. Uploading it: creating a name, description and setting your base price.</Text>
                    <Text>
                      We take care of everything else. Printing, framing, website, packaging, delivery, payments, customer service
                      and refunds. You can concentrate on doing what you love and spreading the word about your work.
                    </Text>
                    <Text>
                      To earn as much as possible, spend some time promoting the listing to your network. We’ve put some top tips
                      below:
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How should I promote my work?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>Here are some of our top tips for promoting your work on our platform:</Text>
                    <Text>- Tell your friends your work is available to buy on GoodMood.</Text>
                    <Text>- Use your social media channels:</Text>
                    <Indent>
                      <Text>- Use stories and posts to announce your piece launching in the collection</Text>
                      <Text>- Tag @goodmoodprints in your posts, stories and reels</Text>
                      <Text>
                        - Let your followers know when it is the last chance to buy. E.g. There’s only 4 days left to purchase my
                        piece on @goodmoodprints
                      </Text>
                      <Text>- Add a link to your listing in your social media bios</Text>
                    </Indent>
                    <Text>- Email - if you have an email database then email your customers.</Text>
                  </Stack>
                ),
              },
              {
                label: "What sizes do you offer?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We currently offer prints and framed prints in 70x100cm (28x40in), A1, 50x70cm (20x28in), A2 & A3 in
                      portrait and landscape and 30x30cm (12x12in), 50x50cm (20x20in) & 70x70cm (28x28in).
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How should I prepare my artwork?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      It’s important that your artwork is prepared in the right way, to meet our printers specifications, and
                      ensure the best print results. See our requirements below:
                    </Text>
                    <Text>
                      1. Export a high quality (minimum 300 dpi) RGB file. We cannot accept CMYK. This may come as a surprise but
                      this allows us to maximise colour accuracy and quality of the finished file. Our printers can print up to 12
                      colours, massively enhancing your colour gamut compared to the traditional 4, and this also means the
                      finished print is closely matched to what customers see on screen.
                    </Text>
                    <Text>2. To meet quality standards, ensure that the file size adheres to the following dimensions.</Text>
                    <div>
                      <Text>Rectangle:</Text>
                      <ul>
                        <li>
                          <Text>Small: 3507px x 4960px</Text>
                        </li>
                        <li>
                          <Text>Medium: 6000px x 8400px</Text>
                        </li>
                        <li>
                          <Text>Large: 7016px x 9933px</Text>
                        </li>
                        <li>
                          <Text>X-Large: 8400px x 12000px</Text>
                        </li>
                      </ul>
                      <Text>Square:</Text>
                      <ul>
                        <li>
                          <Text>Small: 3600px by 3600px</Text>
                        </li>
                        <li>
                          <Text>Medium: 6000px by 6000px</Text>
                        </li>
                        <li>
                          <Text>Large: 8400px by 8400px</Text>
                        </li>
                      </ul>
                    </div>
                    <Text>
                      3. We only print and frame A-sizes, 50x70cm (20x28in), 70x100cm (28x40in) and square, so please provide
                      artwork with an A-size or square aspect ratio.
                    </Text>
                    <Text>
                      4. If you prefer your work to have a border, you must add this to the file itself. We don’t currently offer
                      mounting with our framing
                    </Text>
                    <Text>
                      5. If you want to add a signature to your work, please add this to the digital file. We’re unable to add a
                      signature or edition numbers
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How should I set my prices?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      The only price you need to set is for the smallest sized print. We then set the prices for the other print
                      sizes, and also for all the framed sizes.
                    </Text>
                    <Text>For the smallest sized print, we set a minimum price of £30 and our recommended price is £40.</Text>
                    <Text>
                      To help you decide on your prices, it is worth having a look through the latest collections to give you a
                      feel for how similar pieces are priced. Remember, typically the lower the price the more you will sell. So
                      have a think about whether you want to sell more at a lower price, or fewer at a higher price.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How do I get paid?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      At the end of each month, we will pay you your share of the profit within 10 working days. We currently only
                      pay artists using Paypal. Please make sure you upload your Paypal email to your GoodMood Profile. We
                      currently only pay in GBP.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How much do I get paid?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>We pay you according to how you set your prices and how many pieces you sell in the month.</Text>
                    <Text>
                      We pay our artists 30% commission of the print sale price ie. you are paid the same commission if the print
                      is framed or unframed. When you factor in all of our costs, in nearly all cases the artists takes over 60%
                      of the profit from each sale. Our costs include VAT/GST/taxes, printing & framing, packaging, fulfilment,
                      payment processing, customer service, refunds, web hosting & development and marketing.
                    </Text>
                    <Text>
                      You can set your prices and view a breakdown of how much you get paid per sale in the{" "}
                      <TextLink to="/sell">artwork upload journey</TextLink>
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How do discounts work?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      When selling on GoodMood you agree to participate in discounts. So if a customer uses a 10% discount code
                      for their order, both our sales revenue and your commission on that sale will be reduced 10%. We don’t
                      regularly run promotions on our site.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "Will you use my images?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We will use your artwork on our social media, website, email and other marketing channels. You can read more
                      about this in our <TextLink to="/terms">terms for artists</TextLink>
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How does tax work?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      You’ll need to let us know if you are VAT/GST registered in your artist profile page. It is your
                      responsibility to tell us if you are VAT/GST registered and everyone is responsible for their own individual
                      tax situation. Please note you do not need to be VAT/GST registered to sell on GoodMood but we need to know
                      if you are.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What are your environmental credentials?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      The planet is incredibly important to us. We only use eco-inks and all of our paper, wood and packaging is
                      sustainably sourced.
                    </Text>
                    <Text>
                      We also print on demand as close to the delivery destination as possible. Printing on demand means there is
                      no wastage as every item is made to order. Printing and framing as locally as possible reduces transport
                      distances and therefore emissions.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "Can I have a name or signature on my prints or frame?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      Unfortunately, we can’t offer printing on the back of the photos or prints, or any hand signatures.
                    </Text>
                    <Text>
                      We recommend that you include a digital signature to your art file. This would need to be done before
                      uploading the image file to us.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "What paper do you use?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We use enhanced matte 200gsm, a premium quality heavyweight fine art print material with a smooth, clean
                      finish. This slight off-white museum-quality paper is extremely consistent and works perfectly with large,
                      full colour graphics, illustrations and photography. The matte finish emphasises different highlights and
                      tones in the source artworks; helping to create stunning works of art.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How do you make sure print quality is good?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      We have carefully selected our printing and framing partners to ensure consistent quality. If on a rare
                      occasion a customer is dissatisfied with the quality, we can organise a replacement or a refund.
                    </Text>
                  </Stack>
                ),
              },
              {
                label: "How do I contact GoodMood?",
                content: (
                  <Stack gap={2} paddingX={{ xs: 3, md: 4 }} paddingBottom={4}>
                    <Text>
                      You can email us at <TextLink href="mailto:hello@goodmoodprints.io">hello@goodmoodprints.io</TextLink> and
                      we normally respond within 24 hours.
                    </Text>
                  </Stack>
                ),
              },
            ]}
          />
        </Column>
      </Flex>
    </Container>
  );
};

export default Artist;
