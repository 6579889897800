import { Link } from "react-router-dom";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { PersonAddAltRounded } from "@mui/icons-material";
import { colors } from "../../theme";
import { Image } from "../../components";
import { Wishlist } from "../../types/wishlist";
import { WishlistProductFragment } from "../../generated/storefront";
import { Customer } from "../../state/auth";
import MoreOptions from "./MoreOptions";

const WishlistGrid = ({
  customer,
  wishlists,
}: {
  customer: Customer;
  wishlists: (Wishlist & { products: WishlistProductFragment[] })[];
}) => {
  return (
    <Grid container paddingY={2} spacing={{ xs: 1, md: 3 }} rowGap={{ xs: 4, md: 5 }}>
      {wishlists.map((wishlist) => (
        <Grid item key={wishlist.id} xs={12} md={4}>
          <Stack gap={2}>
            <Link to={`/wishlist/${wishlist.id}`}>
              <Box bgcolor={colors.grey02} width="100%" padding={1} minHeight={{ xs: "80vw", md: "29vw" }}>
                <Grid container spacing={1} alignItems="center" style={{ height: "100%" }}>
                  {wishlist.products.map(
                    (product) =>
                      product && (
                        <Grid item key={product.id} xs={4}>
                          <Image src={product.images.nodes[0].src} alt={product.title} />
                        </Grid>
                      )
                  )}
                </Grid>
              </Box>
            </Link>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Link to={`/wishlist/${wishlist.id}`}>
                <Stack gap={1}>
                  <Typography variant="subtitle1">{wishlist.name}</Typography>
                  {wishlist.privacyType === "collaborative" && (
                    <Typography variant="body2" display="flex" alignItems="center" gap={0.75}>
                      <PersonAddAltRounded fontSize="inherit" /> Collaborative wishlist (
                      {wishlist.collaborators.filter((c) => c.status === "accepted").length})
                    </Typography>
                  )}
                </Stack>
              </Link>
              {customer.id === wishlist.ownerId && <MoreOptions wishlist={wishlist} customer={customer} />}
            </Stack>
          </Stack>
        </Grid>
      ))}
    </Grid>
  );
};

export default WishlistGrid;
