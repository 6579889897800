import { gql } from "@apollo/client";
import { AdminProductFragment } from "../generated/graphql";
import { Ratio, RectangleSize, TShirtSize, frames } from "../types/product";
import { adminClient } from "./apolloClients";
import { getPrices } from "./prices";
import { getSizeAndFrame, getSizesToSell, getSkuCode } from "./product";

const getVariantsArray = (price: number, maxSize: TShirtSize, ratio: Ratio) => {
  const prices = getPrices(price);
  const sizesToAdd = getSizesToSell(maxSize, ratio);

  const variants = frames.flatMap((frame) => {
    const framed = frame !== "unframed";
    return sizesToAdd.map((size) => {
      return {
        price: prices[`${size}${framed ? "Framed" : ""}`],
        options: [size, frame],
        sku: getSkuCode(size, framed),
        weight: framed ? 100 : 20,
        inventoryItem: { tracked: false },
      };
    });
  });

  return variants;
};

const addVariantsToProduct = async (productId: string, variants: any[]) => {
  const ADD_VARIANTS = gql`
    mutation AddVariants($productId: ID!, $variants: [ProductVariantsBulkInput!]!) {
      productVariantsBulkCreate(productId: $productId, variants: $variants) {
        productVariants {
          id
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

  const variables = {
    productId,
    variants,
  };

  const { data, errors } = await adminClient.mutate({ mutation: ADD_VARIANTS, variables });

  if (data.userErrors || errors) console.log("addVariantsToProduct Error", data.userErrors, errors);

  return data;
};

export const updateProductsVariants = async (products: AdminProductFragment[]) => {
  products.forEach(async (product) => {
    const currentVariants = product?.variants?.nodes || [];
    const A2Variant = currentVariants.find((variant) => variant?.sku === "GLOBAL-FAP-A2");
    const A3Variant = currentVariants.find((variant) => variant?.sku === "GLOBAL-FAP-A3");
    if (!A2Variant || !A3Variant) return;
    const fiftyBySeventyVariantExists = currentVariants.find((variant) => variant?.sku === "GLOBAL-FAP-20X28");
    if (fiftyBySeventyVariantExists) return;
    const variants = getVariantsArray(Number(A3Variant.price), TShirtSize.Medium, Ratio.Rectangle);
    const fiftyBySeventyVariants = variants.filter((variant) => variant.options[0] === RectangleSize["20X28"]);
    addVariantsToProduct(product.id, fiftyBySeventyVariants);
  });
};

export const updateProductFramePrices = async (products: AdminProductFragment[]) => {
  const promises = products.map(async (product) => {
    const currentVariants = product?.variants?.nodes || [];
    const framedVariants = currentVariants.filter((variant) => variant?.sku.includes("CFP"));
    const A3Variant = currentVariants.find((variant) => variant?.sku === "GLOBAL-FAP-12X12");
    if (!A3Variant) return;
    const newPrices = getPrices(Number(A3Variant.price));
    const variantsToUpdate: { id: string; price: number }[] = [];
    framedVariants.forEach(async (variant) => {
      const { size } = getSizeAndFrame(variant.selectedOptions);
      const newPrice = newPrices[`${size}Framed`];
      if (newPrice === Number(variant.price)) return;
      variantsToUpdate.push({ id: variant.id, price: newPrice });
    });

    if (!variantsToUpdate.length) return;

    const UPDATE_VARIANTS = gql`
      mutation UpdateVariants($productId: ID!, $variants: [ProductVariantsBulkInput!]!) {
        productVariantsBulkUpdate(productId: $productId, variants: $variants) {
          product {
            id
          }
          productVariants {
            id
          }
          userErrors {
            field
            message
          }
        }
      }
    `;

    const variables = {
      productId: product.id,
      variants: variantsToUpdate,
    };

    // console.log("variables", variables);

    const { data, errors } = await adminClient.mutate({ mutation: UPDATE_VARIANTS, variables });
    if (data.userErrors || errors) console.log("updateProductFramePrices Error", data.userErrors, errors);

    return data;
  });

  console.log("promises", promises.length);

  return await Promise.all(promises);
};
