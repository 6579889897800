import { Frame, Ratio, TShirtSize } from "../../types/product";

//square frames
// small
import blackSquareSmall from "../../assets/images/frames/black.square.small.png";
import blackSquareSmallWebp from "../../assets/images/frames/black.square.small.webp";
import whiteSquareSmall from "../../assets/images/frames/white.square.small.png";
import whiteSquareSmallWebp from "../../assets/images/frames/white.square.small.webp";
import naturalSquareSmall from "../../assets/images/frames/natural.square.small.png";
import naturalSquareSmallWebp from "../../assets/images/frames/natural.square.small.webp";
// import brownSquareSmall from "../../assets/images/frames/brown.square.small.png";
// import brownSquareSmallWebp from "../../assets/images/frames/brown.square.small.webp";

// medium
import blackSquareMedium from "../../assets/images/frames/black.square.medium.png";
import blackSquareMediumWebp from "../../assets/images/frames/black.square.medium.webp";
import whiteSquareMedium from "../../assets/images/frames/white.square.medium.png";
import whiteSquareMediumWebp from "../../assets/images/frames/white.square.medium.webp";
import naturalSquareMedium from "../../assets/images/frames/natural.square.medium.png";
import naturalSquareMediumWebp from "../../assets/images/frames/natural.square.medium.webp";
// import brownSquareMedium from "../../assets/images/frames/brown.square.medium.png";
// import brownSquareMediumWebp from "../../assets/images/frames/brown.square.medium.webp";

// large
import blackSquareLarge from "../../assets/images/frames/black.square.large.png";
import blackSquareLargeWebp from "../../assets/images/frames/black.square.large.webp";
import whiteSquareLarge from "../../assets/images/frames/white.square.large.png";
import whiteSquareLargeWebp from "../../assets/images/frames/white.square.large.webp";
import naturalSquareLarge from "../../assets/images/frames/natural.square.large.png";
import naturalSquareLargeWebp from "../../assets/images/frames/natural.square.large.webp";
// import brownSquareLarge from "../../assets/images/frames/brown.square.large.png";
// import brownSquareLargeWebp from "../../assets/images/frames/brown.square.large.webp";

// x large
import blackSquareXLarge from "../../assets/images/frames/black.square.xlarge.png";
import blackSquareXLargeWebp from "../../assets/images/frames/black.square.xlarge.webp";
import whiteSquareXLarge from "../../assets/images/frames/white.square.xlarge.png";
import whiteSquareXLargeWebp from "../../assets/images/frames/white.square.xlarge.webp";
import naturalSquareXLarge from "../../assets/images/frames/natural.square.xlarge.png";
import naturalSquareXLargeWebp from "../../assets/images/frames/natural.square.xlarge.webp";
// import brownSquareXLarge from "../../assets/images/frames/brown.square.xlarge.png";
// import brownSquareXLargeWebp from "../../assets/images/frames/brown.square.xlarge.webp";

//rectangle frames
// portrait
// small
import blackRectangleSmall from "../../assets/images/frames/black.rectangle.small.png";
import blackRectangleSmallWebp from "../../assets/images/frames/black.rectangle.small.webp";
import whiteRectangleSmall from "../../assets/images/frames/white.rectangle.small.png";
import whiteRectangleSmallWebp from "../../assets/images/frames/white.rectangle.small.webp";
import naturalRectangleSmall from "../../assets/images/frames/natural.rectangle.small.png";
import naturalRectangleSmallWebp from "../../assets/images/frames/natural.rectangle.small.webp";
// import brownRectangleSmall from "../../assets/images/frames/brown.rectangle.small.png";
// import brownRectangleSmallWebp from "../../assets/images/frames/brown.rectangle.small.webp";

// medium
import blackRectangleMedium from "../../assets/images/frames/black.rectangle.medium.png";
import blackRectangleMediumWebp from "../../assets/images/frames/black.rectangle.medium.webp";
import whiteRectangleMedium from "../../assets/images/frames/white.rectangle.medium.png";
import whiteRectangleMediumWebp from "../../assets/images/frames/white.rectangle.medium.webp";
import naturalRectangleMedium from "../../assets/images/frames/natural.rectangle.medium.png";
import naturalRectangleMediumWebp from "../../assets/images/frames/natural.rectangle.medium.webp";
// import brownRectangleMedium from "../../assets/images/frames/brown.rectangle.medium.png";
// import brownRectangleMediumWebp from "../../assets/images/frames/brown.rectangle.medium.webp";

// large
import blackRectangleLarge from "../../assets/images/frames/black.rectangle.large.png";
import blackRectangleLargeWebp from "../../assets/images/frames/black.rectangle.large.webp";
import whiteRectangleLarge from "../../assets/images/frames/white.rectangle.large.png";
import whiteRectangleLargeWebp from "../../assets/images/frames/white.rectangle.large.webp";
import naturalRectangleLarge from "../../assets/images/frames/natural.rectangle.large.png";
import naturalRectangleLargeWebp from "../../assets/images/frames/natural.rectangle.large.webp";
// import brownRectangleLarge from "../../assets/images/frames/brown.rectangle.large.png";
// import brownRectangleLargeWebp from "../../assets/images/frames/brown.rectangle.large.webp";

// x large
import blackRectangleXLarge from "../../assets/images/frames/black.rectangle.xlarge.png";
import blackRectangleXLargeWebp from "../../assets/images/frames/black.rectangle.xlarge.webp";
import whiteRectangleXLarge from "../../assets/images/frames/white.rectangle.xlarge.png";
import whiteRectangleXLargeWebp from "../../assets/images/frames/white.rectangle.xlarge.webp";
import naturalRectangleXLarge from "../../assets/images/frames/natural.rectangle.xlarge.png";
import naturalRectangleXLargeWebp from "../../assets/images/frames/natural.rectangle.xlarge.webp";
// import brownRectangleXLarge from "../../assets/images/frames/brown.rectangle.xlarge.png";
// import brownRectangleXLargeWebp from "../../assets/images/frames/brown.rectangle.xlarge.webp";

// landscape
// small
import blackRectangleSmallLandscape from "../../assets/images/frames/black.rectangle.small.landscape.png";
import blackRectangleSmallLandscapeWebp from "../../assets/images/frames/black.rectangle.small.landscape.webp";
import whiteRectangleSmallLandscape from "../../assets/images/frames/white.rectangle.small.landscape.png";
import whiteRectangleSmallLandscapeWebp from "../../assets/images/frames/white.rectangle.small.landscape.webp";
import naturalRectangleSmallLandscape from "../../assets/images/frames/natural.rectangle.small.landscape.png";
import naturalRectangleSmallLandscapeWebp from "../../assets/images/frames/natural.rectangle.small.landscape.webp";
// import brownRectangleSmallLandscape from "../../assets/images/frames/brown.rectangle.small.landscape.png";
// import brownRectangleSmallLandscapeWebp from "../../assets/images/frames/brown.rectangle.small.landscape.webp";

// medium
import blackRectangleMediumLandscape from "../../assets/images/frames/black.rectangle.medium.landscape.png";
import blackRectangleMediumLandscapeWebp from "../../assets/images/frames/black.rectangle.medium.landscape.webp";
import whiteRectangleMediumLandscape from "../../assets/images/frames/white.rectangle.medium.landscape.png";
import whiteRectangleMediumLandscapeWebp from "../../assets/images/frames/white.rectangle.medium.landscape.webp";
import naturalRectangleMediumLandscape from "../../assets/images/frames/natural.rectangle.medium.landscape.png";
import naturalRectangleMediumLandscapeWebp from "../../assets/images/frames/natural.rectangle.medium.landscape.webp";
// import brownRectangleMediumLandscape from "../../assets/images/frames/brown.rectangle.medium.landscape.png";
// import brownRectangleMediumLandscapeWebp from "../../assets/images/frames/brown.rectangle.medium.landscape.webp";

// large
import blackRectangleLargeLandscape from "../../assets/images/frames/black.rectangle.large.landscape.png";
import blackRectangleLargeLandscapeWebp from "../../assets/images/frames/black.rectangle.large.landscape.webp";
import whiteRectangleLargeLandscape from "../../assets/images/frames/white.rectangle.large.landscape.png";
import whiteRectangleLargeLandscapeWebp from "../../assets/images/frames/white.rectangle.large.landscape.webp";
import naturalRectangleLargeLandscape from "../../assets/images/frames/natural.rectangle.large.landscape.png";
import naturalRectangleLargeLandscapeWebp from "../../assets/images/frames/natural.rectangle.large.landscape.webp";
// import brownRectangleLargeLandscape from "../../assets/images/frames/brown.rectangle.large.landscape.png";
// import brownRectangleLargeLandscapeWebp from "../../assets/images/frames/brown.rectangle.large.landscape.webp";

// x large
import blackRectangleXLargeLandscape from "../../assets/images/frames/black.rectangle.xlarge.landscape.png";
import blackRectangleXLargeLandscapeWebp from "../../assets/images/frames/black.rectangle.xlarge.landscape.webp";
import whiteRectangleXLargeLandscape from "../../assets/images/frames/white.rectangle.xlarge.landscape.png";
import whiteRectangleXLargeLandscapeWebp from "../../assets/images/frames/white.rectangle.xlarge.landscape.webp";
import naturalRectangleXLargeLandscape from "../../assets/images/frames/natural.rectangle.xlarge.landscape.png";
import naturalRectangleXLargeLandscapeWebp from "../../assets/images/frames/natural.rectangle.xlarge.landscape.webp";
// import brownRectangleXLargeLandscape from "../../assets/images/frames/brown.rectangle.xlarge.landscape.png";
// import brownRectangleXLargeLandscapeWebp from "../../assets/images/frames/brown.rectangle.xlarge.landscape.webp";

const frameImages: {
  [key in Frame]: {
    [key in Ratio]: {
      [key in TShirtSize]: {
        src: string;
        webp: string;
      };
    };
  };
} = {
  // [Frame.Brown]: {
  //   [Ratio.Square]: {
  //     [TShirtSize.Small]: {
  //       src: naturalSquareSmall,
  //       webp: naturalSquareSmallWebp,
  //     },
  //     [TShirtSize.Medium]: {
  //       src: naturalSquareMedium,
  //       webp: naturalSquareMediumWebp,
  //     },
  //     [TShirtSize.Large]: {
  //       src: naturalSquareLarge,
  //       webp: naturalSquareLargeWebp,
  //     },
  //     [TShirtSize.XLarge]: {
  //       src: naturalSquareXLarge,
  //       webp: naturalSquareXLargeWebp,
  //     },
  //   },
  //   [Ratio.Rectangle]: {
  //     [TShirtSize.Small]: {
  //       src: brownRectangleSmall,
  //       webp: brownRectangleSmallWebp,
  //     },
  //     [TShirtSize.Medium]: {
  //       src: brownRectangleMedium,
  //       webp: brownRectangleMediumWebp,
  //     },
  //     [TShirtSize.Large]: {
  //       src: brownRectangleLarge,
  //       webp: brownRectangleLargeWebp,
  //     },
  //     [TShirtSize.XLarge]: {
  //       src: brownRectangleXLarge,
  //       webp: brownRectangleXLargeWebp,
  //     },
  //   },
  // },
  [Frame.Natural]: {
    [Ratio.Square]: {
      [TShirtSize.Small]: {
        src: naturalSquareSmall,
        webp: naturalSquareSmallWebp,
      },
      [TShirtSize.Medium]: {
        src: naturalSquareMedium,
        webp: naturalSquareMediumWebp,
      },
      [TShirtSize.Large]: {
        src: naturalSquareLarge,
        webp: naturalSquareLargeWebp,
      },
      [TShirtSize.XLarge]: {
        src: naturalSquareXLarge,
        webp: naturalSquareXLargeWebp,
      },
    },
    [Ratio.Rectangle]: {
      [TShirtSize.Small]: {
        src: naturalRectangleSmall,
        webp: naturalRectangleSmallWebp,
      },
      [TShirtSize.Medium]: {
        src: naturalRectangleMedium,
        webp: naturalRectangleMediumWebp,
      },
      [TShirtSize.Large]: {
        src: naturalRectangleLarge,
        webp: naturalRectangleLargeWebp,
      },
      [TShirtSize.XLarge]: {
        src: naturalRectangleXLarge,
        webp: naturalRectangleXLargeWebp,
      },
    },
  },
  [Frame.Black]: {
    [Ratio.Square]: {
      [TShirtSize.Small]: {
        src: blackSquareSmall,
        webp: blackSquareSmallWebp,
      },
      [TShirtSize.Medium]: {
        src: blackSquareMedium,
        webp: blackSquareMediumWebp,
      },
      [TShirtSize.Large]: {
        src: blackSquareLarge,
        webp: blackSquareLargeWebp,
      },
      [TShirtSize.XLarge]: {
        src: blackSquareXLarge,
        webp: blackSquareXLargeWebp,
      },
    },
    [Ratio.Rectangle]: {
      [TShirtSize.Small]: {
        src: blackRectangleSmall,
        webp: blackRectangleSmallWebp,
      },
      [TShirtSize.Medium]: {
        src: blackRectangleMedium,
        webp: blackRectangleMediumWebp,
      },
      [TShirtSize.Large]: {
        src: blackRectangleLarge,
        webp: blackRectangleLargeWebp,
      },
      [TShirtSize.XLarge]: {
        src: blackRectangleXLarge,
        webp: blackRectangleXLargeWebp,
      },
    },
  },
  [Frame.White]: {
    [Ratio.Square]: {
      [TShirtSize.Small]: {
        src: whiteSquareSmall,
        webp: whiteSquareSmallWebp,
      },
      [TShirtSize.Medium]: {
        src: whiteSquareMedium,
        webp: whiteSquareMediumWebp,
      },
      [TShirtSize.Large]: {
        src: whiteSquareLarge,
        webp: whiteSquareLargeWebp,
      },
      [TShirtSize.XLarge]: {
        src: whiteSquareXLarge,
        webp: whiteSquareXLargeWebp,
      },
    },
    [Ratio.Rectangle]: {
      [TShirtSize.Small]: {
        src: whiteRectangleSmall,
        webp: whiteRectangleSmallWebp,
      },
      [TShirtSize.Medium]: {
        src: whiteRectangleMedium,
        webp: whiteRectangleMediumWebp,
      },
      [TShirtSize.Large]: {
        src: whiteRectangleLarge,
        webp: whiteRectangleLargeWebp,
      },
      [TShirtSize.XLarge]: {
        src: whiteRectangleXLarge,
        webp: whiteRectangleXLargeWebp,
      },
    },
  },
  [Frame.Unframed]: {
    [Ratio.Square]: {
      [TShirtSize.Small]: {
        src: naturalSquareSmall,
        webp: naturalSquareSmallWebp,
      },
      [TShirtSize.Medium]: {
        src: naturalSquareMedium,
        webp: naturalSquareMediumWebp,
      },
      [TShirtSize.Large]: {
        src: naturalSquareLarge,
        webp: naturalSquareLargeWebp,
      },
      [TShirtSize.XLarge]: {
        src: naturalSquareXLarge,
        webp: naturalSquareXLargeWebp,
      },
    },
    [Ratio.Rectangle]: {
      [TShirtSize.Small]: {
        src: naturalRectangleSmall,
        webp: naturalRectangleSmallWebp,
      },
      [TShirtSize.Medium]: {
        src: naturalRectangleMedium,
        webp: naturalRectangleMediumWebp,
      },
      [TShirtSize.Large]: {
        src: naturalRectangleLarge,
        webp: naturalRectangleLargeWebp,
      },
      [TShirtSize.XLarge]: {
        src: naturalRectangleXLarge,
        webp: naturalRectangleXLargeWebp,
      },
    },
  },
};

const landscapeFrameImages: {
  [key in Frame]: {
    [key in TShirtSize]: {
      src: string;
      webp: string;
    };
  };
} = {
  [Frame.Natural]: {
    [TShirtSize.Small]: {
      src: naturalRectangleSmallLandscape,
      webp: naturalRectangleSmallLandscapeWebp,
    },
    [TShirtSize.Medium]: {
      src: naturalRectangleMediumLandscape,
      webp: naturalRectangleMediumLandscapeWebp,
    },
    [TShirtSize.Large]: {
      src: naturalRectangleLargeLandscape,
      webp: naturalRectangleLargeLandscapeWebp,
    },
    [TShirtSize.XLarge]: {
      src: naturalRectangleXLargeLandscape,
      webp: naturalRectangleXLargeLandscapeWebp,
    },
  },
  // [Frame.Brown]: {
  //   [TShirtSize.Small]: {
  //     src: brownRectangleSmallLandscape,
  //     webp: brownRectangleSmallLandscapeWebp,
  //   },
  //   [TShirtSize.Medium]: {
  //     src: brownRectangleMediumLandscape,
  //     webp: brownRectangleMediumLandscapeWebp,
  //   },
  //   [TShirtSize.Large]: {
  //     src: brownRectangleLargeLandscape,
  //     webp: brownRectangleLargeLandscapeWebp,
  //   },
  //   [TShirtSize.XLarge]: {
  //     src: brownRectangleXLargeLandscape,
  //     webp: brownRectangleXLargeLandscapeWebp,
  //   },
  // },
  [Frame.Black]: {
    [TShirtSize.Small]: {
      src: blackRectangleSmallLandscape,
      webp: blackRectangleSmallLandscapeWebp,
    },
    [TShirtSize.Medium]: {
      src: blackRectangleMediumLandscape,
      webp: blackRectangleMediumLandscapeWebp,
    },
    [TShirtSize.Large]: {
      src: blackRectangleLargeLandscape,
      webp: blackRectangleLargeLandscapeWebp,
    },
    [TShirtSize.XLarge]: {
      src: blackRectangleXLargeLandscape,
      webp: blackRectangleXLargeLandscapeWebp,
    },
  },
  [Frame.White]: {
    [TShirtSize.Small]: {
      src: whiteRectangleSmallLandscape,
      webp: whiteRectangleSmallLandscapeWebp,
    },
    [TShirtSize.Medium]: {
      src: whiteRectangleMediumLandscape,
      webp: whiteRectangleMediumLandscapeWebp,
    },
    [TShirtSize.Large]: {
      src: whiteRectangleLargeLandscape,
      webp: whiteRectangleLargeLandscapeWebp,
    },
    [TShirtSize.XLarge]: {
      src: whiteRectangleXLargeLandscape,
      webp: whiteRectangleXLargeLandscapeWebp,
    },
  },
  [Frame.Unframed]: {
    [TShirtSize.Small]: {
      src: naturalRectangleSmallLandscape,
      webp: naturalRectangleSmallLandscapeWebp,
    },
    [TShirtSize.Medium]: {
      src: naturalRectangleMediumLandscape,
      webp: naturalRectangleMediumLandscapeWebp,
    },
    [TShirtSize.Large]: {
      src: naturalRectangleLargeLandscape,
      webp: naturalRectangleLargeLandscapeWebp,
    },
    [TShirtSize.XLarge]: {
      src: naturalRectangleXLargeLandscape,
      webp: naturalRectangleXLargeLandscapeWebp,
    },
  },
};

export const getFrameImages = (ratio: Ratio, frame: Frame, size: TShirtSize, isLandscape: boolean) => {
  if (isLandscape) {
    return landscapeFrameImages[frame][size];
  }

  const colorFrameImages = frame === "unframed" ? frameImages.natural : frameImages[frame];
  return colorFrameImages[ratio][size];
};
