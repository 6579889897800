import styled from "styled-components";
import { Container } from "../../components/Layout";
import { colors } from "../../theme";

export const FrameBadgeButton = styled.button<{ selected: boolean }>`
  border-radius: 100%;
  border: 1px solid ${(p) => (p.selected ? colors.black : colors.grey10)};
  background: none;
  padding: 4px;
  display: flex;
  cursor: pointer;
`;

export const FiltersButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${colors.black};
`;

export const StickyContainer = styled(Container)<{ navHeight: number }>`
  position: sticky;
  top: ${(p) => p.navHeight}px;
  z-index: 1;
`;
