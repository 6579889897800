import { createContext, useEffect, useState } from "react";
import { authStateChange, getLoggedInUser, getUserById } from "../services/Firebase";
import { User } from "../types/user";
import { CustomerFragment } from "../generated/storefront";
import { getCustomer } from "../services/API/customer";
import { getSharedWishlists, getWishlists } from "../services/Firebase/wishlists";
import { Wishlist } from "../types/wishlist";

const AuthContext = createContext<{
  isAdmin: boolean;
  user: User | null;
  setUser: (user: User | null) => void;
  customer?: Customer;
  loading: boolean;
  loadingCustomer: boolean;
  setLoadingCustomer: (loading: boolean) => void;
  refetchCustomer: () => void;
}>({
  isAdmin: false,
  user: null,
  setUser: () => {},
  loading: true,
  loadingCustomer: true,
  setLoadingCustomer: () => {},
  refetchCustomer: () => {},
});

export type Customer = CustomerFragment & { wishlists: Wishlist[] };

export const AuthStateProvider: React.FC = ({ children }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingCustomer, setLoadingCustomer] = useState(true);
  const [customer, setCustomer] = useState<CustomerFragment & { wishlists: Wishlist[] }>();

  const setGhostUser = async (ghostId: string) => {
    const user = await getUserById(ghostId);
    setUser(user);
  };

  const refetchCustomer = async () => {
    if (customer && customer.email) {
      const wishlists = await getWishlists(customer.id);
      const sharedWishlists = await getSharedWishlists(customer.id, customer.email);
      setCustomer({ ...customer, wishlists: [...wishlists, ...sharedWishlists] });
    }
  };

  const getLoggedInCustomer = async () => {
    if (loadingCustomer) {
      const customerAccessToken = localStorage.getItem("customerAccessToken");
      if (customerAccessToken) {
        const loggedInCustomer = await getCustomer({ customerAccessToken });
        if (loggedInCustomer && loggedInCustomer.email) {
          const wishlists = await getWishlists(loggedInCustomer.id);
          const sharedWishlists = await getSharedWishlists(loggedInCustomer.id, loggedInCustomer.email);
          setCustomer({ ...loggedInCustomer, wishlists: [...wishlists, ...sharedWishlists] });
        }
      }
      setLoadingCustomer(false);
    }
  };

  useEffect(() => {
    getLoggedInCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingCustomer]);

  useEffect(() => {
    const ghostId = window.location.search.split("ghost=")[1];
    if (ghostId) {
      setGhostUser(ghostId);
      setLoading(false);
    } else {
      authStateChange(async (authUser) => {
        setLoading(true);
        if (authUser) {
          const { uid } = authUser;
          setIsAdmin(uid === process.env.REACT_APP_ADMIN_UID);
          const user = await getLoggedInUser(uid);
          setUser(user);
          setLoading(false);
        } else {
          setUser(null);
          setLoading(false);
        }
      });
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAdmin, user, customer, loadingCustomer, setLoadingCustomer, refetchCustomer, setUser, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
