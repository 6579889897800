import { ChangeEventHandler, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import dayjs from "dayjs";
import { Autocomplete, AutocompleteRenderInputParams, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRange, DateRangePicker } from "@mui/x-date-pickers-pro";
import { formattedPrice } from "../../../helpers/money";
import { User } from "../../../types/user";
import { OrderFilters } from "..";
import TextLink from "../../../components/TextLink";
import { getIdNumber } from "../../../helpers/shopify";
import { getOrderItemTotals } from "../../../helpers/prices";
import useGetBulkOrders from "../../../hooks/useGetBulkOrders";
import { CurrencyCode } from "../../../generated/storefront";
import { BulkOrderFragment } from "../../../generated/graphql";
import Button from "../../../components/Button";

type Props = {
  users?: User[];
  orders?: BulkOrderFragment[];
  orderFilters: OrderFilters;
  setOrderFilters: (filters: OrderFilters) => void;
  handleSearch: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const defaultPageSettings = {
  pageSize: 25,
  page: 0,
};

const Orders = ({ users, orderFilters, setOrderFilters, handleSearch }: Props) => {
  const [dateRange, setDateRange] = useState<DateRange<string>>([null, null]);
  const [paginationModel, setPaginationModel] = useState(defaultPageSettings);
  const { getOrders, orders, loading } = useGetBulkOrders();

  const handleArtistSelect = (event: any, value: any) => {
    if (value) {
      setOrderFilters({ ...orderFilters, vendorId: value.id });
    } else {
      setOrderFilters({ ...orderFilters, vendorId: undefined });
    }
  };

  const columns: GridColDef[] = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "total", headerName: "Total", width: 100 },
    { field: "discountedTotal", headerName: "Discounted Total", width: 150 },
    { field: "discountCode", headerName: "Discount Code", width: 150 },
    { field: "itemQty", headerName: "Item Qty", width: 100 },
    {
      field: "orderLink",
      headerName: "Order Link",
      width: 100,
      renderCell: (params) => (
        <TextLink openInNewTab href={`https://goodmoodprints.myshopify.com/admin/orders/${getIdNumber(params.row.id, "Order")}`}>
          View
        </TextLink>
      ),
    },
  ];

  const artistOptions = users?.map((u) => ({
    id: u.id,
    label: `${u.firstName} ${u.lastName}`,
  }));

  const formattedOrders = (orders || []).map(({ id, discountCode, lineItems, createdAt }) => {
    const date = format(new Date(createdAt), "dd MMM yyyy");
    const { orderTotal, discountTotal } = getOrderItemTotals(lineItems.nodes.map((i) => ({ ...i, orderCreatedAt: createdAt })));
    return {
      id,
      date,
      total: formattedPrice(orderTotal, CurrencyCode.Gbp),
      discountedTotal: formattedPrice(orderTotal - discountTotal, CurrencyCode.Gbp),
      discountCode,
      itemQty: lineItems.nodes.length,
    };
  });

  const handleDateRangeClose = (range: DateRange<string>) => {
    if (!range[0] || !range[1]) return;
    const startDate = dayjs(range[0]).format("YYYY-MM-DD");
    const endDate = dayjs(range[1]).format("YYYY-MM-DD");
    getOrders({ startDate, endDate });
  };

  useEffect(() => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      const stringDates = dateRange.map((v) => v && dayjs(v).format("YYYY-MM-DD"));
      getOrders({ startDate: stringDates[0], endDate: stringDates[1], vendorId: orderFilters.vendorId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderFilters.vendorId]);

  return (
    <Box sx={{ height: "70vh", width: "100%" }} paddingY={3}>
      <Stack direction="row" alignItems="center" gap={2} paddingBottom={2}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={artistOptions || []}
          sx={{ width: 300 }}
          onChange={handleArtistSelect}
          renderInput={(params: AutocompleteRenderInputParams) => (
            <TextField
              {...params}
              label="Artist"
              onChange={handleSearch}
              InputProps={{
                ...params.InputProps,
                endAdornment: params.InputProps.endAdornment,
              }}
            />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            value={dateRange}
            onChange={setDateRange}
            onAccept={handleDateRangeClose}
            localeText={{ start: "Start", end: "End" }}
          />
        </LocalizationProvider>
        <Link to="/admin/checkout" style={{ flexGrow: 1, display: "flex", justifyContent: "end" }}>
          <Button size="small">Create order</Button>
        </Link>
      </Stack>
      <DataGrid
        loading={loading}
        rows={formattedOrders}
        columns={columns}
        pageSizeOptions={[25]}
        pagination
        rowCount={orders?.length}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        slots={{ toolbar: GridToolbar }}
      />
    </Box>
  );
};

export default Orders;
