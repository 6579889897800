import { LazyLoadImage as LazyLoadImageComp } from "react-lazy-load-image-component";

type Props = {
  src: string;
  alt: string;
  height?: number | string;
  width?: number | string;
  style?: any;
};

const LazyLoadImage = (props: Props) => <LazyLoadImageComp {...props} threshold={2000} />;

export default LazyLoadImage;
