import { Ratio, TShirtSize } from "../../types/product";
import { getResolutionDimensions } from "../../helpers/product";

export const imageZoom = (
  img: HTMLImageElement,
  resultID: string,
  maxSize: TShirtSize,
  ratio: Ratio,
  isLandscape: boolean
): void => {
  img.removeEventListener("mousemove", moveLens);
  img.removeEventListener("mouseenter", showResult);
  img.removeEventListener("mouseleave", removeResult);
  let result: HTMLDivElement;
  const lensWidth = 40;
  const { width } = getResolutionDimensions(maxSize, ratio);

  result = document.getElementById(resultID) as HTMLDivElement;

  if (!result) {
    return;
  }

  const zoomScale = width / (isLandscape ? img.naturalHeight : img.naturalWidth);

  result.style.backgroundImage = `url('${img.src}')`;
  result.style.backgroundSize = `${img.width * zoomScale}px ${img.height * zoomScale}px`;

  img.addEventListener("mousemove", moveLens);
  img.addEventListener("mouseenter", showResult);
  img.addEventListener("mouseleave", removeResult);

  function moveLens(e: MouseEvent): void {
    let pos: { x: number; y: number }, x: number, y: number;

    e.preventDefault();
    pos = getCursorPos(e);

    x = pos.x - lensWidth / 2;
    y = pos.y - lensWidth / 2;

    if (img && x > img.width - lensWidth) {
      x = img.width - lensWidth;
    }
    if (x < 0) {
      x = 0;
    }
    if (img && y > img.height - lensWidth) {
      y = img.height - lensWidth;
    }
    if (y < 0) {
      y = 0;
    }

    const resultX = pos.x - result.offsetWidth / 2;
    const resultY = pos.y - result.offsetHeight / 2;
    result.style.left = `${resultX}px`;
    result.style.top = `${resultY}px`;
    const centerOffsetX = (result.offsetWidth - lensWidth) / 2;
    const centerOffsetY = (result.offsetHeight - lensWidth) / 2;
    const backgroundPositionX = -(x * zoomScale - centerOffsetX);
    const backgroundPositionY = -(y * zoomScale - centerOffsetY);
    result.style.backgroundPosition = `${backgroundPositionX}px ${backgroundPositionY}px`;
  }

  function showResult(): void {
    result.style.display = "initial";
  }

  function removeResult(): void {
    result.style.display = "none";
  }

  function getCursorPos(e: MouseEvent): { x: number; y: number } {
    let a: DOMRect,
      x = 0,
      y = 0;

    e = e || (window.event as MouseEvent);

    if (img) {
      a = img.getBoundingClientRect();

      x = e.pageX - a.left;
      y = e.pageY - a.top;
    }

    x = x - window.pageXOffset;
    y = y - window.pageYOffset;

    return { x, y };
  }
};
