import { useEffect, useRef, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { Box, Grid, IconButton, MenuItem, Popover, Stack, Typography } from "@mui/material";
import { ProductCardProductFragment, useGetProductsForProductCardQuery } from "../../generated/storefront";
import { useAppState } from "../../state";
import ProductCard from "../../components/ProductCard";
import { appendArtistsToProducts } from "../../helpers/product";
import { User } from "../../types/user";
import Button from "../../components/Button";
import {
  CheckRounded,
  ChevronRightRounded,
  ContentCopyRounded,
  IosShareRounded,
  LockRounded,
  MoreHorizRounded,
  PersonAddAltRounded,
} from "@mui/icons-material";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import { Loader } from "../../components/Loader";
import FrameBadges from "../../components/FrameBadges";
import { Frame } from "../../types/product";
import AutoResizeText from "../../components/AutoResizeText";
import { colors } from "../../theme";
import CustomerLoginModal from "../../components/LoginModal/customer";
import EditWishlistModal from "./EditWishlistModal";
import { getWishlistById, updateWishlist } from "../../services/Firebase/wishlists";
import { Wishlist as TWishlist } from "../../types/wishlist";
import TextLink from "../../components/TextLink";
import { Customer } from "../../state/auth";
import EmptyState from "../Wishlists/EmptyState";
import DeleteWishlistModal from "./DeleteWishlistModal";

type ProductCardProduct = ProductCardProductFragment & { artist: User | null };

const Wishlist = ({ customer }: { customer: Customer }) => {
  const { id } = useParams();
  const { isMobileScreen, selectedCountry, toggleGalleryWallBuilderIsOpen } = useAppState();
  const [URLSearchParams] = useSearchParams();
  const source = URLSearchParams.get("source");
  const [selectedFrame, setSelectedFrame] = useState<Frame>(Frame.Natural);
  const ref = useRef<HTMLDivElement>(null);
  const [copied, setCopied] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showEditWishlistModal, setShowEditWishlistModal] = useState(false);
  const [showDeleteWishlistModal, setShowDeleteWishlistModal] = useState(false);
  const [loadingWishlist, setLoadingWishlist] = useState(false);
  const [wishlistProducts, setWishlistProducts] = useState<ProductCardProduct[]>([]);
  const [wishlist, setWishlist] = useState<TWishlist>();
  const { data, loading, fetchMore } = useGetProductsForProductCardQuery({
    variables: {
      query: `id:${wishlist?.productIds.join(" OR ")}`,
      country: selectedCountry,
      limit: 24,
    },
  });
  const hasMore = data?.products?.pageInfo?.hasNextPage || false;
  const afterCursor = data?.products?.pageInfo?.endCursor || "";
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  useLoadMoreOnScroll(
    ref,
    () =>
      fetchMore({
        variables: {
          afterCursor,
        },
      }),
    hasMore,
    loading
  );

  const acceptInvite = async () => {
    if (customer && customer.email && id) {
      const wishlist = await getWishlistById(id);
      if (!wishlist) return;
      const existingCollaborators = wishlist.collaborators.filter((c) => c.email !== customer.email);
      await updateWishlist(id, {
        collaborators: [...existingCollaborators, { id: customer.id, email: customer.email, status: "accepted" }],
      });
      window.location.href = `/wishlist/${id}`;
    }
  };

  const onShare = () => {
    const link = `${process.env.REACT_APP_SITE_URL}/wishlist?ids=${wishlist?.productIds.join(",")}`;
    if (navigator.share) {
      navigator.share({
        title: "Wishlist",
        text: "Check out my wishlist",
        url: link,
      });
    } else {
      navigator.clipboard.writeText(link);
      setCopied(true);
    }
  };

  const onEditWishlist = () => {
    if (customer) {
      setShowEditWishlistModal(true);
    } else {
      setShowLoginModal(true);
    }
  };

  const getWishlist = async () => {
    setLoadingWishlist(true);
    if (customer && id) {
      const list = await getWishlistById(id);
      if (!list.name) {
        setWishlist(undefined);
        return setLoadingWishlist(false);
      }
      setWishlist(list);
    }
    setLoadingWishlist(false);
  };

  useEffect(() => {
    getWishlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, id]);

  useEffect(() => {
    if (wishlist?.productIds.length) {
      const getProductsArtists = async () => {
        const productsWithArtists = await appendArtistsToProducts(data?.products?.nodes || []);
        setWishlistProducts(productsWithArtists);
      };
      getProductsArtists();
    }
  }, [data, wishlist?.productIds.length]);

  const customerIsOwner = customer.id === wishlist?.ownerId;

  if (loadingWishlist) {
    return <Loader />;
  }

  if (wishlist) {
    if (source === "email_invite") {
      const customerIsCollaborator = wishlist.collaborators.some((c) => c.email === customer.email);

      if (customerIsCollaborator) {
        return (
          <Box maxWidth={500} margin="auto">
            <Stack gap={2} padding={5} alignItems="center">
              <Typography variant="h2" align="center">
                You have been invited to collaborate on a wishlist: {wishlist?.name}
              </Typography>
              <Button onClick={acceptInvite} fullWidth>
                Accept invite
              </Button>
            </Stack>
          </Box>
        );
      } else {
        return (
          <Box maxWidth={500} margin="auto">
            <Stack gap={2} padding={5} alignItems="center">
              <Typography variant="h2" align="center">
                {customer.email} was not invited to collaborate
              </Typography>
              <Typography variant="subtitle1" align="center">
                Please check your email invite and login or register with the correct account.
              </Typography>
              <Link to={`/login/customer?redirect_url=${window.location.pathname}?source=email_invite`}>
                <Button fullWidth>Switch accounts</Button>
              </Link>
            </Stack>
          </Box>
        );
      }
    }

    if (
      customer.id !== wishlist.ownerId &&
      !wishlist.collaborators.some((c) => c.status === "accepted" && c.id === customer.id)
    ) {
      return (
        <Box maxWidth={500} margin="auto">
          <Stack gap={2} padding={5} alignItems="center">
            <Typography variant="h2" align="center">
              You don't have permission to view this wishlist
            </Typography>
            <Typography variant="subtitle1" align="center">
              Please contact the owner of the wishlist for access
            </Typography>
          </Stack>
        </Box>
      );
    }

    return (
      <>
        <Box position="relative" padding={2}>
          <Stack position="absolute" left={{ xs: 16, md: 40 }} top={16}>
            <Stack alignItems="center" direction="row">
              <TextLink to="/wishlist">Wishlists</TextLink>
              <ChevronRightRounded fontSize="small" />
              <Typography>{wishlist.name}</Typography>
            </Stack>
          </Stack>
          <Stack gap={1} alignItems="center" marginTop={4} paddingX={2} paddingY={2}>
            <AutoResizeText text={wishlist.name} align="center" />
            {isMobileScreen && (
              <Stack direction="row" gap={2} alignItems="center">
                {match(wishlist.privacyType)
                  .with("private", () => (
                    <Typography fontSize={18} align="center" display="flex" alignItems="center" gap={1}>
                      <LockRounded fontSize="inherit" /> Private wishlist
                    </Typography>
                  ))
                  .with("collaborative", () => (
                    <Typography fontSize={18} align="center" display="flex" alignItems="center" gap={1}>
                      <PersonAddAltRounded fontSize="inherit" /> Collaborative wishlist (
                      {wishlist.collaborators.filter((c) => c.status === "accepted").length})
                    </Typography>
                  ))
                  .exhaustive()}
                {customerIsOwner && (
                  <TextLink onClick={onEditWishlist}>
                    <Typography>Edit</Typography>
                  </TextLink>
                )}
              </Stack>
            )}
          </Stack>
        </Box>

        <Box paddingY={2} paddingX={{ xs: 2, md: 5 }}>
          <Stack gap={1} direction="row" alignItems="center" justifyContent="space-between">
            <Stack width={{ xs: "auto", md: 148 }} direction="row" gap={1}>
              <IconButton
                color="primary"
                onClick={onShare}
                disabled={wishlistProducts.length === 0}
                style={{ width: 40, height: 40, backgroundColor: colors.grey02 }}
              >
                {Boolean(navigator.share) ? (
                  <IosShareRounded />
                ) : copied ? (
                  <CheckRounded fontSize="inherit" />
                ) : (
                  <ContentCopyRounded fontSize="small" color="inherit" />
                )}
              </IconButton>
              {customerIsOwner && (
                <>
                  <IconButton
                    color="primary"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                    style={{ width: 40, height: 40, backgroundColor: colors.grey02 }}
                  >
                    <MoreHorizRounded fontSize="inherit" />
                  </IconButton>
                  <Popover
                    id={popoverId}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    sx={{
                      "& .MuiPopover-paper": {
                        minWidth: 200,
                      },
                    }}
                  >
                    <MenuItem>
                      <Link to="/shop">
                        <Typography display="flex" alignItems="center">
                          Add to wishlist
                        </Typography>
                      </Link>
                    </MenuItem>
                    {!isMobileScreen && (
                      <MenuItem>
                        <Typography display="flex" alignItems="center" onClick={toggleGalleryWallBuilderIsOpen}>
                          Build gallery wall
                        </Typography>
                      </MenuItem>
                    )}
                    <MenuItem onClick={() => setShowDeleteWishlistModal(true)}>
                      <Typography display="flex" alignItems="center" color="error">
                        Delete
                      </Typography>
                    </MenuItem>
                  </Popover>
                </>
              )}
            </Stack>
            {!isMobileScreen && (
              <Stack direction="row" alignItems="center" gap={2}>
                {match(wishlist.privacyType)
                  .with("private", () => (
                    <Typography fontSize={18} align="center" display="flex" alignItems="center" gap={1}>
                      <LockRounded fontSize="inherit" /> Private wishlist
                    </Typography>
                  ))
                  .with("collaborative", () => (
                    <Typography fontSize={18} align="center" display="flex" alignItems="center" gap={1}>
                      <PersonAddAltRounded fontSize="inherit" /> Collaborative wishlist (
                      {wishlist.collaborators.filter((c) => c.status === "accepted").length})
                    </Typography>
                  ))
                  .exhaustive()}
                {customerIsOwner && (
                  <TextLink onClick={onEditWishlist}>
                    <Typography>Edit</Typography>
                  </TextLink>
                )}
              </Stack>
            )}
            <FrameBadges selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} />
          </Stack>
          {wishlistProducts.length === 0 && !loading && <EmptyState />}

          <Grid container paddingY={5} spacing={{ xs: 1, md: 2 }} rowGap={{ xs: 4, md: 9 }} ref={ref}>
            {wishlistProducts.map((product) => (
              <Grid item key={product.id} xs={6} md={3} xl={3} xxl={2} xxxl={1.5}>
                <ProductCard product={product} artist={product.artist} frame={selectedFrame} />
              </Grid>
            ))}
          </Grid>

          {loading ? (
            <Loader />
          ) : wishlistProducts.length && hasMore ? (
            <Stack width="100%" alignItems="center" padding={5}>
              <Button onClick={() => fetchMore({ variables: { afterCursor } })}>Load more</Button>
            </Stack>
          ) : null}
        </Box>
        {showLoginModal && <CustomerLoginModal onClose={() => setShowLoginModal(false)} />}
        {showEditWishlistModal && (
          <EditWishlistModal
            customer={customer}
            open={showEditWishlistModal}
            onClose={() => setShowEditWishlistModal(false)}
            wishlist={wishlist}
          />
        )}
        <DeleteWishlistModal
          open={showDeleteWishlistModal}
          onClose={() => setShowDeleteWishlistModal(false)}
          customer={customer}
          wishlist={wishlist}
        />
      </>
    );
  } else {
    return (
      <Box>
        <Stack gap={2} padding={5} alignItems="center">
          <Typography variant="h2">Wishlist not found</Typography>
          <Typography variant="subtitle1">It may have been deleted</Typography>
          <Link to="/wishlist">
            <Button>Go to my wishlists</Button>
          </Link>
        </Stack>
      </Box>
    );
  }
};

export default Wishlist;
