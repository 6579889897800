import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CancelOutlined, CheckCircleOutlineOutlined } from "@mui/icons-material";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import DragAndDrop from "../../components/DragAndDrop";
import Input from "../../components/Form/Input";
import TextArea from "../../components/Form/TextArea";
import { Column, Container, Flex, Form, Row } from "../../components/Layout";
import { Header, Text } from "../../components/Text";
import { validate } from "../../helpers/formValidation";
import { authSignOutUser, storage } from "../../services/Firebase";
import { updateUser } from "../../services/Firebase/users";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import { User } from "../../types/user";
import { addContact } from "../../services/API";
import { EmailTags } from "../../types/email";
import { checkUsersNewPermalink } from "../../services/Firebase/auth";
import Dropdown from "../../components/Form/Dropdown";

type Props = {
  user: User;
  setUser: Dispatch<SetStateAction<User | null>>;
  imageUrl: string;
  setImageUrl: (url: string) => void;
};

const Account = ({ user, setUser, imageUrl, setImageUrl }: Props) => {
  const navigate = useNavigate();
  const { isMobileScreen } = useAppState();
  const [state, setState] = useState<Partial<User>>({});
  const [updated, setUpdated] = useState(false);
  const [checkingPermalink, setCheckingPermalink] = useState(false);
  const [permalinkAccepted, setPermalinkAccepted] = useState(true);
  const [vatRegistered, setVATRegistered] = useState(Boolean(user.vatRegistered));
  const [edited, setEdited] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageUploadError, setImageUploadError] = useState("");
  const [updateErrors, setUpdateErrors] = useState<Partial<User>>();
  const [error, setError] = useState("");
  const [countries, setCountries] = useState<{ country: string; cities: string[]; iso2: string }[]>([]);
  const disableSave = (!edited && !updated) || Boolean(updateErrors?.permalink) || checkingPermalink;

  const uploadImage = (files: File[]) => {
    const image = files[0];
    const fileTypes = ["jpg", "jpeg", "png"];
    const fileExtension = image.name.split(".").pop()?.toLowerCase();
    setImageUploadError("");

    if (fileExtension && !fileTypes.includes(fileExtension)) {
      setUploading(false);
      return setImageUploadError("Incorrect file extension, must be either jpg or png");
    }
    if (image.size > 1000000) {
      setUploading(false);
      return setImageUploadError("File size must be less than 1MB");
    }
    if (image) {
      setUploading(true);
      const artworkReference = new Date().getTime() + image.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      const storageRef = ref(storage, `userProfiles/${artworkReference}`);
      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        "state_changed",
        () => {},
        (error) => console.log(error),
        () => {
          getDownloadURL(storageRef).then((downloadURL) => {
            console.log("File available at", downloadURL);
            setImageUrl(downloadURL);
            setUploading(false);
            setEdited(true);
          });
        }
      );
    }
  };

  const onUpdateUser = async () => {
    try {
      setLoading(true);
      setUpdateErrors({});
      setError("");
      const image = imageUrl ? { image: imageUrl } : {};
      const { firstName, lastName, about, email, paypalEmail, permalink, city, country } = state;
      const validation = validate({ firstName, lastName, about, email, paypalEmail, permalink, city, country });
      if (validation.error) {
        setLoading(false);
        setUpdateErrors(validation.errors);
        return;
      }
      const fName = firstName?.trim();
      const lName = lastName?.trim();
      const updatedUser = await updateUser(user.id, { ...state, ...image, firstName: fName, lastName: lName });
      if (email && email !== user.email) {
        const tags = [EmailTags.Artist];
        if (user.hasProducts) tags.push(EmailTags.HasProducts);
        await addContact({ email, firstName: fName, lastName: lName, subscribe: true, tags });
      }
      setUser(updatedUser);
      setUpdated(true);
      setEdited(false);
      setLoading(false);
      setTimeout(() => setUpdated(false), 2000);
    } catch (error: any) {
      setError(error.message as string);
      setLoading(false);
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUpdateErrors({ ...updateErrors, [name]: "" });
    setState({ ...state, [name]: value });
    setEdited(true);
  };

  const handleVATChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUpdateErrors({ ...updateErrors, vatRegistered: {} });
    setState({ ...state, vatRegistered: { ...state.vatRegistered, [name]: value } });
    setEdited(true);
  };

  const handlePermalinkChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "") setState({ ...state, permalink: "" });
    if (/^[a-z-.0-9]+$/.test(value)) {
      setState({ ...state, permalink: value });
    }
    setEdited(true);
  };

  const handleLocationChange = (name: string, value: string) => {
    if (name === "country") {
      setState({ ...state, country: value, city: "" });
    } else {
      setState({ ...state, city: value });
    }
    setUpdateErrors({ ...updateErrors, [name]: "" });
    setEdited(true);
  };

  const checkPermalink = async (permalink: string) => {
    setCheckingPermalink(true);
    const alreadyExists = await checkUsersNewPermalink(user.id, permalink);
    if (alreadyExists) {
      setUpdateErrors({ ...updateErrors, permalink: "Permalink already exists" });
    } else {
      setUpdateErrors({ ...updateErrors, permalink: "" });
    }
    setPermalinkAccepted(!alreadyExists);
    setCheckingPermalink(false);
  };

  useDebounce(
    () => {
      state.permalink && checkPermalink(state.permalink);
    },
    600,
    [state.permalink]
  );

  const getCountries = async () => {
    const allCountries = await axios.get("https://countriesnow.space/api/v0.1/countries");
    setCountries(allCountries.data.data);
  };

  useEffect(() => {
    setEdited(true);
    if (vatRegistered) {
      setState({ ...state, vatRegistered: { registeredName: "", vatNumber: "" } });
    } else {
      setState({ ...state, vatRegistered: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vatRegistered]);

  useEffect(() => {
    setState(user);
  }, [user]);

  useEffect(() => {
    if (countries.length === 0) {
      getCountries();
    }
  }, [countries]);

  const countryOptions = countries.map((c) => ({ value: c.iso2, label: c.country }));
  const cityOptions = countries.find((c) => c.iso2 === state.country)?.cities.map((city) => ({ value: city, label: city }));

  return (
    <Row justifyContent="center">
      <Column width={40}>
        <Container padding={isMobileScreen ? "0 24px" : "0"}>
          <Container padding="0 0 18px" width={100}>
            <Flex justifyContent="space-between" alignItems="center" fullWidth>
              <Header type="h2">Update your details</Header>
              <Button secondary size="medium" disabled={disableSave} loading={loading} onClick={onUpdateUser}>
                {updated ? "Saved!" : "Save"}
              </Button>
            </Flex>
          </Container>
          <Flex gap={32} direction="column" alignItems="center">
            <Form>
              <DragAndDrop
                onImageDrop={uploadImage}
                label={uploading ? "Uploading..." : "Upload profile picture"}
                error={imageUploadError}
              />
              <Flex gap={8}>
                <Input
                  placeholder="First name"
                  id="firstName"
                  name="firstName"
                  label="First name"
                  value={state.firstName}
                  onChange={handleChange}
                  error={updateErrors?.firstName}
                />
                <Input
                  placeholder="Last name"
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  value={state.lastName}
                  onChange={handleChange}
                  error={updateErrors?.lastName}
                />
              </Flex>
              <TextArea
                placeholder="A little description about yourself"
                label="About me"
                name="about"
                value={state.about}
                onChange={handleChange}
                error={updateErrors?.about}
              />
              <Stack gap={1} direction="row" width="100%">
                <Dropdown
                  label="Country"
                  name="country"
                  onChange={(event) => handleLocationChange("country", event.value)}
                  options={countryOptions}
                  value={countryOptions?.find((c) => c.value === state.country)}
                  isSearchable
                  error={updateErrors?.country}
                  style={{ flex: 1 }}
                />
                <Dropdown
                  label="City"
                  name="city"
                  onChange={(event) => handleLocationChange("city", event.value)}
                  options={cityOptions || []}
                  value={cityOptions?.find((c) => c.value === state.city) || { value: "", label: "" }}
                  disabled={!state.country}
                  isSearchable
                  error={updateErrors?.city}
                  style={{ flex: 1 }}
                />
              </Stack>
              <FormControl error={Boolean(updateErrors?.permalink)}>
                <Stack gap={0.5}>
                  <FormLabel htmlFor="permalink">
                    Permalink{" "}
                    <Typography fontSize={12} sx={{ color: colors.grey60 }} component="span">
                      (name in your profile url - no spaces or special characters)
                    </Typography>
                  </FormLabel>
                  <TextField
                    placeholder="Permalink"
                    id="permalink"
                    name="permalink"
                    variant="outlined"
                    type="text"
                    onChange={handlePermalinkChange}
                    value={state.permalink}
                    error={Boolean(updateErrors?.permalink)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {checkingPermalink ? (
                            <CircularProgress size="small" />
                          ) : permalinkAccepted ? (
                            <CheckCircleOutlineOutlined fontSize="small" />
                          ) : (
                            <CancelOutlined fontSize="small" color="error" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <FormHelperText id="permalink-error-text">{updateErrors?.permalink}</FormHelperText>
              </FormControl>

              <Input
                placeholder="Email"
                id="email"
                name="email"
                label="Email"
                value={state.email}
                onChange={handleChange}
                error={updateErrors?.email}
              />
              <Input
                placeholder="Paypal email"
                id="paypalEmail"
                name="paypalEmail"
                label="Paypal email"
                value={state.paypalEmail}
                onChange={handleChange}
                error={updateErrors?.paypalEmail}
              />
              <div>
                <Checkbox
                  name="vat"
                  label="Are you VAT/GST registered?"
                  onChange={({ target }) => setVATRegistered(target.checked)}
                  checked={Boolean(state.vatRegistered)}
                />
                <Typography fontSize={12} sx={{ color: colors.grey60 }} paddingLeft={4.5}>
                  (Please note you do not need to be VAT/GST registered to sell on GoodMood but we need to know if you are)
                </Typography>
              </div>
              {vatRegistered && (
                <>
                  <Input
                    placeholder="Registered name"
                    id="registeredName"
                    name="registeredName"
                    label="Registered name"
                    value={state.vatRegistered?.registeredName}
                    onChange={handleVATChange}
                    error={updateErrors?.vatRegistered?.registeredName}
                  />
                  <Input
                    placeholder="VAT/GST number"
                    id="vatNumber"
                    name="vatNumber"
                    label="VAT/GST number"
                    value={state.vatRegistered?.vatNumber}
                    onChange={handleVATChange}
                    error={updateErrors?.vatRegistered?.vatNumber}
                  />
                </>
              )}
              {error && <Text color={colors.error}>{error}</Text>}
            </Form>

            <Container padding="0 0 100px" width={100}>
              <Flex justifyContent="space-between" fullWidth>
                <Button secondary size="medium" onClick={() => authSignOutUser(navigate)}>
                  Logout
                </Button>
                <Button secondary size="medium" disabled={disableSave} loading={loading} onClick={onUpdateUser}>
                  {updated ? "Saved!" : "Save"}
                </Button>
              </Flex>
            </Container>
          </Flex>
        </Container>
      </Column>
    </Row>
  );
};

export default Account;
