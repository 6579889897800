import { useState } from "react";
import { Link } from "react-router-dom";
import { Stack } from "@mui/material";
import { Header, Text } from "../../components/Text";
import Input from "../../components/Form/Input";
import Button from "../../components/Button";
import { Form } from "../../components/Layout";
import useLoginCustomer from "../Login/Customer/useLoginCustomer";

const CustomerResetPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { onForgotPassword } = useLoginCustomer();

  const handleChange = (event: any) => {
    setError("");
    setEmail(event.target.value);
  };

  const sendPasswordResetEmail = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      await onForgotPassword(email);
      setEmailSent(true);
      setTimeout(() => setEmailSent(false), 3000);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = err.message;
      setError(errorMessage);
      setLoading(false);
    }
  };

  return (
    <Stack paddingX={{ xs: 2, md: 5 }} paddingY={{ xs: 3, md: 5 }} height="70vh" justifyContent="center" alignItems="center">
      <Stack gap={1.5} width="100%" maxWidth={500}>
        <Header type="h2">Reset Password</Header>
        <Text>Please enter your email address. You will receive a link to create a new password via email.</Text>
        <Form onSubmit={sendPasswordResetEmail}>
          <Input placeholder="Email" id="email" name="email" onChange={handleChange} error={error} />
          <Button secondary fullWidth loading={loading}>
            {emailSent ? "Sent!" : "Send link"}
          </Button>
          <Stack alignItems="center">
            <Link to="/login/customer">
              <Button tertiary>Back to login</Button>
            </Link>
          </Stack>
        </Form>
      </Stack>
    </Stack>
  );
};

export default CustomerResetPassword;
