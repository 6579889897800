import { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { match } from "ts-pattern";
import { Stack, Typography } from "@mui/material";
import Input from "../../../components/Form/Input";
import Button from "../../../components/Button";
import { Form } from "../../../components/Layout";
import Checkbox from "../../../components/Checkbox";
import { colors } from "../../../theme";
import TextLink from "../../../components/TextLink";
import ErrorMessage from "../../../components/Form/ErrorMessage";
import PasswordInput from "../../../components/Form/PasswordInput";
import useLoginArtist from "./useLoginArtist";
import { useAppState } from "../../../state";

type View = "login" | "register";

export type UserData = {
  firstName?: string;
  lastName?: string;
  about?: string;
  email?: string;
  password?: string;
};

const HeaderButton = styled.button`
  cursor: pointer;
  border: 0;
  padding: 0;
  background: none;
`;

const ArtistLogin = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const referralCode = urlParams.get("referral") || undefined;
  const { errors } = useAppState();
  const [view, setView] = useState<View>("login");

  const {
    handleSignInChange,
    handleSignUpChange,
    signInUser,
    signUpUser,
    loading,
    signInErrors,
    signUpErrors,
    onTermsChange,
    agreedTerms,
  } = useLoginArtist(referralCode);

  return (
    <>
      <Helmet>
        <title>Artist Login | GoodMood</title>
        <meta name="description" content="Login or create an artist account on GoodMood" />
      </Helmet>
      <Stack paddingX={{ xs: 2, md: 5 }} paddingY={{ xs: 3, md: 5 }} height="70vh" justifyContent="center" alignItems="center">
        <Stack gap={1.5} width="100%" maxWidth={500}>
          <Typography variant="h1">Artist</Typography>
          <Stack gap={1.5}>
            <Stack gap={2} direction="row" alignItems="center">
              <HeaderButton onClick={() => setView("login")}>
                <Typography variant="h3" color={view === "login" ? colors.black : colors.grey20}>
                  Login
                </Typography>
              </HeaderButton>
              <HeaderButton onClick={() => setView("register")}>
                <Typography variant="h3" color={view === "register" ? colors.black : colors.grey20}>
                  Register
                </Typography>
              </HeaderButton>
            </Stack>

            {match(view)
              .with("login", () => (
                <Form onSubmit={signInUser}>
                  <Stack gap={1} width="100%">
                    <Input
                      placeholder="Email"
                      id="email"
                      name="email"
                      onChange={handleSignInChange}
                      error={errors?.signIn?.email || signInErrors.email}
                    />
                    <PasswordInput onChange={handleSignInChange} error={errors?.signIn?.password || signInErrors.password} />
                  </Stack>
                  <Button fullWidth loading={loading}>
                    Login
                  </Button>
                  <Stack alignItems="center" width="100%">
                    <Link to="/reset-password">
                      <Button tertiary>Forgot password?</Button>
                    </Link>
                  </Stack>
                </Form>
              ))
              .with("register", () => (
                <>
                  <Typography margin="0 0 8px">For artists wishing to upload and sell</Typography>
                  <Form onSubmit={signUpUser}>
                    <Stack gap={1} width="100%">
                      <Stack gap={1} direction="row">
                        <Input
                          placeholder="First name"
                          id="firstName"
                          name="firstName"
                          onChange={handleSignUpChange}
                          error={signUpErrors.firstName}
                        />
                        <Input
                          placeholder="Last name"
                          id="lastName"
                          name="lastName"
                          onChange={handleSignUpChange}
                          error={signUpErrors.lastName}
                        />
                      </Stack>
                      <Input
                        placeholder="Email"
                        id="email"
                        name="email"
                        onChange={handleSignUpChange}
                        error={errors?.signUp?.email || signUpErrors.email}
                      />
                      <Input
                        placeholder="Paypal email"
                        id="paypalEmail"
                        name="paypalEmail"
                        onChange={handleSignUpChange}
                        error={errors?.signUp?.paypalEmail || signUpErrors.paypalEmail}
                      />
                      <PasswordInput onChange={handleSignUpChange} error={errors?.signUp?.password || signUpErrors.password} />
                    </Stack>
                    <Checkbox
                      name="termsAndConditions"
                      onChange={onTermsChange}
                      error={signUpErrors.agreedTerms}
                      label={
                        <Typography fontSize={14}>
                          I agree to the{" "}
                          <TextLink to="/terms" openInNewTab>
                            terms and conditions
                          </TextLink>
                        </Typography>
                      }
                    />

                    {errors?.signUp?.general && <ErrorMessage errorMessage={errors.signUp.general} />}
                    <Button fullWidth loading={loading} disabled={!agreedTerms}>
                      Create
                    </Button>
                  </Form>
                </>
              ))
              .exhaustive()}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default ArtistLogin;
