import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import sell from "../../assets/images/sellHero.jpg";
import sellWebp from "../../assets/images/sellHero.webp";
import upload from "../../assets/images/laptop.png";
import price from "../../assets/images/price.png";
import work from "../../assets/images/work.png";
import people from "../../assets/images/people.png";
import stepOne from "../../assets/images/stepOne.png";
import stepTwo from "../../assets/images/stepTwo.png";
import stepThree from "../../assets/images/stepThree.png";
import stepFour from "../../assets/images/stepFour.png";
import { useAppState } from "../../state";
import { Container, Flex, Grid, Margin } from "../../components/Layout";
import { Header, Text } from "../../components/Text";
import { Image } from "../../components";

const Sell = () => {
  const appState = useAppState();
  const { isMobileScreen } = appState;
  return (
    <>
      <Helmet>
        <title>Sell | GoodMood</title>
        <meta name="description" content="Artists upload risk free and we take care of the admin" />
      </Helmet>
      <PageHeader header="Sell" background={colors.mint} image={sell} webpImage={sellWebp}>
        {isMobileScreen
          ? "Use a desktop to upload and start selling."
          : "Join a community of artists who get direct access to customers and curate your own digital gallery. We take care of the admin so that you have more time to create."}
        {isMobileScreen ? (
          <>
            <Margin margin={24} />
            <Link
              to={{
                pathname: "/shop",
                search: "?collection=true",
              }}
            >
              <Button secondary>Explore the collection</Button>
            </Link>
          </>
        ) : (
          <>
            <Margin margin={24} />
            <Link to="/upload">
              <Button secondary>Get started</Button>
            </Link>
          </>
        )}
      </PageHeader>

      <Container padding={isMobileScreen ? "24px" : "40px"}>
        <Header type="h2">How it works for Artists</Header>
      </Container>
      <Container padding={isMobileScreen ? "0 24px" : "0 40px"} style={{ overflow: "auto" }}>
        <Grid gap={24} rowGap={0} columns={4} evenColumns>
          <Image src={upload} alt="upload" />
          <Image src={price} alt="price" />
          <Image src={work} alt="work" />
          <Image src={people} alt="people" />
          <Container padding="24px 0" style={{ width: isMobileScreen ? "60vw" : "auto" }}>
            <Text margin="0 0 16px" size={20}>
              Upload your files
            </Text>
            <Text>Upload your files. Set your orientation. Add a title and description.</Text>
          </Container>
          <Container padding="24px 0" style={{ width: isMobileScreen ? "60vw" : "auto" }}>
            <Text margin="0 0 16px" size={20}>
              Set your prices
            </Text>
            <Text>Set the prices you’d like to charge. See your potential payout with our estimated payout calculator.</Text>
          </Container>
          <Container padding="24px 0" style={{ width: isMobileScreen ? "60vw" : "auto" }}>
            <Text margin="0 0 16px" size={20}>
              Your work goes live
            </Text>
            <Text>
              Every submission becomes available to purchase from the following month, with thousands of potential new buyers. If
              we love your work, we’ll also feature it on the shop page and on our social media.
            </Text>
          </Container>
          <Container padding="24px 0" style={{ width: isMobileScreen ? "60vw" : "auto" }}>
            <Text margin="0 0 16px" size={20}>
              People buy your work
            </Text>
            <Text>
              Your work is available for anyone to buy, as a print or framed print. We’ll pay you at the end of each month. The
              more you sell, the more you earn.
            </Text>
          </Container>
        </Grid>
      </Container>

      <Container padding={isMobileScreen ? "60px 24px 24px" : "100px 40px 40px"}>
        <Header type="h2">Why sell on GoodMood</Header>
      </Container>
      <Container padding={isMobileScreen ? "0 24px 24px" : "0 40px 100px"} style={{ overflow: "auto" }}>
        <Grid gap={24} rowGap={0} columns={4} evenColumns>
          <Container background={colors.mint} padding="40px 24px">
            <Flex alignItems="center" justifyContent="center" style={{ height: "100%" }}>
              <Image src={stepOne} alt="step one" width="30%" height="auto" />
            </Flex>
          </Container>
          <Container background={colors.mint} padding="40px 24px">
            <Flex alignItems="center" justifyContent="center" style={{ height: "100%" }}>
              <Image src={stepTwo} alt="step two" width="30%" height="auto" />
            </Flex>
          </Container>
          <Container background={colors.mint} padding="40px 24px">
            <Flex alignItems="center" justifyContent="center" style={{ height: "100%" }}>
              <Image src={stepThree} alt="step three" width="30%" height="auto" />
            </Flex>
          </Container>
          <Container background={colors.mint} padding="40px 24px">
            <Flex alignItems="center" justifyContent="center" style={{ height: "100%" }}>
              <Image src={stepFour} alt="step four" width="30%" height="auto" />
            </Flex>
          </Container>
          <Container padding="24px 0" style={{ width: isMobileScreen ? "60vw" : "auto" }}>
            <Text margin="0 0 16px" size={20}>
              No upfront costs
            </Text>
            <Text>It’s free to submit your work to GoodMood. Just simply upload your files and sell your work.</Text>
          </Container>
          <Container padding="24px 0" style={{ width: isMobileScreen ? "60vw" : "auto" }}>
            <Text margin="0 0 16px" size={20}>
              Reach a global audience
            </Text>
            <Text>
              We market your prints globally through targeted social posts. This helps visitors from all over the world view your
              work.
            </Text>
          </Container>
          <Container padding="24px 0" style={{ width: isMobileScreen ? "60vw" : "auto" }}>
            <Text margin="0 0 16px" size={20}>
              No hassle or effort
            </Text>
            <Text>
              We'll take care of everything. Printing, framing, packaging, delivery, payments, returns & customer service. You can
              concentrate on doing what you love.
            </Text>
          </Container>
          <Container padding="24px 0" style={{ width: isMobileScreen ? "60vw" : "auto" }}>
            <Text margin="0 0 16px" size={20}>
              Earn a passive income
            </Text>
            <Text>Following the end of each month, you’ll get paid your split of the profit within a week.</Text>
          </Container>
        </Grid>
      </Container>
    </>
  );
};

export default Sell;
