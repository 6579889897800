import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { match } from "ts-pattern";
import AuthContext from "../../state/auth";

type Props = {
  element: () => JSX.Element | null;
  type?: "admin" | "artist";
};

const AuthGuard = ({ element: Element, type = "artist" }: Props) => {
  const { isAdmin, user, loading } = useContext(AuthContext);

  const authorised = match(type)
    .with("admin", () => isAdmin)
    .with("artist", () => Boolean(user))
    .exhaustive();

  return authorised || loading ? <Element /> : <Navigate to="/login/artist" replace />;
};

export default AuthGuard;
