import { useEffect, useState } from "react";
import ProductCard from "../../components/ProductCard";
import ScrollCarousel from "../../components/ScrollCarousel";
import { User } from "../../types/user";
import { appendArtistsToProducts } from "../../helpers/product";
import { CollectionFragment, CollectionProductFragment } from "../../generated/storefront";

type Props = {
  collection: CollectionFragment;
};

type CollectionProductWithArtist = CollectionProductFragment & { artist: User | null };

const CollectionCarousel = ({ collection }: Props) => {
  const [products, setProducts] = useState<CollectionProductWithArtist[]>([]);

  useEffect(() => {
    const getProductsArtists = async (products: CollectionProductFragment[]) => {
      const productsWithArtist = await appendArtistsToProducts(products);
      setProducts(productsWithArtist);
    };
    if (collection.products?.nodes) {
      getProductsArtists(collection.products.nodes);
    }
  }, [collection]);

  return (
    <div style={{ minHeight: 375 }}>
      <ScrollCarousel header={collection.title} cta={{ text: "View all", link: `/shop/collections/${collection.handle}` }}>
        {products.map((product) => (
          <ProductCard key={product.id} product={product} artist={product.artist} />
        ))}
      </ScrollCarousel>
    </div>
  );
};

export default CollectionCarousel;
