import { CheckRounded } from "@mui/icons-material";
import { CircularProgress, MenuItem, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Wishlist } from "../../types/wishlist";
import { getIdNumber } from "../../helpers/shopify";
import { addProductToWishlist, removeProductFromWishlist } from "../../services/Firebase/wishlists";

type Props = {
  productId: string;
  wishlist: Wishlist;
  setWishlistItem: (value: boolean) => void;
  refetchCustomer: () => void;
};

const WishlistItem = ({ productId, wishlist, setWishlistItem, refetchCustomer }: Props) => {
  const productIdNumber = getIdNumber(productId);
  const [loading, setLoading] = useState(false);
  const [wishlistSelected, setWishlistSelected] = useState(wishlist.productIds.includes(productIdNumber));

  const addOrRemoveProduct = async (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, wishlistId: string) => {
    event.preventDefault();
    setLoading(true);
    if (wishlistSelected) {
      await removeProductFromWishlist(wishlistId, productIdNumber);
      setWishlistSelected(false);
      setWishlistItem(false);
    } else {
      await addProductToWishlist(wishlistId, productIdNumber);
      setWishlistSelected(true);
      setWishlistItem(true);
    }
    refetchCustomer();
    setLoading(false);
  };

  return (
    <MenuItem onClick={(e) => addOrRemoveProduct(e, wishlist.id)}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1} width="100%">
        <Typography key={wishlist.id}>{wishlist.name}</Typography>
        {loading ? (
          <CircularProgress size="small" color="primary" />
        ) : wishlistSelected ? (
          <CheckRounded fontSize="small" color="primary" style={{ marginLeft: 5 }} />
        ) : null}
      </Stack>
    </MenuItem>
  );
};
export default WishlistItem;
