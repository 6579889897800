import { NavigateFunction } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, deleteUser } from "firebase/auth";
import { addUser, getUsers } from "./users";
import { auth } from ".";
import { Errors } from "../../state";
import { UserData } from "../../pages/Login/Artist/useLoginArtist";

const permalinkExistsInDb = async (permalink: string): Promise<boolean> => {
  const { users } = await getUsers({ filters: { permalink } });
  return users.length > 0;
};

export const checkUsersNewPermalink = async (userId: string, permalink: string): Promise<boolean> => {
  const { users } = await getUsers({ filters: { permalink } });
  return users.filter((user) => user.id !== userId).length > 0;
};

const getPermalink = async (firstName: string, lastName: string) => {
  const perm = (firstName.toLowerCase() + "." + lastName.toLowerCase()).replace(/\s/g, ".");
  let permalink = perm;

  let suffix = 0;
  while (await permalinkExistsInDb(permalink)) {
    suffix++;
    permalink = perm + "." + suffix;
  }

  return permalink;
};

export const authCreateUser = async (
  userData: Partial<UserData>,
  setError: (error: Partial<Errors> | null) => void,
  setUser: (user: any) => void,
  navigate: NavigateFunction,
  redirectUrl?: string
) => {
  const { email, paypalEmail, password, firstName, lastName, referralCode = "" } = userData;
  let user = null;
  if (!email || !paypalEmail || !password || !firstName || !lastName) {
    return setError({ signUp: { password: "Missing fields" } });
  }
  const fName = firstName.trim();
  const lName = lastName.trim();

  // create auth for user
  try {
    setError(null);
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    user = userCredential.user;
  } catch (error: any) {
    const errorCode = error.code;
    const errorMessage = error.message;
    const message = errorMessage.split("Firebase: ");
    const inputError = errorMessage.includes("email") ? "email" : "password";
    setError({ signUp: { [inputError]: message.length > 1 ? message[1] : message[0] } });
    console.log(errorCode, ": ", errorMessage);
    throw error;
  }

  // create user account for user
  try {
    const permalink = await getPermalink(fName, lName);
    const newUser = await addUser({
      uid: user?.uid,
      firstName: fName,
      lastName: lName,
      email,
      paypalEmail,
      referralCode,
      permalink,
      hasProducts: false,
      featured: false,
      salesCount: 0,
      mediums: [],
    });
    setUser(newUser);
    if (redirectUrl) {
      navigate(redirectUrl, { state: { registered: true } });
    }
  } catch (error: any) {
    await deleteUser(user);
    const errorCode = error.code;
    const errorMessage = error.message;
    setError({ signUp: { general: errorMessage || error } });
    console.log(errorCode, ": ", errorMessage);
    throw error;
  }
};

export const authSignInUser = async (
  userData: { email: string; password: string },
  setError: (error: any) => void,
  navigate: NavigateFunction,
  redirectUrl?: string
) => {
  const { email, password } = userData;
  if (email && password) {
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      if (redirectUrl) {
        navigate(redirectUrl);
      }
      return user;
    } catch (error) {
      const errorCode = (error as any).code;
      const errorMessage = (error as any).message;
      const message = errorMessage.split("Firebase: ");
      const inputError = errorMessage.includes("email") ? "email" : "password";
      setError({ signIn: { [inputError]: message.length > 1 ? message[1] : message[0] } });
      console.log(errorCode, ": ", errorMessage);
      throw error;
    }
  }
};

export const authSendPasswordResetEmail = async (email: string) => {
  var actionCodeSettings = {
    url: process.env.REACT_APP_SITE_URL || "",
  };
  return sendPasswordResetEmail(auth, email, actionCodeSettings)
    .then(() => {
      console.log("reset email sent");
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, ": ", errorMessage);
      throw error;
    });
};

export const authSignOutUser = (navigate: NavigateFunction) => {
  auth.signOut().then(() => {
    navigate("/");
  });
};

export const authStateChange = (action: (user: any | null) => void) => {
  auth.onAuthStateChanged((user) => action(user));
};
