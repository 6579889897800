import { HTMLAttributes, MouseEventHandler, ReactElement } from "react";
import styled from "styled-components";
import { match } from "ts-pattern";
import { colors } from "../../theme";
import { Icon } from "../Icon";

type Size = "small" | "medium" | "large";

type Props = {
  children: string | ReactElement;
  onClick?: (event: MouseEventHandler<HTMLButtonElement>) => void;
  secondary?: boolean;
  tertiary?: boolean;
  size?: Size;
  fullWidth?: boolean;
  disabled?: boolean;
  loading?: boolean;
  successText?: string;
} & HTMLAttributes<HTMLButtonElement>;

const StyledButton = styled.button<{
  size: Size;
  secondary?: boolean;
  tertiary?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}>`
  color: ${colors.white};
  background-color: ${colors.black};
  border: 1px solid ${colors.black};
  border-radius: 100px;
  line-height: 1;
  cursor: pointer;
  display: block;

  ${(p) =>
    match(p.size)
      .with(
        "small",
        () => `
          height: 32px;
          font-size: 14px;
          padding: 0 16px;
          border: 1px solid ${colors.grey10};
        `
      )
      .with(
        "medium",
        () => `
          height: 40px;
          font-size: 16px;
          padding: 0 24px;
          min-width: 100px;
        `
      )
      .with(
        "large",
        () => `
          height: 48px;
          font-size: 18px;
          padding: 0 32px;
          min-width: 128px;
        `
      )
      .exhaustive()}

  ${(p) =>
    p.disabled
      ? `
        background-color: ${colors.grey10};
        border-color: ${colors.grey10};
      `
      : `
        &:hover {
          color: ${colors.black};
          background-color: ${colors.cream};
        }

        &:active {
          color: ${colors.black};
          background-color: ${colors.cardGrey};
        }
  `}

  ${(p) =>
    p.secondary &&
    `
      color: ${colors.black};
      background-color: ${colors.cream};

      ${
        p.disabled
          ? `
            background-color: ${colors.cream};
            border-color: ${colors.grey10};
            color: ${colors.grey10};
          `
          : `
            &:hover {
              color: ${colors.white};
              background-color: ${colors.black};
            }

            &:active {
              color: ${colors.white};
              background-color: ${colors.grey80};
            }
          `
      }
  `}

  ${(p) =>
    p.tertiary &&
    `
      background: none;  
      background-color: transparent;
      border: none;
      color: ${colors.black};
      padding: 0;
      margin: 0;
      min-width: 0;
      display: inline-block;

      &:hover {
        background-color: transparent;
        text-decoration: underline;
        text-underline-offset: 3px;
      }
  `}

  ${(p) =>
    p.fullWidth &&
    `
      width: 100%;
  `}

  ${(p) =>
    p.color &&
    `
      background-color: ${p.color};
      color: ${colors.black};
    `}
`;

const Button = ({ secondary, tertiary, fullWidth, size, children, onClick, disabled, loading, successText, ...props }: Props) => {
  const defaultSize = size || "medium";

  return (
    <StyledButton
      secondary={secondary}
      tertiary={tertiary}
      fullWidth={fullWidth}
      onClick={onClick}
      disabled={disabled || loading}
      size={defaultSize}
      {...props}
    >
      {successText || (loading ? <Icon icon={secondary || tertiary ? "blackSpinner" : "whiteSpinner"} size={30} /> : children)}
    </StyledButton>
  );
};

export default Button;
