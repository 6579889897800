import { ReactChild, useEffect, useState } from "react";
import { useToggle } from "react-use";
import styled from "styled-components";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import { Icon } from "../Icon";
import { Container, Flex } from "../Layout";

type ItemProps = {
  item: {
    label: string;
    content: ReactChild;
  };
  onItemOpen?: () => void;
  highlight?: boolean;
};

type Props = {
  items: {
    label: string;
    content: ReactChild;
  }[];
  onItemOpen?: () => void;
  highlight?: boolean;
};

const AccordionItemWrapper = styled.div`
  border-top: 1px solid ${colors.grey05};

  &:last-child {
    border-bottom: 1px solid ${colors.grey05};
  }
`;

const Label = styled.p`
  font-size: 16px;
  margin: 0;
`;

const ItemContent = styled.div<{ open: boolean }>`
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;

  p {
    font-size: 14px;
  }

  ${(p) =>
    p.open &&
    `
      max-height: 1000px;
      transition: max-height 0.25s ease-in;
    `}
`;

const ItemWrapper = styled(Container)`
  cursor: pointer;
  transition: background 0.15s ease-out;
`;

const AccordionItem = ({ item, onItemOpen, highlight }: ItemProps) => {
  const appState = useAppState();
  const { isMobileScreen } = appState;
  const [open, toggleOpen] = useToggle(false);
  const colorPalette = ["blue", "lemon", "peach", "purple", "mint"] as const;
  type ColorPaletteType = (typeof colorPalette)[number];
  const [highlightColor, setHighlightColor] = useState<ColorPaletteType>(colorPalette[0]);

  useEffect(() => {
    onItemOpen && onItemOpen();
    if (open) {
      setHighlightColor(colorPalette[Math.floor(Math.random() * colorPalette.length)]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, onItemOpen]);

  return (
    <AccordionItemWrapper>
      <ItemWrapper
        padding={isMobileScreen ? "16px 24px" : "16px 32px"}
        onClick={toggleOpen}
        className={open ? "open" : ""}
        background={open && highlight ? colors[highlightColor] : colors.white}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Label>{item.label}</Label>
          <Icon icon={open ? "minus" : "plus"} size={14} />
        </Flex>
      </ItemWrapper>
      <ItemContent open={open}>
        <Container background={highlight ? colors[highlightColor] : colors.white}>{item.content}</Container>
      </ItemContent>
    </AccordionItemWrapper>
  );
};

const Accordion = ({ items, onItemOpen, highlight }: Props) => (
  <>
    <Container background={colors.white} width={100}>
      {items.map((item) => (
        <AccordionItem item={item} key={item.label} onItemOpen={onItemOpen} highlight={highlight} />
      ))}
    </Container>
  </>
);

export default Accordion;
