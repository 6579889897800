import styled from "styled-components";
import { Stack } from "@mui/material";
import { FrameBadgeButton } from "../../pages/Shop/styles";
import { Frame, frames } from "../../types/product";
import { colors } from "../../theme";
import FrameBadge from "./FrameBadge";

type Props = {
  selectedFrame: Frame;
  setSelectedFrame: (frame: Frame) => void;
};

export const Circle = styled.div<{ color: string; size: number }>`
  width: ${(p) => p.size}px;
  height: ${(p) => p.size}px;
  background-color: ${(p) => (p.color === "black" ? colors.black : colors.white)};
  border: 1px solid ${(p) => (p.color === "black" ? colors.black : colors.cardGrey)};
  border-radius: 100%;
`;

const FrameBadges = ({ selectedFrame, setSelectedFrame }: Props) => {
  const orderedFrames = [...frames].reverse();
  return (
    <Stack direction="row" gap={0.5} width="min-content">
      {orderedFrames.map((frame) => (
        <FrameBadgeButton key={frame} selected={selectedFrame === frame} onClick={() => setSelectedFrame(frame)}>
          <FrameBadge frame={frame} />
        </FrameBadgeButton>
      ))}
    </Stack>
  );
};

export default FrameBadges;
