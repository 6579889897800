import { Grid, Grid2Props } from "@mui/material";
import { PropsWithChildren } from "react";

const GridItem = ({ children, ...props }: PropsWithChildren<Grid2Props>) => {
  return (
    <Grid item display="flex" alignItems="center" {...props} xs={3} sm={2}>
      {children}
    </Grid>
  );
};

export default GridItem;
