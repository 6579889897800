import SVG from "react-inlinesvg";
import icons from "../../assets/icons";
import { colors } from "../../theme";
import { HTMLAttributes } from "react";

export type IconType = keyof typeof icons;

type Props = {
  icon: IconType;
  fill?: string;
  size?: number;
  width?: number;
  height?: number;
} & HTMLAttributes<SVGElement>;

export const Icon = ({ icon, fill = colors.black, size = 15, width, height }: Props) => (
  <SVG style={{ transition: "fill 0.5s ease" }} src={icons[icon]} fill={fill} width={width || size} height={height || size} />
);
