import { initializeApp } from "firebase/app";
import { initializeAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { addUser, getUsers, getUserById, searchArtists, getLoggedInUser } from "./users";
import { authCreateUser, authSignInUser, authSignOutUser, authStateChange } from "./auth";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "fourth-wall-c7f73.firebaseapp.com",
  projectId: "fourth-wall-c7f73",
  storageBucket: "fourth-wall-c7f73.appspot.com",
  messagingSenderId: "665442147790",
  appId: "1:665442147790:web:b872fac39d7b664ed19f9f",
  measurementId: "G-3VHSZJF34B",
};
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

if (typeof window !== "undefined" && process.env.ENVIRONMENT !== "test") {
  initializeAnalytics(app);
}

export {
  db,
  storage,
  auth,
  addUser,
  getUsers,
  getUserById,
  searchArtists,
  getLoggedInUser,
  authCreateUser,
  authSignInUser,
  authSignOutUser,
  authStateChange,
};
