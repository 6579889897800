import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { Flex } from "../../../components/Layout";
import { DashboardProductFragment, ProductStatus } from "../../../generated/graphql";
import Button from "../../../components/Button";
import { getIdNumber } from "../../../helpers/shopify";

type ActionProps = {
  product: DashboardProductFragment;
  updateStatus: (status: ProductStatus) => void;
  loading: boolean;
};

const ActionButtons = ({ product, updateStatus, loading }: ActionProps) => {
  return match(product)
    .when(
      ({ status }) => status === ProductStatus.Archived,
      () => (
        <Flex width={100} gap={24}>
          <Button onClick={() => updateStatus(ProductStatus.Active)} loading={loading} size="medium">
            Restore
          </Button>
        </Flex>
      )
    )
    .when(
      ({ status }) => status === ProductStatus.Draft || status === ProductStatus.Active,
      () => (
        <Flex gap={24}>
          <Link to={`/profile/dashboard/${getIdNumber(product.id)}/edit`}>
            <Button secondary size="medium">
              Edit
            </Button>
          </Link>
          <Button size="medium" tertiary onClick={() => updateStatus(ProductStatus.Archived)} loading={loading}>
            Archive
          </Button>
        </Flex>
      )
    )
    .run();
};

export default ActionButtons;
