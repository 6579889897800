import styled from "styled-components";
import { media } from "../../helpers/layout";
import { colors, fonts } from "../../theme";

export type TextProps = {
  color?: string;
  size?: 14 | 16 | 18 | 20 | 22 | 24;
  font?: string;
  margin?: string;
  align?: "left" | "right" | "center";
  linethrough?: boolean;
  truncate?: boolean;
  bold?: boolean;
};

export type HeaderProps = {
  type?: "h1" | "h2" | "h3";
  margin?: string;
  align?: "left" | "right" | "center";
  bold?: boolean;
};

export const Header = styled.h2<HeaderProps>`
  color: ${(p) => p.color || colors.black};
  font-family: ${fonts.brand};
  font-weight: ${(p) => (p.bold ? "bold" : "normal")};
  margin: 0;

  ${(p) =>
    p.type === "h1" &&
    `
    font-size: 40px;
    line-height: 1.15;
  `}
  ${(p) =>
    p.type === "h2" &&
    `
    font-size: 28px;
    line-height: 1.5;
  `}
  ${(p) =>
    p.type === "h3" &&
    `
    font-size: 24px;
    line-height: 48px;
  `}
  ${(p) =>
    p.margin &&
    `
    margin: ${p.margin};
  `}
  ${(p) =>
    p.align &&
    `
    text-align: ${p.align};
  `}

  @media ${media.m} {
    ${(p) =>
      p.type === "h1" &&
      `
    font-size: 28px;
    line-height: 1.3;
  `}
    ${(p) =>
      p.type === "h2" &&
      `
    font-size: 24px;
    line-height: 1.15;
  `}
  ${(p) =>
      p.type === "h3" &&
      `
    font-size: 18px;
    line-height: 1.15;
  `}
  }
`;

export const Text = styled.p<TextProps>`
  color: ${(p) => p.color || colors.black};
  font-weight: ${(p) => (p.bold ? "500" : "normal")};
  font-size: 16px;
  line-height: 24px;
  font-family: ${(p) => p.font || fonts.brand};
  margin: 0;
  ${(p) => p.linethrough && `text-decoration: line-through; color: ${colors.grey40};`}
  ${(p) =>
    p.truncate &&
    `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

  ${(p) =>
    p.size === 14 &&
    `
    font-size: 14px;
    line-height: 1.4;
  `}
  ${(p) =>
    p.size === 16 &&
    `
    font-size: 16px;
    line-height: 1.5;
  `}
  ${(p) =>
    p.size === 18 &&
    `
    font-size: 18px;
    line-height: 1.5;
  `}
  ${(p) =>
    p.size === 20 &&
    `
    font-size: 20px;
    line-height: 1.5;
  `}
  ${(p) =>
    p.size === 22 &&
    `
    font-size: 22px;
    line-height: 1.5;
  `}
  ${(p) =>
    p.size === 24 &&
    `
    font-size: 24px;
    line-height: 1.5;
  `}
  ${(p) =>
    p.margin &&
    `
    margin: ${p.margin};
  `}
  ${(p) =>
    p.align &&
    `
    text-align: ${p.align};
  `}

  @media ${media.m} {
    font-size: 14px;
    line-height: 1.4;

    ${(p) =>
      p.size === 14 &&
      `
    font-size: 12px;
    line-height: 1.3;
  `}
    ${(p) =>
      p.size === 16 &&
      `
    font-size: 14px;
    line-height: 1.4;
  `}
  ${(p) =>
      p.size === 18 &&
      `
    font-size: 16px;
    line-height: 1.4;
  `}
  ${(p) =>
      p.size === 20 &&
      `
    font-size: 18px;
    line-height: 1.4;
  `}
  ${(p) =>
      p.size === 22 &&
      `
    font-size: 20px;
    line-height: 1.4;
  `}
  ${(p) =>
      p.size === 24 &&
      `
    font-size: 22px;
    line-height: 1.4;
  `}
  ${(p) =>
      p.margin &&
      `
    margin: ${p.margin};
  `}
  ${(p) =>
      p.align &&
      `
    text-align: ${p.align};
  `}
  }
`;
