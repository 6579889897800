import axios from "axios";
import { OrderInput } from "../../types/order";
import { auth } from "../Firebase";

export const createCustomOrder = async (order: OrderInput) => {
  const currentUser = auth.currentUser;
  if (currentUser) {
    const idToken = await currentUser.getIdToken();

    try {
      const result = await axios.post(`/.netlify/functions/createOrder`, order, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
      return result;
    } catch (error) {
      console.log({ error });
      throw error;
    }
  }
};
