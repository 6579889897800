import { useToggle } from "react-use";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { FormControl, FormHelperText, IconButton, InputAdornment, TextField } from "@mui/material";

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
};

const PasswordInput = ({ onChange, error }: Props) => {
  const [showPassword, toggleShowPassword] = useToggle(false);
  return (
    <FormControl error={Boolean(error)}>
      <TextField
        placeholder="Password"
        id="password"
        name="password"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        onChange={onChange}
        aria-describedby="password-error-text"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton aria-label="toggle password visibility" onClick={toggleShowPassword} edge="end">
                {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText id="password-error-text">{error}</FormHelperText>
    </FormControl>
  );
};

export default PasswordInput;
