import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import { CheckRounded, CloseRounded, ContentCopyRounded, DeleteRounded } from "@mui/icons-material";
import { capitalize } from "lodash";
import { currencyToLocale, formattedPrice } from "../../helpers/money";
import { getIdNumber } from "../../helpers/shopify";
import { useAppState } from "../../state";
import { colors } from "../../theme";
import Button from "../Button";
import { Icon } from "../Icon";
import { Container, Flex } from "../Layout";
import { Text } from "../Text";
import Dropdown from "../Form/Dropdown";
import { getSizeAndFrame, sizeLabelsForCountry } from "../../helpers/product";
import { Frame, Size } from "../../types/product";
import { trackEvent } from "../../helpers/analytics";
import AuthContext from "../../state/auth";
import { giftCardId } from "../../state/constants";
import {
  BasketFragment,
  BasketLineItemFragment,
  useRemoveItemFromBasketMutation,
  useUpdateItemInBasketMutation,
} from "../../generated/storefront";
import FrameMockUp from "../FrameMockUp";

type ContentProps = {
  basket?: BasketFragment;
  added?: boolean;
  onClose: () => void;
  viewOnly?: boolean;
};

type Props = {
  open: boolean;
  basket?: BasketFragment;
  onClose: () => void;
  added?: boolean;
  viewOnly?: boolean;
};

const StickyBottom = styled.div`
  position: sticky;
  bottom: 0;
  background-color: ${colors.white};
`;

const BasketContent = ({ basket, added, onClose, viewOnly }: ContentProps) => {
  const { setBasket, inFreeDeliveryCountry, freeDeliveryThreshold, selectedCountry } = useAppState();
  const { isAdmin } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const items = basket?.lines?.nodes.length || 0;
  const [removeItemFromBasket] = useRemoveItemFromBasketMutation();
  const [updateItemInBasket] = useUpdateItemInBasketMutation();

  if (basket && items > 0) {
    const items = basket.lines.nodes;
    const basketSubtotal = basket.cost.subtotalAmount;
    const basketSubtotalAmount = Number(basket.cost.subtotalAmount.amount);
    const basketTotalAmount = Number(basket.cost.totalAmount.amount);
    const basketTotal = basket.cost.totalAmount;
    const basketSubtotalPrice = formattedPrice(basketSubtotal.amount, basketSubtotal.currencyCode);
    const discountApplied = basketSubtotalAmount > basketTotalAmount;
    const freeDeliveryDifference = freeDeliveryThreshold - basketTotalAmount;
    const dropdownOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((n) => ({
      value: n,
      label: n.toString(),
    }));

    const getVariantName = (item: Pick<BasketLineItemFragment, "merchandise">) => {
      if (item.merchandise.product.id === giftCardId) return "Gift card";
      const { size, frame } = getSizeAndFrame(item.merchandise.selectedOptions);
      const sizeLabels = sizeLabelsForCountry(selectedCountry);
      return `${sizeLabels[size as Size].shortHand} / ${capitalize(frame)}${frame !== Frame.Unframed ? " frame" : ""}`;
    };

    const getFrame = (item: Pick<BasketLineItemFragment, "merchandise">) => {
      if (item.merchandise.product.id === giftCardId) return Frame.Unframed;
      const { frame } = getSizeAndFrame(item.merchandise.selectedOptions);
      return frame;
    };

    const onCheckout = () => {
      if (basket) {
        setLoading(true);
        trackEvent("InitiateCheckout");
      }
    };

    const removeItem = async (itemId: string) => {
      const { data } = await removeItemFromBasket({ variables: { basketId: basket.id, lineId: itemId } });
      if (data?.cartLinesRemove?.cart) {
        if (data.cartLinesRemove.cart.lines.nodes.length === 0) onClose();
        setBasket(data.cartLinesRemove.cart);
      }
    };

    const updateQuantity = async (quantity: number, itemId: string) => {
      if (quantity === 0) return removeItem(itemId);
      const { data } = await updateItemInBasket({ variables: { basketId: basket.id, lineId: itemId, quantity } });
      if (data?.cartLinesUpdate?.cart) {
        if (data.cartLinesUpdate.cart.lines.nodes.length === 0) onClose();
        setBasket(data.cartLinesUpdate.cart);
      }
    };

    return (
      <>
        <Flex direction="column" fullWidth>
          {added && (
            <Container padding="16px 24px 0">
              <Flex alignItems="center" gap={12}>
                <Icon icon="tickRound" fill={colors.green} />
                <Text margin="0" size={20}>
                  Added to basket
                </Text>
              </Flex>
            </Container>
          )}
          <Box padding={{ xs: 2, md: 3 }}>
            {items.map((item) => (
              <Container key={item.id} padding="16px 0">
                <Flex gap={24}>
                  <Link
                    to={item.merchandise.product.id && `/products/${getIdNumber(item.merchandise.product.id)}`}
                    style={{ width: "40%", display: "flex" }}
                  >
                    <FrameMockUp image={item.merchandise.product.images.nodes[0]} frame={getFrame(item)} fit="fill" />
                  </Link>
                  <Flex direction="column" width={60}>
                    <Text size={16} margin="0">
                      {item.merchandise.product.title}
                    </Text>
                    <Text size={14} color={colors.grey40} margin="0">
                      {getVariantName(item)}
                    </Text>
                    <Text size={14} margin="0 0 16px 0">
                      {formattedPrice(item.cost.subtotalAmount.amount, item.cost.subtotalAmount.currencyCode)}
                    </Text>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Dropdown
                        width="fit-content"
                        options={dropdownOptions}
                        onChange={(event) => updateQuantity(event.value, item.id)}
                        value={dropdownOptions.find((option) => option.value === item.quantity)}
                        disabled={viewOnly}
                      />
                      <IconButton onClick={() => removeItem(item.id)} disabled={viewOnly}>
                        <DeleteRounded color={viewOnly ? "disabled" : "primary"} fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Flex>
                </Flex>
              </Container>
            ))}
          </Box>
          <StickyBottom>
            {inFreeDeliveryCountry && (
              <Container padding="12px 16px" borderTop background={freeDeliveryDifference > 0 ? colors.lemon : colors.mint}>
                <Flex alignItems="center" justifyContent="center">
                  <Icon icon="van" size={22} />
                  <Text margin="0 0 0 12px">
                    {freeDeliveryDifference > 0
                      ? `${formattedPrice(freeDeliveryDifference, basketSubtotal.currencyCode)} more for free delivery`
                      : "Free delivery applied"}
                  </Text>
                </Flex>
              </Container>
            )}

            {/* <Link to="/shop" style={{ width: "100%" }}>
              <Container
                padding="12px 16px"
                borderTop
                background={discountApplied ? colors.mint : colors.lemon}
                onClick={discountApplied ? () => {} : onClose}
              >
                <Flex alignItems="center" justifyContent="center">
                  <Icon icon={discountApplied ? "tick" : "plus"} size={discountApplied ? 16 : 14} />
                  <Text margin="0 0 0 12px">{discountApplied ? "20% off applied" : "20% off if you add another piece"}</Text>
                </Flex>
              </Container>
            </Link> */}

            <Box
              paddingX={{ xs: 2, md: 3 }}
              paddingY={2}
              borderTop={`1px solid ${colors.grey10}`}
              borderBottom={`1px solid ${colors.grey10}`}
            >
              <Flex alignItems="center" justifyContent="space-between">
                <Text size={18}>Subtotal</Text>
                <Flex>
                  {discountApplied && (
                    <Text margin="0 6px 0 0">{formattedPrice(basketTotalAmount, basketTotal.currencyCode)}</Text>
                  )}
                  <Text linethrough={discountApplied}>{basketSubtotalPrice}</Text>
                </Flex>
              </Flex>
            </Box>
            <Box padding={{ xs: 2, md: 3 }} margin={{ xs: "0 0 50px 0", md: "0" }}>
              <Flex direction="column" gap={16}>
                <a href={basket.checkoutUrl}>
                  <Button onClick={onCheckout} fullWidth loading={loading}>
                    Checkout
                  </Button>
                </a>

                {/* @ts-ignore */}
                <klarna-placement
                  id="klarna-placement-credit-promotion-badge"
                  data-key="credit-promotion-badge"
                  data-locale={currencyToLocale(basketTotal.currencyCode)}
                  data-purchase-amount={`${basketTotalAmount * 100}`}
                >
                  {/* @ts-ignore */}
                </klarna-placement>

                {isAdmin && (
                  <Link to={`/admin/checkout?basketId=${basket.id}`}>
                    <Button fullWidth color={colors.purple}>
                      Admin checkout
                    </Button>
                  </Link>
                )}
              </Flex>
            </Box>
          </StickyBottom>
        </Flex>
      </>
    );
  } else {
    return (
      <Container padding="145px 24px">
        <Flex justifyContent="center" direction="column">
          <Text size={18} margin="0 0 16px">
            Your basket is empty
          </Text>
          <Link to="/shop" style={{ width: "100%" }}>
            <Button onClick={onClose} fullWidth>
              Shop
            </Button>
          </Link>
        </Flex>
      </Container>
    );
  }
};

const BasketDrawer = ({ open, basket, added, onClose, viewOnly }: Props) => {
  const [copied, setCopied] = useState(false);

  const shareBasketLink = () => {
    if (basket) {
      const url = `${window.location.origin}?basket=${basket.id}`;
      navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box width={{ xs: "90vw", md: "25vw" }} height="100vh" overflow="auto" minWidth={{ xs: "auto", md: "450px" }}>
        <Stack
          paddingY={2}
          paddingX={{ xs: 2, md: 3 }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom={`1px solid ${colors.grey10}`}
        >
          <Typography variant="h3">Basket</Typography>
          <Stack direction="row" gap={1}>
            <IconButton
              onClick={shareBasketLink}
              disabled={!basket?.lines.nodes.length}
              style={{ backgroundColor: colors.grey02 }}
            >
              {copied ? (
                <CheckRounded color="primary" />
              ) : (
                <ContentCopyRounded color={basket?.lines.nodes.length ? "primary" : "disabled"} />
              )}
            </IconButton>
            <IconButton onClick={onClose} style={{ backgroundColor: colors.grey02 }}>
              <CloseRounded color="primary" />
            </IconButton>
          </Stack>
        </Stack>
        <BasketContent basket={basket} added={added} onClose={onClose} viewOnly={viewOnly} />
      </Box>
    </Drawer>
  );
};

export default BasketDrawer;
