import { capitalize } from "lodash";

type Errors = { [key: string]: string };

export const validate = <T>(state: Partial<T>): { error: false; state: T } | { error: true; errors: Errors } => {
  const errors: Errors = {};
  const fields: (keyof T)[] = Object.keys(state) as (keyof T)[];

  fields.forEach((field) => {
    if (!state[field]) {
      errors[String(field)] = `${capitalize(String(field))} is required`;
    }
  });
  if (Object.keys(errors).length === 0) return { error: false, state: state as T };
  return { error: true, errors };
};
