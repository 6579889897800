import { useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, MenuItem, Popover, Typography } from "@mui/material";
import { MoreHorizRounded } from "@mui/icons-material";
import EditWishlistModal from "../Wishlist/EditWishlistModal";
import DeleteWishlistModal from "../Wishlist/DeleteWishlistModal";
import { Customer } from "../../state/auth";
import { Wishlist } from "../../types/wishlist";

const MoreOptions = ({ wishlist, customer }: { wishlist: Wishlist; customer: Customer }) => {
  const [showEditWishlistModal, setShowEditWishlistModal] = useState(false);
  const [showDeleteWishlistModal, setShowDeleteWishlistModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? "simple-popover" : undefined;

  return (
    <>
      <IconButton color="primary" onClick={(e) => setAnchorEl(e.currentTarget)} style={{ width: 25, height: 25 }}>
        <MoreHorizRounded fontSize="inherit" />
      </IconButton>
      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPopover-paper": {
            minWidth: 200,
          },
        }}
      >
        <MenuItem>
          <Link to="/shop">
            <Typography display="flex" alignItems="center">
              Add to wishlist
            </Typography>
          </Link>
        </MenuItem>
        <MenuItem onClick={() => setShowEditWishlistModal(true)}>
          <Typography display="flex" alignItems="center">
            Edit
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setShowDeleteWishlistModal(true)}>
          <Typography display="flex" alignItems="center" color="error">
            Delete
          </Typography>
        </MenuItem>
      </Popover>
      {showEditWishlistModal && (
        <EditWishlistModal
          customer={customer}
          open={showEditWishlistModal}
          onClose={() => setShowEditWishlistModal(false)}
          wishlist={wishlist}
        />
      )}
      <DeleteWishlistModal
        open={showDeleteWishlistModal}
        onClose={() => setShowDeleteWishlistModal(false)}
        customer={customer}
        wishlist={wishlist}
      />
    </>
  );
};

export default MoreOptions;
