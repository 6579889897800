import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import WebpImage from "react-image-webp";
import { format, subMonths } from "date-fns";
import { Box, Stack, Typography } from "@mui/material";
import Button from "../../components/Button";
import { colors } from "../../theme";
import { Flex, Container } from "../../components/Layout";
import How from "../../assets/images/How.png";
import HowWebp from "../../assets/images/How.webp";
import How2 from "../../assets/images/How2.png";
import How2Webp from "../../assets/images/How2.webp";
import photography from "../../assets/images/photography.png";
import graphic from "../../assets/images/graphic.png";
import mixed from "../../assets/images/mixed.png";
import painting from "../../assets/images/painting.png";
import placeholder from "../../assets/images/placeholder.png";
import backgroundImage from "../../assets/images/backgroundImage.png";
import wallBuilderBanner from "../../assets/images/wallBuilderBanner.png";
import wallBuilderBannerWebp from "../../assets/images/wallBuilderBanner.webp";
import Desktop from "../../assets/images/desktop.png";
import DesktopWebp from "../../assets/images/desktop.webp";
import Mobile from "../../assets/images/mobile.png";
import MobileWebp from "../../assets/images/mobile.webp";
import { Text, Header } from "../../components/Text";
import { Image } from "../../components";
import ProductCard from "../../components/ProductCard";
import ScrollCarousel from "../../components/ScrollCarousel";
import { useAppState } from "../../state";
import { ArtistButton, HeroImage, HeroTitle, ImageAndButtonWrapper } from "./styles";
import { getUsers } from "../../services/Firebase";
import { User } from "../../types/user";
import RollingTextBanner from "../../components/RollingTextBanner";
import { Icon } from "../../components/Icon";
import {
  ProductCardProductFragment,
  ProductSortKeys,
  useGetAllCollectionsQuery,
  useGetProductsForProductCardQuery,
} from "../../generated/storefront";
import { getMonthsDate, getThisMonthsDate } from "../../helpers/time";
import { appendArtistsToProducts } from "../../helpers/product";
import CollectionCard from "../../components/Card/Collection";

type ProductWithArtist = ProductCardProductFragment & { artist: User | null };

const Home = () => {
  const { navHeight, setNavTransparent, isMobileScreen, selectedCountry, showCookieBanner, toggleGalleryWallBuilderIsOpen } =
    useAppState();
  const [mostPopular, setMostPopular] = useState<ProductWithArtist[]>();
  const [trending, setTrending] = useState<ProductWithArtist[]>();
  const [ourPicks, setOurPicks] = useState<ProductWithArtist[]>();
  const [communityPicks, setCommunityPicks] = useState<ProductWithArtist[]>();
  const [artists, setArtists] = useState<User[]>();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showTextPadding, setShowTextPadding] = useState(false);
  const [initialWindowHeight] = useState(window.innerHeight);
  const windowHeight = isMobileScreen ? initialWindowHeight : window.innerHeight;
  const { data: collectionsData } = useGetAllCollectionsQuery({ variables: { limit: 12 } });

  const { data: mostPopularData, refetch: refetchMostPopular } = useGetProductsForProductCardQuery({
    variables: {
      sortKey: ProductSortKeys.BestSelling,
      country: selectedCountry,
      limit: 20,
    },
  });
  const { data: trendingData, refetch: refetchTrending } = useGetProductsForProductCardQuery({
    variables: {
      query: `product_type:${getThisMonthsDate()} OR product_type:${getMonthsDate(
        subMonths(new Date(), 1)
      )} OR product_type:${getMonthsDate(subMonths(new Date(), 2))} OR product_type:${getMonthsDate(subMonths(new Date(), 3))}`,
      sortKey: ProductSortKeys.BestSelling,
      country: selectedCountry,
      limit: 20,
    },
  });
  const { data: ourPicksData, refetch: refetchOurPicks } = useGetProductsForProductCardQuery({
    variables: {
      sortKey: ProductSortKeys.BestSelling,
      query: `tag:pick product_type:${getThisMonthsDate()}`,
      country: selectedCountry,
      limit: 20,
    },
  });
  const { data: communityPicksData, refetch: refetchCommunityPicks } = useGetProductsForProductCardQuery({
    variables: {
      sortKey: ProductSortKeys.BestSelling,
      query: "tag:community_favourites",
      country: selectedCountry,
      limit: 20,
    },
  });

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
    setNavTransparent(window.scrollY === 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    setNavTransparent(window.scrollY === 0);
    return () => {
      setNavTransparent(false);
    };
  });

  useEffect(() => {
    const threshold = 100;
    setShowTextPadding(scrollPosition < threshold);
  }, [scrollPosition]);

  const getArtists = async () => {
    const { users } = await getUsers({
      filters: { hasProducts: true },
      orderBy: { field: "salesCount", direction: "desc" },
      limit: 10,
    });
    setArtists(users);
  };

  useEffect(() => {
    if (!artists) {
      getArtists();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artists]);

  useEffect(() => {
    const getProductsArtists = async () => {
      const productsWithArtists = await appendArtistsToProducts(mostPopularData?.products?.nodes || []);
      setMostPopular(productsWithArtists);
    };
    getProductsArtists();
  }, [mostPopularData]);

  useEffect(() => {
    const getProductsArtists = async () => {
      const productsWithArtists = await appendArtistsToProducts(trendingData?.products?.nodes || []);
      setTrending(productsWithArtists);
    };
    getProductsArtists();
  }, [trendingData]);

  useEffect(() => {
    const getProductsArtists = async () => {
      const productsWithArtists = await appendArtistsToProducts(ourPicksData?.products?.nodes || []);
      setOurPicks(productsWithArtists);
    };
    getProductsArtists();
  }, [ourPicksData]);

  useEffect(() => {
    const getProductsArtists = async () => {
      const productsWithArtists = await appendArtistsToProducts(communityPicksData?.products?.nodes || []);
      setCommunityPicks(productsWithArtists);
    };
    getProductsArtists();
  }, [communityPicksData]);

  useEffect(() => {
    refetchMostPopular({
      country: selectedCountry,
    });
    refetchTrending({
      country: selectedCountry,
    });
    refetchOurPicks({
      country: selectedCountry,
    });
    refetchCommunityPicks({
      country: selectedCountry,
    });
  }, [selectedCountry, refetchMostPopular, refetchTrending, refetchOurPicks, refetchCommunityPicks]);

  const interiors = collectionsData?.collections.nodes.filter((collection) => collection.image?.url) || [];

  return (
    <>
      <Helmet>
        <title>GoodMood</title>
        <meta
          name="description"
          content="Support independent artists and fill your space with work that inspires, entertains and resonates with you"
        />
      </Helmet>
      <div style={{ height: windowHeight - navHeight - (isMobileScreen ? 50 : 0) }}>
        <HeroImage
          src={isMobileScreen ? Mobile : Desktop}
          webp={isMobileScreen ? MobileWebp : DesktopWebp}
          height={windowHeight - (isMobileScreen ? 50 : 0)}
          alt="hero"
        />
        {/* <Veil height={windowHeight} /> */}
        <Box
          width="100%"
          padding={{ xs: "64px 24px 40px", md: "48px 40px" }}
          style={{ position: "absolute", bottom: isMobileScreen ? 50 : 0 }}
        >
          <Stack direction="row" gap={2} alignItems="end" justifyContent="space-between">
            <Stack gap={{ xs: 2, md: 3 }}>
              <HeroTitle>Summer sale</HeroTitle>
              <Typography fontSize={{ xs: 16, md: 18 }} color={colors.white} width={{ xs: "100%", md: "55%" }}>
                {isMobileScreen
                  ? "Fill your space with art that inspires, entertains and resonates with you"
                  : "Support independent artists and fill your space with art that inspires, entertains and resonates with you"}
              </Typography>
              <Link
                to={{
                  pathname: "/shop",
                }}
              >
                <Button size="large" fullWidth={isMobileScreen} secondary>
                  Shop 25% off
                </Button>
              </Link>
            </Stack>

            {!isMobileScreen && (
              <a href="https://www.reviews.co.uk/company-reviews/store/www.goodmoodprints.io" target="_blank" rel="noreferrer">
                <Stack
                  gap={1}
                  paddingBottom={showTextPadding && showCookieBanner ? 18 : 0}
                  style={{ transition: "padding-bottom 0.2s ease" }}
                >
                  <Text size={16} color={colors.white} style={{ whiteSpace: "nowrap" }}>
                    Excellent · 4.8 star rating
                  </Text>
                  <Stack direction="row" gap={1} justifyContent={isMobileScreen ? "start" : "end"}>
                    {[
                      ...Array(5)
                        .fill("")
                        .map((r, i) => <Icon key={i} icon="star" size={16} fill={colors.white} />),
                    ]}
                  </Stack>
                </Stack>
              </a>
            )}
          </Stack>
        </Box>
      </div>

      {isMobileScreen && (
        <Container background={colors.beige} padding="40px">
          <a href="https://www.reviews.co.uk/company-reviews/store/www.goodmoodprints.io" target="_blank" rel="noreferrer">
            <Flex direction="column" alignItems="center" gap={8}>
              <Text size={18}>Excellent · 4.8 star rating</Text>
              <Flex gap={6} justifyContent={isMobileScreen ? "start" : "end"}>
                {[
                  ...Array(5)
                    .fill("")
                    .map((r, i) => <Icon key={i} icon="star" size={16} fill={colors.peach} />),
                ]}
              </Flex>
            </Flex>
          </a>
        </Container>
      )}

      <Stack gap={{ xs: 5, md: 8 }} paddingTop={{ xs: 5, md: 5 }} paddingBottom={{ xs: 5, md: 8 }}>
        {/* OUR PICKS */}
        <ScrollCarousel
          header={`${format(new Date(), "MMMM")}'s Picks`}
          cta={{
            link: "/shop?month=" + getThisMonthsDate(),
            text: "Shop all",
          }}
        >
          {ourPicks?.map((product) => <ProductCard product={product} artist={product.artist} key={`picks-${product.id}`} />) ||
            []}
        </ScrollCarousel>

        {/* GALLERY WALLS */}
        <ScrollCarousel
          header="Gallery walls"
          cta={{
            link: "/gallery-walls",
            text: "View all",
          }}
          slideWidth={isMobileScreen ? "60%" : "450px"}
        >
          {interiors.map((collection) => <CollectionCard collection={collection} />) || []}
        </ScrollCarousel>

        {/* COMMUNITY PICKS */}
        <ScrollCarousel
          header="Community picks"
          cta={{
            link: "/shop/collections/community-favourites",
            text: "Shop all",
          }}
        >
          {communityPicks?.map((product) => (
            <ProductCard product={product} artist={product.artist} key={`community-picks-${product.id}`} />
          )) || []}
        </ScrollCarousel>

        {/* <Container padding={isMobileScreen ? "16px 0" : "50px 0 0"} style={{ justifyContent: "center" }}>
        <div
          style={{
            backgroundImage: `url(${giftCardBanner})`,
            backgroundSize: "cover",
            padding: isMobileScreen ? "16px" : "72px 40px",
          }}
        >
          <Link
            to={{
              pathname: `/products/${getIdNumber(giftCardId)}`,
            }}
            style={{ position: "relative" }}
          >
            <h2 style={{ fontSize: isMobileScreen ? 32 : 80, lineHeight: "88%", width: "35%", marginBottom: "36px" }}>
              Can't decide?
            </h2>
            <Button secondary size={isMobileScreen ? "small" : "large"}>
              Shop gift cards
            </Button>
          </Link>
        </div>
      </Container> */}

        {/* TRENDING */}

        <ScrollCarousel
          header="Trending"
          cta={{
            link: `/shop?month=${getThisMonthsDate()},${getMonthsDate(subMonths(new Date(), 1))},${getMonthsDate(
              subMonths(new Date(), 2)
            )},${getMonthsDate(subMonths(new Date(), 3))}`,
            text: "Shop all",
          }}
        >
          {trending?.map((product) => <ProductCard product={product} artist={product.artist} key={`trending-${product.id}`} />) ||
            []}
        </ScrollCarousel>

        {/* MOST POPULAR */}

        <ScrollCarousel
          header="Most popular"
          cta={{
            link: "/shop",
            text: "Shop all",
          }}
        >
          {mostPopular?.map((product) => (
            <ProductCard product={product} artist={product.artist} key={`popular-${product.id}`} />
          )) || []}
        </ScrollCarousel>
      </Stack>

      {!isMobileScreen && (
        <Box
          paddingX={{ xs: 2, md: 5 }}
          paddingY={{ xs: 5, md: 8 }}
          width="100%"
          sx={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover" }}
        >
          <Box border={`3px solid ${colors.white}`}>
            <Box bgcolor={colors.beige}>
              <Stack direction={{ xs: "column-reverse", md: "row" }}>
                <Stack
                  gap={2}
                  width={{ xs: "100%", md: "50%" }}
                  alignItems="start"
                  justifyContent="end"
                  padding={{ xs: 3, md: 5 }}
                >
                  <Typography variant="h2" fontSize={{ xs: 32, md: 48 }}>
                    Your perfect wall
                  </Typography>
                  <Typography>Visualise pieces in your own space, or mix and match with ours</Typography>

                  <Button secondary onClick={toggleGalleryWallBuilderIsOpen}>
                    Gallery wall builder
                  </Button>
                </Stack>
                <Stack width={{ xs: "100%", md: "50%" }}>
                  <WebpImage src={wallBuilderBanner} webp={wallBuilderBannerWebp} alt="gallery wall interior" />
                </Stack>
              </Stack>
            </Box>
          </Box>
        </Box>
      )}

      {/* THE ARTISTS */}
      <Box bgcolor={colors.white} paddingTop={{ xs: 0, md: 8 }} width="100%">
        <ScrollCarousel
          header="Artists"
          cta={{
            link: "/artists",
            text: "View all",
          }}
          slideWidth={isMobileScreen ? "95%" : "450px"}
        >
          {artists?.map((artist) => (
            <div key={artist.id} style={{ height: "100%" }}>
              <Link to={`/artists/${artist.permalink || artist.id}`}>
                <Box height={{ xs: 300, md: 500 }} overflow="hidden" position="relative">
                  <Image src={artist.image || placeholder} alt={artist.firstName} />
                  <ArtistButton>
                    <Button secondary>
                      <>
                        {artist.firstName} {artist.lastName}
                      </>
                    </Button>
                  </ArtistButton>
                </Box>
              </Link>
            </div>
          )) || []}
        </ScrollCarousel>
      </Box>

      {/* SHOP BY */}
      {isMobileScreen ? (
        <Box paddingY={5}>
          <ScrollCarousel
            header="Shop by"
            cta={{
              link: "/shop",
              text: "Shop all",
            }}
            slideWidth="95%"
          >
            <ImageAndButtonWrapper key="photography">
              <Link
                to={{
                  pathname: "/shop",
                  search: "?medium=photography",
                }}
              >
                <Image src={photography} alt="photography" />
                <Button secondary size="medium">
                  Photography
                </Button>
              </Link>
            </ImageAndButtonWrapper>

            <ImageAndButtonWrapper key="graphic">
              <Link
                to={{
                  pathname: "/shop",
                  search: "?medium=graphic_design",
                }}
              >
                <Image src={graphic} alt="graphic" />
                <Button secondary size="medium">
                  Graphic Design
                </Button>
              </Link>
            </ImageAndButtonWrapper>

            <ImageAndButtonWrapper key="mixed">
              <Link
                to={{
                  pathname: "/shop",
                  search: "?medium=mixed_media",
                }}
              >
                <Image src={mixed} alt="mixed" />
                <Button secondary size="medium">
                  Mixed Media
                </Button>
              </Link>
            </ImageAndButtonWrapper>

            <ImageAndButtonWrapper key="painting">
              <Link
                to={{
                  pathname: "/shop",
                  search: "?medium=painting",
                }}
              >
                <Image src={painting} alt="painting" />
                <Button secondary size="medium">
                  Painting
                </Button>
              </Link>
            </ImageAndButtonWrapper>
          </ScrollCarousel>
        </Box>
      ) : (
        <Box paddingX={5} paddingY={8} width="100%">
          <Stack gap={4}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Header type="h2">Shop by</Header>
              <Link to="/shop">
                <Button secondary>Shop all</Button>
              </Link>
            </Stack>

            <Stack gap={2} direction="row">
              <ImageAndButtonWrapper>
                <Link
                  to={{
                    pathname: "/shop",
                    search: "?medium=photography",
                  }}
                >
                  <Image src={photography} alt="photography" />
                  <Button secondary>Photography</Button>
                </Link>
              </ImageAndButtonWrapper>

              <ImageAndButtonWrapper>
                <Link
                  to={{
                    pathname: "/shop",
                    search: "?medium=mixed_media",
                  }}
                >
                  <Image src={mixed} alt="mixed" />
                  <Button secondary>Mixed Media</Button>
                </Link>
              </ImageAndButtonWrapper>

              <ImageAndButtonWrapper>
                <Link
                  to={{
                    pathname: "/shop",
                    search: "?medium=graphic_design",
                  }}
                >
                  <Image src={graphic} alt="graphic" />
                  <Button secondary>Graphic Design</Button>
                </Link>
              </ImageAndButtonWrapper>

              <ImageAndButtonWrapper key="painting">
                <Link
                  to={{
                    pathname: "/shop",
                    search: "?medium=painting",
                  }}
                >
                  <Image src={painting} alt="painting" />
                  <Button secondary size="medium">
                    Painting
                  </Button>
                </Link>
              </ImageAndButtonWrapper>
            </Stack>
          </Stack>
        </Box>
      )}

      <Box bgcolor={colors.beige} paddingX={{ xs: 2, md: 5 }} paddingY={{ xs: 5, md: 8 }} width="100%">
        <Container padding="0 0 32px">
          <Header type="h2">How it works</Header>
        </Container>
        <Stack gap={3} rowGap={6} direction={{ xs: "column", md: "row" }}>
          <Stack width={{ xs: "100%", md: "50%" }}>
            <WebpImage src={How} webp={HowWebp} alt="create" style={{ width: "100%", height: "auto" }} />
            <Header type="h3" margin="24px 0 12px">
              Artists create and upload, risk free
            </Header>
            <Text size={18} margin="0 0 16px">
              Artists upload their work, set their price and we take care of the rest. Printing, packing, sending – we’ve got it.
              There's no costs and no complications to uploading your work, so you can share as much as you want.
            </Text>
            <Link to="/upload">
              <Button secondary>Upload and sell</Button>
            </Link>
          </Stack>
          <Stack width={{ xs: "100%", md: "50%" }}>
            <WebpImage src={How2} webp={How2Webp} alt="size" style={{ width: "100%", height: "auto" }} />
            <Header type="h3" margin="24px 0 12px">
              We launch monthly collections
            </Header>
            <Text size={18} margin="0 0 16px">
              We review every submission and showcase our favourite new pieces on our homepage, in monthly collections – think of
              them as rolling pop-up exhibitions on our platform, which keeps the site fresh for our audience.
            </Text>
            <Link to="/shop">
              <Button secondary>Shop now</Button>
            </Link>
          </Stack>
        </Stack>
      </Box>
      <RollingTextBanner
        text="zero effort · experiment more · risk free · waste nothing · get paid · "
        background={colors.purple}
        color={colors.black}
        padding={14}
        size={isMobileScreen ? 12 : 16}
      />
    </>
  );
};

export default Home;
