import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { Wishlist } from "../../types/wishlist";
import { db } from ".";

export const getWishlists = async (customerId: string) => {
  try {
    const wishlistsRef = collection(db, "wishlists");
    const wishlists = query(wishlistsRef, ...[where("ownerId", "==", customerId)], orderBy("createdAt", "desc"), limit(100));
    const documentSnapshots = await getDocs(wishlists);
    const docs: Wishlist[] = [];
    documentSnapshots.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() } as Wishlist);
    });
    return docs;
  } catch (error) {
    console.error("Error getting wishlists: ", error);
    throw error;
  }
};

export const getSharedWishlists = async (customerId: string, customerEmail: string) => {
  try {
    const wishlistsRef = collection(db, "wishlists");
    const wishlists = query(
      wishlistsRef,
      ...[
        where("collaborators", "array-contains", {
          id: customerId,
          email: customerEmail,
          status: "accepted",
        }),
      ],
      orderBy("createdAt", "desc"),
      limit(100)
    );
    const documentSnapshots = await getDocs(wishlists);
    const docs: Wishlist[] = [];
    documentSnapshots.forEach((doc) => {
      docs.push({ id: doc.id, ...doc.data() } as Wishlist);
    });
    return docs;
  } catch (error) {
    console.error("Error getting shared wishlists: ", error);
    throw error;
  }
};

export const createWishlist = async (wishlist: Omit<Wishlist, "id" | "createdAt" | "updatedAt">) => {
  try {
    const wishlistsRef = collection(db, "wishlists");
    const newWishlistRef = await addDoc(wishlistsRef, { ...wishlist, createdAt: Timestamp.now(), updatedAt: Timestamp.now() });
    const docSnap = await getDoc(newWishlistRef);
    if (docSnap.data()) {
      return { id: docSnap.id, ...docSnap.data() } as Wishlist;
    }
  } catch (error) {
    console.error("Error adding wishlist document: ", error);
    throw error;
  }
};

export const updateWishlist = async (wishlistId: string, updatedFields: Partial<Wishlist>) => {
  try {
    const wishlistRef = doc(db, "wishlists", wishlistId);
    await updateDoc(wishlistRef, { ...updatedFields, updatedAt: Timestamp.now() });
    const docSnap = await getDoc(wishlistRef);
    return { id: docSnap.id, ...docSnap.data() } as Wishlist;
  } catch (error) {
    console.error("Error updating wishlist document: ", error);
    throw error;
  }
};

export const addProductToWishlist = async (wishlistId: string, productId: string) => {
  try {
    const wishlistRef = doc(db, "wishlists", wishlistId);
    const docSnap = await getDoc(wishlistRef);
    const wishlist = { id: docSnap.id, ...docSnap.data() } as Wishlist;
    await updateDoc(wishlistRef, { productIds: [...wishlist.productIds, productId] });
    return wishlistId;
  } catch (error) {
    console.error("Error adding product to wishlist: ", error);
    throw error;
  }
};

export const removeProductFromWishlist = async (wishlistId: string, productId: string) => {
  try {
    const wishlistRef = doc(db, "wishlists", wishlistId);
    const docSnap = await getDoc(wishlistRef);
    const wishlist = { id: docSnap.id, ...docSnap.data() } as Wishlist;
    await updateDoc(wishlistRef, { productIds: wishlist.productIds.filter((id) => id !== productId) });
    return wishlistId;
  } catch (error) {
    console.error("Error removing product from wishlist: ", error);
    throw error;
  }
};

export const getWishlistById = async (wishlistId: string) => {
  try {
    const wishlistRef = doc(db, "wishlists", wishlistId);
    const docSnap = await getDoc(wishlistRef);
    return { id: docSnap.id, ...docSnap.data() } as Wishlist;
  } catch (error) {
    console.error("Error getting wishlist document: ", error);
    throw error;
  }
};

export const deleteWishlist = async (wishlistId: string) => {
  try {
    const wishlistRef = doc(db, "wishlists", wishlistId);
    await deleteDoc(wishlistRef);
    return wishlistId;
  } catch (error) {
    console.error("Error deleting wishlist document: ", error);
    throw error;
  }
};
