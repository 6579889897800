import { RefObject, useEffect, useState } from "react";

const useLoadMoreOnScroll = (ref: RefObject<HTMLDivElement>, callback: () => void, hasMore: boolean, loading: boolean) => {
  const scrollThreshold = 1000;
  const [loadMore, setLoadMore] = useState(false);

  const onScroll = () => {
    if (ref?.current) {
      setLoadMore(window.scrollY > ref?.current?.scrollHeight - scrollThreshold);
    }
  };

  useEffect(() => {
    if (hasMore && loadMore && !loading) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore]);

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });
};

export default useLoadMoreOnScroll;
