import { ChangeEvent, useMemo, useState } from "react";
import styled from "styled-components";
import { Box, Stack, Typography } from "@mui/material";
import { Text } from "../../components/Text";
import Button from "../../components/Button";
import { MediumNames, UploadState } from "../../types/product";
import { Card } from "./styles";
import { Container } from "../../components/Layout";
import { colors } from "../../theme";
import { getPrices } from "../../helpers/prices";
import { getCountDown, getNextMonth } from "../../helpers/time";
import { Icon } from "../../components/Icon";
import { formattedPrice } from "../../helpers/money";
import Checkbox from "../../components/Checkbox";
import TextLink from "../../components/TextLink";
import { useAppState } from "../../state";
import ErrorMessage from "../../components/Form/ErrorMessage";
import { getSizesToSell, sizeLabelsForCountry } from "../../helpers/product";
import { CurrencyCode } from "../../generated/storefront";

type Props = {
  state: UploadState;
  onSubmit: () => void;
  loading: boolean;
  campaign?: string;
  editMode?: boolean;
};

const Divider = styled.div`
  width: 100%;
  height: 1px;
  border: dashed 1px ${colors.grey60};
  margin: 8px 0;
`;

const StepFour = ({ state, onSubmit, loading, campaign, editMode }: Props) => {
  const { errors, countryOfUser } = useAppState();
  const [agreedTerms, setAgreedTerms] = useState(false);
  const prices = useMemo(() => getPrices(state.price), [state.price]);
  const sizesToSell = getSizesToSell(state.maxSize, state.ratio);
  const sizeLabels = sizeLabelsForCountry(countryOfUser);

  const onTermsChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAgreedTerms(event.target.checked);
  };

  return (
    <>
      <Box paddingX={3} paddingY={2} width="100%">
        <Box padding={3} bgcolor={colors.grey05}>
          <Stack gap={2}>
            <Typography variant="h3" component="h1">
              Review your listing
            </Typography>
            <Stack gap={1}>
              <Text size={20}>{state.title}</Text>
              <Text>{state.description}</Text>
            </Stack>
            <Stack direction="row">
              <Text>Medium:</Text>
              <Text margin="0 0 0 16px">{state.medium ? MediumNames[state.medium] : ""}</Text>
            </Stack>
            <Divider />
            <Stack gap={2} direction="row">
              <Stack gap={0.5} width="100%">
                <Text margin="0 0 16px 0">Unframed</Text>
                {sizesToSell.map((size) => (
                  <Card key={size}>
                    <Text as="span">{sizeLabels[size].shortHand}</Text>
                    <Text as="span">{formattedPrice(prices[size], CurrencyCode.Gbp)}</Text>
                  </Card>
                ))}
              </Stack>
              <Stack gap={0.5} width="100%">
                <Text margin="0 0 16px 0">Framed</Text>
                {sizesToSell.map((size) => (
                  <Card key={size}>
                    <Text as="span">{sizeLabels[size].shortHand}</Text>
                    <Text as="span">{formattedPrice(prices[`${size}Framed`], CurrencyCode.Gbp)}</Text>
                  </Card>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Box>
      {!campaign && !editMode && (
        <Container background={colors.purple20} padding="24px">
          <Stack alignItems="center" direction="row">
            <Icon icon="clock" size={24} />
            <Text size={14} margin="0 0 0 16px">
              This piece will be added to the {getNextMonth()} collection starting in {getCountDown()}
            </Text>
          </Stack>
        </Container>
      )}
      <Container padding="6px 24px 0" width={100}>
        {errors?.upload && <ErrorMessage errorMessage={errors.upload} />}
      </Container>

      <Box padding="48px 24px" width="100%">
        <Stack justifyContent="space-between" alignItems="center" width="100%" direction="row">
          {!editMode && (
            <Checkbox
              name="termsAndConditions"
              onChange={onTermsChange}
              label={
                <Typography fontSize={14}>
                  I agree to the{" "}
                  <TextLink to="/terms" openInNewTab>
                    Terms and Conditions
                  </TextLink>
                </Typography>
              }
            />
          )}
          <Button secondary onClick={onSubmit} loading={loading} disabled={!editMode && !agreedTerms}>
            {editMode ? "Save changes" : "Submit"}
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default StepFour;
