import { useEffect, useState } from "react";
import { match } from "ts-pattern";
import { Box, Stack } from "@mui/material";
import { AddRounded } from "@mui/icons-material";
import { WishlistProductFragment, useGetWishlistProductsLazyQuery } from "../../generated/storefront";
import { useAppState } from "../../state";
import Button from "../../components/Button";
import { Loader } from "../../components/Loader";
import { Customer } from "../../state/auth";
import NewWishlistModal from "./NewWishlistModal";
import { getSharedWishlists, getWishlists } from "../../services/Firebase/wishlists";
import { Wishlist as TWishlist } from "../../types/wishlist";
import EmptyState from "./EmptyState";
import Switch from "../../components/Switch";
import WishlistGrid from "./WishlistGrid";

const Authenticated = ({ customer }: { customer: Customer }) => {
  const { isMobileScreen, toggleGalleryWallBuilderIsOpen } = useAppState();
  const [showNewWishlistModal, setShowNewWishlistModal] = useState(false);
  const [wishlists, setWishlists] = useState<(TWishlist & { products: WishlistProductFragment[] })[]>([]);
  const [sharedWishlists, setSharedWishlists] = useState<(TWishlist & { products: WishlistProductFragment[] })[]>([]);
  const [getWishlistProducts] = useGetWishlistProductsLazyQuery();
  const [loading, setLoading] = useState(true);
  const [loadingShared, setLoadingShared] = useState(true);
  const [selectedTab, setSelectedTab] = useState<"mine" | "shared">("mine");

  const onNewWishlist = () => {
    setShowNewWishlistModal(true);
  };

  const getCustomerWishlists = async () => {
    setLoading(true);
    if (customer) {
      const lists = await getWishlists(customer.id);
      const listsWithProductsPromises = lists.map(async (list) => {
        if (list.productIds.length === 0) return { ...list, products: [] };
        const result = await getWishlistProducts({
          variables: {
            limit: 6,
            query: `id:${list.productIds.join(" OR ")}`,
          },
        });
        return {
          ...list,
          products: result.data?.products?.nodes || [],
        };
      });
      const listsWithProducts = await Promise.all(listsWithProductsPromises);
      setWishlists(listsWithProducts);
    }
    setLoading(false);
  };

  const getCustomerSharedWishlists = async () => {
    setLoadingShared(true);
    if (customer && customer.email) {
      const lists = await getSharedWishlists(customer.id, customer.email);
      const listsWithProductsPromises = lists.map(async (list) => {
        if (list.productIds.length === 0) return { ...list, products: [] };
        const result = await getWishlistProducts({
          variables: {
            limit: 6,
            query: `id:${list.productIds.join(" OR ")}`,
          },
        });
        return {
          ...list,
          products: result.data?.products?.nodes || [],
        };
      });
      const listsWithProducts = await Promise.all(listsWithProductsPromises);
      setSharedWishlists(listsWithProducts);
    }
    setLoadingShared(false);
  };

  useEffect(() => {
    getCustomerWishlists();
    getCustomerSharedWishlists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  useEffect(() => {
    if (!loading && !loadingShared && sharedWishlists.length > 0 && wishlists.length === 0) {
      setSelectedTab("shared");
    }
  }, [loading, loadingShared, wishlists, sharedWishlists]);

  return (
    <Box>
      <Stack gap={2} paddingX={{ xs: 2, md: 5 }} paddingBottom={5}>
        <Stack direction="row" alignItems="center" justifyContent={{ xs: "center", md: "space-between" }}>
          <Button tertiary onClick={onNewWishlist}>
            <Stack alignItems="center" direction="row" gap={0.75}>
              New wishlist
              <AddRounded />
            </Stack>
          </Button>
          {!isMobileScreen && (
            <Button tertiary onClick={toggleGalleryWallBuilderIsOpen} style={{ whiteSpace: "nowrap" }}>
              Build a gallery wall
            </Button>
          )}
        </Stack>

        {wishlists.length > 0 && sharedWishlists.length > 0 && (
          <Stack alignItems="center">
            <Switch
              fullWidth={isMobileScreen}
              options={[
                {
                  label: "Mine",
                  value: "mine",
                },
                {
                  label: "Shared",
                  value: "shared",
                },
              ]}
              selected={selectedTab}
              onChange={(value) => setSelectedTab(value as "mine" | "shared")}
            />
          </Stack>
        )}

        {loading || loadingShared ? (
          <Loader />
        ) : (
          <>
            {wishlists.length === 0 && sharedWishlists.length === 0 && <EmptyState />}

            {match(selectedTab)
              .with("mine", () => <WishlistGrid wishlists={wishlists} customer={customer} />)
              .with("shared", () => <WishlistGrid wishlists={sharedWishlists} customer={customer} />)
              .exhaustive()}
          </>
        )}
      </Stack>
      {customer && (
        <NewWishlistModal
          open={showNewWishlistModal}
          onClose={() => setShowNewWishlistModal(false)}
          customer={customer}
          redirectToWishlist
        />
      )}
    </Box>
  );
};

export default Authenticated;
