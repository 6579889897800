import { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";
import { User } from "../../../../types/user";
import ArtistCard from "../../../Card/Artist";
import { getUsers } from "../../../../services/Firebase";
import { Loader } from "../../../Loader";
import { Medium, MediumNames } from "../../../../types/product";
import Tabs from "../../../Tabs";

const ArtistsSearchTab = () => {
  const [defaultArtists, setDefaultArtists] = useState<User[]>();
  const [artists, setArtists] = useState<{ [key: string]: User[] }>({});
  const [selectedTab, setSelectedTab] = useState<Medium | null>(null);
  const mediums = Object.values(Medium);
  const mediumOptions = mediums.map((c) => ({ value: c, label: MediumNames[c] }));
  const mediumTabs = [{ label: "All", value: null }, ...mediumOptions];

  const fetchArtists = async (medium?: string) => {
    const { users } = await getUsers({
      filters: { hasProducts: true, ...(medium ? { mediums: medium } : {}) },
      orderBy: { field: "salesCount", direction: "desc" },
      limit: 9,
    });
    return users;
  };

  const getDefaultArtists = async () => {
    const artists = await fetchArtists();
    setDefaultArtists(artists);
  };

  const getMediumArtists = async (medium: Medium) => {
    const mediumArtists = await fetchArtists(medium);
    setArtists({
      ...artists,
      [medium]: mediumArtists,
    });
  };

  useEffect(() => {
    if (defaultArtists) return;
    getDefaultArtists();
  });

  useEffect(() => {
    if (selectedTab && !artists[selectedTab]) {
      getMediumArtists(selectedTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <Box>
      <Stack gap={1} direction="row" flexWrap="wrap">
        <Tabs
          onTabChange={(value) => setSelectedTab(value as Medium | null)}
          tabs={mediumTabs}
          selectedTab={selectedTab}
          style={{ overflow: "auto", justifyContent: "start" }}
        />
        <Grid container spacing={1} rowSpacing={2}>
          {defaultArtists ? (
            selectedTab && artists[selectedTab] ? (
              artists[selectedTab].map((artist) => (
                <Grid item xs={12 / 3} key={artist.id}>
                  <ArtistCard artist={artist} size="small" imageHeight="150px" borderRadius={12} />
                </Grid>
              ))
            ) : (
              defaultArtists.map((artist) => (
                <Grid item xs={12 / 3} key={artist.id}>
                  <ArtistCard artist={artist} size="small" imageHeight="150px" borderRadius={12} />
                </Grid>
              ))
            )
          ) : (
            <Loader />
          )}
        </Grid>
      </Stack>
    </Box>
  );
};

export default ArtistsSearchTab;
