import { useContext, useState } from "react";
import { sortBy, uniq } from "lodash";
import { Autocomplete, Box, Chip, FormLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Input from "../../components/Form/Input";
import TextArea from "../../components/Form/TextArea";
import Dropdown from "../../components/Form/Dropdown";
import { Medium, MediumNames } from "../../types/product";
import { UploadState } from "../../types/product";
import Button from "../../components/Button";
import AuthContext from "../../state/auth";
import useAdmin from "../Admin/useAdmin";
import { styleFilterOptions, subjectFilterOptions } from "../../state/constants";
import { StyledCheckbox } from "../../components/Checkbox";

type Props = {
  state: UploadState;
  setState: (state: Partial<UploadState>) => void;
  setArtistId?: (artistId?: string) => void;
  onStepChange: (step: number) => void;
  stepComplete: boolean;
};

const StepTwo = ({ state, setState, setArtistId, onStepChange, stepComplete }: Props) => {
  const { isAdmin } = useContext(AuthContext);
  const { handleArtistSearch, searchedUsers } = useAdmin();
  const mediums = Object.values(Medium);
  const [inputValue, setInputValue] = useState<string>("");
  const [options, setOptions] = useState<string[]>([]);

  const handleKeywordsInputChange = (_: any, newValue: string) => {
    setInputValue(newValue);
    setOptions([newValue]);
  };

  const handleKeywordsChange = (_: any, newValue: string[]) => {
    setInputValue("");
    // split keywords by comma or space
    const newKeywords = newValue
      .join(",")
      .split(/,| /)
      .filter((keyword: string) => keyword);
    // limit keywords to 6
    const uniqueKeywords = uniq(newKeywords);
    if (uniqueKeywords.length > 6) {
      setState({ keywords: uniqueKeywords.slice(0, 6) });
    } else {
      setState({ keywords: uniqueKeywords });
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    if (name === "gmTags" && value.length > 10) return;
    setState({ [name]: value });
  };

  const handleMediumChange = (event: { value: Medium }) => {
    setState({ medium: event.value });
  };

  const mediumOptions = mediums.map((c) => ({ value: c, label: MediumNames[c] }));

  const defaultMediumOptions = () => {
    if (state.medium) {
      return mediumOptions.find((medium) => state.medium === medium.value);
    }
  };

  const getTagName = (tag: string) => {
    const subject = subjectFilterOptions.find((option) => option.value === tag);
    const style = styleFilterOptions.find((option) => option.value === tag);
    return subject?.label || style?.label || tag;
  };

  return (
    <Box paddingX={3} paddingY={2} width="100%">
      <Stack gap={2}>
        <Typography variant="h3" component="h1">
          Create product listing
        </Typography>
        <Input
          label="Name of piece"
          placeholder="This will be the headline"
          id="title"
          name="title"
          onChange={handleChange}
          value={state.title}
        />
        <TextArea
          label="About piece"
          placeholder="This will be the supporting body copy beneath your header"
          name="description"
          maxLength={600}
          onChange={handleChange}
          value={state.description}
        />
        <Dropdown
          label="Choose medium"
          options={mediumOptions}
          onChange={handleMediumChange}
          defaultValue={defaultMediumOptions()}
        />
        <Stack gap={1}>
          <FormLabel>Tags</FormLabel>
          <Typography variant="caption" color="text.secondary">
            Choose the most appropriate to your piece
          </Typography>

          <Stack direction="row" gap={2}>
            <Select
              id="multiple-checkbox-tags"
              multiple
              variant="outlined"
              displayEmpty
              value={state.gmTags}
              name="gmTags"
              onChange={handleChange}
              renderValue={(selected) =>
                selected.length === 0
                  ? "Choose tags"
                  : selected.length > 3
                  ? `${selected
                      .slice(0, 3)
                      .map((s) => getTagName(s))
                      .join(", ")}...`
                  : `${selected.map((s) => getTagName(s)).join(", ")}`
              }
              sx={{ width: "100%" }}
            >
              {sortBy([...subjectFilterOptions, ...styleFilterOptions], (o) => o.label).map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={!state.gmTags.includes(option.value) && state.gmTags.length >= 10}
                >
                  <Stack direction="row" gap={1}>
                    <StyledCheckbox name="tags" checked={state.gmTags.findIndex((item) => item === option.value) >= 0} />
                    <Typography fontSize={14}>{option.label}</Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Stack>
        <Stack gap={1}>
          <FormLabel>Keywords</FormLabel>
          <Typography variant="caption" color="text.secondary">
            Add keywords to help buyers find your piece
          </Typography>
          <Autocomplete
            id="keywords-autocomplete"
            multiple
            freeSolo
            value={state.keywords}
            onChange={handleKeywordsChange}
            inputValue={inputValue}
            onInputChange={handleKeywordsInputChange}
            options={options}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="e.g. flowers, red, abstract" />}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
            }
            clearOnBlur={false}
          />
        </Stack>

        {isAdmin && setArtistId && (
          <Stack gap={1}>
            <FormLabel>Choose artist</FormLabel>
            <Autocomplete
              options={searchedUsers?.map((user) => ({ value: user.id, label: user.firstName + " " + user.lastName })) || []}
              onChange={(_, value) => setArtistId(value?.value)}
              renderInput={(params) => (
                <TextField {...params} style={{ zIndex: 0 }} onChange={(event) => handleArtistSearch(event)} />
              )}
            />
          </Stack>
        )}

        <Stack justifyContent="end">
          <Stack alignSelf="end">
            <Button secondary onClick={() => onStepChange(3)} disabled={!stepComplete} size="medium">
              Next
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default StepTwo;
