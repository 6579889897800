import { useState } from "react";
import { Stack, Typography } from "@mui/material";
import Button from "../../../components/Button";
import { CurrencyCode, ProductSortKeys, useGetAdminProductsLazyQuery } from "../../../generated/graphql";
import { getLowestPriceAdmin } from "../../../helpers/prices";
import { updateProductFramePrices } from "../../../helpers/scripts";
import { getIdNumber } from "../../../helpers/shopify";
import { getUserById } from "../../../services/Firebase";

const Extras = () => {
  const [getProducts] = useGetAdminProductsLazyQuery();
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const [afterCursor, setAfterCursor] = useState<string | undefined>();

  const generateCsvOfProducts = async () => {
    const { data } = await getProducts({
      variables: {
        limit: 250,
        query: "tag:favourite status:ACTIVE",
        sortKey: ProductSortKeys.CreatedAt,
        reverse: true,
      },
    });
    const products = data?.products?.nodes || [];
    const csvPromises = products.map(async (product) => {
      const { id, title, descriptionHtml, vendor, images, variants } = product;
      const idNumber = getIdNumber(id);
      const artist = await getUserById(vendor);
      if (!artist) return "";
      const lowestPrice = getLowestPriceAdmin(variants.nodes);
      const description = descriptionHtml?.replace(/<[^>]*>?/gm, "");
      return `${idNumber},"${title}","${description}",in_stock,https://www.goodmoodprints.io/products/${idNumber},${images.nodes[0].src},${lowestPrice} ${CurrencyCode.Gbp},no, , ,${artist.firstName} ${artist?.lastName}\n`;
    });
    const csv = (await Promise.all(csvPromises)).join("");
    const csvString = `id,title,description,availability,link,image link,price,identifier exists,gtin,mpn,brand\n${csv}`;
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "products.csv";
    a.click();
  };

  // const updateProducts = async () => {
  //   const { data } = await getProducts({
  //     fetchPolicy: "network-only",
  //     variables: {
  //       limit: 50,
  //       query: "status:ACTIVE tag:favourite",
  //       afterCursor: "eyJsYXN0X2lkIjo4MzI3NjY4OTkwMTY2LCJsYXN0X3ZhbHVlIjo4MzI3NjY4OTkwMTY2fQ==",
  //     },
  //   });
  //   const products = data?.products?.nodes || [];
  //   console.log("products", products);
  //   console.log("updateProducts", data?.products?.pageInfo);
  //   updateProductsVariants(products);
  // };

  const updateProducts = async () => {
    setLoading(true);
    const { data } = await getProducts({
      fetchPolicy: "no-cache",
      variables: {
        limit: 200,
        afterCursor,
        // query: "product:size:12X12",
      },
    });
    if (data?.products) {
      const products = data.products.nodes;
      console.log("products", products, data.products.pageInfo);
      setAfterCursor(data.products.pageInfo.endCursor || undefined);
      await updateProductFramePrices(products);
      setCounter(counter + 1);
    }
    setLoading(false);
  };

  return (
    <Stack gap={2}>
      <h1>Extras</h1>
      <Stack gap={2} direction="row">
        <Button secondary size="medium" onClick={generateCsvOfProducts}>
          Download CSV of recent favourite products
        </Button>
        <Button secondary size="medium" onClick={updateProducts} loading={loading}>
          Update products
        </Button>
        <Typography variant="h1">{counter}</Typography>
      </Stack>
    </Stack>
  );
};

export default Extras;
