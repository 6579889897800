import axios from "axios";
import { Market } from "../../types/localisation";
import { requestHandler } from ".";
import { CountryCode } from "../../generated/storefront";

export const getMarkets = async () => {
  try {
    const request = async () => await axios.post(`/.netlify/functions/getMarkets`);
    const { markets }: { markets: { nodes: Market[] } } = await requestHandler(request);
    return markets.nodes.filter((m) => m.name !== "International");
  } catch (error) {
    console.error(`Error getting markets: ${error}`);
    throw error;
  }
};

export const getMarketByGeography = async ({ country }: { country: CountryCode }) => {
  try {
    const request = async () => await axios.post(`/.netlify/functions/getMarketByGeography?country=${country}`);
    const { marketByGeography }: { marketByGeography: Market } = await requestHandler(request);
    return marketByGeography;
  } catch (error) {
    console.error(`Error getting market by geography: ${error}`);
    throw error;
  }
};
