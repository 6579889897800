import { useEffect, useRef, useState } from "react";
import { useDebounce } from "react-use";
import { Box, FilledInput, InputAdornment, Stack, Typography } from "@mui/material";
import { CloseRounded, Search } from "@mui/icons-material";
import { BigSearch, SearchBarWrapper, Veil } from "../styles";
import { colors } from "../../../theme";
import { useSearch } from "./useSearch";
import TrendingSearchTab from "./Tabs/Trending";
import ArtistsSearchTab from "./Tabs/Artists";
import ThemesSearchTab from "./Tabs/Themes";
import GalleryWallsSearchTab from "./Tabs/GalleryWalls";
import SearchResults from "./SearchResults";
import { useAppState } from "../../../state";

type Props = {
  setSearchOpen: (isOpen: boolean) => void;
};

const SearchBar = ({ setSearchOpen }: Props) => {
  const vielRef = useRef<HTMLDivElement>(null);
  const [inputValue, setInputValue] = useState("");
  const [selectedTab, setSelectedTab] = useState("trending");
  const { isMobileScreen } = useAppState();
  const {
    defaultArtists,
    artists,
    mediums,
    defaultProducts,
    loadingDefaultProducts,
    products,
    loadingProducts,
    onSearchChange,
    searchQs,
  } = useSearch();

  const closeSearchBar = () => setSearchOpen(false);

  const handleSearch = (event: any) => {
    setInputValue(event.target.value);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    window.location.href = `/search?qs=${searchQs}`;
  };

  useDebounce(() => onSearchChange(inputValue), 500, [inputValue]);

  useEffect(() => {
    setTimeout(() => {
      if (vielRef.current) {
        vielRef.current.style.backgroundColor = `${colors.black}70`;
      }
    }, 200);
  });

  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => {
      document.body.style.overflowY = "unset";
    };
  });

  const searchTabs = [
    {
      title: "Trending",
      value: "trending",
    },
    {
      title: "Gallery walls",
      value: "gallery-walls",
    },
    {
      title: "Artists",
      value: "artists",
    },
    {
      title: "Themes",
      value: "themes",
    },
  ];

  return (
    <BigSearch>
      <Veil ref={vielRef} onClick={closeSearchBar} />
      <SearchBarWrapper>
        <Box padding={2} position="sticky" top={0} zIndex={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
            <form onSubmit={onSubmit} style={{ width: "100%" }}>
              <FilledInput
                name="search"
                id="search"
                hiddenLabel
                autoFocus
                fullWidth
                sx={{ paddingY: 1, paddingX: 3, input: { paddingLeft: 1 } }}
                value={inputValue}
                onChange={handleSearch}
                placeholder="Search"
                startAdornment={
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end" sx={{ marginRight: -1 }}>
                    <CloseRounded
                      color="action"
                      fontSize="small"
                      onClick={() => setInputValue("")}
                      style={{ cursor: "pointer", visibility: inputValue ? "visible" : "hidden" }}
                    />
                  </InputAdornment>
                }
              />
            </form>
            {isMobileScreen && <CloseRounded fontSize="small" onClick={closeSearchBar} />}
          </Stack>
        </Box>

        <Box paddingX={2} paddingY={0} height="calc(100% - 91px)">
          {searchQs ? (
            <SearchResults
              artists={artists}
              products={products}
              searchQs={searchQs}
              loading={loadingProducts}
              isMobile={isMobileScreen}
            />
          ) : (
            <Stack gap={3} direction="row" width="100%" height="100%">
              {!isMobileScreen && (
                <Stack gap={0.5} width="40%">
                  {searchTabs.map((tab) => (
                    <Box
                      padding={2}
                      borderRadius={4}
                      bgcolor={selectedTab === tab.value ? colors.grey05 : colors.white}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography fontSize={20} onClick={() => setSelectedTab(tab.value)}>
                        {tab.title}
                      </Typography>
                    </Box>
                  ))}
                </Stack>
              )}
              <Stack gap={3} paddingBottom={4} width={{ xs: "100%", md: "60%" }} height="100%" sx={{ overflowY: "auto" }}>
                {isMobileScreen ? (
                  <Stack gap={3}>
                    <TrendingSearchTab
                      artists={defaultArtists}
                      defaultProducts={defaultProducts}
                      loadingDefaultProducts={loadingDefaultProducts}
                      isMobile
                    />
                    <Stack gap={2}>
                      <Typography variant="h4">Gallery walls</Typography>
                      <GalleryWallsSearchTab isMobile />
                    </Stack>
                    <ThemesSearchTab mediums={mediums} />
                  </Stack>
                ) : (
                  <>
                    {selectedTab === "trending" && (
                      <TrendingSearchTab
                        artists={defaultArtists}
                        defaultProducts={defaultProducts}
                        loadingDefaultProducts={loadingDefaultProducts}
                      />
                    )}
                    {selectedTab === "gallery-walls" && <GalleryWallsSearchTab />}
                    {selectedTab === "artists" && <ArtistsSearchTab />}
                    {selectedTab === "themes" && <ThemesSearchTab mediums={mediums} />}
                  </>
                )}
              </Stack>
            </Stack>
          )}
        </Box>
      </SearchBarWrapper>
    </BigSearch>
  );
};

export default SearchBar;
