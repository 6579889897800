import { ReactNode } from "react";
import styled from "styled-components";
import { RadioProps, FormControlLabel, FormHelperText, Radio as MuiRadio, Stack } from "@mui/material";
import { colors } from "../../theme";

const Icon = styled("span")(() => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  backgroundColor: colors.cream,
  border: `1px solid ${colors.grey20}`,
  ".Mui-focusVisible &": {
    border: `1px solid ${colors.grey40}`,
  },
  "input:hover ~ &": {
    border: `1px solid ${colors.grey40}`,
  },
  "input:disabled ~ &": {
    backgroundColor: colors.cream,
    border: `1px solid ${colors.grey10}`,
    boxShadow: "none",
    cursor: "not-allowed",
  },
}));

const CheckedIcon = styled(Icon)({
  position: "relative",
  border: `1px solid ${colors.black}`,
  "&::after": {
    content: '""',
    display: "block",
    width: 11,
    height: 11,
    backgroundColor: colors.black,
    borderRadius: "50%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    pointerEvents: "none",
    "input:disabled ~ &": {
      backgroundColor: colors.grey40,
      cursor: "not-allowed",
    },
  },
});

const Radio = ({ label, error, ...props }: RadioProps & { label?: string | ReactNode; error?: string }) => {
  return (
    <Stack gap={0.5}>
      <FormControlLabel
        sx={{ marginRight: 0, marginLeft: 0, color: props.disabled ? colors.grey10 : colors.black }}
        label={label}
        control={<MuiRadio disableRipple color="default" checkedIcon={<CheckedIcon />} icon={<Icon />} {...props} />}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </Stack>
  );
};

export default Radio;
