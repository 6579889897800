import { useState } from "react";
import { getUsers, searchArtists } from "../../services/Firebase";
import { User } from "../../types/user";

const useAdmin = () => {
  const [users, setUsers] = useState<User[]>();
  const [searchedUsers, setSearchedUsers] = useState<User[] | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date | null>();
  const [artistSearchValue, setArtistSearchValue] = useState("");

  const fetchUsers = async () => {
    const { users } = await getUsers({ filters: { hasProducts: true } });
    setUsers(users);
  };

  const handleArtistSearch = async ({ target }: { target: { value: string } }) => {
    if (target.value) {
      setArtistSearchValue(target.value);
      const searchedUsers = await searchArtists(target.value, true);
      setSearchedUsers(searchedUsers);
    } else {
      setSearchedUsers(null);
    }
  };

  return {
    users,
    searchedUsers,
    fetchUsers,
    artistSearchValue,
    handleArtistSearch,
    selectedMonth,
    setSelectedMonth,
  };
};

export default useAdmin;
