import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";

export const trackEvent = (eventName: string, data?: Record<string, string>) => {
  ReactPixel.track(eventName, data);
  ReactGA4.event({
    category: "User",
    action: eventName,
    label: data && Object.values(data)[0],
  });
};
