import { match } from "ts-pattern";
import { colors, fonts } from "../../theme";

export enum Variant {
  Standard = "standard",
  Contrast = "contrast",
  Round = "round",
}

export const labelStyles = (variant: Variant) => `
  color: ${match(variant)
    .with(Variant.Standard, () => colors.black)
    .with(Variant.Contrast, () => colors.white)
    .with(Variant.Round, () => colors.black)
    .exhaustive()};
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 4px;
`;

export const inputStyles = (variant: Variant) => `
  padding: 12px 16px;
  font-family: ${fonts.brand};
  color: ${match(variant)
    .with(Variant.Standard, () => colors.black)
    .with(Variant.Contrast, () => colors.white)
    .with(Variant.Round, () => colors.black)
    .exhaustive()};
  background-color: ${match(variant)
    .with(Variant.Standard, () => colors.white)
    .with(Variant.Contrast, () => colors.grey80)
    .with(Variant.Round, () => colors.white)
    .exhaustive()};
  border: 1px solid ${match(variant)
    .with(Variant.Standard, () => colors.grey10)
    .with(Variant.Contrast, () => colors.grey80)
    .with(Variant.Round, () => colors.grey10)
    .exhaustive()};
  border-radius: ${match(variant)
    .with(Variant.Standard, () => "8px")
    .with(Variant.Contrast, () => "100px")
    .with(Variant.Round, () => "100px")
    .exhaustive()};
  font-size: 16px;
  line-height: ${match(variant)
    .with(Variant.Round, () => "22px")
    .otherwise(() => "24px")};
  outline: none;
  width: 100%;

  &::placeholder {
    color: ${colors.grey20};
  }

  &:hover {
    color: ${match(variant)
      .with(Variant.Standard, () => colors.grey80)
      .with(Variant.Contrast, () => colors.white)
      .with(Variant.Round, () => colors.grey80)
      .exhaustive()};
    border: 1px solid ${colors.grey40};
  }

  &:focus,
  &:active {
    color: ${match(variant)
      .with(Variant.Standard, () => colors.black)
      .with(Variant.Contrast, () => colors.white)
      .with(Variant.Round, () => colors.black)
      .exhaustive()};
    border: 1px solid ${match(variant)
      .with(Variant.Standard, () => colors.black)
      .with(Variant.Contrast, () => colors.white)
      .with(Variant.Round, () => colors.black)
      .exhaustive()};
  }
`;

export const errorStyles = `
  color: ${colors.error};
  border-color: ${colors.error}
`;
