import axios from "axios";
import axiosRetry from "axios-retry";

axiosRetry(axios, { retries: 3 });

export const uploadImageToShopify = async (filename: string, file: File) => {
  try {
    const { data } = await axios.post(`/.netlify/functions/stagedUploadsCreate`, { filename });

    if (data) {
      const [{ url, parameters }] = data.stagedUploadsCreate.stagedTargets as {
        url: string;
        parameters: { name: string; value: string }[];
      }[];

      const formData = new FormData();

      parameters.forEach(({ name, value }) => {
        formData.append(name, value);
      });

      formData.append("file", file);

      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const key = parameters.find((p) => p.name === "key");
        if (!key) {
          throw new Error("No key returned from shopify");
        }
        return `${url}${key.value}`;
      }
    }
  } catch (error) {
    console.error(`Error uploading image to shopify: ${error}`);
    throw error;
  }
};
