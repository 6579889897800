import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { match } from "ts-pattern";
import { Box, Stack } from "@mui/material";
import { getUsers } from "../../services/Firebase";
import PageHeader from "../../components/PageHeader";
import { Container, Grid } from "../../components/Layout";
import { User } from "../../types/user";
import artistsHero from "../../assets/images/ArtistPage.png";
import artistsHeroWebp from "../../assets/images/ArtistPage.webp";
import { colors } from "../../theme";
import { useAppState } from "../../state";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore";
import { Loader } from "../../components/Loader";
import ArtistProducts from "./ArtistProducts";
import Tabs from "../../components/Tabs";
import { Frame, Medium, MediumNames } from "../../types/product";
import Switch from "../../components/Switch";
import FrameBadges from "../../components/FrameBadges";
import useLoadMoreOnScroll from "../../hooks/useLoadMoreOnScroll";
import Button from "../../components/Button";
import ArtistCard from "../../components/Card/Artist";

type SwitchType = "profiles" | "artwork";

const Artists = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobileScreen } = useAppState();
  const [artists, setArtists] = useState<User[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [selectedFrame, setSelectedFrame] = useState(Frame.Natural);
  const [selectedSwitch, setSelectedSwitch] = useState<SwitchType>("profiles");
  const [selectedTab, setSelectedTab] = useState<Medium | "all">("all");
  const [lastUser, setLastUser] = useState<QueryDocumentSnapshot<DocumentData>>();
  const mediums = Object.values(Medium);
  const mediumOptions = mediums.map((c) => ({ value: c, label: MediumNames[c] }));
  const mediumTabs = [{ label: "All", value: "all" }, ...mediumOptions];

  const fetchArtists = async (fetchMore = true) => {
    if (!fetchMore) {
      setArtists([]);
    }
    setLoading(true);
    const { users, lastVisible } = await getUsers({
      lastUser: fetchMore ? lastUser : undefined,
      orderBy: { field: "salesCount", direction: "desc" },
      filters: { hasProducts: true, ...(selectedTab === "all" ? {} : { mediums: selectedTab }) },
    });
    setHasMore(Boolean(users.length));
    if (fetchMore) {
      const currentArtists = artists ? [...artists] : [];
      const allArtists = [...currentArtists, ...users];
      setArtists(allArtists);
    } else {
      setArtists(users);
    }
    setLastUser(lastVisible);
    setLoading(false);
  };

  useLoadMoreOnScroll(ref, fetchArtists, hasMore, loading);

  useEffect(() => {
    if (!artists && !loading) {
      fetchArtists();
    }
  });

  useEffect(() => {
    if (!loading) {
      fetchArtists(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  return (
    <>
      <Helmet>
        <title>Artists | GoodMood</title>
        <meta name="description" content="Explore our creative community" />
      </Helmet>
      <div ref={ref}>
        <PageHeader header="Artists" background={colors.peach} image={artistsHero} webpImage={artistsHeroWebp}>
          We're an online creative community where you can discover, support and be inspired by your new favourite artists.
        </PageHeader>
        <Container padding={isMobileScreen ? "24px 0" : "40px 0"}>
          <Container padding={isMobileScreen ? "0 16px 24px" : "0 40px 40px"}>
            <Stack direction="row">
              <>
                <Box width={{ xs: "100%", md: "22%" }}>
                  <Switch
                    options={[
                      { label: "Profiles", value: "profiles" },
                      { label: "Artwork", value: "artwork" },
                    ]}
                    selected={selectedSwitch}
                    onChange={(value) => setSelectedSwitch(value as SwitchType)}
                    fullWidth={isMobileScreen}
                  />
                </Box>
                {!isMobileScreen && (
                  <Box width="56%">
                    <Tabs
                      onTabChange={(value) => setSelectedTab(value as Medium | "all")}
                      tabs={mediumTabs}
                      selectedTab={selectedTab}
                    />
                  </Box>
                )}
              </>
              {selectedSwitch === "artwork" && !isMobileScreen && (
                <Container width={22}>
                  <Stack direction="row" justifyContent="flex-end">
                    <FrameBadges selectedFrame={selectedFrame} setSelectedFrame={setSelectedFrame} />
                  </Stack>
                </Container>
              )}
            </Stack>
          </Container>

          {match(selectedSwitch)
            .with("profiles", () => (
              <Box padding={{ xs: 0, md: "0 40px 40px" }}>
                <Grid gap={isMobileScreen ? 16 : 24} rowGap={isMobileScreen ? 32 : 72} columns={3} evenColumns>
                  {artists?.map((artist) => (
                    <ArtistCard artist={artist} imageHeight="30vw" />
                  ))}
                </Grid>
              </Box>
            ))
            .with("artwork", () => (
              <Stack gap={{ xs: 6, md: 9 }} padding={{ xs: 0, md: "0 0 40px" }}>
                {artists?.map((artist) => (
                  <ArtistProducts artist={artist} key={artist.id} frame={selectedFrame} />
                ))}
              </Stack>
            ))
            .exhaustive()}
          {loading ? (
            <Loader />
          ) : hasMore ? (
            <Stack width="100%" alignItems="center" padding={5}>
              <Button onClick={() => fetchArtists(true)}>Load more</Button>
            </Stack>
          ) : null}
        </Container>
      </div>
    </>
  );
};

export default Artists;
