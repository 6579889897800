import { Link } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import Button from "../../components/Button";

const EmptyState = () => {
  return (
    <Stack alignItems="center" paddingX={{ xs: 0, md: 5 }} paddingY={{ xs: 3, md: 5 }}>
      <Box bgcolor={colors.grey02} padding={5} minWidth={{ xs: 0, md: 400 }} width={{ xs: "100%", md: "auto" }} borderRadius={4}>
        <Stack alignItems="center" gap={2}>
          <Typography variant="h2" align="center">
            Empty
          </Typography>
          <Link to="/shop" style={{ width: "100%" }}>
            <Button fullWidth>Go to shop</Button>
          </Link>
        </Stack>
      </Box>
    </Stack>
  );
};

export default EmptyState;
