import { ShoppingBagOutlined } from "@mui/icons-material";
import { Badge } from "@mui/material";
import BasketDrawer from "./BasketDrawer";
import { BasketFragment } from "../../generated/storefront";
import { NavItem } from "./styles";
import { colors } from "../../theme";

type BasketNavItemProps = {
  basket?: BasketFragment;
  showBasket: boolean;
  setShowBasket: (value: boolean) => void;
  closeBasket: () => void;
  itemAdded: boolean;
  basketQuantity: number;
  isMobileScreen: boolean;
  viewOnly?: boolean;
  navTransparent: boolean;
};

const BasketNavItem = ({
  basket,
  showBasket,
  setShowBasket,
  closeBasket,
  itemAdded,
  basketQuantity,
  isMobileScreen,
  viewOnly,
  navTransparent,
}: BasketNavItemProps) => {
  return (
    <>
      <NavItem
        fontSize={14}
        color={navTransparent ? colors.white : colors.black}
        onClick={() => setShowBasket(true)}
        style={{ position: "relative" }}
      >
        <Badge badgeContent={basketQuantity} color="info">
          <ShoppingBagOutlined fontSize={isMobileScreen ? "medium" : "small"} />
        </Badge>
      </NavItem>
      <BasketDrawer open={showBasket} basket={basket} onClose={closeBasket} added={itemAdded} viewOnly={viewOnly} />
    </>
  );
};

export default BasketNavItem;
