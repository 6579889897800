import { Typography } from "@mui/material";
import { useEffect, useRef } from "react";

const AutoResizeText = ({
  text,
  maxSize = 85,
  align = "left",
}: {
  text: string;
  maxSize?: number;
  align?: "left" | "center" | "right";
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resizeText = () => {
      if (!containerRef.current || !textRef.current) return;
      const containerWidth = containerRef.current.offsetWidth;
      let newFontSize = 16; // Start with a base font size
      textRef.current.style.fontSize = `${newFontSize}px`;

      while (textRef.current.offsetWidth < containerWidth && newFontSize <= maxSize) {
        newFontSize += 1;
        textRef.current.style.fontSize = `${newFontSize}px`;
      }

      // Step back one size since it would be the first to not fit
      textRef.current.style.fontSize = `${newFontSize - 1}px`;
    };

    resizeText();
    window.addEventListener("resize", resizeText);

    return () => {
      window.removeEventListener("resize", resizeText);
    };
  }, [text, maxSize]);

  return (
    <div ref={containerRef} style={{ width: "100%", overflow: "hidden", textAlign: align }}>
      <Typography variant="h1" ref={textRef} style={{ whiteSpace: "nowrap", display: "inline-block" }}>
        {text}
      </Typography>
    </div>
  );
};

export default AutoResizeText;
