import styled from "styled-components";
import { inputStyles, labelStyles, Variant } from "./styles";

export type Props = {
  label?: string;
  placeholder?: string;
  min?: number;
  value?: number | string;
  onChange: (event: any) => void;
  name?: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledLabel = styled.label`
  ${labelStyles(Variant.Standard)}
`;

const StyledNumberInput = styled.input<{ width?: number }>`
  ${inputStyles(Variant.Standard)}
  width: 100%;
`;

const NumberInput = ({ label, placeholder, min, name, onChange, value }: Props) => {
  return (
    <Wrapper>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledNumberInput placeholder={placeholder} type="number" min={min} name={name} onChange={onChange} value={value} />
    </Wrapper>
  );
};

export default NumberInput;
