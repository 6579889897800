import styled from "styled-components";
import Select from "react-select";
import { Typography } from "@mui/material";
import { inputStyles, labelStyles, Variant } from "./styles";
import { colors } from "../../theme";

type Value = string | number;
type Option = { value: Value; label: string };

export type Props = {
  label?: string;
  name?: string;
  options: Option[];
  defaultOptions?: Option[];
  defaultValue?: Option;
  onChange: (option: any) => void;
  size?: "small" | "large";
  isMulti?: boolean;
  placeholder?: string;
  value?: Option[] | Option;
  width?: string;
  disabled?: boolean;
  isSearchable?: boolean;
  error?: string;
} & { style?: any };

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  svg {
    color: ${colors.grey60};
  }
`;

const StyledLabel = styled.label`
  ${labelStyles(Variant.Standard)}
`;

const input = inputStyles(Variant.Standard);

const selectStyles = (size: "small" | "large", width?: string) => ({
  control: (styles: any) => ({
    ...styles,
    input,
    minWidth: width ? "none" : "200px",
    width: width || "auto",
    minHeight: "48px",
    boxShadow: 0,
    cursor: "pointer",
    borderRadius: size === "small" ? "100px !important" : 0,
  }),
  placeholder: (styles: any) => ({ ...styles, color: colors.grey20 }),
  valueContainer: (styles: any) => ({ ...styles, paddingLeft: "16px" }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: (styles: any) => ({ ...styles, paddingRight: "8px" }),
  option: (styles: any) => {
    return {
      ...styles,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      height: "48px",
    };
  },
});

const Dropdown = ({
  value,
  name,
  label,
  options,
  onChange,
  defaultOptions,
  defaultValue,
  size = "large",
  placeholder,
  isMulti,
  width,
  isSearchable = false,
  disabled = false,
  style,
  error,
}: Props) => {
  return (
    <Wrapper style={style}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <Select
        value={value}
        options={options}
        isMulti={isMulti}
        styles={selectStyles(size, width)}
        onChange={onChange}
        defaultValue={defaultOptions || defaultValue}
        placeholder={placeholder}
        isSearchable={isSearchable}
        name={name}
        isDisabled={disabled}
      />
      {error && (
        <Typography fontSize={14} color="error" component="span" margin="4px 0 0">
          {error}
        </Typography>
      )}
    </Wrapper>
  );
};

export default Dropdown;
