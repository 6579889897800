import { useContext, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { capitalize } from "lodash";
import { Frame, Size, SizeScale } from "../../types/product";
import { Container } from "../../components/Layout";
import { formattedPrice } from "../../helpers/money";
import Button from "../../components/Button";
import { User } from "../../types/user";
import RadioButton from "../../components/Radio/button";
import { useAppState } from "../../state";
import { isNextMonth } from "../../helpers/time";
import { media } from "../../helpers/layout";
import { colors } from "../../theme";
import AuthContext from "../../state/auth";
import { getAvailableSizes, sizeLabelsForCountry } from "../../helpers/product";
import InfoAccordion from "./InfoAccordion";
import { giftCardId } from "../../state/constants";
import { ProductPageProductFragment } from "../../generated/storefront";
import Cards from "./Cards";
import useBasketActions from "../../hooks/useBasketActions";
import FrameBadges from "../../components/FrameBadges";
import WishlistBookmark from "../../components/WishlistBookmark";

export type CheckoutState = {
  quantity: number;
  size?: Size;
  frame?: Frame;
  denominations?: string;
} & { [key: string]: string | number };

type Props = {
  product: ProductPageProductFragment;
  artist?: User | null;
  state: CheckoutState;
  setState: (state: CheckoutState) => void;
  setEdited: (edited: boolean) => void;
};

const StickySection = styled(Container)<{ sticky: boolean; navHeight: number }>`
  position: ${(p) => (p.sticky ? "sticky" : "relative")};
  top: ${(p) => (p.sticky ? `${p.navHeight}px` : "0")};
  z-index: 1;
  background: ${colors.white};

  @media ${media.m} {
    position: relative;
    top: 0;
  }
`;

const SideBar = ({ product, artist, state, setState, setEdited }: Props) => {
  const { isAdmin, customer, refetchCustomer } = useContext(AuthContext);
  const { selectedCountry, navHeight, isMobileScreen } = useAppState();
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const variants = product.variants.nodes;
  const { addOrCreateBasket, loading, error } = useBasketActions();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const popoverOpen = Boolean(anchorEl);
  const selectedVariant = variants.find((variant) =>
    variant.selectedOptions.every((option) => {
      return state[option.name] === option.value;
    })
  );

  const addToBasket = () => {
    if (selectedVariant) {
      const lines = [
        {
          merchandiseId: selectedVariant.id,
          quantity: state.quantity,
        },
      ];
      addOrCreateBasket(lines);
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
    setEdited(true);
  };

  const handleFrameChange = (frame: Frame) => {
    setState({ ...state, frame });
  };

  useEffect(() => {
    const sizeOptions = variants.map((v) => v.selectedOptions.find((o) => o.name === "size")?.value as Size);
    const defaultSize = sizeOptions.find((o) => SizeScale[o] === 1);
    if (defaultSize) {
      setState({ ...state, size: defaultSize, frame: Frame.Natural });
    } else {
      const defaultVariant = variants[0].selectedOptions;
      const { name, value } = defaultVariant[0];
      setState({ ...state, [name]: value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants]);

  const giftCard = product.id === giftCardId;
  // const showDiscountedPrices = Boolean(basket?.lines.nodes.length && basket?.lines.nodes.length > 0);
  const showDiscountedPrices = true;
  const currencyCode = product.variants.nodes[0].price.currencyCode;

  if (giftCard) {
    return (
      <Stack width={{ xs: "100%", md: "33%" }}>
        <StickySection sticky={!itemIsOpen} navHeight={navHeight}>
          <Box paddingX={{ xs: 2, md: 3 }} paddingTop={{ xs: 1.5, md: 0 }}>
            <Stack gap={2} height="100%">
              <Stack gap={1}>
                <Typography fontSize={{ xs: 12, md: 16 }}>Amount</Typography>
                <Stack onChange={handleChange} direction="row" flexWrap="wrap" gap={1}>
                  {variants.map((v) => {
                    const denomationValue = formattedPrice(v.price.amount, currencyCode);
                    return (
                      <RadioButton
                        name="denominations"
                        key={denomationValue}
                        label={denomationValue}
                        id={v.price.amount}
                        checked={state.denominations === denomationValue}
                        value={denomationValue}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <Stack paddingBottom={3} gap={1.5}>
                <Button fullWidth onClick={addToBasket} disabled={!state.denominations} loading={loading} size="large">
                  Add to basket
                </Button>
                {error && <Typography color={colors.error}>{error}</Typography>}
              </Stack>
            </Stack>
          </Box>
        </StickySection>
      </Stack>
    );
  }

  const sizeLabels = sizeLabelsForCountry(selectedCountry);
  const availabileSizes = getAvailableSizes(product.variants.nodes);

  if (!selectedVariant) return null;

  return (
    <Stack width={{ xs: "100%", md: "33%" }}>
      <Stack gap={2} height="100%">
        {artist && (
          <Box paddingX={{ xs: 2, md: 3 }} paddingTop={{ xs: 1.5, md: 0 }}>
            <Stack justifyContent="space-between" direction="row" gap={3}>
              <Stack gap={0.5}>
                {isMobileScreen ? (
                  <Typography component="h1">{product.title}</Typography>
                ) : (
                  <Stack gap={1} direction="row" alignItems="center">
                    <Typography variant="h2" component="h1">
                      {product.title}
                    </Typography>
                  </Stack>
                )}

                <Link to={`/artists/${artist.permalink || artist.id}`}>
                  <Typography fontSize={{ xs: 14, md: 16 }} style={{ textDecoration: "underline" }}>
                    {artist.firstName} {artist.lastName}
                  </Typography>
                </Link>
              </Stack>
              {isMobileScreen && (
                <Stack gap={0.5} alignItems="end">
                  <Stack gap={1.5} direction="row">
                    <Typography
                      style={{ whiteSpace: "nowrap", textDecoration: showDiscountedPrices ? "line-through" : "none" }}
                      color={showDiscountedPrices ? "text.disabled" : "primary"}
                      align="right"
                    >
                      {formattedPrice(selectedVariant.price.amount, currencyCode)}
                    </Typography>
                    {showDiscountedPrices && (
                      <Typography color={colors.red} align="right" style={{ whiteSpace: "nowrap" }}>
                        {formattedPrice(Number(selectedVariant.price.amount) * 0.75, currencyCode)}
                      </Typography>
                    )}
                  </Stack>
                  <WishlistBookmark
                    productId={product.id}
                    customer={customer}
                    refetchCustomer={refetchCustomer}
                    popoverOpen={popoverOpen}
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                  />
                </Stack>
              )}
            </Stack>
          </Box>
        )}
        {isNextMonth(product.productType) && !isAdmin ? (
          <Box paddingX={{ xs: 2, md: 3 }}>
            <Typography>This product is coming in next month's collection</Typography>
          </Box>
        ) : (
          <>
            <StickySection sticky={!itemIsOpen} navHeight={navHeight}>
              <Box paddingX={{ xs: 2, md: 3 }}>
                <Stack gap={2} height="100%">
                  <Stack gap={1}>
                    <Typography fontSize={{ xs: 12, md: 14 }}>Frame: {capitalize(state.frame)}</Typography>
                    <FrameBadges selectedFrame={state.frame || Frame.Natural} setSelectedFrame={handleFrameChange} />
                  </Stack>

                  <Stack gap={3}>
                    <Stack gap={1}>
                      <Typography fontSize={{ xs: 12, md: 14 }}>
                        Size:{" "}
                        {state.size
                          ? sizeLabels[state.size][
                              state.frame === Frame.Unframed ? ("printMeasurements" as const) : ("framedMeasurements" as const)
                            ]
                          : ""}
                      </Typography>
                      <Stack onChange={handleChange} direction="row" flexWrap="wrap" gap={1}>
                        {availabileSizes.map((size) => (
                          <RadioButton
                            name="size"
                            key={size}
                            label={sizeLabels[size].shortHand}
                            id={size}
                            checked={state.size === size}
                            value={size}
                          />
                        ))}
                      </Stack>
                    </Stack>

                    {!isMobileScreen && (
                      <Stack gap={1.5} direction="row" alignItems="center">
                        <Typography
                          color={showDiscountedPrices ? "text.disabled" : "primary"}
                          style={{ whiteSpace: "nowrap", textDecoration: showDiscountedPrices ? "line-through" : "none" }}
                        >
                          {formattedPrice(selectedVariant.price.amount, currencyCode)}
                        </Typography>
                        {showDiscountedPrices && (
                          <Typography color={colors.red}>
                            {formattedPrice(Number(selectedVariant.price.amount) * 0.75, currencyCode)} · 25% OFF
                          </Typography>
                        )}
                      </Stack>
                    )}
                  </Stack>
                  <Stack paddingBottom={1} gap={1.5}>
                    <Button
                      fullWidth
                      onClick={addToBasket}
                      disabled={!(state.size && state.frame && state.quantity)}
                      loading={loading}
                      size="large"
                    >
                      Add to basket
                    </Button>
                    <Typography variant="caption" align="center" color="text.secondary" fontSize={{ xs: 12, md: 14 }}>
                      No import fees for UK, EU, AUS & USA
                    </Typography>
                  </Stack>
                </Stack>
              </Box>
            </StickySection>

            <div>
              <Cards />
              <InfoAccordion product={product} artist={artist} setItemIsOpen={setItemIsOpen} />
            </div>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default SideBar;
