import WebpImage from "react-image-webp";
import { match } from "ts-pattern";
import styled from "styled-components";
import { Frame, Ratio, TShirtSize } from "../../types/product";
import { getFrameImages } from "./helpers";
import { Wrapper } from "./styles";
import LazyLoadImage from "../Image/LazyLoadImage";

type Props = {
  image: {
    src: string;
    altText?: string | null;
    height?: number | null;
    width?: number | null;
  };
  frame: Frame;
  fit: "fixed" | "fill";
  size?: TShirtSize;
  imageLoaded?: boolean;
  setImageLoaded?: (loaded: boolean) => void;
};

const FrameImage = styled(WebpImage)<{ unframed?: boolean }>`
  z-index: 1;
  object-fit: contain;
  width: 100%;
  height: 100%;
  transition: height 0.5s ease;
  ${(p) => p.unframed && `visibility: hidden;`}
`;

const MockUpImage = styled(LazyLoadImage)<{ isLandscape: boolean; frameSize: TShirtSize; onLoad?: () => void }>`
  position: absolute;
  object-fit: contain;
  height: 96%;
  width: 95%;
  ${(p) => p.isLandscape && `width: 96%; height: 94%;`}
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FrameMockUp = ({ image, frame, fit, size, imageLoaded = true, setImageLoaded }: Props) => {
  if (!image.width || !image.height) return null;
  const imageRatio = image.width / image.height;
  const ratio = imageRatio === 1 ? Ratio.Square : Ratio.Rectangle;
  const isLandscape = image.height < image.width;
  const frameSize = size || (isLandscape ? TShirtSize.Medium : TShirtSize.Large);
  const frameImages = getFrameImages(ratio, frame, frameSize, isLandscape);

  return match(fit)
    .with("fill", () => {
      return (
        <Wrapper style={{ width: "100%", height: "auto" }}>
          {imageLoaded && (
            <FrameImage src={frameImages.src} webp={frameImages.webp} alt="frame" unframed={frame === Frame.Unframed} />
          )}
          <MockUpImage
            src={image.src}
            alt="print"
            isLandscape={isLandscape}
            frameSize={frameSize}
            onLoad={() => setImageLoaded && setImageLoaded(true)}
          />
        </Wrapper>
      );
    })
    .with("fixed", () => {
      const height = isLandscape ? 70 : 90;
      return (
        <Wrapper style={{ width: "auto", height: `${height}%`, maxWidth: "90%", alignItems: "center" }}>
          {imageLoaded && (
            <FrameImage src={frameImages.src} webp={frameImages.webp} alt="frame" unframed={frame === Frame.Unframed} />
          )}
          <MockUpImage
            src={image.src}
            alt="print"
            isLandscape={isLandscape}
            frameSize={frameSize}
            onLoad={() => setImageLoaded && setImageLoaded(true)}
          />
        </Wrapper>
      );
    })
    .exhaustive();
};

export default FrameMockUp;
