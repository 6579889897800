import { useState } from "react";
import { Helmet } from "react-helmet";
import { match } from "ts-pattern";
import { colors } from "../../theme";
import PageHeader from "../../components/PageHeader";
import contact from "../../assets/images/contact.png";
import { Container, Flex } from "../../components/Layout";
import { useAppState } from "../../state";
import Tabs from "../../components/Tabs";
import ArtistTerms from "./Artist";
import BuyerTerms from "./Buyer";
import TermsOfUse from "./Use";
import AcceptableTerms from "./Acceptable";

const tabs = [1, 2, 3, 4] as const;
export type Tab = (typeof tabs)[number];

const Terms = () => {
  const { isMobileScreen } = useAppState();
  const [selectedTab, setSelectedTab] = useState<Tab>(1);

  const onTabChange = (tab: number | string) => {
    const isTab = (tab: number | string): tab is Tab => tabs.includes(tab as Tab);

    if (isTab(tab)) {
      setSelectedTab(tab);
    }
  };

  return (
    <>
      <Helmet>
        <title>Terms & Conditions | GoodMood</title>
        <meta
          name="description"
          content="GoodMood Prints is a platform that connects artists and buyers. Find out more about our terms and conditions."
        />
      </Helmet>
      <PageHeader header="Terms & Conditions" background={colors.blue} image={contact} />
      <Container padding="40px 0 300px">
        <Flex justifyContent="center">
          <Container padding="40px 24px" style={{ overflow: "auto" }}>
            <Tabs
              onTabChange={onTabChange}
              selectedTab={selectedTab}
              tabs={[
                { value: 1, label: "Terms for Artists" },
                { value: 2, label: "Terms for Buyers" },
                { value: 3, label: "Terms of Use" },
                { value: 4, label: "Acceptable Use Policy" },
              ]}
            />
          </Container>
        </Flex>
        <Flex gap={72} justifyContent="center" alignItems="center" direction="column">
          <Container width={isMobileScreen ? 100 : 50} padding={isMobileScreen ? "0 24px" : ""}>
            <Flex gap={16} direction="column">
              {match(selectedTab)
                .with(1, () => <ArtistTerms setSelectedTab={setSelectedTab} />)
                .with(2, () => <BuyerTerms setSelectedTab={setSelectedTab} />)
                .with(3, () => <TermsOfUse setSelectedTab={setSelectedTab} />)
                .with(4, () => <AcceptableTerms setSelectedTab={setSelectedTab} />)
                .exhaustive()}
            </Flex>
          </Container>
        </Flex>
      </Container>
    </>
  );
};

export default Terms;
