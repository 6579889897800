import styled from "styled-components";
import { match } from "ts-pattern";
import { Text } from "../../../../components/Text";
import { colors } from "../../../../theme";
import { InvoiceStatus } from "../../../../types/invoice";

type Props = {
  status: InvoiceStatus;
};

const Status = styled.div<{ color: string }>`
  display: inline-block;
  background: ${(p) => p.color};
  border-radius: 40px;
  padding: 4px 12px;
`;

const StatusPill = ({ status }: Props) => {
  return match(status)
    .with(InvoiceStatus.APPROVED, () => (
      <Status color={colors.mint}>
        <Text align="center">Approved</Text>
      </Status>
    ))
    .with(InvoiceStatus.DRAFT, () => (
      <Status color={colors.peach}>
        <Text align="center">Draft</Text>
      </Status>
    ))
    .with(InvoiceStatus.PAID, () => (
      <Status color={colors.blue}>
        <Text align="center">Paid</Text>
      </Status>
    ))
    .exhaustive();
};

export default StatusPill;
