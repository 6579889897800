import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { match } from "ts-pattern";
import { useAppState } from "../../state";
import { appendArtistsToProducts } from "../../helpers/product";
import { ProductCardProductFragment, ProductCollectionSortKeys, useGetOurShopProductsQuery } from "../../generated/storefront";
import { User } from "../../types/user";
import { Medium, Size, SortBy } from "../../types/product";

type ProductsWithArtist = ProductCardProductFragment & { artist: User | null };

export const useShop = ({ handle, variantLimit }: { handle?: string; variantLimit?: number }) => {
  const { selectedCountry } = useAppState();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const sortBy = searchParams.get("sort");
  const month = searchParams.get("month");
  const medium = searchParams.get("medium");
  const size = searchParams.get("size");
  const style = searchParams.get("style");
  const subject = searchParams.get("subject");
  const orientation = searchParams.get("orientation");

  const sortKey = match(sortBy)
    .with(SortBy.BestSelling, () => ProductCollectionSortKeys.BestSelling)
    .with(SortBy.PriceHighToLow, () => ProductCollectionSortKeys.Price)
    .with(SortBy.PriceLowToHigh, () => ProductCollectionSortKeys.Price)
    .with(SortBy.Newest, () => ProductCollectionSortKeys.Created)
    .otherwise(() => ProductCollectionSortKeys.BestSelling);

  const reverse = sortBy === SortBy.PriceHighToLow || sortBy === SortBy.Newest;
  const mediums = (medium?.split(",") || []) as Medium[];
  const styles = style?.split(",") || [];
  const subjects = subject?.split(",") || [];
  const sizes = (size?.split(",") || []) as Size[];
  const orientations = orientation?.split(",") || [];
  const months = month?.split(",") || [];

  const [products, setProducts] = useState<ProductsWithArtist[]>();

  const filters = [
    ...months.map((m) => ({ productType: m })),
    ...sizes.map((s) => ({ variantOption: { name: "size", value: s } })),
    ...orientations.map((o) => ({ productMetafield: { namespace: "custom", key: "orientation", value: o } })),
    ...mediums.map((m) => ({ productMetafield: { namespace: "custom", key: "medium", value: m } })),
    ...styles.flatMap((s) => [{ tag: s }, { tag: `gm.${s}` }]),
    ...subjects.flatMap((s) => [{ tag: s }, { tag: `gm.${s}` }]),
  ];

  const { data, loading, refetch, fetchMore } = useGetOurShopProductsQuery({
    variables: {
      handle,
      sortKey,
      reverse,
      filters,
      variantLimit,
      limit: 24,
      country: selectedCountry,
    },
  });

  const afterCursor = data?.collection?.products.pageInfo.endCursor;
  const hasMore = data?.collection?.products.pageInfo.hasNextPage || false;
  const collectionTitle = data?.collection?.title || "";
  const collectionDescription = data?.collection?.description || "";

  const loadMore = async () => {
    fetchMore({
      variables: {
        afterCursor,
      },
    });
  };

  const clearFilters = () => {
    const newParams = new URLSearchParams();
    setSearchParams(newParams, { preventScrollReset: true });
    if (window.scrollY > 650) window.scrollTo(0, 650);
  };

  useEffect(() => {
    const getProductsArtists = async () => {
      if (data?.collection) {
        const productsWithArtists = await appendArtistsToProducts(data.collection.products.nodes);
        setProducts(productsWithArtists);
      }
    };
    getProductsArtists();
  }, [data]);

  return {
    collectionTitle,
    collectionDescription,
    products,
    searchQuery: search,
    loadMore,
    refetch,
    clearFilters,
    loading,
    hasMore,
  };
};
