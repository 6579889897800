import { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { colors } from "../../theme";
import { Icon, IconType } from "../Icon";
import { Text } from "../Text";
import { errorStyles, inputStyles, labelStyles, Variant } from "./styles";

export type Props = {
  icon?: IconType;
  label?: string;
  placeholder?: string;
  name: string;
  id: string;
  value?: string | number;
  error?: string;
  type?: string;
  variant?: Variant;
  clear?: (event: any) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 50%;
  left: 24px;
  transform: translateY(-50%);
`;

const CloseIconWrapper = styled(IconWrapper)`
  left: auto;
  right: 24px;
`;

const StyledLabel = styled.label<{ variant: Variant; error: boolean }>`
  ${(p) => labelStyles(p.variant)}

  ${(p) =>
    p.error &&
    `
    ${errorStyles}
  `}
`;

const StyledInput = styled.input<Omit<Props, "variant"> & { variant: Variant }>`
  ${(p) => inputStyles(p.variant)}

  ${(p) =>
    p.error &&
    `
    ${errorStyles}
  `}
 
  ${(p) =>
    p.icon &&
    `
    padding-left: 48px;
  `}
`;

const Input = ({ icon, label, error, variant = Variant.Standard, type, clear, ...props }: Props) => {
  return (
    <Wrapper {...props}>
      {label && (
        <StyledLabel variant={variant} error={Boolean(error)}>
          {label}
        </StyledLabel>
      )}
      <InputWrapper>
        {icon && (
          <IconWrapper>
            <Icon icon={icon} fill={colors.grey40} />
          </IconWrapper>
        )}
        <StyledInput icon={icon} type={type || "text"} error={error} variant={variant} {...props} />
        {clear && props.value && (
          <CloseIconWrapper onClick={clear}>
            <Icon icon="close" fill={colors.grey40} />
          </CloseIconWrapper>
        )}
      </InputWrapper>
      {error && (
        <Text size={14} color={colors.error} as="span" margin="4px 0">
          {error}
        </Text>
      )}
    </Wrapper>
  );
};

export default Input;
